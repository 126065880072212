//@ts-nocheck
import { createSelector } from 'reselect';
import { getByPatientId } from 'redux/loyaltyStatus/selectors';
import { Store } from 'types/store';

export const loyaltyIKBSelector = (state: Store.App.State) => state.loyaltyIKB;

export const sendActivationPinSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.sendActivationPin);

export const activationSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.activation);

export const activateAccountSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.activateAccount);

export const categoriesDataSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.categoriesData);

export const categoriesSelector = createSelector([categoriesDataSelector], (categoriesData) => categoriesData.categories || []);

export const solutionsSelector = createSelector([categoriesSelector], (categories) =>
    categories.filter((solution) => solution.maxFavoriteProducts <= 0 && !solution.multiplicator)
);

export const mainCategorySelector = createSelector([categoriesSelector], (categories) => categories.find((category) => category.maxFavoriteProducts > 0));

export const maxCountSelectedServicesSelector = createSelector([mainCategorySelector], (mainCategory) => mainCategory?.maxFavoriteProducts as number);

export const servicesSelector = createSelector([mainCategorySelector], (mainCategory) => mainCategory?.favoriteProducts || []);

export const fetchCategoriesSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.fetchCategories);

export const cashbackStatusSelector = createSelector([loyaltyIKBSelector], (loyaltyIKB) => loyaltyIKB.cashbackStatus);

export const dataLoyaltyIKBSelector = createSelector([getByPatientId], (patientEntities) => ({
    loyaltyStatus: patientEntities?.loyaltyStatus,
    loadingLoyaltyStatus: patientEntities?.loading,
    errorLoyaltyStatus: patientEntities?.error
}));
