import React, { FC, Fragment, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import Card from 'page/auth/components/cards/Card';
import Timer from 'components/timer/Timer';
import Error from 'page/auth/components/error/Error';
import PageLayout from 'page/layout/PageLayout';
import { LoginType } from 'constants/loginType';
import styles from 'page/auth/Common.module.css';
import { getTimeToResend } from 'utils/timeUtils';
import messages from './ReactivationPageMessages';
import commonMessages from 'messages/CommonMessages';
import ServerError from 'page/auth/components/cards/ServerError';
import ButtonLink from 'components/button/link/ButtonLink';
import LoaderModal from 'components/modal/loader/LoaderModal';
import InputSplitted from 'components/input/splitted/InputSplitted';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { reactivationSelector, reactivationConfirmSelector } from 'redux/auth/selectors';
import { login as handleLogin, reactivation, reactivationConfirm, resetAuthState } from 'redux/auth/actions';
import { WRONG_CODE } from 'redux/auth/errorCodes';
import Instruction from './Instruction';

interface IState {
    state: {
        loginType: LoginType;
        login: string;
        confirmedLoginToken: string;
        expired: boolean;
    };
}

const ReactivationPage: FC = () => {
    const { state }: IState = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();

    const reactivationData = useAppSelector(reactivationSelector);
    const reactivationConfirmData = useAppSelector(reactivationConfirmSelector);
    const { territory } = useAppSelector(currentTerritorySettingsSelector);

    useEffect(() => {
        document.title = formatMessage(messages.pageTitle);
    }, [formatMessage]);

    useEffect(() => {
        const { confirmedLoginToken } = state || {};

        if (!confirmedLoginToken) {
            return navigate('/', { replace: true });
        }

        dispatch(reactivation({ territory, confirmedLoginToken }));

        return () => {
            dispatch(resetAuthState());
        };
    }, [dispatch, navigate, state, territory]);

    const renderResendButton = (formatted: string, expired: boolean) => {
        const { loginType, login } = state || {};

        return (
            <ButtonLink className={styles.resend} disabled={!expired} onClick={() => dispatch(handleLogin({ territory, login }))}>
                {!expired
                    ? loginType === LoginType.PHONE
                        ? formatMessage(commonMessages.requestSMSCodeAgainAfterTime, { time: formatted })
                        : formatMessage(commonMessages.requestCodeAgainAfterTime, { time: formatted })
                    : null}

                {expired
                    ? loginType === LoginType.PHONE
                        ? formatMessage(commonMessages.requestSMSCodeAgain)
                        : formatMessage(commonMessages.requestCodeAgain)
                    : null}
            </ButtonLink>
        );
    };

    const handleCodeSubmission = (code: string) => {
        const { confirmedLoginToken } = state || {};

        dispatch(reactivationConfirm({ confirmedLoginToken, code }));
    };

    const handleSuccessClick = () => {
        navigate('/login/password', {
            state: { confirmedLoginToken: reactivationConfirmData.response.confirmedLoginToken }
        });
    };

    return (
        <PageLayout
            backLink='/'
            main={
                <div className={styles.main}>
                    <LoaderModal show={reactivationData.loading || reactivationConfirmData.loading} />
                    <div className={styles.container}>
                        <ServerError errors={[reactivationData.error, reactivationConfirmData.error]} statuses={[WRONG_CODE]}>
                            <Fragment>
                                {!reactivationConfirmData.success && (
                                    <Fragment>
                                        <Instruction loginType={state?.loginType} login={state?.login} />
                                        <InputSplitted length={6} error={reactivationConfirmData.error} done={handleCodeSubmission} />
                                        <Timer seconds={getTimeToResend(reactivationData, reactivationConfirmData)}>
                                            {({ formatted, expired }: any) => renderResendButton(formatted, expired)}
                                        </Timer>
                                    </Fragment>
                                )}
                                <Error error={reactivationData.error} />
                                <Error error={reactivationConfirmData.error} attemptsNotice={commonMessages.attemptsNoticeCode} />
                                <Card
                                    icon='success'
                                    show={reactivationConfirmData.success}
                                    title={formatMessage(messages.cardTitle)}
                                    description={formatMessage(messages.cardDescription)}
                                    textButton={formatMessage(commonMessages.buttonContinue)}
                                    onClick={handleSuccessClick}
                                />
                            </Fragment>
                        </ServerError>
                    </div>
                </div>
            }
        />
    );
};

export default memo(ReactivationPage);
