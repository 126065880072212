import { defineMessages } from 'react-intl';

export default defineMessages({
    info: {
        id: 'IKBCashbackPhoneEntryPage.info',
        defaultMessage: 'To continue, enter the phone number that you want to link to your bonus account'
    },
    phone: {
        id: 'IKBCashbackPhoneEntryPage.phone',
        defaultMessage: 'Phone number'
    },
    newPhone: {
        id: 'IKBCashbackPhoneEntryPage.newPhone',
        defaultMessage: 'Add new phone number'
    },
    placeholder: {
        id: 'IKBCashbackPhoneEntryPage.placeholder',
        defaultMessage: '+7 999 999 99 99'
    },
    error: {
        id: 'IKBCashbackPhoneEntryPage.error',
        defaultMessage: 'Wrong code entered'
    },
    sendCode: {
        id: 'IKBCashbackPhoneEntryPage.sendCode',
        defaultMessage: 'Resend code'
    }
});
