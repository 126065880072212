import React, { FC, memo, ReactElement, useEffect } from 'react';
import styles from './IKBNavigationBar.module.css';
import linkBackIcon from '../assets/linkBackIcon.svg';
import closePageIcon from '../assets/closePageIcon.svg';
import { useNavigate } from 'react-router-dom';

type TProps = {
    title: string;
    back: boolean;
    close?: boolean;
    children?: ReactElement;
    link?: string;
};

const IKBNavigationBar: FC<TProps> = ({ title, back, close, children, link }) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleOnClick = (link?: string) => {
        link ? navigate(link) : navigate(-1);
    };

    return (
        <div className={styles.container}>
            <div className={styles.topBlock}>
                {back && (
                    <button className={styles.back} type='button' onClick={() => handleOnClick(link)}>
                        <img alt='' src={linkBackIcon} />
                    </button>
                )}
                {title && <h1 className={styles.title}>{title}</h1>}
                {close && (
                    <button className={styles.close} type='button' onClick={() => handleOnClick(link)}>
                        <img alt='' src={closePageIcon} />
                    </button>
                )}
            </div>
            {children && <div>{children}</div>}
        </div>
    );
};

export default memo(IKBNavigationBar);
