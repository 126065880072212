//@ts-nocheck
import queryString from 'query-string';
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import { IKBOperation } from 'constants/ikbOperation';

const LOYALTY_URL = `${BASE_URL}/patients`;

export const fetchLoyaltyStatus = (data) => {
    const { patientId, territory } = data;
    return apiClient.get(`${LOYALTY_URL}/${patientId}/loyalty/status?${queryString.stringify({ territory })}`);
};

export const fetchBonusHistory = (data) => {
    const { patientId, ...params } = data;
    return apiClient.get(`${LOYALTY_URL}/${patientId}/loyalty/bonuses/history?${queryString.stringify(params)}`);
};

export const fetchDiscountHistory = (data) => {
    const { patientId, ...params } = data;
    return apiClient.get(`${LOYALTY_URL}/${patientId}/loyalty/discounts/history?${queryString.stringify(params)}`);
};

export const sendActivationPin = (data) => {
    const { patientId, territory, number, phone } = data;
    let sendActivationPin = new IKBOperationRequest(patientId, territory, IKBOperation.SEND_ACTIVATION_PIN, { number, phone });
    return apiClient.post(`${LOYALTY_URL}/${patientId}/loyalty/apply/operation`, sendActivationPin.getRequestData());
};

export const activateAccount = (data) => {
    const { patientId, territory, number, pin } = data;
    let activateAccount = new IKBOperationRequest(patientId, territory, IKBOperation.BONUS_ACTIVATION, { number, pin });
    return apiClient.post(`${LOYALTY_URL}/${patientId}/loyalty/apply/operation`, activateAccount.getRequestData());
};

export const fetchCategories = (data) => {
    const { patientId, territory, positionFrom, positionTo } = data;
    let findCategories = new IKBOperationRequest(patientId, territory, IKBOperation.FIND_CATEGORIES, { positionFrom, positionTo });
    return apiClient.post(`${LOYALTY_URL}/${patientId}/loyalty/apply/operation`, findCategories.getRequestData());
};

export const changeCategory = (data) => {
    const { patientId, territory, number, categoryId, favoriteProductIds } = data;
    let changeCategory = new IKBOperationRequest(patientId, territory, IKBOperation.BONUS_CATEGORY_CHANGE, { number, categoryId, favoriteProductIds });
    return apiClient.post(`${LOYALTY_URL}/${patientId}/loyalty/apply/operation`, changeCategory.getRequestData());
};

export const fetchMotivatingTooltip = (data) => {
    const { patientId, territory } = data;
    return apiClient.get(`${LOYALTY_URL}/${patientId}/loyalty/show-tooltip-ikb?${queryString.stringify({ territory })}`);
};

export const fetchCheckups = (data) => {
    const { patientId, ...params } = data;
    return apiClient.get(`${LOYALTY_URL}/${patientId}/loyalty/checkups?${queryString.stringify(params)}`);
};

export const activateCheckup = (data) => {
    return apiClient.post(`${LOYALTY_URL}/${data.patientId}/loyalty/checkups/personify`, data);
};

export const applyCoupon = (data) => {
    return apiClient.post(`${LOYALTY_URL}/${data.patientId}/loyalty/coupons/apply`, data);
};

class IKBOperationRequest {
    constructor(patientId, territory, operation, data) {
        this.patientId = patientId;
        this.territory = territory;
        this.operation = operation;
        this.data = data;
    }

    getRequestData() {
        return {
            patientId: this.patientId,
            territory: this.territory,
            operation: this.operation,
            data: {
                type: this.operation,
                ...this.data
            }
        };
    }
}
