//@ts-nocheck
import { ClassifierType } from 'constants/classifierType';

const testsType = [ClassifierType.TEST, ClassifierType.PROFILE, ClassifierType.SAMPLE_PREPARATION];
const medicalAppointmentsType = [ClassifierType.DOCTOR_LABORATORY_SERVICE, ClassifierType.MEDICAL_APPOINTMENT, ClassifierType.DOCTOR_MANIPULATION];
const medicalServicesType = [
    ClassifierType.NURSE_LABORATORY_SERVICE,
    ClassifierType.MEDICAL_SERVICE,
    ClassifierType.INJECTION,
    ClassifierType.VACCINATION,
    ClassifierType.NURSE_MANIPULATION
];

export const checkClassifierType = {
    tests: (type) => testsType.includes(type),
    medicalAppointments: (type) => medicalAppointmentsType.includes(type),
    medicalServices: (type) => medicalServicesType.includes(type)
};

export const isNotServiceOrCheckupType = (product) =>
    !product.classifierData || (product.classifierData?.classifierType !== ClassifierType.CHECKUP && !product.classifierData?.service);

export const isCheckup = (product) => product?.id && product?.classifierData?.classifierType === ClassifierType.CHECKUP;

export const isService = (product) => product?.id && product?.classifierData?.service;

export const isVndLogistic = (product) => product?.id && product?.classifierData?.classifierType === ClassifierType.VND_LOGISTIC;
