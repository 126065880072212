import { defineMessages } from 'react-intl';

export default defineMessages({
    location: {
        id: 'Cities.location',
        defaultMessage: 'Your city:'
    },
    hintLocation: {
        id: 'Cities.hintLocation',
        defaultMessage: 'You are in the city'
    },
    confirmLocation: {
        id: 'Cities.confirmLocation',
        defaultMessage: 'Yeah right'
    },
    chooseAnother: {
        id: 'Cities.chooseAnother',
        defaultMessage: 'Choose another'
    },
    notice: {
        id: 'Cities.notice',
        defaultMessage: 'Prices and payment methods depend on the selected city.'
    }
});
