import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from './Switch.module.css';
const cx = classNames.bind(styles);

type TProps = { onClick: () => void; className?: string; active: boolean };

const Switch: FC<TProps> = ({ onClick, active, className, ...rest }) => {
    return (
        <div
            className={cx({
                switchContainer: true,
                switchActive: active,
                switchInactive: !active
            })}
            onClick={() => onClick()}
            {...rest}
        >
            <div className={styles.switch}></div>
        </div>
    );
};
export default memo(Switch);
