//@ts-nocheck
import {
    AUTHENTICATE,
    AUTHORIZATION,
    CLEAR,
    CLEAR_CONFIRMATION,
    CLEAR_LOGIN,
    CLEAR_REACTIVATION,
    CLEAR_RECOVERY,
    CLEAR_REGISTRATION,
    LOGIN_CONFIRM,
    DEAUTHENTICATE,
    LOGIN,
    LOGOUT,
    REACTIVATION,
    REACTIVATION_CONFIRM,
    REGISTER,
    SET_ACTION_LOGIN,
    LOGIN_PASSWORD,
    RESET_AUTH_STATE,
    RECOVERY,
    RESET_PASSWORD,
    RECOVERY_CONFIRM_BY_CODE,
    RECOVERY_SET_PASSWORD,
    RECOVERY_CONFIRM_BY_INZ
} from 'redux/auth/actions';
import { basicReducer } from 'redux/helpers';

const initialState = {
    login: {},
    loginConfirm: {},
    loginPassword: {},
    registration: {},
    confirmation: {},
    recovery: {},
    recoveryConfirmByCode: {},
    recoveryConfirmByINZ: {},
    recoverySetPassword: {},
    reactivation: {},
    reactivationConfirm: {},
    resetPassword: {},
    authenticated: false,
    actionLogin: false,
    authorization: {},
    logout: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN.STARTED:
        case LOGIN.SUCCEEDED:
        case LOGIN.ERRORED: {
            return {
                ...state,
                login: basicReducer(state.login, action)
            };
        }
        case LOGIN_CONFIRM.STARTED:
        case LOGIN_CONFIRM.SUCCEEDED:
        case LOGIN_CONFIRM.ERRORED: {
            return {
                ...state,
                loginConfirm: basicReducer(state.loginConfirm, action)
            };
        }
        case LOGIN_PASSWORD.STARTED:
        case LOGIN_PASSWORD.SUCCEEDED:
        case LOGIN_PASSWORD.ERRORED: {
            return {
                ...state,
                loginPassword: basicReducer(state.loginPassword, action)
            };
        }
        case REGISTER.STARTED:
        case REGISTER.SUCCEEDED:
        case REGISTER.ERRORED: {
            return {
                ...state,
                registration: basicReducer(state.registration, action)
            };
        }
        case RECOVERY.STARTED:
        case RECOVERY.SUCCEEDED:
        case RECOVERY.ERRORED: {
            return {
                ...state,
                recovery: basicReducer(state.recovery, action)
            };
        }
        case RECOVERY_CONFIRM_BY_CODE.STARTED:
        case RECOVERY_CONFIRM_BY_CODE.SUCCEEDED:
        case RECOVERY_CONFIRM_BY_CODE.ERRORED: {
            return {
                ...state,
                recoveryConfirmByCode: basicReducer(state.recoveryConfirmByCode, action)
            };
        }
        case RECOVERY_CONFIRM_BY_INZ.STARTED:
        case RECOVERY_CONFIRM_BY_INZ.SUCCEEDED:
        case RECOVERY_CONFIRM_BY_INZ.ERRORED: {
            return {
                ...state,
                recoveryConfirmByINZ: basicReducer(state.recoveryConfirmByINZ, action)
            };
        }
        case RECOVERY_SET_PASSWORD.STARTED:
        case RECOVERY_SET_PASSWORD.SUCCEEDED:
        case RECOVERY_SET_PASSWORD.ERRORED: {
            return {
                ...state,
                recoverySetPassword: basicReducer(state.recoverySetPassword, action)
            };
        }
        case RESET_PASSWORD.STARTED:
        case RESET_PASSWORD.SUCCEEDED:
        case RESET_PASSWORD.ERRORED: {
            return {
                ...state,
                resetPassword: basicReducer(state.resetPassword, action)
            };
        }
        case REACTIVATION.STARTED:
        case REACTIVATION.SUCCEEDED:
        case REACTIVATION.ERRORED: {
            return {
                ...state,
                reactivation: basicReducer(state.reactivation, action)
            };
        }
        case REACTIVATION_CONFIRM.STARTED:
        case REACTIVATION_CONFIRM.SUCCEEDED:
        case REACTIVATION_CONFIRM.ERRORED: {
            return {
                ...state,
                reactivationConfirm: basicReducer(state.reactivationConfirm, action)
            };
        }
        case LOGOUT.STARTED:
        case LOGOUT.SUCCEEDED:
        case LOGOUT.ERRORED: {
            return {
                ...state,
                logout: basicReducer(state.logout, action)
            };
        }
        case AUTHENTICATE.BASE: {
            return {
                ...state,
                authenticated: true
            };
        }
        case DEAUTHENTICATE.BASE: {
            return {
                ...state,
                authenticated: false
            };
        }
        case CLEAR.SUCCEEDED:
            return {
                login: {},
                registration: {},
                confirmation: {},
                recovery: {},
                authenticated: false,
                actionLogin: false
            };
        case CLEAR_LOGIN.BASE:
            return {
                ...state,
                login: {}
            };
        case CLEAR_REGISTRATION.BASE:
            return {
                ...state,
                registration: {}
            };
        case CLEAR_CONFIRMATION.BASE:
            return {
                ...state,
                confirmation: {}
            };
        case CLEAR_RECOVERY.BASE:
            return {
                ...state,
                recovery: {}
            };
        case CLEAR_REACTIVATION.BASE:
            return {
                ...state,
                reactivation: {}
            };
        case SET_ACTION_LOGIN.BASE: {
            return {
                ...state,
                actionLogin: true
            };
        }
        case AUTHORIZATION.ERRORED:
            return {
                ...state,
                authorization: basicReducer(state.authorization, action)
            };
        case RESET_AUTH_STATE.BASE: {
            return {
                ...initialState,
                authenticated: state.authenticated
            };
        }
        default:
            return state;
    }
}
