import { defineMessages } from 'react-intl';

export default defineMessages({
    formLabel: {
        id: 'Coupons.formLabel',
        defaultMessage: 'Coupon'
    },
    couponHint: {
        id: 'Coupons.couponHint',
        defaultMessage: 'You can apply multiple coupons in one order.'
    },
    couponPlaceholder: {
        id: 'Coupons.couponPlaceholder',
        defaultMessage: 'coupon number'
    },
    applyCoupon: {
        id: 'Coupons.applyCoupon',
        defaultMessage: 'Apply'
    },
    couponNumber: {
        id: 'Coupons.couponNumber',
        defaultMessage: 'Coupon {coupon}'
    }
});
