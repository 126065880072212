//@ts-nocheck
import React, { FC, memo } from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store';
import AsyncIntlProvider from './AsyncIntlProvider';
import Init from './Init';
import { MediaQueryProvider } from 'react-media-query-hoc';
import Routes from './Routes';

const customQueries = {
    mobile: 'screen and (max-width: 391px)',
    middleMobile: 'screen and (max-width: 576px)'
};

const App: FC = () => {
    return (
        <Provider store={store}>
            <AsyncIntlProvider>
                <Init>
                    <MediaQueryProvider queries={customQueries}>
                        <Routes />
                    </MediaQueryProvider>
                </Init>
            </AsyncIntlProvider>
        </Provider>
    );
};

export default memo(App);
