import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DescriptionBlock.title',
        defaultMessage: 'The placed order can be changed / canceled:'
    },
    firstListItem: {
        id: 'DescriptionBlock.firstItemList',
        defaultMessage: 'Cancel in your account in the "Orders" section with the "Cancel" button.'
    },
    secondListItem: {
        id: 'DescriptionBlock.secondListItem',
        defaultMessage: 'Change with the administrator at the medical office.'
    },
    text: {
        id: 'DescriptionBlock.text',
        defaultMessage: 'The cost of the order is calculated according to the price list valid at the time of the change.'
    },
    checkupDescription: {
        id: 'DescriptionBlock.checkupDescription',
        defaultMessage:
            'You can return the health program at any medical office according to the rules for the treatment of health programs.{br}{br}The cost of the health program is fixed for the duration of the product (within 1 calendar year).'
    }
});
