import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'AddResultDialog.title',
        defaultMessage: 'Add results'
    },
    orderNumberLabel: {
        id: 'AddResultDialog.orderNumberLabel',
        defaultMessage: 'Order number'
    },
    orderNumberPlaceholder: {
        id: 'AddResultDialog.orderNumberPlaceholder',
        defaultMessage: '9 numbers'
    },
    orderNumberNote: {
        id: 'AddResultDialog.orderNumberNote',
        defaultMessage: 'Enter 9 numbers from receipt (OIN)'
    },
    lastNameNote: {
        id: 'AddResultDialog.lastNameNote',
        defaultMessage: 'Patient lastname from receipt'
    },
    addBtn: {
        id: 'AddResultDialog.addBtn',
        defaultMessage: 'Add'
    },
    success: {
        id: 'AddResultDialog.success',
        defaultMessage: 'Result added successfully'
    },
    additionalFieldsNote: {
        id: 'AddResultDialog.additionalFieldsNote',
        defaultMessage: 'It is necessary to provide additional information to add the results. You can only view them otherwise.'
    }
});
