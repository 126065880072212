//@ts-nocheck
import {
    FETCH_CONCLUSIONS,
    FETCH_NEXT_CONCLUSIONS_BATCH,
    SEND_DOCUMENT,
    CLEAR_CONCLUSIONS_ALL,
    CLEAR_SEND,
    CANCEL_APPOINTMENT
} from 'redux/conclusions/actions';
import { basicReducer } from 'redux/helpers';

const initialState = {
    loading: false,
    appointments: [],
    error: null,
    sendDocument: {},
    cancelAppointment: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CONCLUSIONS.STARTED: {
            return {
                ...initialState,
                loading: true
            };
        }
        case FETCH_CONCLUSIONS.SUCCEEDED: {
            return {
                ...state,
                loading: false,
                appointments: action?.payload?.data?.appointments
            };
        }
        case FETCH_CONCLUSIONS.ERRORED: {
            return {
                ...state,
                loading: false,
                error: action.payload?.response?.data || true
            };
        }
        case FETCH_NEXT_CONCLUSIONS_BATCH.STARTED: {
            return {
                ...state
            };
        }
        case FETCH_NEXT_CONCLUSIONS_BATCH.SUCCEEDED: {
            const newAppointments = action?.payload?.data?.appointments;
            return {
                ...state,
                newAppointments,
                appointments: [...state.appointments, ...newAppointments]
            };
        }
        case FETCH_NEXT_CONCLUSIONS_BATCH.ERRORED: {
            return {
                ...state,
                error: action.payload?.response?.data || true
            };
        }
        case SEND_DOCUMENT.STARTED:
        case SEND_DOCUMENT.SUCCEEDED:
        case SEND_DOCUMENT.ERRORED: {
            return {
                ...state,
                sendDocument: basicReducer(state.sendDocument, action)
            };
        }
        case CANCEL_APPOINTMENT.STARTED:
        case CANCEL_APPOINTMENT.SUCCEEDED:
        case CANCEL_APPOINTMENT.ERRORED: {
            let appointments = state.appointments;
            if (action.type === CANCEL_APPOINTMENT.SUCCEEDED) {
                const canceledAppointmentId = action.meta.appointmentId;
                appointments = appointments.filter((appointment) => appointment.appointmentId !== canceledAppointmentId);
            }
            return {
                ...state,
                appointments,
                cancelAppointment: basicReducer(state.cancelAppointment, action)
            };
        }
        case CLEAR_SEND.BASE:
            return {
                ...state,
                sendDocument: {}
            };
        case CLEAR_CONCLUSIONS_ALL.BASE:
            return initialState;
        default:
            return state;
    }
}
