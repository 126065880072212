import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'LoginConfirmPage.pageTitle',
        defaultMessage: 'Account confirmation'
    },
    errorEmailNotFound: {
        id: 'LoginConfirmPage.errorEmailNotFound',
        defaultMessage: 'This email is not registered'
    },
    pleaseRegister: {
        id: 'LoginConfirmPage.pleaseRegister',
        defaultMessage: 'Please register.'
    },
    recover: {
        id: 'LoginConfirmPage.recover',
        defaultMessage: 'Recover'
    },
    accountDeactivated: {
        id: 'LoginConfirmPage.accountDeactivated',
        defaultMessage: 'Your personal account has been deactivated'
    },
    youCanRestore: {
        id: 'LoginConfirmPage.youCanRestore',
        defaultMessage: 'You can restore it within 14 days.'
    }
});
