import React, { FC, memo, ReactElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import ButtonLink from 'components/button/link/ButtonLink';
import usePrevious from 'hooks/usePrevious';
import styles from './Modal.module.css';

type TProps = {
    show: boolean;
    closable?: boolean;
    closeOutside?: boolean;
    overlayClassName?: string;
    className?: string;
    children?: ReactElement | ReactElement[];
    style?: object;
    onClose?: (event?: React.MouseEvent) => void;
    fixedCloseButton?: { top: string; right: string };
    updateBodyStyle?: boolean;
};

const Modal: FC<TProps> = ({
    show,
    closable,
    closeOutside = true,
    overlayClassName,
    className,
    children,
    style,
    onClose,
    fixedCloseButton,
    updateBodyStyle
}) => {
    const [modalElement] = useState(() => document.createElement('div'));
    const overlay = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const [modalsCount, setModalsCount] = useState<number>(0);
    const prevShow = usePrevious(show);

    const handleFixedCloseButton = (modalCloseBtn: HTMLButtonElement | null, props: { top: string; right: string }) => {
        if (window.innerWidth <= 768) {
            modalCloseBtn?.style.setProperty('position', `fixed`);
            modalCloseBtn?.style.setProperty('top', props.top);
            modalCloseBtn?.style.setProperty('right', props.right);
        } else {
            modalCloseBtn?.style.removeProperty('top');
            modalCloseBtn?.style.removeProperty('right');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        };
        const handleResize = () => {
            if (fixedCloseButton && container.current) {
                const modalCloseBtn = container.current.querySelector('.modalCloseBtn') as HTMLButtonElement;
                handleFixedCloseButton(modalCloseBtn, fixedCloseButton);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        let modalOverlay: HTMLElement | null = null;

        if (container.current && fixedCloseButton) {
            modalOverlay = document.querySelector('.modalOverlay');
            const modalCloseBtn = container.current.querySelector('.modalCloseBtn') as HTMLButtonElement;

            const handleFixedCloseButtonForRemove = () => {
                if (fixedCloseButton) {
                    handleFixedCloseButton(modalCloseBtn, fixedCloseButton);
                }
            };

            container.current.addEventListener('scroll', handleFixedCloseButtonForRemove);
            modalOverlay?.addEventListener('scroll', handleFixedCloseButtonForRemove);

            return () => {
                window.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
                container.current?.removeEventListener('scroll', handleFixedCloseButtonForRemove);
                modalOverlay?.removeEventListener('scroll', handleFixedCloseButtonForRemove);
            };
        }
    }, [show, container, fixedCloseButton, handleFixedCloseButton]);

    useEffect(() => {
        if (show && !document.body.contains(modalElement)) {
            document.body.appendChild(modalElement);
        }
        return () => {
            if (document.body.contains(modalElement)) {
                document.body.removeChild(modalElement);
            }
        };
    }, [show]);

    useEffect(() => {
        if (prevShow !== undefined && prevShow !== show) {
            changeBodyStyle(show);
        }
    }, [show]);

    useEffect(() => {
        if (!show) {
            changeBodyStyle(false);
        }
    }, [show]);

    const changeBodyStyle = (show: boolean) => {
        if (show) {
            setModalsCount((prev) => prev + 1);
            if (modalsCount === 1) {
                const bodyStyle = document.body.style;
                const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                bodyStyle.position = updateBodyStyle !== false ? 'fixed' : '';
                bodyStyle.top = updateBodyStyle !== false ? `-${scrollY}` : '';
                document.documentElement.style.scrollBehavior = 'auto';
            }
        } else {
            setModalsCount((prev) => prev - 1);
            if (modalsCount === 0) {
                const bodyStyle = document.body.style;
                const scrollY = bodyStyle.top;
                bodyStyle.position = '';
                bodyStyle.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
                document.documentElement.style.scrollBehavior = 'smooth';
            }
        }
    };

    const renderCloseButton = () => {
        if (closable) {
            return <ButtonLink text='×' className={classNames(styles.closeBtn, 'modalCloseBtn')} onClick={(event) => close(event as React.MouseEvent)} />;
        } else {
            return null;
        }
    };

    const close = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (closable && onClose) {
            onClose(event);
        }
    };

    const handleOverlayClick = (event: React.MouseEvent) => {
        if (event.target === overlay.current && closeOutside) {
            close(event);
        }
    };

    return show
        ? ReactDOM.createPortal(
              <div className={classNames(styles.overlay, overlayClassName, 'modalOverlay')} onClick={handleOverlayClick} ref={overlay}>
                  <div ref={container} className={classNames(styles.container, className)} style={style} onClick={(event) => event.stopPropagation()}>
                      {renderCloseButton()}
                      {children}
                  </div>
              </div>,
              modalElement
          )
        : null;
};

export default memo(Modal);
