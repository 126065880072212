import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import AlertWarning from 'components/alert/AlertWarning';
import Button from 'components/button/Button';
import styles from './Error.module.css';
const cx = classNames.bind(styles);

type TProps = {
    className?: string;
    alertClassName?: string;
    alertText: string;
    buttonClassName?: string;
    buttonText: string;
    onClick: () => void;
};

const Error: FC<TProps> = ({ className, alertClassName, alertText, buttonClassName, buttonText, onClick }) => {
    return (
        <div className={cx(styles.container, className)}>
            <AlertWarning className={cx(styles.alert, alertClassName)} content={alertText} />
            <Button className={cx(styles.button, buttonClassName)} text={buttonText} onClick={onClick} />
        </div>
    );
};

export default memo(Error);
