//@ts-nocheck
import apiClient from 'client/ApiClient';
import * as settingsClient from 'client/SettingsClient';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { CLEAR as AUTH_CLEAR, DEAUTHENTICATE } from 'redux/auth/actions';
import { ACCESS_TOKEN, USER_ID_COOKIE_KEY } from 'redux/auth/constants';
import { FETCH_CAPITAL_CITY, FETCH_CITY, GET_ACTIVE_CITY, SET_ACTIVE_CITY } from 'redux/cities/actions';
import { request } from 'redux/helpers';
import { CHANGE as LOCALE_CHANGE } from 'redux/i18n/actions';
import { FETCH as FETCH_USER } from 'redux/user/actions';
import { FETCH as FETCH_CART } from 'redux/cart/actions';
import { FETCH_SETTINGS, INIT } from 'redux/startup/actions';
import cookieService from 'services/CookieService';
import { settingsSelector } from './selectors';
import CookieService from 'services/CookieService';

function* initialize() {
    yield put(FETCH_CART.base());
    yield put(LOCALE_CHANGE.base());
}

function* localeProcessed() {
    const initialized = yield select((state) => state.startup.initialized);
    if (!initialized) {
        yield request(FETCH_SETTINGS, [settingsClient.fetch]);
    }
}

function* settingsProcessed() {
    const { mindboxEndpointId } = yield select(settingsSelector);
    window.mindboxInit(mindboxEndpointId);
    yield put(GET_ACTIVE_CITY.base());
}

function* activeCityProcessed(action) {
    const initialized = yield select((state) => state.startup.initialized);
    if (!initialized) {
        const access_token = CookieService.get(ACCESS_TOKEN);
        if (access_token) {
            apiClient.setGlobalHeader('Authorization', `Bearer ${access_token}`);
            yield put(FETCH_USER.base());
        } else {
            yield put(DEAUTHENTICATE.base());
            yield put(INIT.success());
        }
    }
}

function* fetchUserProcessed(action) {
    const initialized = yield select((state) => state.startup.initialized);
    if (!initialized) {
        const userId = action.payload.data.id;
        yield cookieService.set(USER_ID_COOKIE_KEY, userId);
        yield put(INIT.success());
    }
}

function* fetchCityFailed(action) {
    yield put(INIT.error());
}

function* fetchUserFailed(action) {
    const initialized = yield select((state) => state.startup.initialized);
    if (!initialized) {
        yield cookieService.remove(USER_ID_COOKIE_KEY);
        const caught = action.payload;
        const status = caught?.response?.status;
        if (status === 401) {
            yield put(INIT.success());
        } else {
            yield put(INIT.error());
        }
    }
}

export default function* startupSagas() {
    yield all([
        takeLatest(INIT.BASE, initialize),
        takeLatest(LOCALE_CHANGE.SUCCEEDED, localeProcessed),
        takeLatest(LOCALE_CHANGE.ERRORED, localeProcessed),
        takeLatest(FETCH_SETTINGS.BASE, localeProcessed),
        takeLatest(FETCH_SETTINGS.SUCCEEDED, settingsProcessed),
        takeLatest(GET_ACTIVE_CITY.SUCCEEDED, activeCityProcessed),
        takeLatest(FETCH_CITY.ERRORED, fetchCityFailed),
        takeLatest(FETCH_CAPITAL_CITY.ERRORED, fetchCityFailed),
        takeLatest(SET_ACTIVE_CITY.BASE, activeCityProcessed),
        takeLatest(FETCH_USER.SUCCEEDED, fetchUserProcessed),
        takeLatest(FETCH_USER.ERRORED, fetchUserFailed),
        takeLatest(AUTH_CLEAR.SUCCEEDED, fetchUserProcessed)
    ]);
}
