import { defineMessages } from 'react-intl';

export default defineMessages({
    loading: {
        id: 'Init.loading',
        defaultMessage: 'Loading...'
    },
    error: {
        id: 'Init.error',
        defaultMessage: 'Unable to initialize application.'
    }
});
