//@ts-nocheck
import { Component } from 'react';
import { OnlinePaymentType } from 'constants/paymentType';
const { CLOUD_PAYMENTS, PSB, SBER_PAY, ALFA_PAY, LOYALTY } = OnlinePaymentType;

class DefinePaymentProvider extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedPaymentType, cloudPayments, psb, sberPay, alfaPay, loyalty } = this.props;

        switch (selectedPaymentType) {
            case CLOUD_PAYMENTS:
                return cloudPayments;

            case PSB:
                return psb;

            case SBER_PAY:
                return sberPay;

            case ALFA_PAY:
                return alfaPay;

            case LOYALTY:
                return loyalty;

            default:
                return null;
        }
    }
}

export default DefinePaymentProvider;
