//@ts-nocheck
import { INIT, FETCH_SETTINGS } from 'redux/startup/actions';
import { getHostnameTerritory } from 'utils/hostNameUtils';
import { Store } from 'types/store';

const initialState: Store.Startup.State = {
    initialized: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT.SUCCEEDED:
            return {
                ...state,
                initialized: true
            };
        case INIT.ERRORED:
            return {
                ...state,
                error: true
            };
        case FETCH_SETTINGS.SUCCEEDED:
            const territoryByDomain = getHostnameTerritory();
            const gtmID = action.payload?.data?.territorySettings[territoryByDomain]?.gtmID;
            window.gtmInit(gtmID);

            return {
                ...state,
                settings: action.payload.data
            };
        case FETCH_SETTINGS.ERRORED:
            return {
                ...state,
                error: true
            };
        default:
            return state;
    }
}
