//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import { HistoryRouter as BrowserRouter } from 'redux-first-history/rr6';
import { connect } from 'react-redux';

import IKBCodeEntryPage from 'page/loyalty/ikb/confirmation/codeEntry/IKBCodeEntryPage';
import IKBConfirmationPage from 'page/loyalty/ikb/confirmation/IKBConfirmationPage';
import IKBPatientsPage from 'page/loyalty/ikb/patients/IKBPatientsPage';
import IKBCashbackPage from 'page/loyalty/ikb/cashback/IKBCashbackPage';
import IKBCashbackActivationPage from 'page/loyalty/ikb/cashback/activation/IKBCashbackActivationPage';
import IKBPhoneEntryPage from 'page/loyalty/ikb/cashback/activation/phoneEntry/IKBPhoneEntryPage';
import IKBCashbackCodeEntryPage from 'page/loyalty/ikb/cashback/activation/cashbackCodeEntry/IKBCashbackCodeEntryPage';
import IKBCashbackActivatedPage from 'page/loyalty/ikb/cashback/activated/IKBCashbackActivatedPage';
import IKBSolutionsPage from 'page/loyalty/ikb/solutions/IKBSolutionsPage';
import IKBProductDetailsPage from 'page/loyalty/ikb/solutions/details/IKBProductDetailsPage';
import IKBSolutionsConfirmationPage from 'page/loyalty/ikb/solutionsConfirmation/IKBSolutionsConfirmationPage';
import IKBSolutionDetailsPage from 'page/loyalty/ikb/cashback/details/solution/IKBSolutionDetailsPage';
import IKBServicesPage from 'page/loyalty/ikb/services/IKBServicesPage';
import IKBRegularPage from 'page/loyalty/ikb/regular/IKBRegularPage';
import IKBRegularPageConfirm from 'page/loyalty/ikb/regular/IKBRegularPageConfirm';
import IKBRegularDetailsPage from 'page/loyalty/ikb/regular/details/IKBRegularDetailsPage';
import IKBServicesConfirmationPage from 'page/loyalty/ikb/servicesConfirmation/IKBServicesConfirmationPage';
import IKBServiceDetailsPage from 'page/loyalty/ikb/services/details/IKBServiceDetailsPage';
import IKBServicesDetailsPage from 'page/loyalty/ikb/cashback/details/services/IKBServicesDetailsPage';
import NotFoundPage from 'page/error/NotFoundPage';
import CartAcceptedOfficePage from 'page/cart/accepted/CartAcceptedOfficePage';
import CartConfirmationPage from 'page/cart/confirmation/CartConfirmationPage';
import CartByCodePage from 'page/cart/code/CartByCodePage';
import ResultsByKeyPage from 'page/results/key/ResultsByKeyPage';
import RepeatOrderPage from 'page/cart/repeatOrder/RepeatOrderPage';
import IKBBonusHistoryPage from 'page/loyalty/ikb/bonusHistory/IKBBonusHistoryPage';
import Loader from 'components/loader/Loader';
import { ProtectedRoute } from './ProtectedRoute';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { history } from 'redux/store';

import styles from './Routes.module.css';
import AddPatientPage from 'page/add/AddPatientPage/AddPatientPage';
import ReactivationPage from 'page/auth/reactivate/ReactivationPage';
import RecoveryConfirmPage from 'page/auth/recovery/RecoveryConfirmPage';
import ReactivateInstructionPage from '../page/auth/reactivateInstructions/ReactivateInstructionPage';

const LoginPage = lazy(() => import('page/auth/login/LoginPage/LoginPage'));
const LoginConfirmPage = lazy(() => import('page/auth/login/LoginConfirmPage/LoginConfirmPage'));
const LoginPasswordPage = lazy(() => import('page/auth/login/LoginPasswordPage/LoginPasswordPage'));
const RegistrationPage = lazy(() => import('page/auth/register/RegistrationPage'));
const RecoveryPage = lazy(() => import('page/auth/recovery/RecoveryPage'));
const RecoveryMobilePage = lazy(() => import('page/auth/recovery/RecoveryMobilePage'));
const ResetPasswordPage = lazy(() => import('page/auth/recovery/ResetPasswordPage'));
const UnauthResultsPage = lazy(() => import('page/results/unauth/UnauthResultsPage'));
const CartPage = lazy(() => import('page/cart/CartPage'));
const DynamicsPage = lazy(() => import('page/dynamics/DynamicsPage'));
const PatientsPage = lazy(() => import('page/patients/PatientsPage'));
const LoyaltyPage = lazy(() => import('page/loyalty/LoyaltyPage'));
const DocumentListPage = lazy(() => import('page/documents/DocumentListPage'));
const SettingsPage = lazy(() => import('page/settings/SettingsPage'));
const AddPhonePage = lazy(() => import('page/add/AddPhonePage/AddPhonePage'));
const AddEmailPage = lazy(() => import('page/add/AddEmailPage/AddEmailPage'));
const OrderDetailsPage = lazy(() => import('page/orders/details/OrderDetailsPage'));
const OrdersPage = lazy(() => import('page/orders/OrdersPage'));
const DocumentsPage = lazy(() => import('page/documents/DocumentsPage'));
const CheckupsPage = lazy(() => import('page/checkups/CheckupsPage'));
const ConclusionsPage = lazy(() => import('page/conclusions/ConclusionsPage'));
const CheckupDetails = lazy(() => import('page/checkups/components/CheckupDetails/CheckupDetails'));

class Routes extends React.PureComponent {
    render() {
        const { territorySettings } = this.props;
        const { showDocuments, showCheckups, showAppointments } = territorySettings;

        return (
            <Suspense
                fallback={
                    <div className={styles.loader}>
                        <Loader />
                    </div>
                }
            >
                <BrowserRouter history={history}>
                    <ReactRoutes>
                        <Route path='/' element={<Navigate replace to={'/orders'} />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/login/confirm' element={<LoginConfirmPage />} />
                        <Route path='/login/password' element={<LoginPasswordPage />} />
                        <Route path='/reactivate' element={<ReactivationPage />} />
                        <Route path='/reactivate-instruction' element={<ReactivateInstructionPage />} />
                        <Route path='/registration' element={<RegistrationPage />} />
                        <Route path='/recovery' element={<RecoveryPage />} />
                        <Route path='/recovery/mobile' element={<RecoveryMobilePage />} />
                        <Route path='/recovery/confirm' element={<RecoveryConfirmPage />} />
                        <Route path='/recovery/reset-password' element={<ResetPasswordPage />} />
                        <Route path='/no-registration-results' element={<UnauthResultsPage />} />
                        <Route path='/results' element={<ResultsByKeyPage />} />
                        <Route path='/cart' element={<CartPage />} />
                        <Route path='/cart/confirmation' element={<CartConfirmationPage />} />
                        <Route
                            path='/cart/accepted'
                            element={
                                <ProtectedRoute>
                                    <CartAcceptedOfficePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route path='/cart/repeat' element={<RepeatOrderPage />} />
                        <Route path='/cart/:code' element={<CartByCodePage />} />
                        <Route path='/s/:token' element={<DocumentsPage />} />
                        <Route
                            path='/orders'
                            element={
                                <ProtectedRoute>
                                    <OrdersPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/orders/:orderId'
                            element={
                                <ProtectedRoute>
                                    <OrderDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/dynamics'
                            element={
                                <ProtectedRoute>
                                    <DynamicsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/patients'
                            element={
                                <ProtectedRoute>
                                    <PatientsPage />
                                </ProtectedRoute>
                            }
                        />
                        {showCheckups && (
                            <Route
                                path='/programmy-zdorovya'
                                element={
                                    <ProtectedRoute>
                                        <CheckupsPage />
                                    </ProtectedRoute>
                                }
                            />
                        )}
                        {showCheckups && (
                            <Route
                                path='/programmy-zdorovya/:number'
                                element={
                                    <ProtectedRoute>
                                        <CheckupDetails />
                                    </ProtectedRoute>
                                }
                            />
                        )}
                        <Route
                            path='/loyalty'
                            element={
                                <ProtectedRoute>
                                    <LoyaltyPage />
                                </ProtectedRoute>
                            }
                        />
                        {showAppointments && (
                            <Route
                                path='/conclusions'
                                element={
                                    <ProtectedRoute>
                                        <ConclusionsPage />
                                    </ProtectedRoute>
                                }
                            />
                        )}
                        {showDocuments && (
                            <Route
                                path='/documents'
                                element={
                                    <ProtectedRoute>
                                        <DocumentListPage />
                                    </ProtectedRoute>
                                }
                            />
                        )}
                        <Route
                            path='/loyalty/ikb/patients'
                            element={
                                <ProtectedRoute>
                                    <IKBPatientsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/confirmation'
                            element={
                                <ProtectedRoute>
                                    <IKBConfirmationPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/confirmation/code'
                            element={
                                <ProtectedRoute>
                                    <IKBCodeEntryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback'
                            element={
                                <ProtectedRoute>
                                    <IKBCashbackPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/history'
                            element={
                                <ProtectedRoute>
                                    <IKBBonusHistoryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/activation'
                            element={
                                <ProtectedRoute>
                                    <IKBCashbackActivationPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/activation/phone'
                            element={
                                <ProtectedRoute>
                                    <IKBPhoneEntryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/activation/code'
                            element={
                                <ProtectedRoute>
                                    <IKBCashbackCodeEntryPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/activated'
                            element={
                                <ProtectedRoute>
                                    <IKBCashbackActivatedPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/solutions'
                            element={
                                <ProtectedRoute>
                                    <IKBSolutionsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/solutions/product/:productId'
                            element={
                                <ProtectedRoute>
                                    <IKBProductDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/solutions/confirmation'
                            element={
                                <ProtectedRoute>
                                    <IKBSolutionsConfirmationPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/solution/details'
                            element={
                                <ProtectedRoute>
                                    <IKBSolutionDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/services'
                            element={
                                <ProtectedRoute>
                                    <IKBServicesPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/services/confirmation'
                            element={
                                <ProtectedRoute>
                                    <IKBServicesConfirmationPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/services/product/:productId'
                            element={
                                <ProtectedRoute>
                                    <IKBServiceDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/services/details'
                            element={
                                <ProtectedRoute>
                                    <IKBServicesDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/regular'
                            element={
                                <ProtectedRoute>
                                    <IKBRegularPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/regular/confirm'
                            element={
                                <ProtectedRoute>
                                    <IKBRegularPageConfirm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/loyalty/ikb/cashback/regular/details'
                            element={
                                <ProtectedRoute>
                                    <IKBRegularDetailsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/settings'
                            element={
                                <ProtectedRoute>
                                    <SettingsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path='/add/phone'
                            element={
                                <ProtectedRoute>
                                    <AddPhonePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route path='/add/email' element={<AddEmailPage />} />
                        <Route
                            path='/add/patient'
                            element={
                                <ProtectedRoute>
                                    <AddPatientPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={'*'} element={<NotFoundPage />} />
                    </ReactRoutes>
                </BrowserRouter>
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        territorySettings: currentTerritorySettingsSelector(state)
    };
};

export default connect(mapStateToProps)(Routes);
