import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { currentSelector } from 'redux/user/selectors';
import { fetchPatients, allowLoyalty } from 'redux/patient/actions';
import { setConfirmLoyalty } from 'redux/loyaltyIKB/actions';
import { getOptions } from 'utils/patientUtils';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import AlertWarning from 'components/alert/AlertWarning';
import Loader from 'components/loader/Loader';
import confirmationContactIcon from './assets/confirmationContactIcon.svg';
import messages from './IKBConfirmationPageMessages';
import styles from './IKBConfirmationPage.module.css';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { useNavigate } from 'react-router-dom';
import useFirstRender from 'hooks/useFirstRender';

const IKBConfirmationPage: FC = () => {
    const isFirstRender = useFirstRender();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const user = useAppSelector((state) => currentSelector(state));
    const sendCodeState = useAppSelector((state) => state.patient.allowLoyalty);
    const loading = useAppSelector(() => sendCodeState.loading);
    const needEnterCode = useAppSelector(() => sendCodeState.success);
    const error = useAppSelector(() => sendCodeState.error);

    useEffect(() => {
        if (!selectedPatient || selectedPatient.canUseLoyalty) {
            navigate('/loyalty');
        } else {
            if (user) {
                dispatch(fetchPatients({ userId: user.id }));
            }
        }
    }, []);

    useEffect(() => {
        if (needEnterCode && !isFirstRender) {
            navigate('/loyalty/ikb/confirmation/code');
        }
    }, [needEnterCode]);

    const handleAllowLoyalty = (contactType: string) => {
        if (!loading) {
            const data = {
                patientId: selectedPatient.id,
                contactType,
                territory
            };
            dispatch(
                setConfirmLoyalty({
                    ...data
                })
            );
            dispatch(
                allowLoyalty({
                    ...data
                })
            );
        }
    };

    const renderError = () => {
        if (error) {
            return <AlertWarning content={error.message} />;
        }
        return null;
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(messages.navigationTitle)} back={true} />
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.info}>{formatMessage(messages.info)}</div>
                    {renderError()}
                    <div className={styles.contacts}>
                        {getOptions(selectedPatient).length > 0 &&
                            getOptions(selectedPatient).map((contact, i) => (
                                <div key={i} className={styles.contact}>
                                    <h6>{contact.value}</h6>
                                    <button onClick={() => handleAllowLoyalty(contact.type)}>
                                        <img alt='' src={confirmationContactIcon} />
                                    </button>
                                </div>
                            ))}
                    </div>
                    {loading && (
                        <div className={styles.loaderContainer}>
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(IKBConfirmationPage);
