//@ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

export default class Button extends React.PureComponent {
    render() {
        const { size, type, inline, active, disable, text, className, innerRef, children, ...rest } = this.props;
        return (
            <a
                className={classNames(
                    size ? `ds_b_${size}` : 'ds_b_3x',
                    type ? `ds_b_${type}` : '',
                    inline !== false ? `ds_b_inline` : '',
                    active ? `ds_b_active` : '',
                    disable ? `ds_b_disable` : '',
                    className
                )}
                ref={innerRef}
                {...rest}
            >
                <span>{children ? children : text}</span>
            </a>
        );
    }
}

Button.propTypes = {
    size: PropTypes.oneOf(['2x', '3x']),
    type: PropTypes.oneOf(['outline', 'link']),
    inline: PropTypes.bool,
    active: PropTypes.bool,
    disable: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string
};
