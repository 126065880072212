//@ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import withRouter from 'adapters/WithRouter';

function createProtectedRoute() {
    return class extends React.PureComponent {
        render() {
            const { authenticated, location, children } = this.props;
            return authenticated ? children : <Navigate replace to={'/login'} state={{ referrer: location }} />;
        }
    };
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated
    };
};

export const ProtectedRoute = withRouter(connect(mapStateToProps)(createProtectedRoute()));
