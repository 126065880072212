//@ts-nocheck
import { CLEAR_ORDERS_COUNT, CLEAR_ORDERS_COUNT_BY_PATIENT_ID, FETCH } from 'redux/ordersCount/actions';

const initialState = {
    entities: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH.STARTED: {
            const patientId = action.meta;
            const entities = { ...state.entities };
            entities[patientId] = {
                loading: true,
                error: false
            };
            return {
                ...state,
                entities
            };
        }
        case FETCH.SUCCEEDED: {
            const patientId = action.meta;
            const count = action.payload.data;
            const entities = { ...state.entities };
            entities[patientId] = {
                loading: false,
                ordersCount: {
                    count
                }
            };
            return {
                ...state,
                entities
            };
        }
        case FETCH.ERRORED: {
            const patientId = action.meta;
            const entities = { ...state.entities };
            entities[patientId] = {
                ordersCount: {},
                loading: false,
                error: action.payload?.response?.data || true
            };
            return {
                ...state,
                entities
            };
        }
        case CLEAR_ORDERS_COUNT.SUCCEEDED: {
            return {
                ...state,
                entities: {}
            };
        }
        case CLEAR_ORDERS_COUNT_BY_PATIENT_ID.BASE: {
            const patientId = action.meta;
            const entities = { ...state.entities };
            delete entities[patientId];

            return {
                ...state,
                entities
            };
        }
        default:
            return state;
    }
}
