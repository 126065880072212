import { defineMessages } from 'react-intl';

export default defineMessages({
    navigationTitle: {
        id: 'IKBPatientsPage.navigationTitle',
        defaultMessage: 'Select patient'
    },
    birthday: {
        id: 'IKBPatientsPage.birthday',
        defaultMessage: 'Date of Birth:'
    },
    gender: {
        id: 'IKBPatientsPage.gender',
        defaultMessage: 'Gender:'
    },
    button: {
        id: 'IKBPatientsPage.button',
        defaultMessage: 'Choose'
    }
});
