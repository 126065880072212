import { defineMessages } from 'react-intl';

export default defineMessages({
    searchHint: {
        id: 'IKBServicesPage.searchHint',
        defaultMessage: 'Enter analysis number or name'
    },
    clear: {
        id: 'IKBServicesPage.clear',
        defaultMessage: 'Clear'
    },
    continue: {
        id: 'IKBServicesPage.continue',
        defaultMessage: 'Continue'
    },
    showMore: {
        id: 'IKBServicesPage.showMore',
        defaultMessage: 'Show more'
    },
    noResultsTitle: {
        id: 'IKBServicesPage.noResultsTitle',
        defaultMessage: 'Nothing found'
    },
    noResultsInfo: {
        id: 'IKBServicesPage.noResultsInfo',
        defaultMessage: 'Please repeat the search with a different request.'
    }
});
