import { defineMessages } from 'react-intl';

export default defineMessages({
    nextChange: {
        id: 'NextChangeDays.nextChange',
        defaultMessage: 'The following changes will be possible in {text}'
    },
    nextChangeDays: {
        id: 'NextChangeDays.nextChangeDays',
        defaultMessage: '{days} {days, plural, one {day} other {days}}'
    }
});
