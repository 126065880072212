import React, { FC, memo } from 'react';
import Modal from 'components/modal/Modal';
import styles from './DiscountModal.module.css';
import { MarketingMechanics } from 'types/common';

type TProps = {
    show: boolean;
    marketingMechanics: MarketingMechanics | null;
    onClose: () => void;
};

const DiscountModal: FC<TProps> = ({ marketingMechanics, show, onClose }) => {
    return (
        <Modal
            show={show}
            closable={true}
            onClose={onClose}
            className={styles.modal}
            overlayClassName={styles.overlay}
            fixedCloseButton={{ top: '72px', right: '119px' }}
        >
            <h1 className={styles.title}>{marketingMechanics?.name}</h1>
            <div className={styles.description}>{marketingMechanics?.description}</div>
        </Modal>
    );
};

export default memo(DiscountModal);
