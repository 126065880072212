import React, { FC, memo } from 'react';
import Input from 'components/input/Input';
import styles from './InputValid.module.css';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type TProps = {
    valid?: boolean;
    className?: string;
};

const InputValid: FC<TProps> = ({ valid, className, ...rest }) => {
    return (
        <div className={styles.container}>
            <Input
                className={cx(
                    {
                        valid: valid
                    },
                    className
                )}
                {...rest}
            />
            {valid ? <span className={styles.validIcon} /> : null}
        </div>
    );
};

export default memo(InputValid);
