//@ts-nocheck
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import style from './InputBlock.module.css';

class Input extends Component {
    render() {
        let { value, onChange, mask, placeholder, input, onBlur, type, autoComplete = 'new-password', name } = this.props;

        return (
            <InputMask
                onChange={onChange}
                value={value}
                maskChar={''}
                mask={mask}
                type={type}
                onFocus={() => {
                    input.focused = true;
                }}
                onBlur={(e) => {
                    input.focused = false;
                    input.touched = true;
                    onBlur && onBlur(e);
                }}
                autoComplete={autoComplete}
            >
                {(inputProps) => (
                    <input
                        {...inputProps}
                        ref={input.ref}
                        className={classNames(style.input)}
                        value={value}
                        placeholder={placeholder}
                        autoComplete={autoComplete}
                        name={name}
                    />
                )}
            </InputMask>
        );
    }
}

export default Input;
