import React, { FC, memo, ReactElement, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import styles from './SelectPicker.module.css';
import FormField from 'components/form/field/FormField';

interface IProps {
    name: string;
    label: string;
    options: any;
    notice?: any;
    className?: string;
    placeholder?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    isDisabled?: boolean;
    onChange: (event: any) => void;
    value: { value: string | number; label: Element | string | ReactElement };
}

const SelectPicker: FC<IProps> = ({
    name,
    label,
    value,
    notice,
    options,
    onChange,
    className,
    placeholder = '',
    isClearable = false,
    isSearchable = false,
    isDisabled = false
}) => {
    const { formatMessage } = useIntl();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    useEffect(() => {
        if (notice) {
            document
                .querySelector('.simple-selectpicker__menu-list')
                ?.insertAdjacentHTML('beforeend', `<div class=${styles.phoneRegistrationNotice}>${formatMessage(notice)}</div>`);
        }
    }, [notice, menuIsOpen]);

    return (
        <FormField label={label} className={className}>
            <ReactSelect
                name={name}
                value={value}
                options={options}
                onChange={onChange}
                isDisabled={isDisabled}
                isClearable={isClearable}
                placeholder={placeholder}
                isSearchable={isSearchable}
                classNamePrefix={'simple-selectpicker'}
                className={styles.selectPickerContainer}
                onMenuOpen={() => setMenuIsOpen(true)}
                onMenuClose={() => setMenuIsOpen(false)}
            />
        </FormField>
    );
};

export default memo(SelectPicker);
