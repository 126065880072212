import { defineMessages } from 'react-intl';

export default defineMessages({
    formLabel: {
        id: 'Certificates.formLabel',
        defaultMessage: 'Certificate'
    },
    certificateHint: {
        id: 'Certificates.certificateHint',
        defaultMessage: 'You can apply multiple certificates in one order.'
    },
    certificatePlaceholder: {
        id: 'Certificates.certificatePlaceholder',
        defaultMessage: 'certificate number'
    },
    applyCertificate: {
        id: 'Certificates.applyCertificate',
        defaultMessage: 'Apply'
    },
    certificateNumber: {
        id: 'Certificates.certificateNumber',
        defaultMessage: 'Certificate {certificate} for {cost}'
    },
    certificateWarning: {
        id: 'Certificates.certificateWarning',
        defaultMessage: 'The remainder of the {cost} certificate will be burned after the order is paid.'
    }
});
