import React, { Fragment, FC, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { fetchCategories, setCashbackType } from 'redux/loyaltyIKB/actions';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { categoriesDataSelector, fetchCategoriesSelector } from 'redux/loyaltyIKB/selectors';
import Button from 'components/button/Button';
import Loader from 'components/loader/Loader';
import IKBNoticeCard from 'page/loyalty/ikb/components/cards/notice/IKBNoticeCard';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import readySolutionsIcon from './assets/readySolutionsIcon.svg';
import individualServicesIcon from './assets/individualServicesIcon.svg';
import regularCashbackIcon from './assets/regularCashbackIcon.svg';
import cashbackNoticeIcon from 'page/loyalty/ikb/servicesConfirmation/cashbackNoticeIcon.svg';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import { differenceInDays } from 'utils/timeUtils';
import { getLoyaltyErrorText } from 'utils/messageUtils';
import commonMessages from 'messages/CommonMessages';
import messages from './IKBCashbackPageMessages';
import styles from './IKBCashbackPage.module.css';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { IKBLoyalty, Patient } from 'types/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

type TProps = {
    selected: Patient;
    loyaltyStatus: IKBLoyalty.Status;
    territory: string;
};

const IKBCashbackPage: FC<TProps> = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const fetchCategoriesData = useAppSelector((state) => fetchCategoriesSelector(state));
    const categoriesData = useAppSelector((state) => categoriesDataSelector(state));

    useEffect(() => {
        const patientId = getPatientId();
        if (selectedPatient || patientId) {
            dispatch(
                fetchCategories({
                    patientId: selectedPatient?.id || patientId,
                    territory,
                    positionFrom: 0,
                    positionTo: 99999
                })
            );
        } else {
            navigate('/loyalty');
        }
    }, []);

    useEffect(() => {
        if (fetchCategoriesData && fetchCategoriesData.success && categoriesData.newChangeDate) {
            const daysToChange = differenceInDays(new Date(categoriesData.newChangeDate), new Date());
            if (daysToChange >= 0) {
                navigate('/loyalty');
            }
        }
    }, [fetchCategoriesData, categoriesData]);

    const getPatientId = () => {
        return searchParams.get('patientId') as string;
    };

    const handleSetCashbackType = (type: string, path: string) => {
        dispatch(setCashbackType(type));
        navigate(path);
    };

    const renderCards = () => {
        const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);
        const patientId = getPatientId();

        if (fetchCategoriesData && fetchCategoriesData.loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        }

        if (fetchCategoriesData && fetchCategoriesData.error) {
            const { error } = fetchCategoriesData;

            return (
                <IKBNoticeCard
                    title={formatMessage(messages.fetchError)}
                    description={getLoyaltyErrorText(intl, error.errorCode, error.message)}
                    icon={cashbackNoticeIcon}
                    textButton={intl.formatMessage(messages.back)}
                    onClick={() => navigate('/loyalty')}
                />
            );
        }

        const regularCategory = ikbLoyaltyService.getRegularCategory(categoriesData?.categories);

        return (
            <Fragment>
                <div className={styles.card}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoBlockTop}>
                            <h3 className={styles.title}>{formatMessage(commonMessages.readySolutions)}</h3>
                            <img className={styles.cashbackTypeIcon} alt='' src={readySolutionsIcon} />
                        </div>
                        <div className={styles.info}>
                            <p>{formatMessage(messages.solutionsDescription)}</p>
                            <p className={styles.additionalInformation}>
                                {formatMessage(messages.notice, { percent: ikbLoyaltyService.getBaseDiscountPercent() })}
                            </p>
                        </div>
                    </div>
                    <Button onClick={() => handleSetCashbackType(ikbCashbackType.READY_SOLUTIONS, `/loyalty/ikb/cashback/solutions?patientId=${patientId}`)}>
                        {formatMessage(commonMessages.choose)}
                    </Button>
                </div>
                <div className={styles.card}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoBlockTop}>
                            <h3 className={styles.title}>{intl.formatMessage(commonMessages.individualServices)}</h3>
                            <img className={styles.cashbackTypeIcon} alt='' src={individualServicesIcon} />
                        </div>
                        <div className={styles.info}>
                            <p>{intl.formatMessage(messages.servicesDescription)}</p>
                            <p className={styles.additionalInformation}>
                                {intl.formatMessage(messages.notice, { percent: ikbLoyaltyService.getBaseDiscountPercent() })}
                            </p>
                        </div>
                    </div>
                    <Button onClick={() => handleSetCashbackType(ikbCashbackType.INDIVIDUAL_SERVICES, `/loyalty/ikb/cashback/services?patientId=${patientId}`)}>
                        {intl.formatMessage(commonMessages.choose)}
                    </Button>
                </div>
                {regularCategory?.category && (
                    <div className={styles.card}>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoBlockTop}>
                                <h3 className={styles.title}>{intl.formatMessage(commonMessages.regularCashback)}</h3>
                                <img className={styles.cashbackTypeIcon} alt='' src={regularCashbackIcon} />
                            </div>
                            <div className={styles.regularInfo}>
                                <p className={styles.info} style={{ marginBottom: '20px' }}>
                                    {intl.formatMessage(messages.regularCardDescription, {
                                        first: regularCategory?.category?.thirdOrderCashback
                                    })}
                                </p>
                                <ul className={styles.info}>
                                    {regularCategory?.percents?.map((percent, i) => (
                                        <ol key={i}>{`${percent.message} – ${percent.percent}%`}</ol>
                                    ))}
                                </ul>
                                <div className={styles.info}>
                                    <p className={styles.additionalInformation} style={{ marginBottom: 'unset' }}>
                                        {intl.formatMessage(messages.regularCardFooterTitle)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Button onClick={() => handleSetCashbackType(ikbCashbackType.REGULAR_CASHBACK, `/loyalty/ikb/cashback/regular?patientId=${patientId}`)}>
                            {intl.formatMessage(commonMessages.choose)}
                        </Button>
                    </div>
                )}
            </Fragment>
        );
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true} />
            <div className={styles.container}>
                <div className={styles.cards}>{renderCards()}</div>
            </div>
        </div>
    );
};

export default memo(IKBCashbackPage);
