import React, { FC, forwardRef, memo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import Input from 'components/input/Input';
import { getDateFormat } from 'utils/timeUtils';
import styles from './InputDate.module.css';
import inputStyles from 'components/input/Input.module.css';
import './react-datepicker.css';

type TInputWrapperProps = {
    locale: string;
    placeholder?: string;
    disabled?: boolean;
    setOpen: (value: boolean) => void;
};

const InputWrapper: FC<TInputWrapperProps> = forwardRef(({ locale, placeholder, disabled, setOpen, ...rest }, ref) => {
    const correctDateFormat = (dateFormat: string) => {
        let dateFormatCorrected = dateFormat;
        const yearLettersCount = (dateFormatCorrected.match(/y/g) || []).length;
        if (yearLettersCount === 1 || yearLettersCount === 3) {
            dateFormatCorrected = dateFormatCorrected.replace('y'.repeat(yearLettersCount), 'yyyy');
        }
        const monthLettersCount = (dateFormatCorrected.match(/M/g) || []).length;
        if (monthLettersCount === 1) {
            dateFormatCorrected = dateFormatCorrected.replace('M'.repeat(monthLettersCount), 'MM');
        }
        const daysLettersCount = (dateFormatCorrected.match(/d/g) || []).length;
        if (daysLettersCount === 1) {
            dateFormatCorrected = dateFormatCorrected.replace('d'.repeat(daysLettersCount), 'dd');
        }

        return dateFormatCorrected;
    };

    const dateFormat = getDateFormat(locale);
    const mask = correctDateFormat(dateFormat).replace(/[a-zA-Z]/g, '9');

    return (
        <div className={styles.container}>
            <InputMask mask={mask} disabled={disabled} {...rest}>
                {
                    // @ts-ignore
                    (props) => <Input ref={ref} {...props} disabled={disabled} placeholder={placeholder || mask.replace(/9/g, '_')} />
                }
            </InputMask>
            <span onClick={() => setOpen(true)} className={styles.icon} />
        </div>
    );
});

type TInputDateProps = {
    error?: boolean;
    isClearable?: boolean;
    locale: string;
    onChange: (event?: any) => void;
    maxDate?: Date;
    minDate?: Date | null;
    name?: string;
    selected?: any;
    disabled?: boolean;
    calendarClassName?: string;
    placeholderText?: string;
    onBlur?: () => void;
    onSelect?: (date: Date) => void;
};

const InputDate: FC<TInputDateProps> = (props) => {
    const ref = useRef<any>();
    const { error, isClearable, ...rest } = props;
    return (
        <DatePicker
            ref={ref}
            showYearDropdown
            showMonthDropdown
            dropdownMode='select'
            showPopperArrow={false}
            customInput={<InputWrapper setOpen={ref?.current?.setOpen} locale={props.locale} />}
            dateFormat='P'
            className={error ? inputStyles.error : ''}
            onChangeRaw={(event) => {
                if (isClearable && props.onChange && !(event.target.value && event.target.value.replace(/[_\\.]/g, ''))) {
                    props.onChange();
                }
            }}
            {...rest}
        />
    );
};

export default memo(InputDate);
