import { defineMessages } from 'react-intl';

export default defineMessages({
    UNKNOWN_ERROR: {
        id: 'LoyaltyError.UNKNOWN_ERROR',
        defaultMessage: 'Error while interacting with loyalty service'
    },
    ACCOUNT_NOT_FOUND: {
        id: 'LoyaltyError.ACCOUNT_NOT_FOUND',
        defaultMessage: ''
    },
    ACCOUNT_BLOCKED: {
        id: 'LoyaltyError.ACCOUNT_BLOCKED',
        defaultMessage: ''
    },
    ACCOUNT_DEACTIVATED: {
        id: 'LoyaltyError.ACCOUNT_DEACTIVATED',
        defaultMessage: ''
    },
    CONTACT_DEACTIVATED: {
        id: 'LoyaltyError.CONTACT_DEACTIVATED',
        defaultMessage: ''
    },
    CONTACT_NO_MATCH_ACCOUNT: {
        id: 'LoyaltyError.CONTACT_NO_MATCH_ACCOUNT',
        defaultMessage: ''
    },
    CREATE_ACCOUNT_NOT_ALLOWED: {
        id: 'LoyaltyError.CREATE_ACCOUNT_NOT_ALLOWED',
        defaultMessage: ''
    },
    ACCOUNT_STATUS_ACTIVE: {
        id: 'LoyaltyError.ACCOUNT_STATUS_ACTIVE',
        defaultMessage: ''
    },
    ACCOUNT_ALREADY_ACTIVATED: {
        id: 'LoyaltyError.ACCOUNT_ALREADY_ACTIVATED',
        defaultMessage: 'Account already activated'
    },
    CONTACT_WRONG_PHONE: {
        id: 'LoyaltyError.CONTACT_WRONG_PHONE',
        defaultMessage: 'The phone number does not match the contact'
    },
    PIN_SEND_TIMEOUT_NOT_EXPIRED: {
        id: 'LoyaltyError.PIN_SEND_TIMEOUT_NOT_EXPIRED',
        defaultMessage: 'The confirmation code was already sent earlier'
    },
    PIN_AWAITING_TIME_EXPIRED: {
        id: 'LoyaltyError.PIN_AWAITING_TIME_EXPIRED',
        defaultMessage: 'Timeout while waiting for the confirmation code'
    },
    WRONG_PIN: {
        id: 'LoyaltyError.WRONG_PIN',
        defaultMessage: 'Invalid activation code'
    },
    PIN_NOT_SENT: {
        id: 'LoyaltyError.PIN_NOT_SENT',
        defaultMessage: 'No confirmation code was sent for this account'
    }
});
