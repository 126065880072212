import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames/bind';
import styles from './UpButton.module.css';
import messages from 'page/layout/footer/middle/UpButtonMessages';

const cx = classNames.bind(styles);

const UpButton = () => {
    const scrollToTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <button className={cx(styles.upButton, 'up-button')} onClick={scrollToTop}>
            <span className={styles.upButtonText}>
                <FormattedMessage {...messages.text} />
            </span>
        </button>
    );
};

export default UpButton;
