//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { CREATE, CLEAR } from 'redux/homeVisits/actions';

const initialState = {
    create: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE.STARTED:
        case CREATE.SUCCEEDED:
        case CREATE.ERRORED: {
            return {
                ...state,
                create: basicReducer(state.create, action)
            };
        }
        case CLEAR.BASE: {
            return initialState;
        }
        default:
            return state;
    }
}
