import React, { FC, useState, memo, useRef, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useAppSelector } from 'app/Hooks';
import Cost from 'containers/const/Cost';
import { BonusTransactionType } from 'constants/bonusTransactionType';
import { localeSelector } from 'redux/i18n/selectors';
import { formatDate, parseISO } from 'utils/timeUtils';
import { EVENT_TYPE } from '../constants';
import arrow from './arrow.svg';
import messages from './IKBBonusHistoryRecordMessages';
import styles from './IKBBonusHistoryRecord.module.css';
import { Store } from 'types/store';

interface IProps {
    bonus: Partial<Store.LoyaltyHistory.IHistory>;
    eventType: string;
}

const IKBBonusHistoryRecord: FC<IProps> = ({ bonus, eventType }): ReactElement => {
    const { orderProducts, orderInfo, date, charged, chargedOff, transactionType, bonusReasonDescription, message } = bonus;
    const { orderNumber, orderAmount, orderCurrency } = orderInfo ?? {};
    const { formatMessage } = useIntl();
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const content = useRef<HTMLDivElement>(null);
    const { locale } = useAppSelector(localeSelector);
    const isBonusMovement = eventType === EVENT_TYPE.BONUS_MOVEMENT;

    const renderItem = ({ article, name, charged, chargedOff }: Store.LoyaltyHistory.IOrderProduct) => {
        return (
            <div className={styles.item}>
                <div className={styles.product}>
                    <div className={styles.article}>{article}</div>
                    <div className={styles.name}>{name}</div>
                </div>
                <div className={cn(styles.count, { [styles.none]: !charged, [styles.mb]: charged })}>
                    <span className={styles.mobile}>{formatMessage(messages.accrued)}</span>
                    {charged && `+${charged}`}
                </div>
                <div className={cn(styles.writeOff, styles.nested, { [styles.none]: !chargedOff, [styles.mb]: chargedOff })}>
                    <span className={styles.mobile}>{formatMessage(messages.writeOff)}</span>
                    {chargedOff && `-${chargedOff}`}
                </div>
            </div>
        );
    };

    const renderEventBonus = () => {
        if (transactionType === BonusTransactionType.RETURN_POINTS) {
            return formatMessage(messages.orderReturn, { number: orderNumber });
        } else if (transactionType === BonusTransactionType.SALE_POINTS) {
            return formatMessage(messages.order, { number: orderNumber });
        } else {
            return bonusReasonDescription;
        }
    };

    const isClickable = () => Array.isArray(orderProducts) && orderProducts.length > 0;

    return (
        <>
            <div className={cn(styles.section, { [styles.opened]: isOpened })} onClick={() => isClickable() && setIsOpened(!isOpened)}>
                <div className={styles.container}>
                    {isClickable() && (
                        <div className={styles.arrowWrapper}>
                            <img className={cn(styles.arrow, { [styles.rotate]: isOpened })} src={arrow} alt='' />
                        </div>
                    )}
                    <div className={styles.date}>{date && formatDate(parseISO(date), locale, 'd MMMM yyyy')}</div>
                    <div className={cn(styles.event, { [styles.discountEvent]: !isBonusMovement })}>{isBonusMovement ? renderEventBonus() : message}</div>
                    {isBonusMovement && (
                        <>
                            <div className={cn(styles.accured, { [styles.none]: !charged, [styles.mb]: charged })}>
                                <span className={styles.mobile}>{formatMessage(messages.accrued)}</span>
                                {charged && `+${charged}`}
                            </div>
                            <div className={cn(styles.writeOff, { [styles.none]: !chargedOff, [styles.mb]: chargedOff })}>
                                <span className={styles.mobile}>{formatMessage(messages.writeOff)}</span>
                                {chargedOff && `-${chargedOff}`}
                            </div>
                            <div className={cn(styles.amountWithDiscount, { [styles.none]: !orderAmount, [styles.mb]: orderAmount })}>
                                <span className={styles.mobile}>{formatMessage(messages.paid)}</span>
                                {orderAmount && orderCurrency ? <Cost amount={orderAmount} currency={orderCurrency} /> : <span>{orderAmount}</span>}
                            </div>
                        </>
                    )}
                </div>
                <div ref={content} style={{ maxHeight: isOpened ? `${content.current?.scrollHeight}px` : '0px' }} className={styles.contentWrapper}>
                    {orderProducts?.map((product: Store.LoyaltyHistory.IOrderProduct, index) => (
                        <React.Fragment key={index}>{renderItem(product)}</React.Fragment>
                    ))}
                </div>
            </div>
        </>
    );
};

export default memo(IKBBonusHistoryRecord);
