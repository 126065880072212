import { Store } from 'types/store';
import { createSelector } from 'reselect';

export const localeSelector = createSelector([(state: Store.App.State) => state.i18n], (locale) => ({
    defaultLocale: locale.defaultLocale,
    defaultMessages: locale.defaultMessages,
    messagesCache: locale.messagesCache,
    locale: locale.currentLocale,
    currentMessages: locale.currentMessages
}));
