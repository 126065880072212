//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { SEND_EMAIL_RESULT } from 'redux/result/actions';
import {
    ADD_RESULT,
    CLEAR,
    CLEAR_ADD_RESULT,
    CLEAR_REQUEST_TRANSLATION,
    CLEAR_SEARCH_RESULT_UNAUTH,
    CLEAR_SEND_EMAIL_RESULT_UNAUTH,
    PARSE_KEY_UNAUTH,
    REQUEST_TRANSLATION,
    SEARCH_RESULT_UNAUTH,
    SEND_EMAIL_RESULT_UNAUTH,
    SEARCH_RESULT_BY_KEY
} from 'redux/result/actions';

const initialState = {
    search: {},
    sendEmail: {},
    addResult: {},
    parseKey: {},
    translate: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_RESULT_UNAUTH.STARTED:
        case SEARCH_RESULT_UNAUTH.SUCCEEDED:
        case SEARCH_RESULT_UNAUTH.ERRORED: {
            return {
                ...state,
                search: basicReducer(state.search, action, 'data')
            };
        }
        case SEND_EMAIL_RESULT_UNAUTH.STARTED:
        case SEND_EMAIL_RESULT_UNAUTH.SUCCEEDED:
        case SEND_EMAIL_RESULT_UNAUTH.ERRORED:
        case SEND_EMAIL_RESULT.STARTED:
        case SEND_EMAIL_RESULT.SUCCEEDED:
        case SEND_EMAIL_RESULT.ERRORED: {
            return {
                ...state,
                sendEmail: basicReducer(state.sendEmail, action, 'data')
            };
        }
        case ADD_RESULT.STARTED:
        case ADD_RESULT.SUCCEEDED:
        case ADD_RESULT.ERRORED: {
            return {
                ...state,
                addResult: basicReducer(state.addResult, action, 'data')
            };
        }
        case REQUEST_TRANSLATION.STARTED:
        case REQUEST_TRANSLATION.SUCCEEDED:
        case REQUEST_TRANSLATION.ERRORED: {
            return {
                ...state,
                translate: basicReducer(state.translate, action, 'data')
            };
        }
        case PARSE_KEY_UNAUTH.STARTED:
        case PARSE_KEY_UNAUTH.SUCCEEDED:
        case PARSE_KEY_UNAUTH.ERRORED: {
            return {
                ...state,
                parseKey: basicReducer(state.parseKey, action, 'data')
            };
        }
        case CLEAR_SEARCH_RESULT_UNAUTH.BASE: {
            return {
                ...state,
                search: {}
            };
        }
        case CLEAR_SEND_EMAIL_RESULT_UNAUTH.BASE: {
            return {
                ...state,
                sendEmail: {}
            };
        }
        case CLEAR_ADD_RESULT.BASE: {
            return {
                ...state,
                addResult: {}
            };
        }
        case CLEAR_REQUEST_TRANSLATION.BASE: {
            return {
                ...state,
                translate: {}
            };
        }
        case CLEAR.BASE: {
            return initialState;
        }
        case SEARCH_RESULT_BY_KEY.STARTED:
        case SEARCH_RESULT_BY_KEY.SUCCEEDED:
        case SEARCH_RESULT_BY_KEY.ERRORED: {
            return {
                ...state,
                search: basicReducer(state.search, action, 'data')
            };
        }
        default:
            return state;
    }
}
