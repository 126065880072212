import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'PatientBlock.title',
        defaultMessage: 'Patient'
    },
    addPatientButton: {
        id: 'PatientBlock.addPatientButton',
        defaultMessage: 'Add patient'
    },
    onlineRegistrationMainHint: {
        id: 'PatientBlock.onlineRegistrationMainHint',
        defaultMessage: 'Online registration is available only for the main patient'
    },
    adultWarning: {
        id: 'PatientBlock.adultWarning',
        defaultMessage: 'Online registration is available for persons over 18 years old'
    },
    changeRegistrationMode: {
        id: 'PatientBlock.changeRegistrationMode',
        defaultMessage: 'Change mode'
    },
    nonAdultPatientWarning: {
        id: 'PatientBlock.nonAdultPatientWarning',
        defaultMessage: 'Online registration allowed only for people above 18 y.o.'
    }
});
