//@ts-nocheck
import { AcceptSolutionType } from 'constants/acceptSolutionType';
import { OnlinePaymentType, PaymentType } from 'constants/paymentType';
import { OrderStatus } from 'constants/orderStatus';
import { OrderType } from 'constants/orderType';

const { OFFICE } = PaymentType;
const { CLOUD_PAYMENTS, PSB, SBER_PAY, ALFA_PAY, LOYALTY } = OnlinePaymentType;

export const isSolutionTypeAccepted = (accept) => accept.solution === AcceptSolutionType.ACCEPTED;

export const getOnlinePaymentData = ({ cardCryptogramPacket, selectedPaymentType, phone, paymentEmail }) => {
    if (selectedPaymentType === CLOUD_PAYMENTS && cardCryptogramPacket) {
        return {
            type: selectedPaymentType,
            cardCryptogramPacket
        };
    }

    if (selectedPaymentType === PSB || selectedPaymentType === LOYALTY) {
        return {
            type: selectedPaymentType
        };
    }

    if (selectedPaymentType === OFFICE) {
        return null;
    }

    if (selectedPaymentType === SBER_PAY) {
        return {
            type: selectedPaymentType,
            phone: phone
        };
    }

    if (selectedPaymentType === ALFA_PAY) {
        return {
            type: selectedPaymentType,
            email: paymentEmail,
            phone: phone
        };
    }

    return {
        type: OnlinePaymentType.PSB
    };
};

export const getLoyaltyOption = (saleAction) => {
    let loyaltyOption = {};
    const marketingMechanics = saleAction?.marketingMechanics;

    if (saleAction) {
        if (saleAction.bonus?.applied) {
            loyaltyOption.bonus = { writeOff: saleAction.bonus.appliedBonuses };
        }
        if (marketingMechanics) {
            loyaltyOption.marketingMechanics = {
                isApplied: true,
                marketingMechanicsItems: [
                    ...marketingMechanics
                        .filter((mechanics) => mechanics.isApplied)
                        .map((mechanics) => {
                            const { id, type } = mechanics;
                            return {
                                id,
                                type
                            };
                        })
                ]
            };
        }
        if (saleAction.employeeDiscount?.applied) {
            loyaltyOption.employeeDiscount = { apply: true };
        }
        if (saleAction.personalDiscount?.applied) {
            loyaltyOption.discountCard = { number: saleAction.personalDiscount.number };
        }
        if (saleAction.coupons?.some((coupon) => coupon.applied)) {
            loyaltyOption.coupons = [
                ...saleAction.coupons
                    .filter((coupon) => coupon.applied)
                    .map((item) => {
                        return {
                            number: item.number
                        };
                    })
            ];
        }
        if (saleAction.certificates?.some((certificate) => certificate.applied)) {
            loyaltyOption.certificates = [
                ...saleAction.certificates
                    .filter((certificate) => certificate.applied)
                    .map((item) => {
                        return {
                            number: item.number
                        };
                    })
            ];
        }
    }

    return loyaltyOption;
};

export const isPrintCancelAvailable = (status) => {
    return (
        status === OrderStatus.PREORDER ||
        status === OrderStatus.NOT_PAID_PREORDER ||
        status === OrderStatus.PAID_PREORDER ||
        status === OrderStatus.PAYMENT_IN_PROGRESS_PREORDER
    );
};

export const isCheckup = (order) => order.orderType === OrderType.ORDER;
