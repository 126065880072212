import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'CartConfirmationPage.pageTitle',
        defaultMessage: 'Checkout'
    },
    title: {
        id: 'CartConfirmationPage.title',
        defaultMessage: 'Checkout'
    },
    backToCart: {
        id: 'CartConfirmationPage.backToCart',
        defaultMessage: 'Back to cart'
    },
    backToOrders: {
        id: 'CartConfirmationPage.backToOrders',
        defaultMessage: 'Back'
    },
    errorCalculate: {
        id: 'CartConfirmationPage.errorCalculate',
        defaultMessage: 'Unable to calculate preorder'
    },
    errorCreatePreorder: {
        id: 'CartConfirmationPage.errorCreatePreorder',
        defaultMessage: 'Unable to create preorder'
    },
    errorCreateOrder: {
        id: 'CartConfirmationPage.errorCreateOrder',
        defaultMessage: 'Unable to create order'
    }
});
