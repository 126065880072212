//@ts-nocheck
import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Popup.module.css';

const cx = classNames.bind(styles);

export default class Popup extends React.PureComponent {
    render() {
        const { text, position, className } = this.props;
        const defaultClassName = cx({
            popup: true,
            top: position === 'top',
            bottom: position === 'bottom'
        });
        return <div className={classNames(defaultClassName, className)}>{text}</div>;
    }
}

Popup.propTypes = {
    text: PropTypes.string.isRequired,
    position: PropTypes.oneOf(['top', 'bottom']).isRequired
};

Popup.defaultProps = {
    position: 'bottom'
};
