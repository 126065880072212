//@ts-nocheck
import queryString from 'query-string';
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const CONCLUSIONS_URL = `${BASE_URL}/appointments`;

export const fetchConclusions = (data) => {
    return apiClient.get(`${CONCLUSIONS_URL}?${queryString.stringify(data)}`);
};

export const documentDownload = (patientId, documentId, data) => {
    return apiClient.get(`${CONCLUSIONS_URL}/${patientId}/documents/${documentId}/download?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const documentPrint = (patientId, documentId, data) => {
    return apiClient.get(`${CONCLUSIONS_URL}/${patientId}/documents/${documentId}/print?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const sendDocument = (data) => {
    return apiClient.post(`${CONCLUSIONS_URL}/documents/send`, data);
};

export const cancelAppointment = (data) => {
    const { patientId, appointmentId } = data;
    return apiClient.delete(`${CONCLUSIONS_URL}/${appointmentId}/patients/${patientId}`);
};
