import { createRequestTypes } from 'redux/helpers';
import { IKBLoyalty, IKBService, IKBSolution } from 'types/common';

export const SET_CASHBACK_TYPE = createRequestTypes('LOYALTY_IKB/SET_CASHBACK_TYPE');
export const SELECT_CASHBACK_SOLUTION = createRequestTypes('LOYALTY_IKB/SELECT_CASHBACK_SOLUTION');
export const SELECT_CASHBACK_SERVICE = createRequestTypes('LOYALTY_IKB/SELECT_CASHBACK_SERVICE');
export const CLEAR_SELECTED_CASHBACK_SERVICES = createRequestTypes('LOYALTY_IKB/CLEAR_SELECTED_CASHBACK_SERVICES');
export const SEARCH_CASHBACK_SERVICES = createRequestTypes('LOYALTY_IKB/SEARCH_CASHBACK_SERVICES');
export const SEND_ACTIVATION_PIN = createRequestTypes('LOYALTY_IKB/SEND_ACTIVATION_PIN');
export const CLEAR_SEND_ACTIVATION_PIN = createRequestTypes('LOYALTY_IKB/CLEAR_SEND_ACTIVATION_PIN');
export const SET_ACTIVATION = createRequestTypes('LOYALTY_IKB/SET_ACTIVATION');
export const ACTIVATE_ACCOUNT = createRequestTypes('LOYALTY_IKB/ACTIVATE_ACCOUNT');
export const CLEAR_ACTIVATION = createRequestTypes('LOYALTY_IKB/CLEAR_ACTIVATION');
export const SET_CONFIRM_DATA = createRequestTypes('LOYALTY_IKB/SET_CONFIRM_DATA');
export const CLEAR_CONFIRM_DATA = createRequestTypes('LOYALTY_IKB/CLEAR_CONFIRM_LOYALTY');
export const FETCH_CATEGORIES = createRequestTypes('LOYALTY_IKB/FETCH_CATEGORIES');
export const SET_CASHBACK_STATUS = createRequestTypes('LOYALTY_IKB/SET_CASHBACK_STATUS');
export const CLEAR_CASHBACK_STATUS = createRequestTypes('LOYALTY_IKB/CLEAR_CASHBACK_STATUS');
export const CHANGE_CATEGORY = createRequestTypes('LOYALTY_IKB/CHANGE_CATEGORY');
export const CLEAR_CHANGE_CATEGORY = createRequestTypes('LOYALTY_IKB/CLEAR_CHANGE_CATEGORY');
export const SET_NUMBER_OF_SERVICES = createRequestTypes('LOYALTY_IKB/SET_NUMBER_OF_SERVICES');
export const FETCH_MOTIVATING_TOOLTIP = createRequestTypes('LOYALTY_IKB/FETCH_MOTIVATING_TOOLTIP');

export const sendActivationPin = (payload: { patientId: string; territory: string; number: string; phone: string }) => SEND_ACTIVATION_PIN.base(payload);
export const clearSendActivationPin = () => CLEAR_SEND_ACTIVATION_PIN.base();
export const setActivation = (payload: { accountNumber: number; discountNumber?: number | null }) => SET_ACTIVATION.base(payload);
export const activateAccount = (payload: { patientId: string; territory: string; number: string; pin: string }) => ACTIVATE_ACCOUNT.base(payload);
export const setCashbackType = (payload: string) => SET_CASHBACK_TYPE.base(payload);
export const setCashbackStatus = (payload: IKBLoyalty.BonusStatus) => SET_CASHBACK_STATUS.base(payload);
export const fetchCategories = (payload: { patientId: string | null; territory: string; positionFrom: number; positionTo: number }) =>
    FETCH_CATEGORIES.base(payload);
export const clearCashbackStatus = () => CLEAR_CASHBACK_STATUS.base();
export const setConfirmLoyalty = (payload: { patientId: string; contactType: string; territory: string }) => SET_CONFIRM_DATA.base(payload);
export const selectCashbackSolution = (payload: { solution: IKBSolution; selected: boolean }) => SELECT_CASHBACK_SOLUTION.base(payload);
export const selectCashbackService = (payload: { service: IKBService; selected: boolean }) => SELECT_CASHBACK_SERVICE.base(payload);
export const searchCashbackServices = (payload: { services: any[]; param: string }) => SEARCH_CASHBACK_SERVICES.base(payload);
export const changeCategory = (payload: { patientId: string; territory: string; categoryId: string; number: string; favoriteProductIds?: any }) =>
    CHANGE_CATEGORY.base(payload);
export const clearChangedCategory = () => CLEAR_CHANGE_CATEGORY.base();

export const setNumberOfServices = (payload: number) => SET_NUMBER_OF_SERVICES.base(payload);
export const setConfirmData = () => SET_CONFIRM_DATA.base();
export const clearConfirmData = () => CLEAR_CONFIRM_DATA.base();
export const clearSelectedCashbackServices = () => CLEAR_SELECTED_CASHBACK_SERVICES.base();
