import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'IKBCashbackActivationPage.title',
        defaultMessage: 'Confirm activation?'
    },
    notice: {
        id: 'IKBCashbackActivationPage.notice',
        defaultMessage: 'Your discount card with {cardNumber} will be blocked'
    },
    cancel: {
        id: 'IKBCashbackActivationPage.cancel',
        defaultMessage: 'Cancel'
    },
    iAgree: {
        id: 'IKBCashbackActivationPage.iAgree',
        defaultMessage: 'I agree'
    }
});
