import React, { FC, memo, ReactElement } from 'react';
import IconWarning from 'components/icon/warning/IconWarning';
import styles from './AlertWarning.module.css';
import classNames from 'classnames/bind';
import Timer from 'components/timer/Timer';
import commonMessages from 'messages/CommonMessages';
import { useIntl } from 'react-intl';
const cx = classNames.bind(styles);

type TProps = {
    show?: boolean;
    className?: string;
    content: string | ReactElement;
    timeToResend?: number;
};

const AlertWarning: FC<TProps> = ({ content, className, show = true, timeToResend }) => {
    const { formatMessage } = useIntl();

    return show && content ? (
        <div className={cx(styles.error, className)}>
            <IconWarning />
            <div>
                {content}
                {timeToResend && (
                    <Timer className={styles.timer} seconds={timeToResend}>
                        {({ formatted }: any) => {
                            return formatMessage(commonMessages.afterTime, { time: formatted });
                        }}
                    </Timer>
                )}
            </div>
        </div>
    ) : null;
};

export default memo(AlertWarning);
