//@ts-nocheck
import { OfficeType } from 'constants/officeType';
import styles from 'page/cart/accepted/CartAcceptedPage.module.css';
import messages from 'page/cart/accepted/CartAcceptedPageMessages';
import queryString from 'query-string';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from 'adapters/WithRouter';
import { CLEAR } from 'redux/homeVisits/actions';
import OrderService from 'services/OrderService';
import CartAcceptedContent from 'page/cart/accepted/components/CartAcceptedContent';

import { FETCH_PAYMENT_STATUS, SET_PAYMENT_STATUS } from 'redux/cart/actions';
import { OrderType } from 'constants/orderType';
import { PaymentStatusService } from 'services/PaymentService';
import { CLEAR_ACCEPTED_INFO, FETCH_ACCEPTED_INFO } from 'redux/orders/actions';
import { acceptedInfoSelector } from 'redux/orders/selectors';
import commonMessages from 'messages/CommonMessages';

class CartAcceptedOfficePage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.getParams = this.getParams.bind(this);
    }

    componentDidMount() {
        const { fetchAcceptedInfo } = this.props;
        const { id, patientId, orderType } = this.getParams();
        const hasRequiredParameters = id && patientId;

        if (hasRequiredParameters) {
            fetchAcceptedInfo({
                patientId,
                orderId: id,
                orderType
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.success && prevProps.success !== this.props.success && this.getParams()?.onlinePayment) {
            this.handleFetchPaymentStatus();
        }

        if (this.props.paymentStatus.status === 403) {
            this.timeoutId = setTimeout(() => this.props.navigate('/cart'));
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
        this.props.clearAcceptedInfo();
    }

    getParams() {
        const { acceptedInfo, location } = this.props;
        const query = queryString.parse(location.search, { parseBooleans: true });

        return {
            id: query?.id,
            createdTime: acceptedInfo?.createdTime,
            orderNumber: acceptedInfo?.orderNumber,
            orderId: acceptedInfo?.orderId,
            patientId: query?.patientId,
            orderType: query?.orderType,
            onlinePayment: query?.onlinePayment,
            paymentType: query?.source,
            source: acceptedInfo?.source,
            passportRequired: acceptedInfo?.passportRequired,
            officeAddress: acceptedInfo?.officeAddress
        };
    }

    handleFetchPaymentStatus() {
        const { fetchPaymentStatus, paymentSettings } = this.props;
        const { id, patientId, orderType, paymentType } = this.getParams();

        fetchPaymentStatus({
            orderType,
            paymentType,
            orderId: id,
            patientId,
            pollingDelay: paymentSettings?.poolingDelay,
            pollingCount: paymentSettings?.poolingCount
        });
    }

    handlePrint() {
        const { locale } = this.props;
        const { patientId, id, orderType } = this.getParams();
        OrderService.handlePdfPrint({ id: patientId }, { id, orderType }, locale);
    }

    handleDownload() {
        const { locale } = this.props;
        const { patientId, id, orderType } = this.getParams();
        OrderService.handlePdfDownload({ id: patientId }, { id, orderType }, locale);
    }

    render() {
        const { intl, acceptedInfo, location, paymentStatus } = this.props;
        const { orderNumber, orderId, patientId, orderType, source, passportRequired } = this.getParams();

        const renderOrderNumber = (
            <Link to={`/orders/${orderId}?patientId=${patientId}&source=${source}`} className={styles.orderNumberLink}>
                {orderType === OrderType.REORDER ? (
                    intl.formatMessage(commonMessages.reorderNumberTitle, {
                        orderNumber: () => orderNumber
                    })
                ) : (
                    <FormattedMessage {...messages.orderNumberLinkOffice} values={{ number: orderNumber }} />
                )}
            </Link>
        );

        const paymentService = new PaymentStatusService(intl, paymentStatus, location);
        const statusIcon = paymentService.getStatusIcon();
        const title = paymentService.getTitle();
        const message = paymentService.getMessage();
        const hasDocumentNotice = paymentService.hasDocumentNotice(passportRequired);
        const hasOverdueNotice = paymentService.hasOverdueNotice(orderType);
        const hasOnlineRegistration = paymentService.hasOnlineRegistration(acceptedInfo);

        return (
            <CartAcceptedContent
                title={title}
                message={message}
                getParams={this.getParams}
                orderType={orderType}
                orderNumber={renderOrderNumber}
                officeType={OfficeType.OFFICE}
                print={this.handlePrint}
                download={this.handleDownload}
                hasRequiredParameters={orderId && patientId}
                iconSrc={statusIcon}
                hasDocumentNotice={hasDocumentNotice}
                hasOverdueNotice={hasOverdueNotice}
                hasOnlineRegistration={hasOnlineRegistration}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        paymentStatus: state.cart.paymentStatus,
        paymentSettings: state.startup.settings.paymentSettings,
        acceptedInfo: acceptedInfoSelector(state),
        loading: state.orders.fetchAcceptedInfo?.loading,
        success: state.orders.fetchAcceptedInfo?.success
    };
};

const mapDispatchToProps = {
    fetchAcceptedInfo: FETCH_ACCEPTED_INFO.base,
    setPaymentStatus: SET_PAYMENT_STATUS.base,
    clearHomeVisit: CLEAR.base,
    fetchPaymentStatus: FETCH_PAYMENT_STATUS.base,
    clearAcceptedInfo: CLEAR_ACCEPTED_INFO.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartAcceptedOfficePage)));
