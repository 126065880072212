//@ts-nocheck
import ReactSelect from 'react-select';
import FormField from 'components/form/field/FormField';
import InputDate from 'components/input/date/InputDate';
import InputValid from 'components/input/valid/InputValid';
import isEqual from 'lodash.isequal';
import Popup from 'page/cart/components/cartSummary/components/popup/Popup';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UPDATE_ONLINE_REGISTRATION_DATA } from 'redux/cart/actions';
import { getOnlineRegistrationData, mainPatientSelector } from 'redux/patient/selectors';
import FormHelper from 'utils/FormHelper';
import { parseISO } from 'utils/timeUtils';
import { validateNotBlank } from 'utils/validators';
import styles from './OnlineRegistrationBlock.module.css';
import messages from './OnlineRegistrationBlockMessages';
import commonMessages from 'messages/CommonMessages';
import { FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION, FETCH_REGIONS, CLEAR_ONLINE_REGISTRATION_DATA } from 'redux/patient/actions';
import { TerritoryType } from 'constants/territoryType';
import SelectPicker from 'components/selectpicker/SelectPicker';

class OnlineRegistrationBlock extends React.Component {
    constructor(props) {
        super(props);
        this.today = new Date();
        this.passportFieldNames = [
            'onlineRegistrationData.passport.series',
            'onlineRegistrationData.passport.number',
            'onlineRegistrationData.passport.issuer',
            'onlineRegistrationData.passport.issueDate',
            'onlineRegistrationData.passport.unitCode'
        ];
        this.addressFieldNames = [
            'onlineRegistrationData.address.country',
            'onlineRegistrationData.address.countries',
            'onlineRegistrationData.address.regionId',
            'onlineRegistrationData.address.regions',
            'onlineRegistrationData.address.city',
            'onlineRegistrationData.address.street',
            'onlineRegistrationData.address.homeNumber',
            'onlineRegistrationData.address.block',
            'onlineRegistrationData.address.apartment'
        ];
        this.state = this.initialState(props);
        this.validators = {
            'onlineRegistrationData.passport.series': validateNotBlank,
            'onlineRegistrationData.passport.number': validateNotBlank,
            'onlineRegistrationData.passport.issuer': validateNotBlank,
            'onlineRegistrationData.passport.issueDate': validateNotBlank,
            'onlineRegistrationData.passport.unitCode': validateNotBlank,
            'onlineRegistrationData.address.regionId': validateNotBlank,
            'onlineRegistrationData.address.countries': validateNotBlank,
            'onlineRegistrationData.address.city': validateNotBlank,
            'onlineRegistrationData.address.street': validateNotBlank,
            'onlineRegistrationData.address.homeNumber': validateNotBlank
        };
        this.formHelper = new FormHelper(this, this.validators);
        this.validate = this.validate.bind(this);
    }

    initialState(props) {
        const state = {
            errors: {}
        };
        this.passportFieldNames.forEach((name) => (state[name] = ''));
        this.addressFieldNames.forEach((name) => (state[name] = ''));
        return state;
    }

    componentDidMount() {
        if (this.props.onlineRegistrationData.errors) {
            this.setState(this.props.onlineRegistrationData);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            creationError,
            updateOnlineRegistrationData,
            patientOnlineRegistrationData,
            fetchAllowedCountries,
            fetchAllowedCountriesForOnlineRegistration,
            fetchRegions,
            allowedCountries,
            fetchAllRegions,
            regions,
            fetchOnlineRegistrationData
        } = this.props;
        if (!isEqual(prevState, this.state)) {
            const { ['onlineRegistrationData.address.countries']: countries, ['onlineRegistrationData.address.regions']: regions, ...rest } = this.state;

            updateOnlineRegistrationData({
                data: {
                    ...rest,
                    valid: this.isValid()
                }
            });
        }

        if (prevProps.creationError !== creationError && creationError?.errors) {
            const errorsState = {};
            const noPassport = creationError.errors.some(
                (error) => error.field === 'onlineRegistrationData' || error.field === 'onlineRegistrationData.passport'
            );
            if (noPassport) {
                this.passportFieldNames.forEach((name) => {
                    if (name.startsWith('onlineRegistrationData.passport.')) {
                        errorsState[name] = true;
                    }
                });
            }
            const noAddress = creationError.errors.some(
                (error) => error.field === 'onlineRegistrationData' || error.field === 'onlineRegistrationData.address'
            );
            if (noAddress) {
                this.addressFieldNames.forEach((name) => {
                    if (noAddress && name.startsWith('onlineRegistrationData.address.')) {
                        errorsState[name] = true;
                    }
                });
            }
            creationError.errors.forEach((error) => (errorsState[error.field] = true));
            this.setState({ errors: errorsState });
        }

        if (prevProps.fetchOnlineRegistrationData.success !== fetchOnlineRegistrationData.success && fetchOnlineRegistrationData.success) {
            fetchAllowedCountriesForOnlineRegistration();
        }

        if (prevProps.fetchAllowedCountries.success !== fetchAllowedCountries.success && fetchAllowedCountries.success) {
            if (!!allowedCountries.length) {
                const countries = allowedCountries?.map((country) => ({
                    value: country,
                    label: this.renderTerritoryName(country)
                }));
                this.setState({
                    ['onlineRegistrationData.address.countries']: countries,
                    ['onlineRegistrationData.address.country']: countries[0]
                });
                fetchRegions(countries[0]?.value);
            }
        }

        if (prevProps.fetchAllRegions.success !== fetchAllRegions.success && fetchAllRegions.success) {
            this.setState({ ['onlineRegistrationData.address.regions']: regions?.map((region) => ({ value: region.id, label: region.name })) });
        }

        const selectedCountry = this.state['onlineRegistrationData.address.country']?.value;
        const prevSelectedCountry = prevState['onlineRegistrationData.address.country']?.value;
        if (prevSelectedCountry && prevSelectedCountry !== selectedCountry) {
            fetchRegions(selectedCountry);
        }
    }

    componentWillUnmount() {
        this.props.clearOnlineRegistrationData();
    }

    render() {
        const { patientOnlineRegistrationData, intl } = this.props;
        if (patientOnlineRegistrationData.hasPassport && patientOnlineRegistrationData.hasAddress) {
            return null;
        }
        return (
            <div>
                <h2 className={styles.title}>
                    <FormattedMessage {...messages.title} />
                </h2>
                <p className={styles.description}>
                    <FormattedMessage {...messages.description} />
                </p>
                {!this.isValid() ? <Popup text={intl.formatMessage(messages.hint)} position='top' className={styles.popup} /> : null}
                {this.renderPassportForm()}
                {this.renderAddressForm()}
            </div>
        );
    }

    renderPassportForm() {
        const { patientOnlineRegistrationData, locale, intl } = this.props;
        if (patientOnlineRegistrationData.hasPassport) {
            return null;
        }
        return (
            <React.Fragment>
                <h3 className={styles.subtitle}>
                    <FormattedMessage {...messages.passport} />
                </h3>
                <form>
                    <FormField label={intl.formatMessage(messages.passportSeriesLabel)}>
                        <InputValid
                            name='onlineRegistrationData.passport.series'
                            value={this.state['onlineRegistrationData.passport.series']}
                            placeholder={intl.formatMessage(messages.passportSeriesPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.passport.series']}
                            maxLength='12'
                            valid={this.validate('onlineRegistrationData.passport.series')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.passportNumberLabel)}>
                        <InputValid
                            name='onlineRegistrationData.passport.number'
                            value={this.state['onlineRegistrationData.passport.number']}
                            placeholder={intl.formatMessage(messages.passportNumberPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.passport.number']}
                            maxLength='10'
                            valid={this.validate('onlineRegistrationData.passport.number')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.passportIssuerLabel)}>
                        <InputValid
                            name='onlineRegistrationData.passport.issuer'
                            value={this.state['onlineRegistrationData.passport.issuer']}
                            placeholder={intl.formatMessage(messages.passportIssuerPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.passport.issuer']}
                            maxLength='255'
                            valid={this.validate('onlineRegistrationData.passport.issuer')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.passportIssueDateLabel)}>
                        <InputDate
                            name='onlineRegistrationData.passport.issueDate'
                            selected={this.state['onlineRegistrationData.passport.issueDate']}
                            onChange={(date) =>
                                this.formHelper.handleValueChange({
                                    target: { name: 'onlineRegistrationData.passport.issueDate', value: date }
                                })
                            }
                            maxDate={this.today}
                            minDate={new Date(1900, 1, 1)}
                            locale={locale}
                            error={this.state.errors['onlineRegistrationData.passport.issueDate']}
                            valid={this.validate('onlineRegistrationData.passport.issueDate')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.passportUnitCodeLabel)}>
                        <InputValid
                            name='onlineRegistrationData.passport.unitCode'
                            value={this.state['onlineRegistrationData.passport.unitCode']}
                            placeholder={intl.formatMessage(messages.passportUnitCodePlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.passport.unitCode']}
                            maxLength='10'
                            valid={this.validate('onlineRegistrationData.passport.unitCode')}
                        />
                    </FormField>
                </form>
            </React.Fragment>
        );
    }

    renderAddressForm() {
        const { patientOnlineRegistrationData, intl } = this.props;
        if (patientOnlineRegistrationData.hasAddress) {
            return null;
        }
        const countries = this.state['onlineRegistrationData.address.countries'];
        return (
            <React.Fragment>
                <h3 className={styles.subtitle}>
                    <FormattedMessage {...messages.address} />
                </h3>
                <form>
                    <SelectPicker
                        label={intl.formatMessage(messages.addressCountryLabel)}
                        className={styles.selectField}
                        name={'country'}
                        value={countries[0]}
                        options={countries}
                        onChange={(event) =>
                            this.formHelper.handleValueChange(
                                {
                                    target: {
                                        name: 'onlineRegistrationData.address.country',
                                        value: event
                                    }
                                },
                                (value) => this.setState({ ['onlineRegistrationData.address.country']: value })
                            )
                        }
                        isClearable={false}
                        isSearchable={false}
                        isDisabled={countries?.length <= 1}
                    />

                    <SelectPicker
                        label={intl.formatMessage(messages.addressRegionLabel)}
                        className={styles.selectField}
                        name={'region'}
                        value={this.state['onlineRegistrationData.address.regionId']}
                        options={this.state['onlineRegistrationData.address.regions']}
                        onChange={(event) =>
                            this.formHelper.handleValueChange(
                                {
                                    target: {
                                        name: 'onlineRegistrationData.address.regionId',
                                        value: event
                                    }
                                },
                                (value) => this.setState({ ['onlineRegistrationData.address.regionId']: value })
                            )
                        }
                        isClearable={false}
                        isSearchable={true}
                        placeholder={intl.formatMessage(commonMessages.choose)}
                    />

                    <FormField label={intl.formatMessage(messages.addressCityLabel)}>
                        <InputValid
                            name='onlineRegistrationData.address.city'
                            value={this.state['onlineRegistrationData.address.city']}
                            placeholder={intl.formatMessage(messages.addressCityPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.address.city']}
                            maxLength='50'
                            valid={this.validate('onlineRegistrationData.address.city')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.addressStreetLabel)}>
                        <InputValid
                            name='onlineRegistrationData.address.street'
                            value={this.state['onlineRegistrationData.address.street']}
                            placeholder={intl.formatMessage(messages.addressStreetPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.address.street']}
                            maxLength='128'
                            valid={this.validate('onlineRegistrationData.address.street')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.addressHomeNumberLabel)}>
                        <InputValid
                            name='onlineRegistrationData.address.homeNumber'
                            value={this.state['onlineRegistrationData.address.homeNumber']}
                            placeholder={intl.formatMessage(messages.addressHomeNumberPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.address.homeNumber']}
                            maxLength='10'
                            valid={this.validate('onlineRegistrationData.address.homeNumber')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.addressBlockLabel)}>
                        <InputValid
                            name='onlineRegistrationData.address.block'
                            value={this.state['onlineRegistrationData.address.block']}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.address.block']}
                            maxLength='10'
                            valid={this.validate('onlineRegistrationData.address.block')}
                        />
                    </FormField>

                    <FormField label={intl.formatMessage(messages.addressApartmentLabel)}>
                        <InputValid
                            name='onlineRegistrationData.address.apartment'
                            value={this.state['onlineRegistrationData.address.apartment']}
                            placeholder={intl.formatMessage(messages.addressApartmentPlaceholder)}
                            onChange={(event) => this.formHelper.handleValueChange(event)}
                            error={this.state.errors['onlineRegistrationData.address.apartment']}
                            maxLength='10'
                            valid={this.validate('onlineRegistrationData.address.apartment')}
                        />
                    </FormField>
                </form>
            </React.Fragment>
        );
    }

    validate(fieldName) {
        return this.formHelper.isValid([fieldName]);
    }

    isValid() {
        const { patientOnlineRegistrationData } = this.props;
        return (
            (patientOnlineRegistrationData.hasPassport || this.formHelper.isValid(this.passportFieldNames)) &&
            (patientOnlineRegistrationData.hasAddress || this.formHelper.isValid(this.addressFieldNames))
        );
    }

    renderTerritoryName(territory) {
        const { intl } = this.props;
        switch (territory) {
            case TerritoryType.RUSSIA:
                return intl.formatMessage(commonMessages.RUSSIA);
            case TerritoryType.BELARUS:
                return intl.formatMessage(commonMessages.BELARUS);
            case TerritoryType.KAZAKHSTAN:
                return intl.formatMessage(commonMessages.KAZAKHSTAN);
            case TerritoryType.UKRAINE:
                return intl.formatMessage(commonMessages.UKRAINE);
            case TerritoryType.ARMENIA:
                return intl.formatMessage(commonMessages.ARMENIA);
            case TerritoryType.KYRGYZSTAN:
                return intl.formatMessage(commonMessages.KYRGYZSTAN);
            default:
                return '';
        }
    }
}

OnlineRegistrationBlock.propTypes = {
    locale: PropTypes.string.isRequired,
    updateOnlineRegistrationData: PropTypes.func.isRequired,
    onlineRegistrationData: PropTypes.object,
    mainPatient: PropTypes.object,
    creationError: PropTypes.any,
    patientOnlineRegistrationData: PropTypes.object
};

const mapStateToProps = (state) => {
    const mainPatient = mainPatientSelector(state);
    const create = state.orders.create;
    return {
        locale: state.i18n.currentLocale,
        onlineRegistrationData: state.cart.onlineRegistrationData,
        patientOnlineRegistrationData: getOnlineRegistrationData(state),
        creationError: create.error,
        mainPatient,
        fetchAllowedCountries: state.patient.fetchAllowedCountriesForOnlineRegistration,
        allowedCountries: state.patient.allowedCountries,
        fetchAllRegions: state.patient.fetchRegions,
        regions: state.patient.regions,
        fetchOnlineRegistrationData: state.patient.fetchOnlineRegistrationData
    };
};

const mapDispatchToProps = {
    updateOnlineRegistrationData: UPDATE_ONLINE_REGISTRATION_DATA.base,
    fetchAllowedCountriesForOnlineRegistration: FETCH_ALLOWED_COUNTRIES_FOR_ONLINE_REGISTRATION.base,
    fetchRegions: FETCH_REGIONS.base,
    clearOnlineRegistrationData: CLEAR_ONLINE_REGISTRATION_DATA.base
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OnlineRegistrationBlock));
