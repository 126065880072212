import { createSelector } from 'reselect';
import { selectedPatientIdSelector } from 'redux/patient/selectors';
import { Store } from 'types/store';

export const loyaltyEntitiesSelector = (state: Store.App.State) => state.loyaltyStatus.entities;

export const getByPatientId = createSelector(
    [selectedPatientIdSelector, loyaltyEntitiesSelector],
    (selectedPatientId, loyaltyEntities) => loyaltyEntities[selectedPatientId]
);

export const fetchCouponSelector = (state: Store.App.State) => state.loyaltyStatus.fetchAppliedCoupon;
