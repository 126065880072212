import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'ReactivationPage.pageTitle',
        defaultMessage: 'Account activation'
    },
    cardTitle: {
        id: 'ReactivationPage.cardTitle',
        defaultMessage: 'The patient has been found, come up with a new password'
    },
    cardDescription: {
        id: 'ReactivationPage.cardDescription',
        defaultMessage: 'Come up with a new password'
    }
});
