import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/Hooks';
import { activationSelector } from 'redux/loyaltyIKB/selectors';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import styles from './IKBCashbackActivationPage.module.css';
import activationCashbackIcon from '../assets/activationCashbackIcon.svg';
import messages from './IKBCashbackActivationMessages';
import Button from 'components/button/Button';
import commonMessages from 'messages/CommonMessages';

const IKBCashbackActivationPage: FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const activation = useAppSelector((state) => activationSelector(state));

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.cashbackActivation)} back={true} />
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.infoBlock}>
                        <div className={styles.info}>
                            <h3 className={styles.title}>{formatMessage(messages.title)}</h3>
                            <span>{formatMessage(messages.notice, { cardNumber: activation.discountNumber })}</span>
                        </div>
                        <img className={styles.activationCashbackIcon} alt='' src={activationCashbackIcon} />
                    </div>
                    <div className={styles.buttons}>
                        <Button onClick={() => navigate('/loyalty')}>{formatMessage(messages.cancel)}</Button>
                        <Button onClick={() => navigate('/loyalty/ikb/cashback/activation/phone')}>{formatMessage(messages.iAgree)}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(IKBCashbackActivationPage);
