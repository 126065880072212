import React, { FC, memo } from 'react';
import styles from './IKBNoticeCard.module.css';
import Button from 'components/button/Button';
type TProps = {
    icon: string;
    title: string;
    description: string;
    textButton?: string;
    onClick?: () => void;
};

const IKBNoticeCard: FC<TProps> = ({ icon, title, description, textButton, onClick }) => {
    return (
        <div className={styles.card}>
            <div className={styles.infoBlock}>
                <div className={styles.info}>
                    {title && <h3 className={styles.title}>{title}</h3>}
                    {description && <span>{description}</span>}
                </div>
                {icon && <img className={styles.icon} alt='' src={icon} />}
            </div>
            {textButton && onClick && <Button onClick={onClick}>{textButton}</Button>}
        </div>
    );
};

export default memo(IKBNoticeCard);
