//@ts-nocheck
/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import AlertWarning from 'components/alert/AlertWarning';
import commonMessages from 'messages/CommonMessages';

export default class FormHelper {
    constructor(component, validators) {
        this.component = component;
        this.validators = validators || {};
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleValueChange(object, onValidCallback = null, validate = true) {
        const target = object.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const valid = validate && this.validateField(target.name, value);
        this.component.setState({ [target.name]: value });
        if (valid && onValidCallback && typeof onValidCallback === 'function') {
            onValidCallback(value, target.name);
        }
    }

    validateField(field, value, errorsHolder) {
        const validator = this.validators[field];
        let isValid;
        if (validator) {
            isValid = validator(value);
        } else {
            isValid = true;
        }
        // Если передан holder для ошибок, то не нужно доставать его из state
        // так как вызывающая функция сама займётся обновлением состояния
        const errors = errorsHolder || { ...(this.component.state.errors || {}) };
        if (isValid) {
            delete errors[field];
        } else {
            errors[field] = true;
        }
        if (!errorsHolder) {
            this.component.setState({ errors });
        }
        return isValid;
    }

    validate(fields) {
        let isValid = true;
        const errorsHolder = {};
        fields.forEach((field) => {
            const isFieldValid = this.validateField(field, this.component.state[field], errorsHolder);
            isValid = isFieldValid && isValid;
        });
        this.component.setState({ errors: errorsHolder });
        return isValid;
    }

    isValid(fields) {
        return fields
            .map((field) => !this.validators[field] || this.validators[field](this.component.state[field]))
            .reduce((acc, isValid) => acc && !!isValid, true);
    }

    renderError() {
        return this.component.props.error ? <AlertWarning content={this.renderErrorMessage()} /> : null;
    }

    renderErrorMessage() {
        const error = this.component.props.error;
        if (error?.errors && error.errors.length !== 0) {
            const errors = error.errors;
            return errors.map((error) => {
                return (
                    <React.Fragment key={error.message}>
                        {error.message}
                        <br />
                    </React.Fragment>
                );
            });
        } else if (error?.message) {
            return error.message;
        } else {
            return <FormattedMessage {...commonMessages.serverError} />;
        }
    }

    isValidReCaptchaV3(enable, code) {
        return (enable && code.length > 0) || !enable;
    }
}
