import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './IKBNotificationMessages';
import styles from './IKBNotification.module.css';

const IKBNotification: FC = () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{formatMessage(messages.title)}</h1>
            <div className={styles.notification}>
                <p>{formatMessage(messages.notification)}</p>
            </div>
        </div>
    );
};

export default memo(IKBNotification);
