import React, { FC, memo, ReactElement } from 'react';
import styles from './Card.module.css';
import Button from 'components/button/Button';
import warning from 'shared/assets/warning.svg';
import success from 'page/auth/assets/success.svg';

interface TProps {
    title: string;
    show?: boolean;
    textButton?: string;
    description?: any;
    onClick?: () => void;
    buttons?: ReactElement;
    icon?: 'warning' | 'success';
}

const Card: FC<TProps> = ({ show, icon, title, description, textButton, buttons, onClick }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={styles.container}>
            {icon && <img className={styles.icon} src={icon === 'warning' ? warning : success} alt='' />}
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.description}>{description}</div>
            {textButton && <Button onClick={onClick}>{textButton}</Button>}
            {buttons && <div className={styles.buttons}>{buttons}</div>}
        </div>
    );
};

export default memo(Card);
