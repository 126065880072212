import React, { FC, memo } from 'react';
import styles from './RegularPageCard.module.css';

type TProps = {
    title?: string;
    message: string;
    percent: number;
    index: number;
    icon?: string;
};

const RegularPageCard: FC<TProps> = ({ title, message, percent, index, icon }) => {
    const getTitleColorHex = (index: number) => {
        return { color: ['#FC6A77', '#F79440', '#08C3DC'][index] };
    };

    return (
        <div className={styles.card}>
            <div className={styles.cardHeader}>
                <span className={styles.cardHeaderTitle} style={getTitleColorHex(index)}>
                    {title}
                </span>
                <span className={styles.cardHeaderMessage}>{message}</span>
            </div>
            <div className={styles.cardBody}>
                <span className={styles.cardPercent} style={getTitleColorHex(index)}>
                    {percent}%
                </span>
                <img alt='' src={icon} />
            </div>
        </div>
    );
};

export default memo(RegularPageCard);
