import { useState, useEffect } from 'react';

export const useTimer = (isCodeSent: boolean, hideTimer: () => void, delay: number) => {
    const [timer, setTimer] = useState<number>(0);

    useEffect(() => {
        if (isCodeSent && delay) {
            setTimer(delay);
            let newTime = delay;
            const interval = setInterval(() => {
                newTime -= 1;
                setTimer(newTime);
                if (newTime <= 0) {
                    hideTimer();
                    clearInterval(interval);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isCodeSent]);

    return timer;
};
