import React, { FC, memo } from 'react';
import styles from './IconCart.module.css';

type TProps = {
    className?: string;
};

const IconCart: FC<TProps> = ({ className }) => {
    return (
        <svg className={className ? className : styles.icon}>
            <g transform='translate(-1150 -10)'>
                <path d='M1170 50c-11.03 0-20-8.97-20-20s8.97-20 20-20 20 8.97 20 20-8.97 20-20 20zm0-38c-9.92 0-18 8.07-18 18s8.08 18 18 18 18-8.07 18-18-8.08-18-18-18zm-7.3 13h-1.7v-2h3l1.33 3H1177v5.5l-9.9 2.83A2 2 0 011166 38s1.1 0 0 0a2 2 0 11.77-3.85zm3.3 13zm.22-10l1.78 4 7-2v-2zm7.78 10a2 2 0 110-4 2 2 0 010 4s1.1 0 0 0z' />
                <path d='M1170 50c-11.03 0-20-8.97-20-20s8.97-20 20-20 20 8.97 20 20-8.97 20-20 20zm0-38c-9.92 0-18 8.07-18 18s8.08 18 18 18 18-8.07 18-18-8.08-18-18-18zm-7.3 13h-1.7v-2h3l1.33 3H1177v5.5l-9.9 2.83A2 2 0 011166 38s1.1 0 0 0a2 2 0 11.77-3.85zm3.3 13zm.22-10l1.78 4 7-2v-2zm7.78 10a2 2 0 110-4 2 2 0 010 4s1.1 0 0 0z' />
            </g>
        </svg>
    );
};

export default memo(IconCart);
