import { defineMessages } from 'react-intl';

export default defineMessages({
    statusPreorder: {
        id: 'OrderStatus.statusPreorder',
        defaultMessage: 'Result prepared, waiting you in BO "{office}"'
    },
    statusPreorderUnknownOffice: {
        id: 'OrderStatus.statusPreorderUnknownOffice',
        defaultMessage: 'Result prepared, waiting you in selected office'
    },
    statusNew: {
        id: 'OrderStatus.statusNew',
        defaultMessage: 'Order in work, result would be ready at {date} including'
    },
    statusNewUnknownDate: {
        id: 'OrderStatus.statusNewUnknownDate',
        defaultMessage: 'Order in work, results not completed yet'
    },
    statusPartial: {
        id: 'OrderStatus.statusPartial',
        defaultMessage: 'Order is partially ready, check order results'
    },
    statusCompleted: {
        id: 'OrderStatus.statusCompleted',
        defaultMessage: 'Order ready'
    },
    statusCompletedResultsReady: {
        id: 'OrderStatus.statusCompletedResultsReady',
        defaultMessage: ', order results completed'
    },
    statusCancelled: {
        id: 'OrderStatus.statusCancelled',
        defaultMessage: 'Order cancelled'
    },
    statusErrorWithSupportPhone: {
        id: 'OrderStatus.statusErrorWithSupportPhone',
        defaultMessage: 'Error while processing order, please call <phone></phone>'
    },
    statusError: {
        id: 'OrderStatus.statusError',
        defaultMessage: 'Error while processing order, please call contact center'
    },
    statusNotPaid: {
        id: 'OrderStatus.statusNotPaid',
        defaultMessage: 'Order not paid'
    },
    statusPaymentInProgress: {
        id: 'OrderStatus.statusPaymentInProgress',
        defaultMessage: 'The order is in the process of payment'
    },
    statusPaid: {
        id: 'OrderStatus.statusPaid',
        defaultMessage: 'Order paid'
    }
});
