import React, { FC, useEffect, useState, memo } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button from 'components/button/Button';
import FormField from 'components/form/field/FormField';
import InputDate from 'components/input/date/InputDate';
import Input from 'components/input/Input';
import InputRadio from 'components/input/radio/InputRadio';
import LoaderModal from 'components/modal/loader/LoaderModal';
import Modal from 'components/modal/Modal';
import ModalSuccess from 'components/modal/success/ModalSuccess';

import { create } from 'redux/patient/actions';
import { currentSelector } from 'redux/user/selectors';
import { CITY_GUID_COOKIE_KEY } from 'redux/cities/constants';
import FC_FormHelper from 'utils/FC_FormHelper';
import { formatISODate } from 'utils/timeUtils';
import { validateEmail, validateNotBlank, validatePhoneNumber } from 'utils/validators';
import { getCookie } from 'utils/browserUtils';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { localeSelector } from 'redux/i18n/selectors';
import messages from 'page/partial/patient/create/CreatePatientModalMessages';
import commonMessages from 'messages/CommonMessages';
import styles from 'page/partial/patient/create/CreatePatientModal.module.css';

declare const window: any;

type TProps = {
    show: boolean;
    onClose: () => void;
};

interface IErrors {
    gender: boolean;
    firstName: boolean;
    lastName: boolean;
    birthday: boolean;
    mobilePhone: boolean;
    email: boolean;
}
interface IState {
    gender: string;
    lastName: string;
    firstName: string;
    middleName: string;
    birthday: string;
    mobilePhone: string;
    email: string;
    errors: Partial<IErrors>;
    showSuccess: boolean;
}

const initState = () => {
    return {
        gender: '',
        lastName: '',
        firstName: '',
        middleName: '',
        birthday: '',
        mobilePhone: '',
        email: '',
        errors: {
            gender: false,
            lastName: false,
            firstName: false,
            middleName: false,
            birthday: false,
            mobilePhone: false,
            email: false
        },
        showSuccess: false
    };
};

const CreatePatientModal: FC<TProps> = ({ show, onClose }) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const { locale } = useAppSelector(localeSelector);
    const { success, loading, error } = useAppSelector((state) => state.patient.create);
    const user = useAppSelector(currentSelector);
    const [state, setState] = useState<IState>(initState());
    const today = new Date();

    const validators = {
        gender: validateNotBlank,
        lastName: validateNotBlank,
        firstName: validateNotBlank,
        birthday: validateNotBlank,
        mobilePhone: validatePhoneNumber,
        email: validateEmail
    };

    const formHelper = new FC_FormHelper(state, setState, error, validators);

    useEffect(() => {
        if (show) {
            setState(initState());
        }
    }, [show]);

    useEffect(() => {
        if (success) {
            window.dataLayer.push({
                event: 'addEvents_makeActions',
                action_cat: 'lk3',
                action_name: 'patients',
                action_param: 'Add patient_save',
                eNI: '0'
            });
            setState((prevState) => ({ ...prevState, showSuccess: true }));
        }
    }, [success]);

    useEffect(() => {
        if (error?.errors) {
            const errorsState: Record<string, boolean> = { ...state.errors };
            error.errors.forEach((error: Record<string, string>) => (errorsState[error.field] = true));
            setState((prevState: IState) => ({ ...prevState, errors: errorsState }));
        }
    }, [error]);

    const handleCreate = async () => {
        const { gender, lastName, firstName, middleName, birthday, mobilePhone, email } = state;

        const cityId = getCookie(CITY_GUID_COOKIE_KEY) as string;
        if (formHelper.validate(['gender', 'lastName', 'firstName', 'middleName', 'birthday', 'mobilePhone', 'email'])) {
            dispatch(
                create({
                    userId: user.id,
                    gender,
                    lastName,
                    firstName,
                    middleName,
                    birthday: formatISODate(birthday),
                    mobilePhone,
                    email,
                    cityId
                })
            );
        }
    };

    return (
        <Modal show={show} onClose={onClose} closable={true} className={styles.modal} overlayClassName={styles.container}>
            <h2>{formatMessage(messages.title)}</h2>
            <>{formHelper.renderError()}</>
            <div>
                <FormField label={formatMessage(commonMessages.genderLabel)}>
                    <div className={styles.formGroup}>
                        <InputRadio
                            name='gender'
                            value='MALE'
                            error={state.errors.gender}
                            checked={state.gender === 'MALE'}
                            onChange={formHelper.handleValueChange}
                            label={formatMessage(commonMessages.genderMale)}
                        />
                        <InputRadio
                            name='gender'
                            value='FEMALE'
                            error={state.errors.gender}
                            checked={state.gender === 'FEMALE'}
                            onChange={formHelper.handleValueChange}
                            label={formatMessage(commonMessages.genderFemale)}
                        />
                    </div>
                </FormField>
            </div>
            <div className={classNames(styles.formGroup, styles.stretch)}>
                <FormField label={formatMessage(commonMessages.lastNameLabel)}>
                    <Input name='lastName' error={state.errors.lastName} value={state.lastName} onChange={formHelper.handleValueChange} />
                </FormField>

                <FormField label={formatMessage(commonMessages.firstNameLabel)}>
                    <Input name='firstName' error={state.errors.firstName} value={state.firstName} onChange={formHelper.handleValueChange} />
                </FormField>

                <FormField label={formatMessage(messages.middleNameLabel)}>
                    <Input name='middleName' value={state.middleName} onChange={formHelper.handleValueChange} />
                </FormField>
            </div>
            <div className={classNames(styles.formGroup, styles.stretch)}>
                <FormField label={formatMessage(commonMessages.birthdayLabel)}>
                    <InputDate
                        name='birthday'
                        selected={state.birthday}
                        onChange={(date) => formHelper.handleValueChange({ target: { name: 'birthday', value: date } })}
                        maxDate={today}
                        minDate={new Date(1900, 1, 1)}
                        locale={locale}
                        error={state.errors.birthday}
                    />
                </FormField>

                <FormField label={formatMessage(commonMessages.phone)}>
                    <Input name='mobilePhone' type='phone' error={state.errors.mobilePhone} value={state.mobilePhone} onChange={formHelper.handleValueChange} />
                </FormField>

                <FormField label={formatMessage(commonMessages.email)}>
                    <Input name='email' type='email' error={state.errors.email} value={state.email} onChange={formHelper.handleValueChange} />
                </FormField>
            </div>
            <div className={classNames(styles.formGroup, styles.buttonsContainer)}>
                <Button text={formatMessage(commonMessages.save)} onClick={() => handleCreate()} />
            </div>
            <LoaderModal show={loading} />
            <ModalSuccess
                message={formatMessage(messages.createSuccess)}
                show={state.showSuccess}
                onClose={() => {
                    setState((prevState) => ({ ...prevState, showSuccess: false }));
                    onClose();
                }}
            />
        </Modal>
    );
};

export default memo(CreatePatientModal);
