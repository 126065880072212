//@ts-nocheck
import queryString from 'query-string';
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';

const PRODUCTS_URL = `${BASE_URL}/products`;

export const fetchAll = (data) => {
    return apiClient.get(`${PRODUCTS_URL}?${queryString.stringify(data)}`);
};
