import React, { FC, Fragment } from 'react';
import styles from '../Common.module.css';
import commonMessages from 'messages/CommonMessages';
import { useIntl } from 'react-intl';
import { LoginType } from 'constants/loginType';

interface InstructionProps {
    loginType: LoginType;
    login: string;
}

const Instruction: FC<InstructionProps> = ({ loginType, login }) => {
    const { formatMessage } = useIntl();

    if (loginType === LoginType.PHONE) {
        return (
            <Fragment>
                <h2 className={styles.title}>{formatMessage(commonMessages.enterCodeSMS)}</h2>
                <div className={styles.description}>
                    {formatMessage(commonMessages.weSentCodePhone)} <span>{login}</span>.
                </div>
            </Fragment>
        );
    }

    if (loginType === LoginType.EMAIL) {
        return (
            <Fragment>
                <h2 className={styles.title}>{formatMessage(commonMessages.enterCodeFromMail)}</h2>
                <div className={styles.description}>
                    {formatMessage(commonMessages.weSentCodeEmail)} <span>{login}</span>.
                </div>
            </Fragment>
        );
    }

    return null;
};

export default Instruction;
