//@ts-nocheck
import { OfficeType } from 'constants/officeType';
import { OrderWarningType } from 'constants/orderWarningType';
import { OnlinePaymentType, PaymentType } from 'constants/paymentType';
import { currentCitySelector } from 'redux/cities/selectors';
import { chosenOfficeSelector } from 'redux/offices/selectors';
import { productsByArticlesSelector } from 'redux/products/selectors';
import { createSelector } from 'reselect';
import { CART_ORDER_TYPE } from './constants';
import { isCheckup, isService } from 'utils/classifierTypeUtils';
import { Store } from 'types/store';
import { ordersSelector } from 'redux/orders/selectors';
import { mainPatientSelector, patientsByIdSelector, patientSelector, sortedByMainPatientsSelector } from 'redux/patient/selectors';
import {
    orderIdSelector,
    reorderCalculationSelector,
    reorderDataSelector,
    reorderPatientSelector,
    reorderValidAnalyzesIdsSelector
} from 'redux/cart/reorder.selectors';
import { authSelector } from 'redux/auth/selectors';
import { Currency } from 'constants/currency';
import { MarketingMechanics } from 'types/common';
const { OFFICE } = PaymentType;
const { CLOUD_PAYMENTS, PSB, SBER_PAY, ALFA_PAY, LOYALTY } = OnlinePaymentType;

export const cartSelector = (state: Store.App.State) => state.cart;

export const officeCalculationSelector = (state) => state.cart.calculateOffice;

export const officeDataSelector = (state: Store.App.State) => state.cart.officeData;

export const homeVisitCalculationSelector = (state) => state.cart.calculateHomeVisit;

export const homeVisitDataSelector = (state) => state.cart.homeVisitData;

export const checkupsCalculationSelector = (state) => state.cart.calculateCheckups;

export const checkupsDataSelector = (state) => state.cart.checkupsData;

export const paymentPreorderIdSelector = (state) => state.cart.paymentPreorderId;

export const paymentPreorderCalculationSelector = (state) => state.cart.calculatePaymentPreorder;

export const paymentPreorderDataSelector = (state) => state.cart.paymentPreorderData;

export const onlineRegistrationEnabledSelector = (state) => state.cart.onlineRegistrationEnabled;

export const appointmentSelector = (state) => state.cart.appointment;

const selectedPatientIdSelector = (state) => state.cart.selectedPatientId;

export const selectedPaymentTypeSelector = (state) => state.cart.selectedPaymentType;

export const cartDataSelector = (state) => state.cart.data;

export const cartByCodeSelector = (state) => state.cart.cartByCode;

export const cartNumberSelector = (state) => state.cart.cartNumber;

export const cartCheckupDeliverySelector = (state) => state.cart.checkupDelivery;

export const cartDataProductsSelector = createSelector([cartDataSelector, productsByArticlesSelector], (cartData, productByArticle) => {
    if (!cartData.products || !Array.isArray(cartData.products)) {
        return [];
    } else {
        return cartData.products.map((product) => {
            return { ...product, ...productByArticle[product.article] };
        });
    }
});

export const cartDataCheckupsSelector = createSelector([cartDataProductsSelector], (products) => {
    return products
        .filter((product) => isCheckup(product))
        .map((product) => {
            return { ...product, removable: true, quantity: product.quantity || 1 };
        });
});

export const cartDataAnalyzesSelector = createSelector([cartDataProductsSelector], (products) => {
    return products.filter((product) => product.id && !isCheckup(product)) || [];
});

export const cartModeSelector = createSelector([(state) => state.cart.cartOrderType], (cartMode) => cartMode);

export const cartOrderTypeSelector = createSelector([cartModeSelector], (cartMode) => {
    return {
        analyzes: cartMode === CART_ORDER_TYPE.ANALYZES,
        checkups: cartMode === CART_ORDER_TYPE.CHECKUPS,
        reorder: cartMode === CART_ORDER_TYPE.REORDER,
        paymentPreorder: cartMode === CART_ORDER_TYPE.PAYMENT_PREORDER
    };
});

export const cartDataProductsCountSelector = createSelector(
    [cartDataProductsSelector],
    (cartDataProducts) => cartDataProducts.filter((product) => !product.deleted)?.length || 0
);

export const cartDataAnalyzesArticlesSelector = createSelector([cartDataAnalyzesSelector], (cartDataAnalyzes) => [
    ...new Set(cartDataAnalyzes.filter((analysis) => !analysis.deleted).map((analysis) => analysis.article) || [])
]);

export const cartDataProductsArticlesSelector = createSelector([cartDataSelector], (cartData) => [
    ...new Set(cartData?.products?.map((product) => product.article) || [])
]);

export const cartDataProductsIdsSelector = createSelector([cartDataAnalyzesSelector], (cartDataAnalyzes) => [
    ...new Set(
        cartDataAnalyzes
            .filter((product) => !product.deleted)
            .filter((product) => product.id)
            .map((product) => product.id) || []
    )
]);

export const officeTypeSelector = createSelector(
    [(state) => state.cart.officeType, (state) => currentCitySelector(state).homeVisitAvailable, cartModeSelector],
    (officeType, homeVisitAvailable, cartMode) => {
        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: !homeVisitAvailable ? OfficeType.OFFICE : null
            },
            officeType ? officeType : null
        );
    }
);

export const homeVisitDataByOfficeType = createSelector(
    [officeTypeSelector, chosenOfficeSelector, homeVisitDataSelector],
    (officeType, chosenOffice, homeVisitData) => (officeType === OfficeType.HOME_VISIT ? homeVisitData : null)
);

export const officeDataByChosenOfficeSelector = createSelector(
    [officeTypeSelector, chosenOfficeSelector, officeDataSelector],
    (officeType, chosenOffice, officeData) => (officeType === OfficeType.OFFICE && chosenOffice?.office?.id === officeData?.officeId ? officeData : null)
);

export const commonCalculationRequestSelector = createSelector(
    [
        cartModeSelector,
        officeTypeSelector,
        homeVisitCalculationSelector,
        officeCalculationSelector,
        checkupsCalculationSelector,
        reorderCalculationSelector,
        paymentPreorderCalculationSelector
    ],
    (cartMode, officeType, homeVisitCalculation, officeCalculation, checkupsCalculation, reorderCalculation, paymentPreorderCalculation) => {
        let request = {};

        if (officeType === OfficeType.HOME_VISIT) {
            request = homeVisitCalculation?.request;
        }

        if (officeType === OfficeType.OFFICE) {
            request = officeCalculation?.request;
        }

        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: request,
                CHECKUPS: checkupsCalculation?.request,
                REORDER: reorderCalculation?.request,
                PAYMENT_PREORDER: paymentPreorderCalculation?.request
            },
            {}
        );
    }
);

export const commonCalculationResponseSelector = createSelector(
    [
        cartModeSelector,
        officeTypeSelector,
        homeVisitCalculationSelector,
        officeCalculationSelector,
        checkupsCalculationSelector,
        reorderCalculationSelector,
        paymentPreorderCalculationSelector
    ],
    (cartMode, officeType, homeVisitCalculation, officeCalculation, checkupsCalculation, reorderCalculation, paymentPreorderCalculation) => {
        let response = {};

        if (officeType === OfficeType.HOME_VISIT) {
            response = homeVisitCalculation?.response;
        }

        if (officeType === OfficeType.OFFICE) {
            response = officeCalculation?.response;
        }

        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: response,
                CHECKUPS: checkupsCalculation?.response,
                REORDER: reorderCalculation?.response,
                PAYMENT_PREORDER: paymentPreorderCalculation?.response
            },
            {}
        );
    }
);

export const commonCalculationDataSelector = createSelector(
    [
        cartModeSelector,
        officeTypeSelector,
        homeVisitDataByOfficeType,
        officeDataByChosenOfficeSelector,
        checkupsDataSelector,
        reorderDataSelector,
        paymentPreorderDataSelector
    ],
    (cartMode, officeType, homeVisitData, officeData, checkupsData, reorderData, paymentPreorderData) => {
        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: homeVisitData || officeData || {},
                CHECKUPS: checkupsData,
                REORDER: reorderData,
                PAYMENT_PREORDER: paymentPreorderData
            },
            null
        );
    }
);

export const productsSelector = createSelector(
    [
        (state) => state.cart.cartOrderType,
        cartDataAnalyzesSelector,
        cartDataCheckupsSelector,
        officeTypeSelector,
        chosenOfficeSelector,
        reorderValidAnalyzesIdsSelector,
        commonCalculationDataSelector,
        checkupsDataSelector,
        officeDataSelector
    ],
    (cartMode, cartDataAnalyzes, cartDataCheckups, officeType, cartOffice, reorderValidAnalyzesIds, calculationData, checkupsData, officeData) => {
        let validAnalyzes = [];
        let invalidAnalyzes = [];
        let validCheckups = [];
        let invalidCheckups = [];

        const hasActive = (products) => {
            return products.filter((product) => !product.deleted).length > 0;
        };

        const hasDeleted = (products) => {
            return products.filter((product) => product.deleted).length > 0;
        };

        const getAllAnalyzes = () => {
            if (hasActive(cartDataAnalyzes)) {
                validAnalyzes.push(...cartDataAnalyzes.map((product) => ({ ...product, removable: true })));
            }
        };

        const getAllCheckups = () => {
            if (hasActive(cartDataCheckups)) {
                validCheckups.push(...cartDataCheckups);
            }
        };

        const validationCheckups = () => {
            validCheckups = [];
            invalidCheckups = [];

            if (hasActive(cartDataCheckups)) {
                if (checkupsData?.checkups) {
                    const validIds = new Set<string>();

                    validCheckups.push(
                        ...checkupsData.checkups.map((checkup) => {
                            validIds.add(checkup.product.id);

                            return {
                                ...checkup,
                                ...checkup.product,
                                removable: isRemovable(checkup),
                                quantity: checkup.quantity
                            };
                        })
                    );

                    invalidCheckups.push(...cartDataCheckups.filter((checkup) => !validIds.has(checkup.id) && !checkup.deleted));
                } else {
                    getAllCheckups();
                }
            }

            return {
                all: [...validCheckups, ...invalidCheckups],
                valid: validCheckups,
                invalid: invalidCheckups,
                hasValid: validCheckups.length > 0,
                hasInvalid: invalidCheckups.length > 0
            };
        };

        const validationHomeVisit = () => {
            if (hasActive(cartDataAnalyzes)) {
                if (officeType === OfficeType.HOME_VISIT && calculationData?.products) {
                    const cartProductsById = cartDataAnalyzes.reduce((map, product) => {
                        map[product.id] = product;
                        return map;
                    }, {});

                    const validIds = new Set();
                    calculationData?.products?.forEach((product) => {
                        validIds.add(product.product.id);
                        const convertedProduct = {
                            ...toProduct(product),
                            deleted: cartProductsById[product.product.id]?.deleted,
                            removable: isRemovable(product)
                        };
                        if (product.available) {
                            validAnalyzes.push(convertedProduct);
                        } else {
                            invalidAnalyzes.push(convertedProduct);
                        }
                    });
                    validAnalyzes.push(...cartDataAnalyzes.filter((product) => !validIds.has(product.id) && product.deleted));
                } else {
                    getAllAnalyzes();
                }
            }
        };

        const validationOffice = () => {
            if (hasActive(cartDataAnalyzes)) {
                if (cartOffice) {
                    if (officeData?.orderProducts && cartOffice?.office?.id === officeData?.officeId) {
                        const validIds = new Set<string>();
                        validAnalyzes.push(
                            ...officeData.orderProducts.map((product) => {
                                validIds.add(product.product.id);
                                return {
                                    ...toProduct(product),
                                    deleted: false,
                                    removable: product.removable && isRemovable(product)
                                };
                            })
                        );

                        validAnalyzes.push(...cartDataAnalyzes.filter((product) => !validIds.has(product.id)).filter((product) => product.deleted));
                    } else {
                        cartDataAnalyzes
                            .filter((product) => product.id)
                            .forEach((product) => {
                                const convertedProduct = {
                                    ...product,
                                    removable: isRemovable(product)
                                };
                                if (product.deleted || cartOffice.validProductsIds?.includes(product.id)) {
                                    validAnalyzes.push(convertedProduct);
                                } else {
                                    invalidAnalyzes.push(convertedProduct);
                                }
                            });
                    }
                } else {
                    getAllAnalyzes();
                }
            }
        };

        const validationOfficeType = () => {
            validAnalyzes = [];
            invalidAnalyzes = [];

            if (officeType === OfficeType.HOME_VISIT) {
                validationHomeVisit();
            } else if (officeType === OfficeType.OFFICE) {
                validationOffice();
            } else {
                getAllAnalyzes();
            }

            return {
                all: [...validAnalyzes, ...invalidAnalyzes],
                valid: validAnalyzes,
                invalid: invalidAnalyzes,
                hasValid: validAnalyzes.length > 0,
                hasInvalid: invalidAnalyzes.length > 0
            };
        };

        const validationReorder = () => {
            validAnalyzes = [];
            invalidAnalyzes = [];

            if (calculationData?.orderProducts && hasActive(cartDataAnalyzes)) {
                const validIds = new Set();
                validAnalyzes.push(
                    ...calculationData.orderProducts.map((product) => {
                        validIds.add(product.product.id);
                        return {
                            ...toProduct(product),
                            deleted: false,
                            removable: product.removable && isRemovable(product)
                        };
                    })
                );

                invalidAnalyzes.push(...cartDataAnalyzes.filter((product) => !validIds.has(product.id) && !product.deleted));
            } else {
                getAllAnalyzes();
            }

            return {
                analyzes: {
                    all: [...validAnalyzes, ...invalidAnalyzes],
                    valid: validAnalyzes,
                    invalid: invalidAnalyzes,
                    hasValid: validAnalyzes.length > 0,
                    hasInvalid: invalidAnalyzes.length > 0
                }
            };
        };

        const validationPaymentPreorder = () => {
            validAnalyzes = [];
            invalidAnalyzes = [];

            if (calculationData?.orderProducts) {
                validAnalyzes.push(
                    ...calculationData.orderProducts.map((product) => {
                        return {
                            ...toProduct(product)
                        };
                    })
                );
            }

            return {
                analyzes: {
                    all: [...validAnalyzes, ...invalidAnalyzes],
                    valid: validAnalyzes,
                    invalid: invalidAnalyzes,
                    hasValid: validAnalyzes.length > 0,
                    hasInvalid: invalidAnalyzes.length > 0
                }
            };
        };

        return getDataByCartMode(
            cartMode,
            {
                REORDER: validationReorder(),
                PAYMENT_PREORDER: validationPaymentPreorder()
            },
            {
                checkups: validationCheckups(),
                analyzes: validationOfficeType()
            }
        );
    }
);

export const defaultCartModeSelector = createSelector(
    [authSelector, (state) => state.cart.cartOrderType, paymentPreorderIdSelector, orderIdSelector, productsSelector],
    ({ authenticated }, cartMode, paymentPreorderId, orderId, { checkups, analyzes }) => {
        if (orderId && analyzes.hasValid) {
            if (authenticated) {
                return CART_ORDER_TYPE.REORDER;
            } else {
                return CART_ORDER_TYPE.ANALYZES;
            }
        }

        if (paymentPreorderId) {
            return CART_ORDER_TYPE.PAYMENT_PREORDER;
        }

        if (checkups?.all.length > 0 && !cartMode) {
            return CART_ORDER_TYPE.CHECKUPS;
        }

        if (analyzes?.all.length > 0 && !cartMode) {
            return CART_ORDER_TYPE.ANALYZES;
        }

        if (checkups?.all.length > 0 && !(analyzes?.all.length > 0) && cartMode === CART_ORDER_TYPE.ANALYZES) {
            return CART_ORDER_TYPE.CHECKUPS;
        }

        if (!(checkups?.all.length > 0) && cartMode === CART_ORDER_TYPE.CHECKUPS) {
            return CART_ORDER_TYPE.ANALYZES;
        }
    }
);

export const reorderInvalidAnalyzesArticlesSelector = createSelector([cartModeSelector, productsSelector], (cartMode, products) => {
    return getDataByCartMode(
        cartMode,
        {
            REORDER:
                products?.analyzes.invalid?.map((analysis) => ({
                    article: analysis.article
                })) || []
        },
        []
    );
});

export const cartWarningTypesSelector = createSelector(
    [officeDataSelector],
    (officeData) => new Set(officeData?.warnings?.map((warning) => warning.type) || [])
);

export const productsIdsRequiredAppointmentSelector = createSelector(
    [productsSelector],
    (productsData) => new Set(productsData?.analyzes?.valid?.filter((p) => p.appointmentRequired).map((p) => p.id) || [])
);

export const appointmentRequiredSelector = createSelector(
    [productsIdsRequiredAppointmentSelector, appointmentSelector, chosenOfficeSelector],
    (productsIds, appointment, cartOffice) => productsIds.size > 0 && appointment?.officeId?.toLowerCase() !== cartOffice.office?.id?.toLowerCase()
);

export const cartValidCheckupsSelector = createSelector([productsSelector], (products) => products?.checkups?.valid?.length > 0);

const cartValidAnalyzesSelector = createSelector(
    [
        cartModeSelector,
        cartDataAnalyzesSelector,
        officeTypeSelector,
        chosenOfficeSelector,
        homeVisitDataSelector,
        officeDataSelector,
        onlineRegistrationEnabledSelector,
        cartWarningTypesSelector,
        appointmentRequiredSelector,
        productsSelector
    ],
    (cartMode, cartDataAnalyzes, officeType, office, homeVisitData, officeData, onlineRegistrationEnabled, cartWarningTypes, appointmentRequired, products) => {
        if (cartDataAnalyzes.filter((product) => !product.deleted).length === 0) {
            return false;
        }

        const validationHomeVisit = () => {
            return homeVisitData.valid && homeVisitData.products.some((product) => product.available && !isService(product.product));
        };

        const validationOffice = () => {
            if (!office || !office.allProductsAvailable || !officeData.orderProducts) {
                return false;
            }
            if (onlineRegistrationEnabled) {
                return !cartWarningTypes.has(OrderWarningType.PRODUCT_BIOMATERIAL_NOT_SET) && !cartWarningTypes.has(OrderWarningType.MEDICAL_INFO_NOT_SET);
            }
            return !appointmentRequired;
        };

        const validationOfficeType = () => {
            if (officeType === OfficeType.HOME_VISIT) {
                return validationHomeVisit();
            }

            if (officeType === OfficeType.OFFICE) {
                return validationOffice();
            }

            return false;
        };

        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: validationOfficeType(),
                REORDER: products.analyzes.valid.length > 0,
                PAYMENT_PREORDER: products.analyzes.valid.length > 0
            },
            false
        );
    }
);

export const cartValidSelector = createSelector([cartValidAnalyzesSelector, cartValidCheckupsSelector], (validAnalyzes, validCheckups) => {
    return {
        validAnalyzes,
        validCheckups
    };
});

export const saleActionSelector = createSelector([commonCalculationDataSelector], (commonCalculationData) => commonCalculationData?.saleAction);

export const appliedMarketingMechanicsSelector = createSelector([saleActionSelector], (saleAction): MarketingMechanics[] =>
    saleAction?.marketingMechanics?.filter((mechanics) => mechanics.isApplied)
);

export const couponsDataSelector = createSelector([saleActionSelector], (saleAction) => saleAction?.coupons || []);

export const certificatesDataSelector = createSelector([saleActionSelector], (saleAction) => saleAction?.certificates || []);

export const cartAnalyzesSelector = createSelector(
    [
        cartDataSelector,
        productsSelector,
        cartValidAnalyzesSelector,
        officeTypeSelector,
        appointmentRequiredSelector,
        productsIdsRequiredAppointmentSelector,
        homeVisitDataByOfficeType,
        cartOrderTypeSelector,
        couponsDataSelector,
        certificatesDataSelector,
        commonCalculationDataSelector
    ],
    (
        data,
        products,
        cartValidAnalyzes,
        officeType,
        appointmentRequired,
        appointmentRequiredProductIds,
        homeVisitData,
        cartOrderType,
        coupons,
        certificates,
        calculationData
    ) => {
        if (!data.products && !cartOrderType.paymentPreorder) {
            return {
                products: []
            };
        } else {
            const { valid, invalid } = products.analyzes;
            const appointmentRequiredProductFilter = (product) => appointmentRequiredProductIds.has(product.id);
            const appointmentRequiredProducts = valid.filter((product) => appointmentRequiredProductFilter(product));
            const services = valid.filter((product) => isService(product));

            return {
                ...data,
                ...(homeVisitData || {}),
                ...calculationData,
                products: appointmentRequired ? valid.filter((product) => !appointmentRequiredProductFilter(product)) : valid,
                services: services.length > 0 ? services : null,
                invalidProducts: invalid,
                appointmentRequiredProducts: appointmentRequired ? appointmentRequiredProducts : [],
                totalCost: calculateProductsCost(appointmentRequired ? valid.filter((product) => !appointmentRequiredProductFilter(product)) : valid),
                productsCost: calculateProductsCost(
                    valid.filter(
                        (product) => !isService(product) && isRemovable(product) && (appointmentRequired ? !appointmentRequiredProductFilter(product) : true)
                    )
                ),
                notIncludedCost: calculateProductsCost(appointmentRequired ? invalid.concat(appointmentRequiredProducts) : invalid),
                hasNotAppliedCoupons: coupons.filter((coupons) => !coupons.applied && coupons.number).length > 0,
                hasAppliedCertificates: certificates.filter((certificate) => certificate.applied).length > 0,
                hasNotAppliedCertificates: certificates.filter((certificate) => !certificate.applied && certificate.number).length > 0,
                isPrepaid: calculationData?.costWithDiscount === 0,
                valid: cartValidAnalyzes
            };
        }
    }
);

export const cartServicesSelector = createSelector([cartModeSelector, cartAnalyzesSelector], (cartMode, cartAnalyzes) => {
    return getDataByCartMode(
        cartMode,
        {
            CHECKUPS: null,
            ANALYZES: cartAnalyzes.services
        },
        null
    );
});

export const biomaterialSetsSelector = createSelector(
    [officeDataSelector, (state) => state.cart.biomaterialModal.productsIds],
    (officeData, chosenProductsIds) => {
        if (!officeData.orderProducts) {
            return [];
        } else {
            const { orderProducts } = officeData;
            const productById = {};
            const biomaterialsById = {};
            const biomaterialsByProduct = {};
            const selectedBiomaterialByProduct = {};
            orderProducts.forEach((orderProduct) => {
                if (orderProduct.availableBiomaterials.length > 0) {
                    const product = orderProduct.product;
                    productById[product.id] = product;
                    biomaterialsByProduct[product.id] = new Set();
                    orderProduct.availableBiomaterials.forEach((biomaterial) => {
                        biomaterialsById[biomaterial.id] = biomaterial;
                        biomaterialsByProduct[product.id].add(biomaterial.id);
                    });
                    selectedBiomaterialByProduct[product.id] = orderProduct.biomaterial?.id;
                }
            });

            const productsByBiomaterials = new Map();
            for (const [productId, biomaterialsIds] of Object.entries(biomaterialsByProduct)) {
                let existentBiomaterialsIds = [...productsByBiomaterials.keys()].find((set) => eqSet(set, biomaterialsIds));
                if (!existentBiomaterialsIds) {
                    productsByBiomaterials.set(biomaterialsIds, new Set([productId]));
                } else {
                    productsByBiomaterials.get(existentBiomaterialsIds).add(productId);
                }
            }

            return [...productsByBiomaterials]
                .filter(([biomaterialsIds, productsIds]) => {
                    return chosenProductsIds.length === 0 || [...productsIds].some((p) => chosenProductsIds.indexOf(p) >= 0);
                })
                .map(([biomaterialsIds, productsIds]) => {
                    const productsIdsArr = [...productsIds];
                    const biomaterials = [...biomaterialsIds].map((biomaterialId) => {
                        return {
                            ...biomaterialsById[biomaterialId],
                            selected: selectedBiomaterialByProduct[productsIdsArr[0]] === biomaterialId
                        };
                    });
                    const products = productsIdsArr.map((productId) => productById[productId]);

                    return {
                        biomaterials: biomaterials.sort((b1, b2) => sortStrings(b1.name, b2.name)),
                        products: products.sort((p1, p2) => sortStrings(p1.article, p2.article))
                    };
                });
        }
    }
);

export const selectedPatientSelector = createSelector(
    [selectedPatientIdSelector, patientsByIdSelector, mainPatientSelector],
    (id, patientsById, mainPatient) => patientsById[id] || mainPatient
);

export const cartPatientsSelector = createSelector(
    [cartModeSelector, sortedByMainPatientsSelector, selectedPatientSelector, reorderPatientSelector],
    (cartMode, sortedByMainPatients, selectedPatient, reorderPatient) => {
        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: sortedByMainPatients,
                CHECKUPS: sortedByMainPatients,
                REORDER: reorderPatient ? [reorderPatient] : [],
                PAYMENT_PREORDER: selectedPatient ? [selectedPatient] : []
            },
            []
        );
    }
);

export const cartCalculationErrorSelector = createSelector(
    [cartModeSelector, cartSelector],
    (cartMode, { calculateOffice, calculateHomeVisit, calculateCheckups, calculateReorder, calculatePaymentPreorder }) => {
        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: calculateHomeVisit?.error || calculateOffice?.error,
                CHECKUPS: calculateCheckups?.error,
                REORDER: calculateReorder?.error,
                PAYMENT_PREORDER: calculatePaymentPreorder?.error
            },
            null
        );
    }
);

export const cartConfirmationDataSelector = createSelector([cartModeSelector, ordersSelector], (cartMode, orders) => {
    const { create, createCheckup, createReorder, preorderPay } = orders;

    return getDataByCartMode(cartMode, {
        ANALYZES: create,
        CHECKUPS: createCheckup,
        REORDER: createReorder,
        PAYMENT_PREORDER: preorderPay
    });
});

export const cartPaymentPayloadSelector = createSelector(
    [cartConfirmationDataSelector],
    (cartConfirmationData) => cartConfirmationData?.response?.paymentPayload
);

export const cartConfirmationStatusSelector = createSelector(
    [patientSelector, commonCalculationDataSelector, cartConfirmationDataSelector, cartSelector],
    (
        { fetchOnlineRegistrationData },
        cartCalculationData,
        cartConfirmationData,
        { calculateOffice, calculateHomeVisit, calculateCheckups, calculatePaymentPreorder, calculateReorder }
    ) => {
        const loading =
            fetchOnlineRegistrationData?.loading ||
            calculateOffice?.loading ||
            calculateHomeVisit?.loading ||
            calculateCheckups?.loading ||
            calculatePaymentPreorder?.loading ||
            calculateReorder?.loading ||
            cartConfirmationData?.loading ||
            false;

        const success = cartConfirmationData?.success;

        return {
            loading,
            success
        };
    }
);

export const cartConfirmationErrorSelector = createSelector([cartConfirmationDataSelector], (cartConfirmationData) =>
    cartConfirmationData?.error ? cartConfirmationData?.error : null
);

export const carConfirmationLoyaltyErrorSelector = createSelector([cartConfirmationErrorSelector], (cartConfirmationError) =>
    cartConfirmationError?.errorCode ? cartConfirmationError : null
);

export const onlinePaymentTypesSelector = createSelector([authSelector, commonCalculationDataSelector], ({ authenticated }, commonCalculationData) =>
    authenticated
        ? commonCalculationData?.onlinePaymentConfigs?.reduce((map, onlinePayment) => {
              map[onlinePayment.type] = onlinePayment;
              return map;
          }, {}) || {}
        : { [PSB]: { type: PSB } }
);

export const paymentTypesSelector = createSelector(
    [cartModeSelector, authSelector, onlineRegistrationEnabledSelector, cartOrderTypeSelector, onlinePaymentTypesSelector],
    (cartMode, { authenticated }, onlineRegistrationEnabled, checkupsData, onlinePaymentTypes) => {
        const ONLINE_PAYMENTS = Object.keys(onlinePaymentTypes);
        const ALL = authenticated && Object.values(onlinePaymentTypes)?.length > 0 ? [...ONLINE_PAYMENTS, OFFICE] : [OFFICE];

        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: onlineRegistrationEnabled ? ONLINE_PAYMENTS : ALL,
                CHECKUPS: ONLINE_PAYMENTS,
                REORDER: ONLINE_PAYMENTS,
                PAYMENT_PREORDER: ONLINE_PAYMENTS
            },
            []
        );
    }
);

export const paymentTypeSelector = createSelector(
    [selectedPaymentTypeSelector, paymentTypesSelector, onlinePaymentTypesSelector],
    (selectedPaymentType, paymentTypes, onlinePaymentTypes) => {
        const defaultPaymentType =
            onlinePaymentTypes[CLOUD_PAYMENTS]?.type || onlinePaymentTypes[PSB]?.type || onlinePaymentTypes[LOYALTY]?.type || paymentTypes[0];

        return selectedPaymentType && paymentTypes.includes(selectedPaymentType)
            ? selectedPaymentType
            : paymentTypes.length > 0
            ? defaultPaymentType
            : undefined;
    }
);

export const cartConfirmableSelector = createSelector(
    [
        cartModeSelector,
        authSelector,
        paymentTypeSelector,
        selectedPatientSelector,
        onlineRegistrationEnabledSelector,
        cartSelector,
        cartSelector,
        commonCalculationDataSelector,
        onlinePaymentTypesSelector,
        selectedPaymentTypeSelector
    ],
    (
        cartMode,
        { authenticated },
        paymentType,
        patient,
        onlineRegistrationEnabled,
        { onlineRegistrationData },
        { deliveryData },
        commonCalculationData,
        onlinePaymentTypes,
        selectedPaymentType
    ) => {
        const hasApprovalData = selectedPaymentType === ALFA_PAY;
        const hasPhone = selectedPaymentType === SBER_PAY;

        const valid =
            !(!authenticated || !paymentType || !patient || !commonCalculationData) &&
            (!onlinePaymentTypes[selectedPaymentType] || commonCalculationData?.onlinePaymentConfigs) &&
            (!onlineRegistrationEnabled || (onlineRegistrationData.valid && deliveryData.valid));

        return getDataByCartMode(
            cartMode,
            {
                CHECKUPS: { valid, hasEmail: true, hasApprovalData, hasPhone },
                REORDER: { valid, hasEmail: true, hasApprovalData, hasPhone }
            },
            { valid, hasApprovalData, hasPhone }
        );
    }
);

export const onlinePaymentPublicIdSelector = createSelector(
    [paymentTypeSelector, onlinePaymentTypesSelector],
    (paymentType, onlinePaymentTypes) => onlinePaymentTypes[paymentType]?.publicId || null
);

export const costSelector = createSelector(
    [cartModeSelector, checkupsDataSelector, cartAnalyzesSelector, paymentPreorderDataSelector],
    (cartMode, checkupsData, cartAnalyzes, paymentPreorderData) => {
        return getDataByCartMode(
            cartMode,
            {
                ANALYZES: {
                    totalCost: cartAnalyzes.totalCost,
                    productsCost: cartAnalyzes.productsCost,
                    notIncludedCost: cartAnalyzes.notIncludedCost
                },
                CHECKUPS: {
                    totalCost: checkupsData.cost,
                    productsCost: checkupsData.cost
                },
                REORDER: {
                    totalCost: cartAnalyzes.totalCost,
                    productsCost: cartAnalyzes.productsCost
                },
                PAYMENT_PREORDER: {
                    totalCost: paymentPreorderData.cost,
                    productsCost: paymentPreorderData.cost
                }
            },
            {
                totalCost: null,
                productsCost: null
            }
        );
    }
);

export const currencySelector = createSelector([commonCalculationDataSelector], (calculationData) => calculationData?.currency || Currency.RUB);

export const calculateProductsCost = (products) => {
    return !products || products.length === 0
        ? 0
        : products
              .filter((product) => !product.deleted)
              .map((product) => product.price || '0')
              .map((price) => parseFloat(price))
              .reduce((a, b) => a + b, 0);
};

export const calculateProfilePrice = (profileId, products) => {
    const filteredProducts = products?.filter((item) => profileId === item.profileId);
    return filteredProducts?.reduce((sum: number, product) => sum + product.price, 0);
};

const toProduct = (orderProduct) => {
    return {
        ...orderProduct.product,
        profileId: orderProduct.profileId,
        biomaterial: orderProduct.biomaterial,
        availableBiomaterials: orderProduct.availableBiomaterials,
        price: orderProduct.cost,
        priceWithDiscount: orderProduct.costWithDiscount,
        removable: orderProduct.removable,
        selected: orderProduct.selected,
        appointmentRequired: orderProduct.appointmentRequired
    };
};

const eqSet = (set1, set2) => {
    if (set1.size !== set2.size) {
        return false;
    } else {
        for (let element of set1) {
            if (!set2.has(element)) {
                return false;
            }
        }

        return true;
    }
};

const sortStrings = (s1, s2) => {
    if (s1 < s2) {
        return -1;
    } else if (s1 > s2) {
        return 1;
    } else {
        return 0;
    }
};

const isRemovable = (product) => !product?.product?.classifierData || !product?.product?.classifierData?.service;

export const getDataByCartMode = (cartOrderType, data: Record<keyof typeof CART_ORDER_TYPE, any>, defaultData = null) => {
    switch (cartOrderType) {
        case CART_ORDER_TYPE.ANALYZES:
            return data[CART_ORDER_TYPE.ANALYZES] || defaultData;
        case CART_ORDER_TYPE.CHECKUPS:
            return data[CART_ORDER_TYPE.CHECKUPS] || defaultData;
        case CART_ORDER_TYPE.REORDER:
            return data[CART_ORDER_TYPE.REORDER] || defaultData;
        case CART_ORDER_TYPE.PAYMENT_PREORDER:
            return data[CART_ORDER_TYPE.PAYMENT_PREORDER] || defaultData;
        default:
            return defaultData;
    }
};
