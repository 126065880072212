//@ts-nocheck
import React, { Component } from 'react';

class Icon extends Component {
    render() {
        const { src, alt } = this.props;

        return <img src={src} alt={alt} />;
    }
}

export default Icon;
