import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CommonMessages from 'messages/CommonMessages';
import { webChatSettingsSelector } from 'redux/chat/selectors';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { currentCitySelector } from 'redux/cities/selectors';
import { getEdnaSettings } from 'redux/chat/actions';
import { currentSelector } from 'redux/user/selectors';
import { setMultibuttonScript, capitalizeFirstLetter, getChatLinks, setC2CConfig } from 'utils/chatUtils';
import { authSelector } from 'redux/auth/selectors';
declare const window: any;

const useChat = () => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const user = useAppSelector(currentSelector);
    const { authenticated } = useAppSelector(authSelector);
    const currentCity = useAppSelector(currentCitySelector);
    const { webChatEnabled, chatLinks, webrtcCallEnabled } = useAppSelector(webChatSettingsSelector);
    const [multiButtonEnabled, setMultiButtonEnabled] = useState<boolean | null>(false);

    useEffect(() => {
        setMultiButtonEnabled(webChatEnabled || (chatLinks && chatLinks?.length > 0) || webrtcCallEnabled);
    }, [webChatEnabled, chatLinks, webrtcCallEnabled]);

    useEffect(() => {
        setMultibuttonScript(multiButtonEnabled);
    }, [multiButtonEnabled]);

    useEffect(() => {
        dispatch(getEdnaSettings());
    }, [currentCity]);

    return (
        multiButtonEnabled && (
            <div>
                <div id='chat-container'>
                    <div className='ednamb-container-shadow ednamb-hide'></div>
                    <div className='ednamb-container ednamb-hide' id='ednamb-container'>
                        <div className='ednamb-top-buttons ednamb-hide'></div>
                        <div className='ednamb-left-buttons ednamb-hide'>
                            <div className='ednamb-left-buttons-head'>
                                <div>{formatMessage(CommonMessages.contactUs)}</div>
                                <img className='ednamb-left-cross' src='/assets/close.svg' alt='' />
                            </div>
                            <div className='ednamb-left-buttons-elements'>
                                {webrtcCallEnabled && (
                                    <button
                                        onClick={() => {
                                            setC2CConfig(authenticated, user);
                                            window.onCallButtonClick();
                                        }}
                                        id='ednaCallBtn'
                                        className='ednamb-left-button edna-call'
                                        data-call='true'
                                    >
                                        <div className='ednamb-left-button-icon' data-call='true'></div>
                                        <div className='ednamb-left-button-text' data-call='true'>
                                            {formatMessage(CommonMessages.call)}
                                        </div>
                                    </button>
                                )}
                                {webChatEnabled && (
                                    <button id='edna-chat' className='ednamb-left-button edna-chat'>
                                        <div className='ednamb-left-button-icon'></div>
                                        <div className='ednamb-left-button-text'>{formatMessage(CommonMessages.chat)}</div>
                                        <div className='count-messages' />
                                    </button>
                                )}
                                {chatLinks?.map((link) => {
                                    const { type, uri } = link;

                                    return (
                                        <a key={type} id={type} href={uri} target='_blank' className={`ednamb-left-button ${link?.type.toLowerCase()}`}>
                                            <div className='ednamb-left-button-icon'></div>
                                            <div className='ednamb-left-button-text'>{capitalizeFirstLetter(type)}</div>
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='ednamb-button ednamb-hide start' id='ednamb-button' style={{ bottom: '-60px' }}>
                        <div className='count-messages' />
                    </div>
                    {webrtcCallEnabled && (
                        <div>
                            <div className='c2c-call__container' id='c2cCallContainer'>
                                <button id='c2c_call_btn' data-url={window.location.href}>
                                    <div className='c2c-call__text'>
                                        <p className='c2c-call__text--head'>{formatMessage(CommonMessages.callToCallCenter)}</p>
                                        <p className='c2c-call__text--base'>{formatMessage(CommonMessages.connection)}</p>
                                    </div>
                                    <div className='c2c-call__icon'>
                                        <img src='/static/webrtc/image/c2c-error.svg' alt='' />
                                    </div>
                                </button>
                            </div>
                            <div onLoad={() => window.c2c_init()} id='c2c_widget_div'>
                                <button id='c2c_select_devices_btn' title={formatMessage(CommonMessages.clickSelectDevices)}>
                                    <img src='/static/webrtc/image/c2c-devices.svg' alt='' />
                                    <span>{formatMessage(CommonMessages.devices)}</span>
                                </button>
                                <button id='c2c_test_btn' title={formatMessage(CommonMessages.clickCheckConnectionQuality)}>
                                    <img src='/static/webrtc/image/c2c-test.svg' alt='' />
                                    <span>{formatMessage(CommonMessages.testLine)}</span>
                                </button>
                                &nbsp;
                                <button id='c2c_call_btn' title={formatMessage(CommonMessages.clickCallUsPhone)}>
                                    <img src='/static/webrtc/image/c2c-call.svg' alt='' />
                                    <span>{formatMessage(CommonMessages.call)}</span>
                                </button>
                                <span id='c2c_video_chk_span'>
                                    <input id='c2c_video_chk' type='checkbox' title='video call' />
                                    {formatMessage(CommonMessages.video)}
                                </span>
                                <button id='c2c_camera_btn'>
                                    <img src='/static/webrtc/image/c2c-camera.svg' alt='' />
                                </button>
                                <div id='c2c_select_devices_div'>
                                    <form id='c2c_devices' onSubmit={(event) => event.preventDefault()}>
                                        <fieldset>
                                            <legend>{formatMessage(CommonMessages.selectDevices)}</legend>
                                            <div id='microphone_dev'>
                                                {formatMessage(CommonMessages.microphone)}
                                                <br />
                                                <select name='microphone'></select>
                                                <br />
                                                <br />
                                            </div>
                                            <div id='speaker_dev'>
                                                {formatMessage(CommonMessages.speaker)}
                                                <br />
                                                <select name='speaker'></select>
                                                <br />
                                                <br />
                                            </div>
                                            <div id='camera_dev'>
                                                {formatMessage(CommonMessages.camera)}
                                                <br />
                                                <select name='camera'></select>
                                                <br />
                                                <br />
                                            </div>
                                        </fieldset>
                                        <input id='select_devices_done_btn' type='button' value='Done' />
                                    </form>
                                </div>
                                <div id='c2c_status_line'></div>
                            </div>
                            <div id='c2c_video_div' style={{ display: 'none' }}>
                                <video id='c2c_local_video' style={{ display: 'none' }} playsInline={true}></video>
                                <video id='c2c_remote_video' style={{ display: 'none' }} autoPlay={true}></video>
                            </div>
                        </div>
                    )}
                    <div className='edna-banner edna-banner_viber'>
                        <div className='edna-banner__close banner-close'>
                            <img src='/assets/edna-banner-close.svg' alt='' />
                        </div>
                        <div className='edna-banner__wrapper'>
                            <div className='ds_tag_v01_color edna-banner__wrapper-head'>
                                <span className='dark'>{formatMessage(CommonMessages.viberHeader)}</span>
                            </div>
                            <div className='edna-banner__wrapper-content edna-content'>
                                <a href={getChatLinks(chatLinks, 'VIBER')} className='ds_shadow_dark edna-content__card bg_gray' target='_blank'>
                                    <div className='edna-content__card-icon violet_viber'>
                                        <img src='/assets/edna-banner-chat.svg' alt='' />
                                    </div>
                                    <div className='edna-content__card-text ds_tag_v01_color'>
                                        <span>{formatMessage(CommonMessages.viberOpenChat)}</span>
                                        <p className='dark_grey'>{formatMessage(CommonMessages.viberInApp)}</p>
                                    </div>
                                </a>
                                <a href='https://www.viber.com/ru/download/' className='ds_shadow_dark edna-content__card bg_gray' target='_blank'>
                                    <div className='edna-content__card-icon violet_viber'>
                                        <img src='/assets/edna-banner-download.svg' alt='' />
                                    </div>
                                    <div className='edna-content__card-text ds_tag_v01_color'>
                                        <span>{formatMessage(CommonMessages.downloadAppViber)}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default useChat;
