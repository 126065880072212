import { defineMessages } from 'react-intl';

export default defineMessages({
    navigationTitle: {
        id: 'IKBCodeEntryPage.navigationTitle',
        defaultMessage: 'Code entry'
    },
    info: {
        id: 'IKBCodeEntryPage.info',
        defaultMessage: 'Request to view and manage loyalty options has been sent. Enter the confirmation code from the message.'
    },
    code: {
        id: 'IKBCodeEntryPage.code',
        defaultMessage: 'Code'
    },
    error: {
        id: 'IKBCodeEntryPage.error',
        defaultMessage: 'Wrong code entered'
    },
    noticeSendCode: {
        id: 'IKBCodeEntryPage.noticeSendCode',
        defaultMessage: 'You can send the code again via <span></span> s'
    },
    sendCode: {
        id: 'IKBCodeEntryPage.sendCode',
        defaultMessage: 'Resend code'
    }
});
