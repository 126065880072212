//@ts-nocheck
import { FETCH, CLEAR_ALL, CLEAR_BY_PATIENT_ID, APPLY_COUPON } from 'redux/loyaltyStatus/actions';
import { basicReducer } from 'redux/helpers';

const initialState = {
    entities: {},
    fetchAppliedCoupon: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH.STARTED: {
            const { patientId } = action.meta;
            const entities = { ...state.entities };
            entities[patientId] = {
                loading: true,
                error: false
            };
            return {
                ...state,
                entities
            };
        }
        case FETCH.SUCCEEDED: {
            const { patientId } = action.meta;
            const entities = { ...state.entities };
            entities[patientId] = {
                loading: false,
                error: false,
                loyaltyStatus: { ...action.payload.data }
            };
            return {
                ...state,
                entities
            };
        }
        case FETCH.ERRORED: {
            const { patientId } = action.meta;
            const entities = { ...state.entities };
            entities[patientId] = {
                loading: false,
                error: action.payload?.response?.data || true
            };
            return {
                ...state,
                entities
            };
        }
        case CLEAR_BY_PATIENT_ID.BASE: {
            const patientId = action.meta;
            const entities = { ...state.entities };
            delete entities[patientId];

            return {
                ...state,
                entities
            };
        }
        case CLEAR_ALL.SUCCEEDED: {
            return initialState;
        }
        case APPLY_COUPON.SUCCEEDED:
        case APPLY_COUPON.STARTED:
        case APPLY_COUPON.ERRORED:
            const entities = { ...state.entities };
            if (action.type === APPLY_COUPON.SUCCEEDED) {
                const { patientId } = action.meta;
                entities[patientId] = {
                    loyaltyStatus: { ...action.payload.data }
                };
            }
            return {
                ...state,
                entities,
                fetchAppliedCoupon: basicReducer(state.fetchAppliedCoupon, action)
            };
        default:
            return state;
    }
}
