//@ts-nocheck
import classNames from 'classnames/bind';
import Cost from 'containers/const/Cost';
import styles from 'page/cart/confirmation/orderBlock/productList/ProductList.module.css';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/cart/confirmation/orderBlock/productList/ProductListMessages';
import * as React from 'react';
import { injectIntl } from 'react-intl';

const cx = classNames.bind(styles);

class ProductList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showOrderContents: false
        };
        this.handleShowOrderContents = this.handleShowOrderContents.bind(this);
    }

    render() {
        const { cart, intl, cartOrderType, currency } = this.props;
        const { showOrderContents } = this.state;

        const classNameOrderContents = cx({
            orderContentsShowing: showOrderContents,
            orderContentsHidden: !showOrderContents
        });

        const analyzes = cart.products.filter((product) => !product.deleted);
        const checkups = this.props?.checkups?.filter((checkup) => !checkup.deleted);
        const orders = cartOrderType.analyzes || cartOrderType.reorder || cartOrderType.paymentPreorder ? analyzes : checkups;

        return (
            <React.Fragment>
                {orders.length > 0 && (
                    <div>
                        <div>
                            {!showOrderContents && (
                                <div>
                                    <span
                                        onClick={() => {
                                            this.handleShowOrderContents(true);
                                        }}
                                        className={styles.orderContentsLinkShow}
                                    >
                                        {intl.formatMessage(cartOrderType.reorder ? messages.reorderContentsLinkShow : messages.orderContentsLinkShow)}
                                    </span>
                                </div>
                            )}
                            {showOrderContents && (
                                <div
                                    onClick={() => {
                                        this.handleShowOrderContents(false);
                                    }}
                                >
                                    <span className={styles.orderContentsLinkHide}>
                                        {intl.formatMessage(cartOrderType.reorder ? messages.reorderContentsLinkHide : messages.orderContentsLinkHide)}
                                    </span>
                                    <span className={styles.orderContentsIcon}>×</span>
                                </div>
                            )}
                        </div>
                        <div className={classNameOrderContents}>
                            {orders.map((product) => (
                                <div className={styles.orderContentsItem} key={product.article}>
                                    <div className={styles.orderContentsItemTitle}>{product.name}</div>
                                    {product.price || product.cost ? (
                                        <div className={styles.orderContentsItemPrice}>
                                            <Cost amount={product.priceWithDiscount} currency={currency} />
                                            <Cost
                                                currency={currency}
                                                amount={product.price || product.cost}
                                                className={styles.priceWithDiscount}
                                                show={(product.price || product.cost) > product.priceWithDiscount}
                                            />
                                            {product?.quantity > 1 && (
                                                <div className={styles.onePrice}>
                                                    <Cost amount={product.price || product.costPerItem} currency={currency} /> × {product.quantity}{' '}
                                                    {intl.formatMessage(commonMessages.quantity)}
                                                </div>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }

    handleShowOrderContents(params) {
        this.setState({ showOrderContents: params });
    }
}

export default injectIntl(ProductList);
