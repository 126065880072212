//@ts-nocheck
import * as productClient from 'client/ProductClient';
import { all, takeLatest, put } from 'redux-saga/effects';
import { request } from 'redux/helpers';
import { FETCH_ALL } from 'redux/products/actions';

export function* fetchAll(action) {
    yield request(FETCH_ALL, [productClient.fetchAll, action.meta]);
}

export default function* productsSagas() {
    yield all([takeLatest(FETCH_ALL.BASE, fetchAll)]);
}
