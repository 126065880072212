import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ChangeProductsCartModal.title',
        defaultMessage: 'Confirm the replacement of the current bucket?'
    },
    cancel: {
        id: 'ChangeProductsCartModal.cancel',
        defaultMessage: 'Cancel'
    },
    merge: {
        id: 'ChangeProductsCartModal.merge',
        defaultMessage: 'Merge'
    },
    replace: {
        id: 'ChangeProductsCartModal.replace',
        defaultMessage: 'Replace'
    },
    retry: {
        id: 'ChangeProductsCartModal.retry',
        defaultMessage: 'Retry'
    },
    close: {
        id: 'ChangeProductsCartModal.close',
        defaultMessage: 'Close'
    },
    serverError: {
        id: 'ChangeProductsCartModal.serverError',
        defaultMessage: 'An error occurred. Please, try again.'
    },
    codeError: {
        id: 'ChangeProductsCartModal.codeError',
        defaultMessage: 'The code is invalid or our of date'
    }
});
