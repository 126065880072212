//@ts-nocheck
import * as documentsClient from 'client/DocumentsClient';
import * as patientClient from 'client/PatientClient';
import { all, takeLatest } from 'redux-saga/effects';
import { request } from 'redux/helpers';
import { FETCH_DOCUMENTS, FETCH_SIGNED_DOCUMENTS } from 'redux/documents/actions';

function* fetchDocuments(action) {
    yield request(FETCH_DOCUMENTS, [documentsClient.fetchDocuments, action.meta], action.meta);
}

function* fetchSignedDocuments(action) {
    const { patientId } = action.meta;
    yield request(FETCH_SIGNED_DOCUMENTS, [patientClient.fetchSignedDocuments, patientId], patientId);
}

export default function* documentsSagas() {
    yield all([takeLatest(FETCH_DOCUMENTS.BASE, fetchDocuments), takeLatest(FETCH_SIGNED_DOCUMENTS.BASE, fetchSignedDocuments)]);
}
