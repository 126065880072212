//@ts-nocheck
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import HeaderBottomMenuLink from 'page/layout/header/bottom/HeaderBottomMenuLink';

import styles from 'page/layout/header/bottom/HeaderBottomMenu.module.css';
import messages from 'page/layout/header/bottom/HeaderBottomMenuMessages';
import { SET_SCROLLBAR_POSITION } from 'redux/headerBottomMenu/actions';
import IKBTooltipMenu from 'page/layout/components/tooltip/IKBTooltipMenu';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { getByPatientId } from 'redux/loyaltyStatus/selectors';
import { checkShowTooltipMenu } from 'utils/cashbackUtils';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

const HeaderBottomMenu = ({ intl, selected, showMotivatingTooltip, canWatchDocuments, territorySettings, scrollbarPosition, setScrollbarPosition }) => {
    const navListRef = useRef();

    useEffect(() => {
        scroll();
    }, [scrollbarPosition]);

    useEffect(() => {
        return () => {
            setScrollbarPosition(navListRef?.current?.scrollLeft);
        };
    }, [setScrollbarPosition]);

    const scroll = () => {
        navListRef.current.scrollLeft = scrollbarPosition;
    };

    const { showDocuments, showCheckups, showAppointments } = territorySettings;

    return (
        <nav className={classNames(styles.container, checkShowTooltipMenu(selected?.id) && showMotivatingTooltip ? 'cashbackNotActivated' : null)}>
            <div className={classNames(styles.nav, styles.navScroller)}>
                <ul ref={navListRef} className={classNames(styles.navList, styles.navScrollerContent)}>
                    <HeaderBottomMenuLink to='/orders' title={intl.formatMessage(messages.orders)} />
                    <HeaderBottomMenuLink to='/dynamics' title={intl.formatMessage(messages.dynamics)} />
                    <HeaderBottomMenuLink to='/patients' title={intl.formatMessage(messages.patients)} />
                    {showCheckups && <HeaderBottomMenuLink to='/programmy-zdorovya' title={intl.formatMessage(messages.checkups)} />}
                    <HeaderBottomMenuLink tooltip={<IKBTooltipMenu />} to='/loyalty' title={intl.formatMessage(messages.loyalty)} />
                    {showAppointments && <HeaderBottomMenuLink to='/conclusions' title={intl.formatMessage(messages.conclusions)} />}
                    {showDocuments && canWatchDocuments && <HeaderBottomMenuLink to='/documents' title={intl.formatMessage(messages.documents)} />}
                    <HeaderBottomMenuLink to='/settings' title={intl.formatMessage(messages.settings)} />
                </ul>
            </div>
        </nav>
    );
};

const mapStateToProps = (state) => {
    const selected = selectedPatientSelector(state);
    const data = getByPatientId(state, { patientId: selected?.id });

    return {
        scrollbarPosition: state.headerBottomMenu.scrollbarPosition,
        loyaltyStatus: data?.loyaltyStatus,
        showMotivatingTooltip: state.loyaltyIKB.showMotivatingTooltip,
        territory: currentTerritorySelector(state),
        canWatchDocuments: selected?.canWatchDocuments,
        territorySettings: currentTerritorySettingsSelector(state)
    };
};

const mapDispatchToProps = {
    setScrollbarPosition: SET_SCROLLBAR_POSITION.base
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HeaderBottomMenu));
