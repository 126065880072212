import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/button/Button';
import styles from './ButtonSecondary.module.css';
const cx = classNames.bind(styles);

type TProps = {
    text: string;
    onClick: () => void;
    className?: string;
    variant?: string;
};

const ButtonSecondary: FC<TProps> = ({ className, variant, onClick, ...rest }) => {
    return <Button onClick={onClick} className={cx(styles.button, className, { [styles.active]: variant === 'active' })} {...rest} />;
};

export default memo(ButtonSecondary);
