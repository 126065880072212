//@ts-nocheck
import { OrderStatus as OrderStatusEnum } from 'constants/orderStatus';
import { ResultStatus as ResultStatusEnum } from 'constants/resultStatus';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { formatDate, parseISO } from 'utils/timeUtils';
import styles from './OrderStatus.module.css';
import messages from 'page/orders/component/status/OrderStatusMessages';
import { OrderType } from 'constants/orderType';

class OrderStatus extends React.PureComponent {
    render() {
        return <div className={styles.orderStatus}>{this.renderStatus()}</div>;
    }

    renderStatus() {
        const { order, supportPhone, paymentAvailable } = this.props;
        if (order.status === OrderStatusEnum.PREORDER) {
            if (order.office) {
                return (
                    <span>
                        <FormattedMessage {...messages.statusPreorder} values={{ office: order.office.name }} />
                    </span>
                );
            } else {
                return (
                    <span>
                        <FormattedMessage {...messages.statusPreorderUnknownOffice} />
                    </span>
                );
            }
        } else if (order.orderType === OrderType.ORDER && order.status === OrderStatusEnum.COMPLETED) {
            return (
                <span className={styles.orderReady}>
                    <FormattedMessage {...messages.statusPaid} />
                </span>
            );
        } else if (order.status === OrderStatusEnum.NEW || order.status === ResultStatusEnum.NOT_COMPLETED) {
            if (order.deadline) {
                return (
                    <span>
                        <FormattedMessage {...messages.statusNew} values={{ date: formatDate(parseISO(order.deadline), this.props.locale) }} />
                    </span>
                );
            } else {
                return (
                    <span>
                        <FormattedMessage {...messages.statusNewUnknownDate} />
                    </span>
                );
            }
        } else if (order.status === OrderStatusEnum.PARTIAL || order.status === ResultStatusEnum.PARTIAL) {
            return (
                <span>
                    <FormattedMessage {...messages.statusPartial} />
                </span>
            );
        } else if (order.status === OrderStatusEnum.COMPLETED || order.status === ResultStatusEnum.COMPLETED) {
            if (parseInt(order.orderItemsSize, 10) > 0 || order.inz) {
                return (
                    <React.Fragment>
                        <span className={styles.orderReady}>
                            <FormattedMessage {...messages.statusCompleted} />
                        </span>
                        <span>
                            <FormattedMessage {...messages.statusCompletedResultsReady} />
                        </span>
                    </React.Fragment>
                );
            } else {
                return (
                    <span className={styles.orderReady}>
                        <FormattedMessage {...messages.statusCompleted} />
                    </span>
                );
            }
        } else if (order.status === OrderStatusEnum.CANCELLED) {
            return (
                <span>
                    <FormattedMessage {...messages.statusCancelled} />
                </span>
            );
        } else if (order.status === OrderStatusEnum.ERROR_DATA) {
            return (
                <span>
                    {supportPhone ? (
                        <FormattedMessage
                            {...messages.statusErrorWithSupportPhone}
                            values={{
                                phone: () => (
                                    <a className={styles.phoneLink} href={`tel:${supportPhone.value}`}>
                                        {supportPhone.name}
                                    </a>
                                )
                            }}
                        />
                    ) : (
                        <FormattedMessage {...messages.statusError} />
                    )}
                </span>
            );
        } else if (order.status === OrderStatusEnum.NOT_PAID_PREORDER && paymentAvailable) {
            return (
                <span className={styles.notPaid}>
                    <FormattedMessage {...messages.statusNotPaid} />
                </span>
            );
        } else if (order.status === OrderStatusEnum.PAYMENT_IN_PROGRESS_PREORDER) {
            return (
                <span className={styles.paidProgress}>
                    <FormattedMessage {...messages.statusPaymentInProgress} />
                </span>
            );
        } else if (order.status === OrderStatusEnum.PAID_PREORDER) {
            return (
                <span className={styles.paid}>
                    <FormattedMessage {...messages.statusPaid} />
                </span>
            );
        }
        // неизвестный статус или null
        return null;
    }
}

OrderStatus.propTypes = {
    order: PropTypes.object.isRequired,
    supportPhone: PropTypes.object,
    locale: PropTypes.string.isRequired
};

export default injectIntl(OrderStatus);
