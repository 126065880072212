//@ts-nocheck
import { INPUT_ERRORS_CODES } from 'page/cart/confirmation/cardForm/cardForm.config';

export const cardValidation = (value) => {
    const valueWithoutSlash = value.replace('/', '');
    const valueLength = valueWithoutSlash.length;

    const checkEmpty = !valueLength;

    const DATE_LENGTH = 4;
    const CVV_LENGTH = 3;
    const MAX_MONTH = 12;

    return {
        number: {
            empty: () => {
                if (checkEmpty) {
                    throw new Error(INPUT_ERRORS_CODES.CardNumber_Empty);
                }
            },
            length: () => {
                if (valueLength < 16) {
                    throw new Error(INPUT_ERRORS_CODES.CardNumber_Length);
                } else if (valueLength >= 17 && valueLength < 19) {
                    throw new Error(INPUT_ERRORS_CODES.CardNumber_Length);
                }
            }
        },
        date: {
            empty() {
                if (checkEmpty) {
                    throw new Error(INPUT_ERRORS_CODES.ExpDateMonthYear_Empty);
                }
            },
            min() {
                if (valueLength < DATE_LENGTH) {
                    throw new Error(INPUT_ERRORS_CODES.ExpDateMonthYear_Invalid);
                }
            },
            betweenMonth() {
                const [month] = value.split('/');

                if (Number(month) > MAX_MONTH || Number(month) === 0) {
                    throw new Error(INPUT_ERRORS_CODES.ExpDateMonth_Invalid);
                }
            },
            addZero() {
                if (valueLength === 3) {
                    const [first, second, third] = valueWithoutSlash;
                    return `${first}${second}/0${third}`;
                }

                return null;
            }
        },
        cvv: {
            empty() {
                if (checkEmpty) {
                    throw new Error(INPUT_ERRORS_CODES.Cvv_Empty);
                }
            },
            min() {
                if (valueLength < CVV_LENGTH) {
                    throw new Error(INPUT_ERRORS_CODES.Cvv_Invalid);
                }
            }
        }
    };
};
