import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'IKBServicesRegularPage.title',
        defaultMessage: 'Regular cashback'
    },
    purchasesPerMonth: {
        id: 'IKBServicesRegularPage.purchasesPerMonth',
        defaultMessage: '{count} {count, plural, one {order} other {orders}} per month'
    },
    nextChangesAvailable: {
        id: 'IKBServicesRegularPage.nextChangesAvailable',
        defaultMessage: 'The next changes will be possible <mark>30 days</mark> after the date of pressing the "Confirm" button.'
    },
    takeNoticeNote: {
        id: 'IKBServicesRegularPage.takeNoticeNote',
        defaultMessage: 'Please note!'
    },
    takeNotice: {
        id: 'IKBServicesRegularPage.takeNotice',
        defaultMessage:
            'Certain tests may not be available at all medical offices. Before making your choice, please check the availability of the products you are interested in at a convenient medical office.'
    },
    cardInfo: {
        id: 'IKBServicesRegularPage.cardInfo',
        defaultMessage: '<b>Regular cashback</b> — percentage increase in cashback based on frequency of purchase.'
    },
    cardInfo2: {
        id: 'IKBServicesRegularPage.cardInfo2',
        defaultMessage: 'If you pay for medical services more than once within 30 calendar days, the amount of the cashback increases accordingly.'
    },
    featuredCardTitle: {
        id: 'IKBServicesRegularPage.featuredCardTitle',
        defaultMessage: '{count, plural, one {Basic} =2 {Increased} other {Maximum}} cashback'
    },
    info: {
        id: 'IKBServicesRegularPage.info',
        defaultMessage: `<p>The Participant of the Bonus Program has the right to earn an increased number of INVITRO Bonus Roubles, determined by the Organizer, when ordering and paying for medical services.</p> <p> For this purpose, the Participant of the Bonus Programme can activate this option in the INVITRO Personal Office" service or in the INVITRO medical office/diagnostic centre".</p>`
    }
});
