import { defineMessages } from 'react-intl';

export default defineMessages({
    study: {
        id: 'TestResultsTable.study',
        defaultMessage: 'Study'
    },
    result: {
        id: 'TestResultsTable.result',
        defaultMessage: 'Result'
    },
    referenceValues: {
        id: 'TestResultsTable.referenceValues',
        defaultMessage: 'The reference values'
    },
    comment: {
        id: 'TestResultsTable.comment',
        defaultMessage: 'Comment'
    },
    noResults: {
        id: 'TestResultsTable.noResults',
        defaultMessage: 'There are no results.'
    },
    inProgressText: {
        id: 'TestResultsTable.inProgressText',
        defaultMessage: 'Processing'
    }
});
