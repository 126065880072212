import { defineMessages } from 'react-intl';

export default defineMessages({
    navigationTitle: {
        id: 'IKBConfirmationPage.navigationTitle',
        defaultMessage: 'Come back'
    },
    info: {
        id: 'IKBConfirmationPage.info',
        defaultMessage: 'To continue, you need to confirm any of your contacts. Which contact is convenient for you to confirm?'
    }
});
