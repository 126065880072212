//@ts-nocheck
import {
    SELECT_CASHBACK_SOLUTION,
    SELECT_CASHBACK_SERVICE,
    CLEAR_SELECTED_CASHBACK_SERVICES,
    SET_CASHBACK_TYPE,
    SEARCH_CASHBACK_SERVICES,
    SEND_ACTIVATION_PIN,
    SET_ACTIVATION,
    CLEAR_ACTIVATION,
    CLEAR_SEND_ACTIVATION_PIN,
    ACTIVATE_ACCOUNT,
    SET_CONFIRM_DATA,
    CLEAR_CONFIRM_DATA,
    FETCH_CATEGORIES,
    SET_CASHBACK_STATUS,
    CLEAR_CASHBACK_STATUS,
    CHANGE_CATEGORY,
    CLEAR_CHANGE_CATEGORY,
    SET_NUMBER_OF_SERVICES,
    FETCH_MOTIVATING_TOOLTIP
} from 'redux/loyaltyIKB/actions';
import { basicReducer } from 'redux/helpers';
import { IKBNumberOfServices } from 'constants/ikbNumberOfServices';

const initialState = {
    sendActivationPin: {},
    activation: {},
    activateAccount: {},
    confirmLoyalty: {},
    categoriesData: {},
    fetchCategories: {},
    activeCashbackType: null,
    selectedCashbackSolutions: [],
    selectedCashbackServices: [],
    searchString: '',
    searchResults: [],
    cashbackStatus: {},
    changeCategory: {},
    numberOfServices: IKBNumberOfServices,
    showMotivatingTooltip: false,
    fetchMotivatingTooltip: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CASHBACK_TYPE.BASE: {
            const params = action?.meta;
            return {
                ...state,
                activeCashbackType: params
            };
        }
        case SELECT_CASHBACK_SOLUTION.BASE: {
            const { solution } = action?.meta;
            return {
                ...state,
                selectedCashbackSolutions: [solution]
            };
        }
        case SELECT_CASHBACK_SERVICE.BASE: {
            const { service, selected } = action?.meta;
            let selectedCashbackServices = [];
            if (!selected) {
                selectedCashbackServices = [...state.selectedCashbackServices];
                selectedCashbackServices.push(service);
            } else {
                selectedCashbackServices = state.selectedCashbackServices.filter((selectedService) => service.productId !== selectedService.productId);
            }
            return {
                ...state,
                selectedCashbackServices: [...selectedCashbackServices]
            };
        }
        case CLEAR_SELECTED_CASHBACK_SERVICES.BASE: {
            return {
                ...state,
                selectedCashbackServices: []
            };
        }
        case SEARCH_CASHBACK_SERVICES.BASE: {
            const { services, param } = action.meta;
            if (!param.length) {
                return {
                    ...state,
                    searchString: param,
                    searchResults: [...services]
                };
            }
            const filteredServices = services.filter(
                (service) => service.name?.toUpperCase()?.indexOf(param.toUpperCase()) >= 0 || service.article?.toUpperCase()?.indexOf(param.toUpperCase()) >= 0
            );
            return {
                ...state,
                searchString: param,
                searchResults: [...filteredServices]
            };
        }
        case SEND_ACTIVATION_PIN.STARTED:
        case SEND_ACTIVATION_PIN.SUCCEEDED:
        case SEND_ACTIVATION_PIN.ERRORED: {
            return {
                ...state,
                sendActivationPin: basicReducer(state.sendActivationPin, action)
            };
        }
        case CLEAR_SEND_ACTIVATION_PIN.BASE: {
            return {
                ...state,
                sendActivationPin: {}
            };
        }
        case SET_ACTIVATION.BASE: {
            const { accountNumber, discountNumber, phone } = action.meta;
            return {
                ...state,
                activation: {
                    accountNumber,
                    discountNumber,
                    phone
                }
            };
        }
        case ACTIVATE_ACCOUNT.STARTED:
        case ACTIVATE_ACCOUNT.SUCCEEDED:
        case ACTIVATE_ACCOUNT.ERRORED: {
            let activation = state.activation;
            if (action.type === ACTIVATE_ACCOUNT.SUCCEEDED) {
                activation = {
                    ...activation,
                    status: action.payload.data
                };
            }
            return {
                ...state,
                activation,
                activateAccount: basicReducer(state.activateAccount, action)
            };
        }
        case CLEAR_ACTIVATION.BASE: {
            return {
                ...state,
                activation: {}
            };
        }
        case SET_CONFIRM_DATA.BASE: {
            const { patientId, contactType, territory } = action.meta;
            return {
                ...state,
                confirmData: {
                    patientId,
                    contactType,
                    territory
                }
            };
        }
        case CLEAR_CONFIRM_DATA.BASE: {
            return {
                ...state,
                confirmData: {}
            };
        }
        case FETCH_CATEGORIES.STARTED:
        case FETCH_CATEGORIES.SUCCEEDED:
        case FETCH_CATEGORIES.ERRORED: {
            let categoriesData = state.categoriesData;
            if (action.type === FETCH_CATEGORIES.SUCCEEDED) {
                categoriesData = action.payload.data?.data;
            }
            return {
                ...state,
                categoriesData,
                fetchCategories: basicReducer(state.fetchCategories, action)
            };
        }
        case SET_CASHBACK_STATUS.BASE: {
            return {
                ...state,
                cashbackStatus: action.meta
            };
        }
        case CLEAR_CASHBACK_STATUS.BASE: {
            return {
                ...state,
                cashbackStatus: {}
            };
        }
        case CHANGE_CATEGORY.STARTED:
        case CHANGE_CATEGORY.SUCCEEDED:
        case CHANGE_CATEGORY.ERRORED: {
            return {
                ...state,
                changeCategory: basicReducer(state.changeCategory, action)
            };
        }
        case CLEAR_CHANGE_CATEGORY.BASE: {
            return {
                ...state,
                changeCategory: {}
            };
        }
        case SET_NUMBER_OF_SERVICES.BASE: {
            return {
                ...state,
                numberOfServices: action.meta
            };
        }
        case FETCH_MOTIVATING_TOOLTIP.SUCCEEDED: {
            return {
                ...state,
                showMotivatingTooltip: action.payload.data,
                fetchMotivatingTooltip: basicReducer(state.fetchMotivatingTooltip, action)
            };
        }
        default:
            return state;
    }
}
