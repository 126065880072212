import { fork } from 'redux-saga/effects';
import authSagas from 'redux/auth/sagas';
import cartSagas from 'redux/cart/sagas';
import citiesSagas from 'redux/cities/sagas';
import codeSagas from 'redux/code/sagas';
import dynamicsSagas from 'redux/dynamics/sagas';
import homeVisitsSagas from 'redux/homeVisits/sagas';
import i18nSagas from 'redux/i18n/sagas';
import loyaltyHistorySagas from 'redux/loyaltyHistory/sagas';
import loyaltyStatusSagas from 'redux/loyaltyStatus/sagas';
import officesSagas from 'redux/offices/sagas';
import orderDetailsSagas from 'redux/orderDetails/sagas';
import ordersSagas from 'redux/orders/sagas';
import ordersCountSagas from 'redux/ordersCount/sagas';
import patientSagas from 'redux/patient/sagas';
import productsSagas from 'redux/products/sagas';
import resultsSagas from 'redux/result/sagas';
import startupSagas from 'redux/startup/sagas';
import userSagas from 'redux/user/sagas';
import watcherSagas from 'redux/watcher/sagas';
import loyaltyIKBSagas from 'redux/loyaltyIKB/sagas';
import documentsSagas from 'redux/documents/sagas';
import paymentSaga from 'redux/cart/paymentStatus.saga';
import chatWatcher from 'redux/chat/sagas';
import conclusionsSagas from 'redux/conclusions/sagas';
import checkupsSagas from 'redux/checkups/sagas';

export default function* rootSaga() {
    yield fork(startupSagas);
    yield fork(i18nSagas);
    yield fork(authSagas);
    yield fork(userSagas);
    yield fork(patientSagas);
    yield fork(ordersSagas);
    yield fork(orderDetailsSagas);
    yield fork(loyaltyStatusSagas);
    yield fork(loyaltyHistorySagas);
    yield fork(loyaltyIKBSagas);
    yield fork(ordersCountSagas);
    yield fork(dynamicsSagas);
    yield fork(resultsSagas);
    yield fork(watcherSagas);
    yield fork(codeSagas);
    yield fork(citiesSagas);
    yield fork(cartSagas);
    yield fork(paymentSaga);
    yield fork(officesSagas);
    yield fork(productsSagas);
    yield fork(homeVisitsSagas);
    yield fork(documentsSagas);
    yield fork(chatWatcher);
    yield fork(conclusionsSagas);
    yield fork(checkupsSagas);
}
