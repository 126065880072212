import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CheckupDeliveryBlock.title',
        defaultMessage: 'Send the number of the health program by'
    },
    email: {
        id: 'CheckupDeliveryBlock.email',
        defaultMessage: 'E-mail'
    },
    phone: {
        id: 'CheckupDeliveryBlock.phone',
        defaultMessage: 'Phone'
    }
});
