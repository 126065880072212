//@ts-nocheck
import InputValid from 'components/input/valid/InputValid';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import styles from './LoyaltySummary.module.css';
import messages from 'page/orders/component/loyaltySummary/LoyaltySummaryMessages';
import { IKBBonusAccountStatus } from 'constants/ikbBonusAccountStatus';
import ChangeMarketingMechanicsModal from 'page/cart/components/changeMarketingMechanicsModal/ChangeMarketingMechanicsModal';

class LoyaltySummary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            appliedBonuses: this.props.saleAction?.bonus?.appliedBonuses || 0
        };
        this.handleValueUpdate = this.handleValueUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            typeof this.props.saleAction?.bonus?.appliedBonuses === 'number' &&
            prevProps.saleAction?.bonus?.appliedBonuses !== this.props.saleAction?.bonus?.appliedBonuses
        ) {
            this.setState({ appliedBonuses: this.props.saleAction?.bonus.appliedBonuses });
        }
    }

    render() {
        const { intl, handleBonusesChanged, saleAction } = this.props;
        const { appliedBonuses } = this.state;

        const bonus = saleAction?.bonus;

        if (!bonus || bonus.status !== IKBBonusAccountStatus.ACTIVE) {
            return null;
        }

        return (
            <div className={styles.pointsBalance}>
                <div className={styles.pointsBalanceItems}>
                    <div className={styles.pointsBalanceItem}>
                        <div className={styles.pointsBalanceTitle}>{intl.formatMessage(messages.title)}</div>
                        <div className={styles.pointsBalanceCount}>{bonus.activeBonuses}</div>
                    </div>
                    <div className={styles.pointsBalanceItem}>
                        <div className={styles.pointsBalanceTitle}>{intl.formatMessage(messages.writeOff)}</div>
                        <div className={styles.pointsBalanceCount}>{bonus.maximumBonuses}</div>
                    </div>
                </div>
                <div className={styles.pointsBalanceNotice}>{intl.formatMessage(messages.notice)}</div>
                <div className={styles.pointsBalanceForm}>
                    <label>{intl.formatMessage(messages.formLabel)}</label>
                    <div className={styles.pointsWriteOffBlock}>
                        <InputValid
                            name='pointsWriteOff'
                            type={'number'}
                            min={0}
                            max={bonus.maximumBonuses}
                            value={appliedBonuses}
                            placeholder={`1-${bonus.maximumBonuses}`}
                            onChange={this.handleValueUpdate}
                            onBlur={() => {
                                if (appliedBonuses !== saleAction?.bonus?.appliedBonuses) {
                                    handleBonusesChanged(appliedBonuses);
                                }
                            }}
                            valid={appliedBonuses === bonus.appliedBonuses}
                            onKeyDown={(event) => {
                                if (!appliedBonuses) {
                                    event.target.select();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    handleValueUpdate(event) {
        const value = Math.max(0, event.target.value);
        this.setState({ appliedBonuses: Math.min(value, this.props.saleAction?.bonus.maximumBonuses) });
    }
}

export default injectIntl(LoyaltySummary);
