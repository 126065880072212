//@ts-nocheck
import { FieldErrorResponse } from 'types/common';

export function getOrigin() {
    return window.location.origin;
}

export function getHostname() {
    return window.location.hostname;
}

export function goTo(url, newTab = false) {
    if (url) {
        if (newTab) {
            window.open(url, '_blank');
        } else {
            window.location = url;
        }
    }
}

export function goBack() {
    window.history.back();
}

export function reload() {
    window.location.reload();
}

export function print() {
    window.print();
}

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + '=([^;]*)'));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getErrorsOfResponse(response) {
    response?.payload?.data?.errors.length > 0
        ? response?.payload?.data?.errors?.reduce((map, error: FieldErrorResponse) => {
              map[error.field] = error;
              return map;
          }, {})
        : null;
}
