import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'IKBCashbackActivatedPage.info',
        defaultMessage: 'Cashback is activated!'
    },
    noticeCalculated: {
        id: 'IKBCashbackActivatedPage.noticeCalculated',
        defaultMessage: 'Your charges {percent}%'
    },

    notice: {
        id: 'IKBCashbackActivatedPage.notice',
        defaultMessage:
            'You can increase the amount of charges by choosing either <services>individual services</services> increased by the cashback ratio, or <solutions>ready-made solutions</solutions>.'
    }
});
