//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import {
    CLEAR,
    CLEAR_DEACTIVATE,
    CLEAR_FETCH,
    CLEAR_HISTORY,
    CLEAR_PASSWORD,
    CLEAR_UPDATE,
    DEACTIVATE,
    FETCH,
    FETCH_HISTORY,
    PASSWORD,
    UPDATE,
    UPDATE_EMAIL,
    UPDATE_PHONE
} from 'redux/user/actions';

const initialState = {
    entities: {},
    currentId: null,
    fetch: {},
    update: {},
    updatePhone: {},
    updateEmail: {},
    password: {},
    history: {},
    deactivate: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH.STARTED:
        case FETCH.SUCCEEDED:
        case FETCH.ERRORED: {
            let entities = state.entities;
            let currentId = state.currentId;
            if (action.type === FETCH.SUCCEEDED) {
                const current = action.payload.data;
                currentId = current.id;
                entities[currentId] = current;
                entities = Object.assign({}, entities);
            }
            return {
                ...state,
                entities,
                currentId,
                fetch: basicReducer(state.fetch, action, 'data')
            };
        }
        case UPDATE.STARTED:
        case UPDATE.SUCCEEDED:
        case UPDATE.ERRORED: {
            let entities = state.entities;
            if (action.type === UPDATE.SUCCEEDED) {
                const user = action.payload.data;
                entities[user.id] = user;
                entities = Object.assign({}, entities);
            }
            return {
                ...state,
                update: basicReducer(state.update, action, 'data'),
                entities
            };
        }
        case UPDATE_PHONE.STARTED:
        case UPDATE_PHONE.SUCCEEDED:
        case UPDATE_PHONE.ERRORED: {
            let entities = state.entities;
            if (action.type === UPDATE_PHONE.SUCCEEDED) {
                const user = action.payload.data;
                entities[user.id] = user;
                entities = Object.assign({}, entities);
            }
            return {
                ...state,
                updatePhone: basicReducer(state.updatePhone, action, 'data'),
                entities
            };
        }
        case UPDATE_EMAIL.STARTED:
        case UPDATE_EMAIL.SUCCEEDED:
        case UPDATE_EMAIL.ERRORED: {
            let entities = state.entities;
            if (action.type === UPDATE_EMAIL.SUCCEEDED) {
                const user = action.payload.data;
                entities[user.id] = user;
                entities = Object.assign({}, entities);
            }
            return {
                ...state,
                updateEmail: basicReducer(state.updateEmail, action, 'data'),
                entities
            };
        }
        case PASSWORD.STARTED:
        case PASSWORD.SUCCEEDED:
        case PASSWORD.ERRORED: {
            return {
                ...state,
                password: basicReducer(state.password, action, 'data')
            };
        }
        case FETCH_HISTORY.STARTED:
        case FETCH_HISTORY.SUCCEEDED:
        case FETCH_HISTORY.ERRORED: {
            return {
                ...state,
                history: basicReducer(state.history, action, 'data')
            };
        }

        case DEACTIVATE.STARTED:
        case DEACTIVATE.SUCCEEDED:
        case DEACTIVATE.ERRORED: {
            return {
                ...state,
                deactivate: basicReducer(state.deactivate, action, 'data')
            };
        }

        case CLEAR.BASE: {
            return {
                ...state,
                currentId: null,
                entities: {}
            };
        }
        case CLEAR_FETCH.BASE: {
            return {
                ...state,
                fetch: {}
            };
        }
        case CLEAR_UPDATE.BASE: {
            return {
                ...state,
                update: {},
                updateEmail: {},
                updatePhone: {}
            };
        }
        case CLEAR_PASSWORD.BASE: {
            return {
                ...state,
                password: {}
            };
        }
        case CLEAR_HISTORY.BASE: {
            return {
                ...state,
                history: {}
            };
        }
        case CLEAR_DEACTIVATE.BASE: {
            return {
                ...state,
                deactivate: {}
            };
        }
        default:
            return state;
    }
}
