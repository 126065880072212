//@ts-nocheck
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withRouter from 'adapters/WithRouter';
import { cartByCodeSelector } from 'redux/cart/selectors';
import { FETCH_CART_BY_CODE } from 'redux/cart/actions';
import ChangeProductsCartModal from 'page/cart/components/changeProductsCartModal/ChangeProductsCartModal';

class CartByCodePage extends React.PureComponent {
    componentDidMount() {
        const { code } = this.props?.match?.params;
        this.props.fetchCartByCode(code);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props?.cartByCode) {
            const prevProducts = this.props?.cartByCode?.prevProducts;
            if (prevProducts?.length < 1) {
                this.props.navigate('/cart');
            }
        }
        if (this.props?.actionCart) {
            this.props.navigate('/cart');
        }
    }

    render() {
        const { loading, success, cartByCode, error } = this.props;
        return (
            <React.Fragment>
                {(cartByCode.prevProducts?.length > 0 || error) && <ChangeProductsCartModal show={success} loading={loading} error={error} />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cartByCode: cartByCodeSelector(state),
        loading: state.cart?.fetch?.loading,
        success: state.cart?.fetch?.success,
        error: state.cart.fetch.error,
        actionCart: state.cart.actionCart
    };
};

const mapDispatchToProps = {
    fetchCartByCode: FETCH_CART_BY_CODE.base
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartByCodePage)));
