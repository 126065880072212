//@ts-nocheck
import * as unauthResultClient from 'client/UnauthResultClient';
import * as resultClient from 'client/ResultClient';
import { all, put, takeLatest } from 'redux-saga/effects';
import { request } from 'redux/helpers';
import { CLEAR as CLEAR_ORDER_DETAILS } from 'redux/orderDetails/actions';
import { SEND_EMAIL_RESULT } from 'redux/result/actions';
import {
    ADD_RESULT,
    CLEAR,
    PARSE_KEY_UNAUTH,
    REQUEST_TRANSLATION,
    SEARCH_RESULT_UNAUTH,
    SEND_EMAIL_RESULT_UNAUTH,
    SEARCH_RESULT_BY_KEY
} from 'redux/result/actions';

function* searchUnauth(action) {
    yield request(SEARCH_RESULT_UNAUTH, [unauthResultClient.search, action.meta], action.meta);
}

function* addResult(action) {
    yield request(ADD_RESULT, [resultClient.addResult, action.meta]);
}

function* requestTranslation(action) {
    yield request(REQUEST_TRANSLATION, [resultClient.requestTranslation, action.meta]);
}

function* sendEmailUnauth(action) {
    yield request(SEND_EMAIL_RESULT_UNAUTH, [unauthResultClient.sendEmail, action.meta]);
}

function* sendEmail(action) {
    yield request(SEND_EMAIL_RESULT, [resultClient.sendEmail, action.meta]);
}

function* parseKeyUnauth(action) {
    yield request(PARSE_KEY_UNAUTH, [unauthResultClient.parseKey, action.meta]);
}

function* clear() {
    yield put(CLEAR.base());
}

function* searchResultByKey(action) {
    yield request(SEARCH_RESULT_BY_KEY, [unauthResultClient.searchByKey, action.meta], action.meta);
}

export default function* resultsSagas() {
    yield all([
        takeLatest(SEARCH_RESULT_UNAUTH.BASE, searchUnauth),
        takeLatest(SEND_EMAIL_RESULT_UNAUTH.BASE, sendEmailUnauth),
        takeLatest(PARSE_KEY_UNAUTH.BASE, parseKeyUnauth),
        takeLatest(ADD_RESULT.BASE, addResult),
        takeLatest(SEND_EMAIL_RESULT.BASE, sendEmail),
        takeLatest(REQUEST_TRANSLATION.BASE, requestTranslation),
        takeLatest(CLEAR_ORDER_DETAILS.BASE, clear),
        takeLatest(SEARCH_RESULT_BY_KEY.BASE, searchResultByKey)
    ]);
}
