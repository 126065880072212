import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'DocumentListPage.pageTitle',
        defaultMessage: 'Documents'
    },
    noDocumentsMsg: {
        id: 'DocumentListPage.noDocumentsMsg',
        defaultMessage: 'No documents found'
    },
    unableToFetchDocuments: {
        id: 'DocumentListPage.unableToFetchDocuments',
        defaultMessage: 'Unable to fetch documents'
    },
    AGREEMENT_BLANK_TYPE_GENERAL: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_GENERAL',
        defaultMessage: 'The main agreement between the patient and the organization'
    },
    AGREEMENT_BLANK_TYPE_AIDS: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_AIDS',
        defaultMessage: 'Agreeing to an AIDS test'
    },
    AGREEMENT_BLANK_TYPE_CONSENT: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_CONSENT',
        defaultMessage: 'Agreement to the processing of personal data'
    },
    AGREEMENT_BLANK_TYPE_INTERVENTION: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_INTERVENTION',
        defaultMessage: 'Agreement to medical intervention'
    },
    AGREEMENT_BLANK_TYPE_LOYALTY: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY',
        defaultMessage: 'Agreement to participate in the loyalty program'
    },
    AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL: {
        id: 'DocumentType.AGREEMENT_BLANK_TYPE_LOYALTY_DENIAL',
        defaultMessage: 'Refusal to participate in the loyalty program'
    },
    MAIN_FORM: {
        id: 'DocumentType.MAIN_FORM',
        defaultMessage: 'Referral form'
    },
    ANTIGEN_TEST: {
        id: 'DocumentType.ANTIGEN_TEST',
        defaultMessage: 'Analysis for qualitative determination of antigens'
    },
    CYTOLOGY: {
        id: 'DocumentType.CYTOLOGY',
        defaultMessage: 'Referral for cytological examination'
    },
    ECOLOGY: {
        id: 'DocumentType.ECOLOGY',
        defaultMessage: 'Water and soil quality research'
    },
    FEMOFLOR: {
        id: 'DocumentType.FEMOFLOR',
        defaultMessage: 'Femoflor'
    },
    GENETICS: {
        id: 'DocumentType.GENETICS',
        defaultMessage: 'Molecular genetic research'
    },
    HISTOLOGY: {
        id: 'DocumentType.HISTOLOGY',
        defaultMessage: 'Referral for histological examination'
    },
    INTERFERON_STATUS: {
        id: 'DocumentType.INTERFERON_STATUS',
        defaultMessage: 'Interferon status'
    },
    MICRIBIOLOGY: {
        id: 'DocumentType.MICRIBIOLOGY',
        defaultMessage: 'Referral for microbiological research'
    },
    PCR: {
        id: 'DocumentType.PCR',
        defaultMessage: 'PCR-diagnostics of infectious diseases'
    },
    STUDIES_OF_TRACE_ELEMENTS: {
        id: 'DocumentType.STUDIES_OF_TRACE_ELEMENTS',
        defaultMessage: 'Study of trace elements'
    },
    MICROSCOPIC_STUDY_ON_FUNGAL_CELLS: {
        id: 'DocumentType.MICROSCOPIC_STUDY_ON_FUNGAL_CELLS',
        defaultMessage: 'Microscopic examination of skin and nail samples for the presence of fungal cells'
    },
    PCR_DIGENE_TEST: {
        id: 'DocumentType.PCR_DIGENE_TEST',
        defaultMessage: 'PCR DIGENE-TEST'
    },
    FIBROMAX: {
        id: 'DocumentType.FIBROMAX',
        defaultMessage: 'Fibromax'
    },
    ANTROPOMETRY: {
        id: 'DocumentType.ANTROPOMETRY',
        defaultMessage: 'Antropometry'
    },
    GLUCOSE_TUBES_OF_GRAY: {
        id: 'DocumentType.GLUCOSE_TUBES_OF_GRAY',
        defaultMessage: 'Gastropanel'
    },
    ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER: {
        id: 'DocumentType.ECOLOGY_HEIGHT_TECHNOLOGY_STUDY_OF_WATER',
        defaultMessage: 'High-tech water quality research'
    },
    ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER: {
        id: 'DocumentType.ECOLOGY_SCREENING_CHEMICAL_STUDY_OF_WATER',
        defaultMessage: 'Screening chemical examination of water'
    },
    ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER: {
        id: 'DocumentType.ECOLOGY_RADIONUCLIDE_ANALYSIS_OF_WATER',
        defaultMessage: 'Radionuclide analysis of watern'
    },
    ECOLOGY_SOIL: {
        id: 'DocumentType.ECOLOGY_SOIL',
        defaultMessage: 'Water and soil quality research'
    },
    AIDS: {
        id: 'DocumentType.AIDS',
        defaultMessage: 'Informed consent to be tested for HIV infection'
    },
    MOLECULAR_GENETIC: {
        id: 'DocumentType.MOLECULAR_GENETIC',
        defaultMessage: 'Questionnaire of molecular genetic research'
    },
    VOLUNTARY: {
        id: 'DocumentType.VOLUNTARY',
        defaultMessage: 'Informed voluntary consent of the patient to complex medical intervention'
    },
    PRISCA: {
        id: 'DocumentType.PRISCA',
        defaultMessage: 'Prisca - Prenatal screening program'
    },
    ENCEPHALITIS: {
        id: 'DocumentType.ENCEPHALITIS',
        defaultMessage: 'Tick research'
    },
    SURVEYS: {
        id: 'DocumentType.SURVEYS',
        defaultMessage: 'Surveys'
    },
    SPERMOGRAMM: {
        id: 'DocumentType.SPERMOGRAMM',
        defaultMessage: 'Ejaculate examination'
    },
    ESTIMATE: {
        id: 'DocumentType.ESTIMATE',
        defaultMessage: 'Referral form (estimate)'
    },
    REFUND_ACT: {
        id: 'DocumentType.REFUND_ACT',
        defaultMessage: 'Return certificate'
    }
});
