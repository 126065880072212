import * as React from 'react';
import styles from './HeaderTopMenu.module.css';

interface IHeaderTopMenuProps {
    links: { uri: string; localizations: { [key: string]: string } }[];
    locale: string;
}

const HeaderTopMenu = ({ links, locale }: IHeaderTopMenuProps) => {
    const renderLinks = () => {
        return links?.map((link) => (
            <li key={link.uri} className={styles.navItem}>
                <a href={link.uri} className={styles.navLink}>
                    {link.localizations[locale]}
                </a>
            </li>
        ));
    };

    return <ul className={styles.nav}>{renderLinks()}</ul>;
};

export default HeaderTopMenu;
