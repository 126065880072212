import React, { ReactNode, memo, ReactElement } from 'react';

import Header from 'page/layout/header/Header';
import Footer from 'page/layout/footer/Footer';

import styles from './PageLayout.module.css';
import backButtonIcon from './assets/backButton.svg';
import { useNavigate } from 'react-router-dom';

interface PageLayoutProps {
    header?: ReactNode;
    main: ReactNode;
    footer?: ReactNode;
    backLink?: any;
}

const PageLayout = ({ header, main, footer, backLink }: PageLayoutProps): ReactElement => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            {header ? header : <Header />}
            <main>
                {backLink && <img onClick={() => navigate(backLink)} className={styles.backLink} src={backButtonIcon} alt='' />}
                {main}
            </main>
            {footer ? footer : <Footer />}
        </div>
    );
};

export default memo(PageLayout);
