import React, { FC, ReactNode, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import ButtonLink from 'components/button/link/ButtonLink';
import LoaderModal from 'components/modal/loader/LoaderModal';
import commonMessages from 'messages/CommonMessages';
import styles from '../CartAcceptedPage.module.css';
import messages from '../CartAcceptedPageMessages';
import prepareIcon from '../assets/prepareIcon.svg';
import printIcon from '../assets/printIcon.svg';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import officeImg from '../assets/office.svg';
import { CommunicationType } from 'constants/communicationType';
import Phone from 'page/layout/components/phone/Phone';
import { formatDate, parseISO } from 'utils/timeUtils';
import PageLayout from 'page/layout/PageLayout';
import { OrderType } from 'types/common';
import { OrderType as OrderTypeValues } from 'constants/orderType';
import { useAppSelector } from 'app/Hooks';
import { localeSelector } from 'redux/i18n/selectors';
import useFlocktory from 'hooks/useFlocktory';

type TProps = {
    title: string;
    message: string;
    iconSrc: string;
    download: () => void;
    getParams: () => any;
    orderType: OrderType;
    orderNumber: number;
    dateTimeNotice: string;
    hasOverdueNotice: boolean;
    hasDocumentNotice: boolean;
    hasOnlineRegistration: boolean;
    hasRequiredParameters: boolean;
};

const CartAcceptedContent: FC<TProps> = ({
    title,
    message,
    iconSrc,
    download,
    getParams,
    orderType,
    orderNumber,
    dateTimeNotice,
    hasOverdueNotice,
    hasDocumentNotice,
    hasOnlineRegistration,
    hasRequiredParameters
}) => {
    const intl = useIntl();
    const flocktory = useFlocktory();
    const navigate = useNavigate();
    const { locale } = useAppSelector((state) => localeSelector(state));
    const { formatMessage } = useIntl();
    const { loading, success } = useAppSelector((state) => state.orders.fetchAcceptedInfo);
    const territorySettings = useAppSelector(currentTerritorySettingsSelector);

    useEffect(() => {
        document.title = formatMessage(messages.pageTitle);
        flocktory.setExchangeUserEmail();
        return () => flocktory.removeExchangeUserEmail();
    }, []);

    useEffect(() => {
        if (!hasRequiredParameters && !getParams()?.orderNumber && success) {
            navigate('/cart');
        }
    }, [success]);

    useEffect(() => {
        document.title = formatMessage(messages.pageTitle);
        if (territorySettings && territorySettings.territory && !territorySettings.newOrderLink.available) {
            navigate('/orders');
        }
    }, [territorySettings]);

    const renderDocumentNotice = () => {
        if (!hasDocumentNotice) {
            return null;
        }
        return (
            <div className={styles.documentNotice}>
                <p>
                    {formatMessage(messages.documentNotice, {
                        span: (...chunks: ReactNode[]) => <span>{chunks}</span>
                    })}
                </p>
            </div>
        );
    };

    const renderOverdueNotice = () => {
        if (!hasOverdueNotice) {
            return null;
        }
        const fromPlusDay = addDays(getParams()?.createdTime ? parseISO(getParams()?.createdTime) : new Date(), 7);

        return (
            <p className={styles.overdueNotice}>
                {formatMessage(messages.overdueNotice, {
                    date: formatDate(fromPlusDay, locale)
                })}
            </p>
        );
    };

    const renderOnlineRegistrationBlock = () => {
        const { orderNumber, officeAddress } = getParams();

        if (!hasOnlineRegistration) {
            return null;
        }
        return (
            <div className={styles.onlineRegistrationBlock}>
                <h2>{formatMessage(messages.registrationCode, { code: orderNumber })}</h2>
                {renderStep(1, formatMessage(messages.onlineRegistrationStep1))}
                {renderStep(2, formatMessage(messages.onlineRegistrationStep2))}
                {renderStep(3, formatMessage(messages.onlineRegistrationStep3))}
                <p className={styles.onlineRegistrationWarning}>{formatMessage(commonMessages.onlineRegistrationWarning)}</p>
                {officeAddress ? (
                    <div className={styles.office}>
                        <img src={officeImg} alt={officeAddress} />
                        <div>
                            <h3>{formatMessage(messages.office)}</h3>
                            <p>{officeAddress}</p>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    const renderStep = (number: number, text: string) => {
        return (
            <div className={styles.step}>
                <div className={styles.number}>{number}</div>
                <p>{text}</p>
            </div>
        );
    };

    return (
        <PageLayout
            main={
                <div className={styles.orderAcceptedContainer}>
                    <div className={styles.firstSection}>
                        <div className={styles.orderAcceptedIcon}>
                            <img width='52' height='52' src={iconSrc} alt='' />
                        </div>
                        <div className={styles.orderNumber}>{orderNumber && orderNumber}</div>
                        <div className={styles.orderAcceptedTitle}>
                            <span>{title}</span>
                        </div>
                    </div>
                    {dateTimeNotice && (
                        <div className={styles.dateTimeNotice}>
                            <p>{dateTimeNotice}</p>
                        </div>
                    )}
                    {message && <p className={styles.overdueNotice}>{message}</p>}
                    {renderDocumentNotice()}
                    {renderOverdueNotice()}
                    {renderOnlineRegistrationBlock()}
                    <div className={styles.linkBlock}>
                        {orderType === OrderTypeValues.ORDER || orderType === OrderTypeValues.PREORDER || orderType === OrderTypeValues.REORDER ? (
                            <ButtonLink className={styles.printLink} onClick={() => download()}>
                                <img className={styles.printIcon} src={printIcon} alt='' />
                                <div className={styles.printText}>{formatMessage(messages.saveLink)}</div>
                            </ButtonLink>
                        ) : (
                            <a className={styles.prepareLink} href={formatMessage(messages.prepareLinkUrl)} target='_blank' rel='noopener noreferrer'>
                                <img className={styles.prepareIcon} src={prepareIcon} alt='' />
                                <div className={styles.prepareText}>{formatMessage(messages.prepareLink)}</div>
                            </a>
                        )}
                        <ButtonLink className={styles.printLink} onClick={() => print()}>
                            <img className={styles.printIcon} src={printIcon} alt='' />
                            <div className={styles.printText}>{formatMessage(messages.printLink)}</div>
                        </ButtonLink>
                    </div>
                    {hasDocumentNotice && (
                        <div className={styles.secondSection}>
                            <div className={styles.documentNote}>
                                <p>{formatMessage(messages.documentNote)}</p>
                            </div>
                        </div>
                    )}
                    <div className={styles.phonesBlock}>
                        <div className={styles.phonesText}>{formatMessage(messages.phonesText)}</div>
                        <div className={styles.phones}>
                            {territorySettings.communications
                                ?.filter((c) => c.type === CommunicationType.PHONE)
                                .map((communication, i) => {
                                    // @ts-ignore
                                    return <Phone key={i} intl={intl} territory={territorySettings.territory} data={communication} />;
                                })}
                        </div>
                    </div>
                    <LoaderModal show={loading} />
                </div>
            }
        />
    );
};

export default memo(CartAcceptedContent);
