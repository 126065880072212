//@ts-nocheck
import { FETCH_ALL } from 'redux/watcher/actions';
import { all, takeLatest } from 'redux-saga/effects';

import * as watcherClient from 'client/WatcherClient';
import { request } from 'redux/helpers';

function* fetchWatchers(action) {
    const meta = action.meta;
    yield request(FETCH_ALL, [watcherClient.fetchAll, meta], meta);
}

export default function* watcherSagas() {
    yield all([takeLatest(FETCH_ALL.BASE, fetchWatchers)]);
}
