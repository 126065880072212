import React, { memo } from 'react';
import styles from './PageTitle.module.css';
import commonStyles from 'components/style/Common.module.css';
import { FC } from 'react';

type TProps = {
    text: string;
};

const PageTitle: FC<TProps> = ({ text }) => {
    return (
        <div className={commonStyles.generalRow}>
            <div className={commonStyles.generalContent}>
                <h1 className={styles.title}>{text}</h1>
            </div>
        </div>
    );
};

export default memo(PageTitle);
