import { defineMessages } from 'react-intl';

export default defineMessages({
    orders: {
        id: 'HeaderBottomMenu.orders',
        defaultMessage: 'Orders'
    },
    dynamics: {
        id: 'HeaderBottomMenu.dynamics',
        defaultMessage: 'Dynamics'
    },
    patients: {
        id: 'HeaderBottomMenu.patients',
        defaultMessage: 'Patients'
    },
    loyalty: {
        id: 'HeaderBottomMenu.loyalty',
        defaultMessage: 'Loyalty program'
    },
    settings: {
        id: 'HeaderBottomMenu.settings',
        defaultMessage: 'Settings'
    },
    documents: {
        id: 'HeaderBottomMenu.documents',
        defaultMessage: 'Documents'
    },
    conclusions: {
        id: 'HeaderBottomMenu.conclusions',
        defaultMessage: 'Medical card'
    },
    checkups: {
        id: 'HeaderBottomMenu.checkups',
        defaultMessage: 'Health programs and subscriptions'
    }
});
