import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { changeCategory as change, clearChangedCategory } from 'redux/loyaltyIKB/actions';
import { cashbackStatusSelector, loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import { selectedPatientSelector } from 'redux/patient/selectors';
import Button from 'components/button/Button';
import IKBNoticeCard from 'page/loyalty/ikb/components/cards/notice/IKBNoticeCard';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import cashbackNoticeIcon from './assets/cashbackNoticeIcon.svg';
import { getLoyaltyErrorText } from 'utils/messageUtils';
import commonMessages from 'messages/CommonMessages';
import messages from './IKBSolutionsConfirmationMessages';
import styles from './IKBSolutionsConfirmation.module.css';
import Loader from 'components/loader/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

const IKBSolutionsConfirmationPage: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { formatMessage, formatDate } = useIntl();
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { selectedCashbackSolutions } = useAppSelector((state) => loyaltyIKBSelector(state));
    const bonusStatus = useAppSelector((state) => cashbackStatusSelector(state));
    const { changeCategory } = useAppSelector((state) => loyaltyIKBSelector(state));
    const { loading } = useAppSelector(() => changeCategory);
    const { error } = useAppSelector(() => changeCategory);
    const { success } = useAppSelector(() => changeCategory);

    useEffect(() => {
        if (selectedCashbackSolutions.length === 0) {
            navigate(-1);
        }
    }, [selectedCashbackSolutions]);

    useEffect(() => {
        if (success) {
            navigate('/loyalty');
        }
    }, [success]);

    useEffect(() => {
        return () => {
            dispatch(clearChangedCategory());
        };
    }, []);

    const createNotice = (text: string) => {
        return <span key={text}>{text}</span>;
    };

    const handleConfirmButton = () => {
        const patientId = searchParams.get('patientId') as string;
        dispatch(
            change({
                patientId: selectedPatient?.id || patientId,
                territory,
                categoryId: selectedCashbackSolutions[0].categoryId,
                number: bonusStatus.number
            })
        );
    };

    const renderContent = () => {
        const solution = selectedCashbackSolutions[0];

        const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);

        if (loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        } else if (error) {
            return (
                <IKBNoticeCard
                    title={formatMessage(messages.requestError)}
                    description={getLoyaltyErrorText(intl, error.errorCode, error.message)}
                    icon={cashbackNoticeIcon}
                />
            );
        } else if (solution) {
            return (
                <div className={styles.card}>
                    <h5 className={styles.title}>{solution.name}</h5>
                    <div className={styles.products}>
                        {solution.categoryProducts.map((product) => (
                            <div className={styles.productItem} key={product.productId}>
                                <div className={styles.productTitle}>{product.shortName}</div>
                                <div className={styles.productDiscount}>{product.charge}%</div>
                            </div>
                        ))}
                    </div>
                    {solution.charge && (
                        <div className={styles.noticeCashback}>
                            <div>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({ id: 'Common.otherProductsNotice' })
                                    }}
                                />
                            </div>
                            <span>{solution.charge}%</span>
                        </div>
                    )}
                    {solution.validDate && (
                        <div className={styles.noticeSolution}>
                            {formatMessage(messages.notice, {
                                span: () => createNotice(formatDate(solution.validDate, { day: 'numeric', month: 'long', year: 'numeric' })),
                                percent: ikbLoyaltyService.getBaseDiscountPercent()
                            })}
                        </div>
                    )}
                    <Button className={styles.confirmButton} onClick={() => handleConfirmButton()}>
                        {formatMessage(messages.confirmSelection)}
                    </Button>
                </div>
            );
        }

        return null;
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.readySolutions)} back={true} />
            <div className={styles.container}>{renderContent()}</div>
        </div>
    );
};

export default memo(IKBSolutionsConfirmationPage);
