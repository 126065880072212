//@ts-nocheck
import { CountryCode, isValidNumberForRegion } from 'libphonenumber-js';

export function validateNotBlank(value) {
    return value;
}

export function validatePassword(password) {
    return validateNotBlank(password) && import('zxcvbn').then(({ default: zxcvbn }) => zxcvbn(password).score > 1);
}

export function validatePhoneNumber(phoneNumber, countries: CountryCode[] = ['RU', 'AM', 'BY', 'KZ', 'KG']) {
    try {
        return countries.some((countryCode) => isValidNumberForRegion(phoneNumber, countryCode));
    } catch (e) {
        return false;
    }
}

export function validateEmail(email) {
    return /^(("")("".+?""@)|((((\.(?!\.))|[-!#$%&'*+/=?^`{}|~\w])*)(?:[0-9a-zA-Z_-])@))((\[)(\[(\d{1,3}\.){3}\d{1,3}])|(([0-9a-zA-Z]*[-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,6}))$/.test(
        email
    );
}

export function validateInz(inz) {
    return /^\d{9}$/.test(inz);
}
