//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { CLEAR_OFFICE, SHOW_MODAL } from 'redux/offices/actions';
import { CHOOSE_OFFICE, FETCH_ALL, FILTER, SELECT_OFFICE, SET_ADAPTIVE_MODE } from 'redux/offices/actions';

const defaultPosition = [55.751574, 37.573856];

const initialState = {
    showModal: false,
    entities: {},
    validationData: {},
    filter: {},
    position: {
        center: defaultPosition,
        zoom: 10
    },
    selectedId: undefined,
    chosenId: undefined,
    adaptiveMode: {
        openedOfficeList: false,
        activeOfficeMap: false
    },
    fetchAll: {},
    validate: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED:
        case FETCH_ALL.SUCCEEDED:
        case FETCH_ALL.ERRORED: {
            let entities = state.entities;
            let position = state.position;
            if (action.type === FETCH_ALL.SUCCEEDED) {
                const offices = action.payload.data;
                entities = offices.reduce((map, cartOffice) => {
                    map[cartOffice.office.id] = cartOffice;
                    return map;
                }, {});
                position = {
                    ...position,
                    center: calculateCenter(offices)
                };
            }
            return {
                ...state,
                entities,
                position,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        }

        case FILTER.BASE: {
            return {
                ...state,
                filter: action.meta
            };
        }

        case SET_ADAPTIVE_MODE.BASE: {
            return {
                ...state,
                adaptiveMode: {
                    ...state.adaptiveMode,
                    ...(action.meta || {})
                }
            };
        }

        case SELECT_OFFICE.STARTED:
        case SELECT_OFFICE.ERRORED:
        case SELECT_OFFICE.SUCCEEDED: {
            const { officeId, productsIds } = action.meta;
            let selectedId = state.selectedId;
            let adaptiveMode = state.adaptiveMode;
            let position = state.position;
            let entities = state.entities;
            let validationData = state.validationData;
            if (action.type === SELECT_OFFICE.SUCCEEDED) {
                selectedId = officeId;
                const selectedOffice = state.entities[officeId];
                position = {
                    ...state.position,
                    center: [selectedOffice.office.latitude, selectedOffice.office.longitude]
                };
                adaptiveMode = {
                    openedOfficeList: true,
                    activeOfficeMap: true
                };
                const response = action.payload.data;
                entities = {
                    ...entities,
                    [officeId]: {
                        ...selectedOffice,
                        allProductsAvailable: productsIds.length === response.validProductsIds.length
                    }
                };
                validationData = {
                    ...validationData,
                    [officeId]: {
                        validProductsIds: response.validProductsIds
                    }
                };
            }
            return {
                ...state,
                entities,
                validationData,
                selectedId,
                adaptiveMode,
                position,
                validate: basicReducer(state.validate, action)
            };
        }

        case CHOOSE_OFFICE.SUCCEEDED: {
            const { id } = action.meta;
            return {
                ...state,
                chosenId: id
            };
        }

        case CLEAR_OFFICE.BASE: {
            return {
                ...state,
                selectedId: undefined,
                chosenId: undefined
            };
        }

        case SHOW_MODAL.BASE:
            const { show } = action.meta;
            let filter = state.filter;
            if (!show) {
                filter = {};
            }
            return {
                ...state,
                filter,
                showModal: show
            };

        default:
            return state;
    }
}

function calculateCenter(cartOffices) {
    if (!(cartOffices?.length > 0)) {
        return defaultPosition;
    }

    const firstOffice = cartOffices[0].office;
    const data = {
        minLatitude: firstOffice.latitude,
        maxLatitude: firstOffice.latitude,
        minLongitude: firstOffice.longitude,
        maxLongitude: firstOffice.longitude
    };

    cartOffices.forEach((cartOffice) => {
        const office = cartOffice.office;
        if (data.minLatitude > office.latitude) {
            data.minLatitude = office.latitude;
        }
        if (data.maxLatitude < office.latitude) {
            data.maxLatitude = office.latitude;
        }
        if (data.minLongitude > office.longitude) {
            data.minLongitude = office.longitude;
        }
        if (data.maxLongitude < office.longitude) {
            data.maxLongitude = office.longitude;
        }
    });

    return [data.minLatitude + (data.maxLatitude - data.minLatitude) / 2, data.minLongitude + (data.maxLongitude - data.minLongitude) / 2];
}
