import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'LoyaltySummary.title',
        defaultMessage: 'Points balance'
    },
    writeOff: {
        id: 'LoyaltySummary.writeOff',
        defaultMessage: 'Available for write-off'
    },
    notice: {
        id: 'LoyaltySummary.notice',
        defaultMessage: 'No more than 50% of the total order value'
    },
    formLabel: {
        id: 'LoyaltySummary.formLabel',
        defaultMessage: 'Specify the number of points to be debited:'
    }
});
