import React, { FC, memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames/bind';
const classNames = cn.bind(styles);
import Input from 'components/input/Input';
import { ErrorResponse } from 'types/common';
import styles from './InputSplitted.module.css';

type TProps = {
    length: number;
    className?: string;
    error: ErrorResponse | boolean;
    done: (value: string) => void;
};

const InputSplitted: FC<TProps> = ({ length, done, error }) => {
    const containerRef = useRef<any>(null);
    const [values, setValues] = useState<string[]>([]);
    const [inputError, setInputError] = useState<ErrorResponse | null | boolean>(null);

    useEffect(() => {
        setInputError(null);
        if (values?.filter((values) => values?.length > 0)?.length === length) {
            done(values.join(''));
        }
    }, [values]);

    useEffect(() => {
        setInputError(error);
    }, [error]);

    const moveFocus = (value: string, index: number) => {
        if (!(value.length > 0) && index > 0) {
            containerRef.current.querySelectorAll('input')[index - 1].focus();
        }
        if (value.length > 0 && index < length - 1) {
            containerRef.current.querySelectorAll('input')[index + 1].focus();
        }
    };

    return (
        <div className={styles.container} ref={containerRef}>
            {Array.from(Array(length).keys()).map((_, index) => (
                <Input
                    value={values[index]}
                    className={classNames({ error: inputError })}
                    onChange={(event) => {
                        const { value } = event.target;

                        if (value.length > 1 && index > 0) {
                            event.preventDefault();
                            return false;
                        }

                        if (values.length <= length) {
                            if (value.length > 1 && index >= 0) {
                                setValues(
                                    value
                                        .substring(0, length++)
                                        .split('')
                                        .map((value) => value)
                                );
                            } else {
                                setValues((prevState) => {
                                    const newState = [...prevState];
                                    newState[index] = value;
                                    return [...newState];
                                });
                            }
                            moveFocus(value, index);
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default memo(InputSplitted);
