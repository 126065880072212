import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { differenceInSeconds, addSeconds } from 'date-fns';

import Input from 'components/input/Input';
import Button from 'components/button/Button';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import AlertWarning from 'components/alert/AlertWarning';

import { getLoyaltyErrorText } from 'utils/messageUtils';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { activateAccountSelector, activationSelector, sendActivationPinSelector } from 'redux/loyaltyIKB/selectors';
import { activateAccount, sendActivationPin, clearSendActivationPin } from 'redux/loyaltyIKB/actions';
import { getPadTime } from 'utils/timeUtils';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { useTimer } from 'hooks/useTimer';

import commonMessages from 'messages/CommonMessages';
import messages from './IKBCashbackCodeEntryPageMessages';
import styles from './IKBCashbackCodeEntryPage.module.css';

type TState = {
    code: string;
    error: { errorCode: string; message: string } | null;
    timeLeft: number;
};

const IKBCashbackCodeEntryPage: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const territorySettings = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { formatMessage } = useIntl();
    const sendActivationPinResponse = useAppSelector((state) => sendActivationPinSelector(state));
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const activateAccountResponse = useAppSelector((state) => activateAccountSelector(state));
    const activation = useAppSelector((state) => activationSelector(state));
    const sendActivationPinData = useAppSelector(() => sendActivationPinResponse?.response?.data);

    const [isCodeSent, setCodeSent] = useState<boolean>(false);
    const [state, setState] = useState<TState>({
        code: '',
        error: null,
        timeLeft: 0
    });

    const hideTimer = (): void => {
        setCodeSent(false);
        setState((prevState) => ({ ...prevState, timeLeft: 0 }));
    };
    const secondsLeft = useTimer(isCodeSent, hideTimer, state.timeLeft);

    useEffect(() => {
        if (!activation || !activation.accountNumber) {
            navigate('/loyalty');
        } else {
            setState((prevState) => ({ ...prevState, code: '' }));
            resetError();
        }
    }, []);

    useEffect(() => {
        if (activateAccountResponse?.success) {
            navigate('/loyalty/ikb/cashback/activated');
        } else if (sendActivationPinResponse?.success) {
            const expiredTime = addSeconds(new Date(sendActivationPinData.startTime + 'Z'), sendActivationPinData.ttl);
            const differenceSeconds = differenceInSeconds(expiredTime, new Date());
            setState((prevState) => ({ ...prevState, timeLeft: differenceSeconds }));
        }
    }, [sendActivationPinResponse?.success, activateAccountResponse?.success]);

    useEffect(() => {
        if (sendActivationPinResponse?.error || activateAccountResponse?.error) {
            setState((prevState) => ({ ...prevState, error: sendActivationPinResponse.error || activateAccountResponse.error }));
        }
    }, [sendActivationPinResponse?.error, activateAccountResponse?.error]);

    useEffect(() => {
        if (state.timeLeft > 0) {
            dispatch(clearSendActivationPin());
            setCodeSent(true);
        }
    }, [state.timeLeft]);

    const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pattern = /^[0-9\b]+$/;

        if (event.target.value === '' || pattern.test(event.target.value)) {
            setState((prevState) => ({ ...prevState, code: event.target.value }));
        }
    };

    const handleSendActivationPin = () => {
        resetError();
        dispatch(
            sendActivationPin({
                patientId: selectedPatient.id,
                territory: territorySettings.territory,
                number: activation.accountNumber,
                phone: activation.phone
            })
        );
    };

    const handleActivateAccount = () => {
        resetError();
        dispatch(
            activateAccount({
                patientId: selectedPatient.id,
                territory: territorySettings.territory,
                number: activation.accountNumber,
                pin: state.code
            })
        );
    };

    const createNotice = (text: ReactNode[]) => {
        const { ikbLoyaltyRulesLink } = territorySettings;

        return (
            <a href={ikbLoyaltyRulesLink?.uri} target='_blank' rel='noopener noreferrer'>
                {text}
            </a>
        );
    };

    const createNoticeSendCode = (seconds: number) => {
        return <span key={seconds}>{seconds}</span>;
    };

    const resetError = () => {
        setState((prevState) => ({ ...prevState, error: null }));
    };

    const renderError = () => {
        const { error } = state;
        if (error) {
            return <AlertWarning content={getLoyaltyErrorText(intl, error.errorCode, error.message)} />;
        }
        return null;
    };

    const renderContent = () => {
        const { code, error } = state;
        const minutes = Math.floor(secondsLeft / 60);
        const seconds = getPadTime(secondsLeft - minutes * 60);

        const classNameCode = classNames({
            [styles.code]: true,
            [styles.error]: error,
            [styles.valid]: !error && code.length
        });

        return (
            <div className={styles.container}>
                <div className={styles.card}>
                    <div className={styles.info}>
                        {formatMessage(messages.info)}
                        <div>{activation.phone}</div>
                    </div>
                    {renderError()}
                    <div className={styles.inner}>
                        <div className={classNameCode}>
                            <label className={styles.labelCode}>{formatMessage(messages.code)}</label>
                            <Input value={code} onChange={(event) => handleChangeCode(event)} placeholder={'0000'} autoComplete={'off'} maxLength={4} />
                        </div>
                        <Button disabled={code.length < 4} onClick={handleActivateAccount}>
                            {formatMessage(messages.activate)}
                        </Button>
                    </div>
                    <div className={styles.notice}>
                        {secondsLeft > 0 && (
                            <div>
                                {intl.formatMessage(commonMessages.noticeSendCode, {
                                    minutes: () => createNoticeSendCode(minutes),
                                    seconds: () => createNoticeSendCode(seconds)
                                })}
                            </div>
                        )}
                        {secondsLeft === 0 && (
                            <div className={styles.sendCode} onClick={handleSendActivationPin}>
                                {formatMessage(messages.sendCode)}
                            </div>
                        )}
                    </div>
                    <div className={styles.noticeRules}>
                        {formatMessage(commonMessages.IKBNoticeRules, {
                            link: (...chunks: ReactNode[]) => createNotice(chunks),
                            span: (...chunks: ReactNode[]) => <span>{chunks}</span>
                        })}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.cashbackActivation)} back={true} />
            {renderContent()}
        </div>
    );
};

export default memo(IKBCashbackCodeEntryPage);
