//@ts-nocheck
import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { SUPPORTED_LOCALES } from 'redux/i18n/constants';
import ResultService from 'services/ResultService';
import messages from 'page/orders/details/results/result/action/download/ResultDownloadControlMessages';
import download from 'page/orders/assets/details/result/download.svg';
import iconTranslate from 'page/orders/assets/details/result/iconTranslate.svg';
import ActionCard from 'page/orders/component/actionCard/ActionCard';
import commonMessages from 'messages/CommonMessages';

class ResultDownloadControl extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleDownload = this.handleDownload.bind(this);
    }

    render() {
        const { result, intl } = this.props;
        if (!result.filesLocales) {
            return null;
        } else if (result.filesLocales.length > 1) {
            return (
                <React.Fragment>
                    <ActionCard
                        text={intl.formatMessage(commonMessages.download)}
                        icon={download}
                        onClick={(event) => this.handleDownload(event, SUPPORTED_LOCALES.RUSSIAN)}
                    />
                    <ActionCard
                        icon={iconTranslate}
                        text={intl.formatMessage(messages.downloadTranslation)}
                        onClick={(event) => this.handleDownload(event, SUPPORTED_LOCALES.ENGLISH)}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <ActionCard
                    text={intl.formatMessage(commonMessages.download)}
                    icon={download}
                    onClick={(event) => this.handleDownload(event, SUPPORTED_LOCALES.RUSSIAN)}
                />
            );
        }
    }

    handleDownload(event, lang) {
        event.stopPropagation();
        const { resultKey, result, patient, territory } = this.props;
        if (resultKey) {
            ResultService.handleFileUnauth({ inz: result.inz, key: resultKey, lang, download: true, territory });
        } else {
            ResultService.handleFile({ inz: result.inz, patientId: patient.id, lang, download: true, territory });
        }
    }
}

ResultDownloadControl.propTypes = {
    result: PropTypes.object.isRequired,
    patient: PropTypes.object,
    resultKey: PropTypes.string
};

export default injectIntl(ResultDownloadControl);
