import { createSelector } from 'reselect';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { Store } from 'types/store';
import { territorySettingsByTerritorySelector } from 'redux/startup/selectors';

export const chatSelector = (state: Store.App.State) => state.chat;
export const ednaSettingsSelector = (state: Store.App.State) => state.chat.ednaSettings;

export const webChatSettingsSelector = createSelector(
    [territorySettingsByTerritorySelector, currentTerritorySelector],
    (territorySettings, currentTerritory) => {
        if (territorySettings && currentTerritory) {
            const { webChatEnabled, chatLinks, webrtcCallEnabled } = territorySettings[currentTerritory];
            return { webChatEnabled, chatLinks, webrtcCallEnabled };
        }

        return { webChatEnabled: null, chatLinks: null, webrtcCallEnabled: null };
    }
);
