//@ts-nocheck
import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './ActionCard.module.css';

const ActionCard = ({ icon, data, text, className, ...rest }) => {
    return (
        <div className={classNames(styles.container, className)} {...rest}>
            <div className={styles.top}>
                {icon && <img className={styles.icon} alt='' src={icon} />}
                {data && <div className={styles.data}>{data}</div>}
            </div>
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default ActionCard;
