import React, { FC, ReactElement, useId, memo } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import classNames from 'classnames/bind';
import styles from './Hint.module.css';
const cx = classNames.bind(styles);

type TProps = {
    show?: boolean;
    initTooltip?: boolean;
    tooltipId?: null | string;
    tooltipContent?: string;
    img?: string;
    children: ReactElement | ReactElement[] | string;
    text?: string | ReactElement;
    className?: string;
    tooltipClassName?: string;
    onClick?: () => void;
};

const Hint: FC<TProps> = ({
    show = true,
    initTooltip = true,
    tooltipId,
    tooltipContent,
    img,
    children,
    text,
    className,
    tooltipClassName,
    onClick,
    ...rest
}) => {
    const id = useId();
    return show ? (
        <>
            <div
                onClick={onClick}
                data-tooltip-id={tooltipId || id}
                data-tooltip-content={tooltipContent}
                data-tooltip-delay-show={90}
                data-tooltip-delay-hide={100}
                className={cx(styles.hint, className)}
            >
                {img ? img : <span>{text || '?'}</span>}
            </div>
            {initTooltip && (
                <Tooltip
                    id={tooltipId || id}
                    place='top'
                    float={false}
                    variant={'light'}
                    className={cx(styles.tooltip, tooltipClassName)}
                    aria-haspopup='true'
                    style={{ backgroundColor: 'white', fontSize: '13px', borderRadius: '3px' }}
                    children={children}
                    {...rest}
                />
            )}
        </>
    ) : null;
};

export default memo(Hint);
