//@ts-nocheck
import Table from 'components/table/Table';
import commonMessages from 'messages/CommonMessages';
import TestValue from 'page/partial/result/test/value/TestValue';
import PropTypes from 'prop-types';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { groupComparator, renderReference } from 'utils/testUtils';
import styles from './TestResultsTable.module.css';
import messages from 'page/orders/details/results/result/table/TestResultsTableMessages';

class TestResultsTable extends React.PureComponent {
    render() {
        const { intl, result } = this.props;
        const columns = [
            {
                Header: intl.formatMessage(messages.study),
                accessor: 'name',
                Cell: (props) => {
                    return props.cell.value || null;
                }
            },
            {
                Header: intl.formatMessage(messages.result),
                accessor: 'value',
                Cell: (props) => {
                    const test = props.cell.value;
                    return <TestValue test={test} inProgressText={intl.formatMessage(messages.inProgressText)} />;
                }
            },
            {
                Header: intl.formatMessage(messages.referenceValues),
                accessor: 'reference',
                Cell: (props) => {
                    return renderReference(props.cell.value);
                }
            },
            {
                Header: intl.formatMessage(messages.comment),
                accessor: 'comment',
                Cell: (props) => {
                    return props.cell.value || null;
                }
            }
        ];

        const data = [];
        const groupIdToTests = {};
        (result?.testResults ? result.testResults : []).forEach((test) => {
            groupIdToTests[test.groupId] = groupIdToTests[test.groupId] || [];
            groupIdToTests[test.groupId].push(test);
        });
        let firstRow = true;
        Object.entries(groupIdToTests)
            .sort(groupComparator)
            .forEach(([key, values]) => {
                let test = values.find((v) => v.serviceName);
                if (test) {
                    let groupTest = {};
                    groupTest.name = <b>{test.serviceName}</b>;
                    groupTest.value = '';
                    groupTest.unit = '';
                    data.push({
                        name: groupTest.name,
                        value: groupTest,
                        reference: groupTest,
                        comment: ''
                    });
                } else {
                    if (!firstRow) {
                        let groupTest = {};
                        groupTest.name = '';
                        groupTest.value = '';
                        groupTest.unit = '';
                        data.push({
                            name: groupTest.name,
                            value: groupTest,
                            reference: groupTest,
                            comment: ''
                        });
                    }
                }
                values.forEach((test) => {
                    data.push({
                        name: test.shortName,
                        value: test,
                        reference: test,
                        comment: test.laboratoryComment
                    });
                });
                if (firstRow) {
                    firstRow = false;
                }
            });

        return (
            <React.Fragment>
                <Table className={styles.table} columns={columns} data={data} emptyText={intl.formatMessage(messages.noResults)} />
                <div className={styles.footnote}>{intl.formatMessage(commonMessages.valuesBeyondReference)}</div>
            </React.Fragment>
        );
    }
}

TestResultsTable.propTypes = {
    result: PropTypes.object.isRequired
};

export default injectIntl(TestResultsTable);
