import React, { FC, memo } from 'react';
import { SUPPORTED_LOCALES } from 'redux/i18n/constants';
import styles from './LanguageSelector.module.css';
import classNames from 'classnames';
const Select = React.lazy(() => import('react-select'));
const localesGlobal = Object.values(SUPPORTED_LOCALES);

type TProps = {
    locales?: string[];
    locale: string;
    className?: string;
    changeRequest: (locale: { locale: string }) => void;
};

const LanguageSelector: FC<TProps> = ({ locales, locale, className, changeRequest }) => {
    const getLocales = () => {
        return locales || localesGlobal;
    };

    const toOption = (locale: string) => {
        return { label: locale, value: locale };
    };

    const options = getLocales().map((locale: string) => toOption(locale));
    const currentOption = toOption(locale);

    return (
        <Select
            className={classNames(className, styles.selector)}
            classNamePrefix='language-select'
            options={options}
            value={currentOption}
            onChange={(item: { value: string }) => changeRequest({ locale: item.value })}
            isSearchable={false}
        />
    );
};

export default memo(LanguageSelector);
