//@ts-nocheck
import { all, takeLatest } from 'redux-saga/effects';
import { request } from 'redux/helpers';

import { ACTIVATE_ACCOUNT, FETCH_CATEGORIES, SEND_ACTIVATION_PIN, CHANGE_CATEGORY, FETCH_MOTIVATING_TOOLTIP } from 'redux/loyaltyIKB/actions';
import * as loyaltyClient from 'client/LoyaltyClient';
import LocalStorageService from 'services/LocalStorageService';
import IKBTooltipConstants from 'constants/ikbTooltipMenu';

function* sendActivationPin(action) {
    yield request(SEND_ACTIVATION_PIN, [loyaltyClient.sendActivationPin, action.meta], action.meta);
}

function* activateAccount(action) {
    yield request(ACTIVATE_ACCOUNT, [loyaltyClient.activateAccount, action.meta], action.meta);
}

function* fetchCategories(action) {
    yield request(FETCH_CATEGORIES, [loyaltyClient.fetchCategories, action.meta], action.meta);
}

function* changeCategory(action) {
    yield request(CHANGE_CATEGORY, [loyaltyClient.changeCategory, action.meta], action.meta);
}

function* fetchMotivatingTooltip(action) {
    const { data } = yield request(FETCH_MOTIVATING_TOOLTIP, [loyaltyClient.fetchMotivatingTooltip, action.meta], action.meta);
    if (!data) {
        const tooltipDataStorage = yield JSON.parse(LocalStorageService.getItem(IKBTooltipConstants.LOCAL_STORAGE_KEY)) || [];
        LocalStorageService.setItem(
            IKBTooltipConstants.LOCAL_STORAGE_KEY,
            JSON.stringify([...tooltipDataStorage.filter((item) => item.patientId !== action.meta.patientId)])
        );
    }
}

export default function* loyaltyIKBSagas() {
    yield all([
        takeLatest(SEND_ACTIVATION_PIN.BASE, sendActivationPin),
        takeLatest(ACTIVATE_ACCOUNT.BASE, activateAccount),
        takeLatest(FETCH_CATEGORIES.BASE, fetchCategories),
        takeLatest(CHANGE_CATEGORY.BASE, changeCategory),
        takeLatest(FETCH_MOTIVATING_TOOLTIP.BASE, fetchMotivatingTooltip)
    ]);
}
