import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CreatePatientModal.title',
        defaultMessage: 'Add patient'
    },
    middleNameLabel: {
        id: 'CreatePatientModal.middleNameLabel',
        defaultMessage: 'Middle name'
    },
    createSuccess: {
        id: 'CreatePatientModal.createSuccess',
        defaultMessage: 'Patient successfully created'
    }
});
