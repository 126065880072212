import { defineMessages } from 'react-intl';

export default defineMessages({
    info: {
        id: 'IKBCashbackCodeEntryPage.info',
        defaultMessage: 'Phone confirmation'
    },
    code: {
        id: 'IKBCashbackCodeEntryPage.code',
        defaultMessage: 'SMS code'
    },
    error: {
        id: 'IKBCashbackCodeEntryPage.error',
        defaultMessage: 'Wrong SMS code entered'
    },
    sendCode: {
        id: 'IKBCashbackCodeEntryPage.sendCode',
        defaultMessage: 'Send SMS again'
    },
    activate: {
        id: 'IKBCashbackCodeEntryPage.activate',
        defaultMessage: 'Activate'
    }
});
