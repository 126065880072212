import React, { FC, memo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
// @ts-ignore
import { isEmpty } from 'lodash';
import { cashbackStatusSelector } from 'redux/loyaltyIKB/selectors';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import NextChangeDays from 'page/loyalty/ikb/components/NextChangeDays';
import { getDaysToChanges } from 'utils/cashbackUtils';
import commonMessages from 'messages/CommonMessages';
import styles from './IKBServicesDetails.module.css';
import { clearCashbackStatus } from 'redux/loyaltyIKB/actions';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { getCashbackStatus } from 'redux/loyaltyStatus/actions';

const IKBServicesDetailsPage: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { territory } = useAppSelector(currentTerritorySettingsSelector);
    const bonusStatus = useAppSelector(cashbackStatusSelector);

    useEffect(() => {
        const patientId = searchParams.get('patientId');
        if (bonusStatus?.category?.maxFavoriteProducts <= 0) {
            navigate('/loyalty');
        } else if (isEmpty(bonusStatus) && patientId) {
            dispatch(getCashbackStatus(patientId));
        }
        return () => {
            dispatch(clearCashbackStatus());
        };
    }, []);

    const renderContent = () => {
        const { category } = bonusStatus;
        const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);

        return (
            <div className={styles.container}>
                {category && (
                    <div className={styles.card}>
                        <h5 className={styles.title}>{category.name}</h5>
                        {category.favoriteProducts.length > 0 && (
                            <div className={styles.products}>
                                {category.favoriteProducts.map((product: any) => (
                                    <div className={styles.productItem} key={product.productId}>
                                        <div className={styles.productTitle}>
                                            {intl.formatMessage(commonMessages.numberFormat, {
                                                number: product.article
                                            })}{' '}
                                            — {product.shortName}
                                        </div>
                                        <div className={styles.productDiscount}>{product.charge}%</div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className={styles.noticeCashback}>
                            <p>{intl.formatMessage(commonMessages.otherServices)}</p>
                            <span>{ikbLoyaltyService.getBaseDiscountPercent()}%</span>
                        </div>
                        {bonusStatus.newChangeDate && (
                            <div className={styles.noticeService}>
                                <NextChangeDays days={getDaysToChanges(bonusStatus.newChangeDate)} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <IKBNavigationBar title={intl.formatMessage(commonMessages.individualServices)} back={true} />
            {renderContent()}
        </div>
    );
};

export default memo(IKBServicesDetailsPage);
