import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'ResultsByKeyPage.title',
        defaultMessage: 'Tests results'
    },
    clientLabelFormat: {
        id: 'ResultsByKeyPage.clientLabelFormat',
        defaultMessage: 'Client: {client}'
    },
    gotAccount: {
        id: 'ResultsByKeyPage.gotAccount',
        defaultMessage: 'Got an account?'
    }
});
