import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import ButtonLink from 'components/button/link/ButtonLink';
import styles from './ButtonLinkSvg.module.css';
const cx = classNames.bind(styles);

type TProps = {
    svg: string;
    text?: string;
    className?: string;
    onClick: () => void;
    disabled?: boolean;
};

const ButtonLinkSvg: FC<TProps> = ({ svg, text, className, ...rest }) => {
    return (
        <ButtonLink className={cx(styles.button, className)} {...rest}>
            <svg>
                <use href={svg} />
            </svg>
            {text ? <span>{text}</span> : null}
        </ButtonLink>
    );
};

export default memo(ButtonLinkSvg);
