//@ts-nocheck

export const INVITRO_CART_COOKIE_KEY = 'INVITRO_CART';

export const INVITRO_CART_ORDER_ID_COOKIE_KEY = 'orderId';

export const INVITRO_CART_OFFICE_ID_COOKIE_KEY = 'officeId';

export const APPOINTMENT_COOKIE_KEY = 'covidorder';

export const PAYMENT_STATUS = {
    AWAITING: 'AWAITING',
    AUTHORIZED: 'AUTHORIZED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    DECLINED: 'DECLINED'
};

export const POLLING_ERROR_STATUS = {
    NETWORK_ERROR: 'NETWORK_ERROR',
    MAX_POLLING: 'MAX_POLLING'
};

export const POLLING_DELAY = 3000;

export const MAX_POLLING_COUNT = 120;

export const CART_ORDER_TYPE = {
    CHECKUPS: 'CHECKUPS',
    ANALYZES: 'ANALYZES',
    REORDER: 'REORDER',
    PAYMENT_PREORDER: 'PAYMENT_PREORDER'
};

export const MINDBOX_CLEAR_CART = 'Website.ClearCart';
