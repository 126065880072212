import React, { FC, memo } from 'react';
import classNames from 'classnames';

import styles from './Loader.module.css';

type TProps = {
    className?: string;
};

const Loader: FC<TProps> = ({ className }) => {
    return <div className={classNames(styles.loader, className)} />;
};

export default memo(Loader);
