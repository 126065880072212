//@ts-nocheck
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { cartAnalyzesSelector, cartConfirmableSelector, onlinePaymentPublicIdSelector } from 'redux/cart/selectors';

import Button from 'components/button/Button';

import yandexPay from 'components/icon/paymentSystems/yandexPay.svg';
import ButtonSvg from 'components/button/svg/ButtonSvg';

import style from '../CardForm.module.css';

import cardFormMessages from 'page/cart/confirmation/cardForm/cardForm.messages';
import { INPUT_ERRORS_CODES } from 'page/cart/confirmation/cardForm/cardForm.config';

class ActionsBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.script = null;

        this.handleCreatePaymentCryptogram = this.handleCreatePaymentCryptogram.bind(this);
    }

    async handleCreatePaymentCryptogram() {
        const { publicId, cartConfirmable, email, onConfirmation, formData, setState } = this.props;

        const checkout = new window.cp.Checkout({ publicId });

        const { cvv, cardNumber, expDate } = formData;

        const expDateMonth = expDate.split('/')[0];
        const expDateYear = expDate.split('/')[1];

        const fieldValues = {
            cvv,
            cardNumber,
            expDateMonth,
            expDateYear
        };

        try {
            const cryptogram = await checkout.createPaymentCryptogram(fieldValues);

            const paymentPayload = {
                cardCryptogramPacket: cryptogram
            };

            onConfirmation({ ...paymentPayload, email: cartConfirmable.hasEmail ? email : null });
        } catch (error) {
            let translateErrors = {};

            for (const errorKey in error) {
                translateErrors = {
                    ...translateErrors,
                    [errorKey]: INPUT_ERRORS_CODES[error[errorKey]]
                };
            }

            setState({
                cpErrors: translateErrors
            });
        }
    }

    checkAllPayCondition() {
        const { formData, inputErrors, deliveryData, onlineRegistrationEnabled, emailError, email, onlineRegistrationData, cartConfirmable } = this.props;
        const { errors } = deliveryData;

        const hasEmptyField = Object.keys(formData).some((key) => !formData[key]);

        const hasError = Object.keys(inputErrors).some((key) => !!inputErrors[key]);

        let hasDeliveryDataError = false;
        if (onlineRegistrationEnabled) {
            hasDeliveryDataError = (errors && Object.keys(errors).some((key) => errors[key])) || !onlineRegistrationData.valid;
        }
        let hasEmailError = false;
        if (cartConfirmable.hasEmail) {
            if (!email || emailError.email) {
                hasEmailError = true;
            }
        }
        return !(!hasEmptyField && !hasError && !hasDeliveryDataError && !hasEmailError);
    }

    componentDidMount() {
        this.script = document.createElement('script');
        this.script.src = 'https://checkout.cloudpayments.ru/checkout.js';
        this.script.async = true;

        document.body.appendChild(this.script);
    }

    componentWillUnmount() {
        document.body.removeChild(this.script);
    }

    render() {
        const { intl, onConfirmation, cartAnalyzes, authenticated } = this.props;
        const hasNotAppliedData = cartAnalyzes.hasNotAppliedCertificates || cartAnalyzes.hasNotAppliedCoupons;
        const payDisabled = this.checkAllPayCondition() || hasNotAppliedData || !authenticated;

        return (
            <div className={style.actions}>
                {cartAnalyzes.hasAppliedCertificates && cartAnalyzes.isPrepaid ? (
                    <Button disabled={hasNotAppliedData} onClick={() => onConfirmation({ cardCryptogramPacket: ' ' })}>
                        {intl.formatMessage(cardFormMessages.confirmButton)}
                    </Button>
                ) : (
                    <Button onClick={this.handleCreatePaymentCryptogram} disabled={payDisabled}>
                        {intl.formatMessage(cardFormMessages.payButton)}
                    </Button>
                )}
                {this.props?.yp && (
                    <ButtonSvg
                        disabled={hasNotAppliedData}
                        svg={`${yandexPay}#yandexPay`}
                        onClick={() => console.info('yandexPay')}
                        text={intl.formatMessage(cardFormMessages.yandexBtn)}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deliveryData: state.cart.deliveryData,
        cartAnalyzes: cartAnalyzesSelector(state),
        onlineRegistrationEnabled: state.cart.onlineRegistrationEnabled,
        publicId: onlinePaymentPublicIdSelector(state),
        onlineRegistrationData: state.cart.onlineRegistrationData,
        authenticated: state.auth.authenticated,
        cartConfirmable: cartConfirmableSelector(state)
    };
};

export default connect(mapStateToProps)(ActionsBlock);
