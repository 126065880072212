//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const RESULTS_URL = `${BASE_URL}/unauth/results`;

export const search = (data) => {
    return apiClient.get(`${RESULTS_URL}?${queryString.stringify(data)}`);
};

export const getPdf = (data) => {
    return apiClient.get(`${RESULTS_URL}/pdf?${queryString.stringify(data)}`, { responseType: 'blob' });
};

export const getPdfLink = (data) => {
    return `${RESULTS_URL}/pdf?${queryString.stringify(data)}`;
};

export const sendEmail = (data) => {
    return apiClient.post(`${RESULTS_URL}/send`, data);
};

export const parseKey = (data) => {
    return apiClient.post(`${RESULTS_URL}/parse-key`, data);
};

export const searchByKey = (data) => {
    return apiClient.get(`${RESULTS_URL}/key?${queryString.stringify(data)}`);
};
