//@ts-nocheck
import * as React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames/bind';

import { SUPPORTED_TERRITORIES } from 'redux/cities/constants';
import { CommunicationType } from 'constants/communicationType';

import Logo from 'components/icon/logo/Logo';
import Cart from 'page/layout/header/middle/Cart';
import WhatsApp from 'page/layout/components/whatsApp/WhatsApp';
import Phone from 'page/layout/components/phone/Phone';
import IvitroLogo from 'components/icon/Ivitro/IvitroLogo';

import messages from 'page/layout/header/middle/HeaderMiddleMessages';
import styles from './HeaderMiddle.module.css';

interface IHeaderMiddleProps {
    intl: any;
    territorySettings: any;
}

const HeaderMiddle = ({ intl, territorySettings }) => {
    const renderLogo = (territorySettings) => {
        if (territorySettings?.territory === SUPPORTED_TERRITORIES.CRIMEA) {
            return <IvitroLogo className={styles.logo} />;
        }
        return <Logo className={styles.logo} />;
    };

    const renderCommunications = (territorySettings) => {
        const { communications } = territorySettings;
        const filteredCommunications = communications?.filter((c) => c.type === CommunicationType.WHATS_APP || c.type === CommunicationType.PHONE);

        return filteredCommunications?.map((communication) => {
            if (communication.type === CommunicationType.WHATS_APP) {
                return <WhatsApp key={communication.type} data={communication} />;
            } else {
                const { territory } = territorySettings;
                return <Phone key={communication.number} className={styles.phone} intl={intl} territory={territory} data={communication} />;
            }
        });
    };

    const renderCart = (territorySettings) => {
        if (territorySettings?.availableCart) {
            return <Cart />;
        }
        return null;
    };

    return (
        <div className={styles.container}>
            <div className={classNames(styles.row, styles.center)}>
                <div className={classNames(styles.row, styles.center, styles.middleRow)}>
                    <div className={classNames(styles.row, styles.start, styles.center)}>
                        <a href={territorySettings.headerLogoLink?.uri}>{renderLogo(territorySettings)}</a>
                        <div className={styles.headerTitle}>{intl.formatMessage(messages.text)}</div>
                    </div>
                    <div className={classNames(styles.row, styles.start, styles.center)}>
                        <div className={styles.communications}>{renderCommunications(territorySettings)}</div>
                        {renderCart(territorySettings)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(HeaderMiddle);
