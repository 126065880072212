//@ts-nocheck
import axios from 'axios';
import { logout, refresh } from 'client/UserClient';
import CookieService from 'services/CookieService';
import { ACCESS_TOKEN } from 'redux/auth/constants';
import { WRONG_CREDENTIALS } from 'redux/auth/errorCodes';
import { AUTHORIZE_URL } from 'client/constants';

class ApiClient {
    constructor() {
        this.setInterceptors();
    }

    setInterceptors() {
        let isRefreshing = false;
        let refreshSubscribers = [];

        axios.interceptors.request.use(
            (config) => {
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        const subscribeTokenRefresh = (callback) => {
            refreshSubscribers.push(callback);
        };

        const onRefreshed = (token) => {
            refreshSubscribers.map((callback) => callback(token));
        };

        const isRefresh = (response) => {
            return response.config.url === `${AUTHORIZE_URL}/refresh/cookies`;
        };

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const { response } = error;
                const access_token = CookieService.get(ACCESS_TOKEN);
                if (response?.status === WRONG_CREDENTIALS && access_token && !isRefresh(response)) {
                    return handle401Error(error);
                }

                return Promise.reject(error);
            }
        );

        const handle401Error = async (error) => {
            const pendingRequest = error.config;

            if (!isRefreshing) {
                isRefreshing = true;
                refresh()
                    .then(() => {
                        const access_token = CookieService.get(ACCESS_TOKEN);
                        isRefreshing = false;
                        onRefreshed(access_token);
                        refreshSubscribers = [];
                    })
                    .catch(() => {
                        logout().finally(() => {
                            this.removeGlobalHeader('Authorization');
                            CookieService.remove(ACCESS_TOKEN);
                            window.location.replace('/login');
                        });
                    });
            }

            return new Promise((resolve) => {
                subscribeTokenRefresh(() => {
                    const access_token = CookieService.get(ACCESS_TOKEN);
                    this.setGlobalHeader('Authorization', `Bearer ${access_token}`);
                    pendingRequest.headers['Authorization'] = `Bearer ${access_token}`;
                    resolve(axios(pendingRequest));
                });
            });
        };
    }

    setGlobalHeader(name, value) {
        axios.defaults.headers.common[name] = value;
    }

    getGlobalHeader(name) {
        return axios.defaults.headers.common[name];
    }

    removeGlobalHeader(name) {
        delete axios.defaults.headers.common[name];
    }

    get(url, config?) {
        return axios.get(url, config);
    }

    post(url, data, config) {
        return axios.post(url, data, config);
    }

    put(url, data, config) {
        return axios.put(url, data, config);
    }

    patch(url, data, config) {
        return axios.patch(url, data, config);
    }

    delete(url, config) {
        return axios.delete(url, config);
    }
}

export default new ApiClient();
