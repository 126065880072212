//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { FETCH_CHECKUPS, CLEAR_ACTIVATE_CHECKUP, ACTIVATE_CHECKUP, CLEAR_ALL_CHECKUPS } from 'redux/checkups/actions';

const initialState = {
    fetchAll: {},
    activate: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CHECKUPS.STARTED:
        case FETCH_CHECKUPS.SUCCEEDED:
        case FETCH_CHECKUPS.ERRORED: {
            return {
                ...state,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        }
        case ACTIVATE_CHECKUP.SUCCEEDED:
        case ACTIVATE_CHECKUP.STARTED:
        case ACTIVATE_CHECKUP.ERRORED:
            return {
                ...state,
                activate: basicReducer(state.activate, action)
            };
        case CLEAR_ACTIVATE_CHECKUP.BASE:
            return {
                ...state,
                activate: {}
            };
        case CLEAR_ALL_CHECKUPS.BASE:
            return initialState;
        default:
            return state;
    }
}
