import { defineMessages } from 'react-intl';

export default defineMessages({
    orderListTitle: {
        id: 'OrderBlock.orderListTitle',
        defaultMessage: 'Order list'
    },
    reorderListTitle: {
        id: 'OrderBlock.reorderListTitle',
        defaultMessage: 'Reorder list'
    },
    totalTitle: {
        id: 'OrderBlock.totalTitle',
        defaultMessage: 'Total:'
    },
    refresh: {
        id: 'OrderBlock.refresh',
        defaultMessage: 'Refresh'
    },
    checkupInformation: {
        id: 'OrderBlock.checkupInformation',
        defaultMessage: 'Bonus points, certificates, coupons and other discounts cannot be used when purchasing health programs.'
    }
});
