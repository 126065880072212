import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'OnlineRegistrationBlock.title',
        defaultMessage: 'Online registration'
    },
    description: {
        id: 'OnlineRegistrationBlock.description',
        defaultMessage: 'Data to form a contract'
    },
    hint: {
        id: 'OnlineRegistrationBlock.hint',
        defaultMessage: 'Fill out the online registration form. This data will be saved for future orders.'
    },
    passport: {
        id: 'OnlineRegistrationBlock.passport',
        defaultMessage: 'Passport'
    },
    passportSeriesLabel: {
        id: 'OnlineRegistrationBlock.passportSeriesLabel',
        defaultMessage: 'Passport series'
    },
    passportSeriesPlaceholder: {
        id: 'OnlineRegistrationBlock.passportSeriesPlaceholder',
        defaultMessage: '0000'
    },
    passportNumberLabel: {
        id: 'OnlineRegistrationBlock.passportNumberLabel',
        defaultMessage: 'Passport number'
    },
    passportNumberPlaceholder: {
        id: 'OnlineRegistrationBlock.passportNumberPlaceholder',
        defaultMessage: '000000'
    },
    passportIssuerLabel: {
        id: 'OnlineRegistrationBlock.passportIssuerLabel',
        defaultMessage: 'Issuer'
    },
    passportIssuerPlaceholder: {
        id: 'OnlineRegistrationBlock.passportIssuerPlaceholder',
        defaultMessage: 'NYPD'
    },
    passportIssueDateLabel: {
        id: 'OnlineRegistrationBlock.passportIssueDateLabel',
        defaultMessage: 'Issue date'
    },
    passportUnitCodeLabel: {
        id: 'OnlineRegistrationBlock.passportUnitCodeLabel',
        defaultMessage: 'Unit code'
    },
    passportUnitCodePlaceholder: {
        id: 'OnlineRegistrationBlock.passportUnitCodePlaceholder',
        defaultMessage: '000 000'
    },
    address: {
        id: 'OnlineRegistrationBlock.address',
        defaultMessage: 'Registration address'
    },
    addressCountryLabel: {
        id: 'OnlineRegistrationBlock.addressCountryLabel',
        defaultMessage: 'Country'
    },
    addressRegionLabel: {
        id: 'OnlineRegistrationBlock.addressRegionLabel',
        defaultMessage: 'Region'
    },
    addressCityLabel: {
        id: 'OnlineRegistrationBlock.addressCityLabel',
        defaultMessage: 'City'
    },
    addressCityPlaceholder: {
        id: 'OnlineRegistrationBlock.addressCityPlaceholder',
        defaultMessage: 'New-York'
    },
    addressStreetLabel: {
        id: 'OnlineRegistrationBlock.addressStreetLabel',
        defaultMessage: 'Street'
    },
    addressStreetPlaceholder: {
        id: 'OnlineRegistrationBlock.addressStreetPlaceholder',
        defaultMessage: 'Lincoln Blvd.'
    },
    addressHomeNumberLabel: {
        id: 'OnlineRegistrationBlock.addressHomeNumberLabel',
        defaultMessage: 'Home number'
    },
    addressHomeNumberPlaceholder: {
        id: 'OnlineRegistrationBlock.addressHomeNumberPlaceholder',
        defaultMessage: '21'
    },
    addressBlockLabel: {
        id: 'OnlineRegistrationBlock.addressBlockLabel',
        defaultMessage: 'Block (optional)'
    },
    addressApartmentLabel: {
        id: 'OnlineRegistrationBlock.addressApartmentLabel',
        defaultMessage: 'Apartment'
    },
    addressApartmentPlaceholder: {
        id: 'OnlineRegistrationBlock.addressApartmentPlaceholder',
        defaultMessage: '3'
    }
});
