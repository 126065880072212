import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'ChangeCityForm.search',
        defaultMessage: 'City search'
    },
    searchMessage: {
        id: 'ChangeCityForm.searchMessage',
        defaultMessage: 'Nothing found'
    },
    cities: {
        id: 'ChangeCityForm.cities',
        defaultMessage: 'Cities'
    },
    countries: {
        id: 'ChangeCityForm.countries',
        defaultMessage: 'Countries'
    }
});
