import { defineMessages } from 'react-intl';

export default defineMessages({
    apply: {
        id: 'Common.apply',
        defaultMessage: 'Apply'
    },
    genderMale: {
        id: 'Common.genderMale',
        defaultMessage: 'Male'
    },
    genderFemale: {
        id: 'Common.genderFemale',
        defaultMessage: 'Female'
    },
    genderUnknown: {
        id: 'Common.genderUnknown',
        defaultMessage: 'Unknown'
    },
    newOrder: {
        id: 'Common.newOrder',
        defaultMessage: 'New order'
    },
    addResults: {
        id: 'Common.addResults',
        defaultMessage: 'Add results'
    },
    addPatient: {
        id: 'Common.addPatient',
        defaultMessage: 'Add patient'
    },
    addPatientTitle: {
        id: 'Common.addPatientTitle',
        defaultMessage: 'Add patient information to place your order'
    },
    back: {
        id: 'Common.back',
        defaultMessage: 'Back'
    },
    serverError: {
        id: 'Common.serverError',
        defaultMessage: 'unable to get response from server'
    },
    send: {
        id: 'Common.send',
        defaultMessage: 'Send'
    },
    resend: {
        id: 'Common.resend',
        defaultMessage: 'Send again'
    },
    save: {
        id: 'Common.save',
        defaultMessage: 'Save'
    },
    ok: {
        id: 'Common.ok',
        defaultMessage: 'OK'
    },
    confirm: {
        id: 'Common.confirm',
        defaultMessage: 'Confirm'
    },
    valuesBeyondReference: {
        id: 'Common.valuesBeyondReference',
        defaultMessage: '* values beyond reference values'
    },
    view: {
        id: 'Common.view',
        defaultMessage: 'View'
    },
    download: {
        id: 'Common.download',
        defaultMessage: 'Download'
    },
    print: {
        id: 'Common.print',
        defaultMessage: 'print'
    },
    sendByEmail: {
        id: 'Common.sendByEmail',
        defaultMessage: 'Send by e-mail'
    },
    email: {
        id: 'Common.email',
        defaultMessage: 'E-mail'
    },
    emailPlaceholder: {
        id: 'Common.emailPlaceholder',
        defaultMessage: 'yournickname@domain.com'
    },
    phone: {
        id: 'Common.phone',
        defaultMessage: 'Phone'
    },
    phonePlaceholder: {
        id: 'Common.phonePlaceholder',
        defaultMessage: '+12345678901'
    },
    formattedNumber: {
        id: 'Common.formattedNumber',
        defaultMessage: '#{number}'
    },
    completed: {
        id: 'Common.completed',
        defaultMessage: 'Completed'
    },
    notCompleted: {
        id: 'Common.notCompleted',
        defaultMessage: 'Not completed'
    },
    sendEmailSuccess: {
        id: 'Common.sendEmailSuccess',
        defaultMessage: 'Email sent successfully'
    },
    language: {
        id: 'Common.language',
        defaultMessage: 'Language'
    },
    retry: {
        id: 'Common.retry',
        defaultMessage: 'Retry'
    },
    firstNameLabel: {
        id: 'Common.firstNameLabel',
        defaultMessage: 'Name'
    },
    firstNamePlaceholder: {
        id: 'Common.firstNamePlaceholder',
        defaultMessage: 'John'
    },
    middleNameLabel: {
        id: 'Common.middleNameLabel',
        defaultMessage: 'Middle name'
    },
    middleNamePlaceholder: {
        id: 'Common.middleNamePlaceholder',
        defaultMessage: '-'
    },
    lastNameLabel: {
        id: 'Common.lastNameLabel',
        defaultMessage: 'Last name'
    },
    lastNamePlaceholder: {
        id: 'Common.lastNamePlaceholder',
        defaultMessage: 'Smith'
    },
    officeNameFormat: {
        id: 'Common.officeNameFormat',
        defaultMessage: 'Medical office {office}'
    },
    inzNumberFormat: {
        id: 'Common.inzNumberFormat',
        defaultMessage: 'INZ {inz}'
    },
    birthdayLabel: {
        id: 'Common.birthdayLabel',
        defaultMessage: 'Birth date'
    },
    birthdayLabelFormat: {
        id: 'Common.birthdayLabelFormat',
        defaultMessage: 'Birth date: {birthday}'
    },
    genderLabel: {
        id: 'Common.genderLabel',
        defaultMessage: 'Gender'
    },
    genderLabelFormat: {
        id: 'Common.genderLabelFormat',
        defaultMessage: 'Gender: {gender}'
    },
    resultsConsultationWarning: {
        id: 'Common.resultsConsultationWarning',
        defaultMessage: 'The results of research are not a diagnosis, you need to consult a specialist.'
    },
    login: {
        id: 'Common.login',
        defaultMessage: 'Login'
    },
    freeCall: {
        id: 'Common.freeCall',
        defaultMessage: 'Free call in {territoryName}'
    },
    inRussia: {
        id: 'Common.inRussia',
        defaultMessage: 'Russia'
    },
    inKazakhstan: {
        id: 'Common.inKazakhstan',
        defaultMessage: 'Kazakhstan'
    },
    inArmenia: {
        id: 'Common.inArmenia',
        defaultMessage: 'Armenia'
    },
    inBelarus: {
        id: 'Common.inBelarus',
        defaultMessage: 'Belarus'
    },
    inKyrgyzstan: {
        id: 'Common.inKyrgyzstan',
        defaultMessage: 'Kyrgyzstan'
    },
    inUkraine: {
        id: 'Common.inUkraine',
        defaultMessage: 'Ukraine'
    },
    acceptPersonalAgreement: {
        id: 'Common.acceptPersonalAgreement',
        defaultMessage: ' and accept <link>personal agreement</link>'
    },
    acceptPersonalAgreementBelarus: {
        id: 'Common.acceptPersonalAgreementBelarus',
        defaultMessage: ' and give <link>consent</link> to the processing of personal data'
    },
    acceptUserAgreement: {
        id: 'Common.acceptUserAgreement',
        defaultMessage: 'Accept <link>user agreement</link>'
    },
    discountProgram: {
        id: 'Common.discountProgram',
        defaultMessage: 'discount program'
    },
    employeeDiscountProgram: {
        id: 'Common.employeeDiscount',
        defaultMessage: 'employee discount'
    },
    employeeDiscountNominal: {
        id: 'Common.employeeDiscountNominal',
        defaultMessage: 'Discount amount {percent}%'
    },
    bonusProgram: {
        id: 'Common.bonusProgram',
        defaultMessage: 'your status in the bonus program'
    },
    bonusScore: {
        id: 'Common.bonusScore',
        defaultMessage: 'active balance'
    },
    bonusScoreUnit: {
        id: 'Common.bonusScoreUnit',
        defaultMessage: '{points, plural, one {# point} other {# point}}'
    },
    bonusHint: {
        id: 'Common.bonusHint',
        defaultMessage: 'The bonuses you can use when forming the order. 1 bonus = 1 {currency}'
    },
    statusHint: {
        id: 'Common.bonusStatusHint',
        defaultMessage: 'Bonus program membership status. In accordance with status different amount of bonuses will be accrued.'
    },
    notConnectedLoyalty: {
        id: 'Common.notConnectedLoyalty',
        defaultMessage: 'You are not a member of the loyalty program'
    },
    subscribe: {
        id: 'Common.subscribe',
        defaultMessage: 'I agree to receive promotional and informational messages'
    },
    fullName: {
        id: 'Common.fullName',
        defaultMessage: 'Full name'
    },
    fullNamePlaceholder: {
        id: 'Common.fullNamePlaceholder',
        defaultMessage: 'John Smith'
    },
    address: {
        id: 'Common.address',
        defaultMessage: 'Address'
    },
    addressPlaceholder: {
        id: 'Common.addressPlaceholder',
        defaultMessage: 'Brannan st. 10, apt. 5'
    },
    readySolutions: {
        id: 'Common.readySolutions',
        defaultMessage: 'Ready solutions'
    },
    individualServices: {
        id: 'Common.individualServices',
        defaultMessage: 'Individual services'
    },
    regularCashback: {
        id: 'Common.regularCashback',
        defaultMessage: 'Regular cashback'
    },
    increaseCashback: {
        id: 'Common.increaseCashback',
        defaultMessage: 'Increase cashback'
    },
    choose: {
        id: 'Common.choose',
        defaultMessage: 'Choose'
    },
    otherServices: {
        id: 'Common.otherServices',
        defaultMessage: 'Other services'
    },
    otherProductsNotice: {
        id: 'Common.otherProductsNotice',
        defaultMessage: 'Other tests not included in the category'
    },
    activateCashback: {
        id: 'Common.activateCashback',
        defaultMessage: 'Activate cashback'
    },
    cashbackActivation: {
        id: 'Common.cashbackActivation',
        defaultMessage: 'Cashback activation'
    },
    personalArea: {
        id: 'Common.personalArea',
        defaultMessage: 'Personal area'
    },
    IKBNoticeRules: {
        id: 'Common.IKBNoticeRules',
        defaultMessage: '<span>By clicking the activate button,</span> you accept the <link>program rules</link>'
    },
    IKBCashbackTitle: {
        id: 'Common.IKBCashbackTitle',
        defaultMessage: 'Cashback'
    },
    numberFormat: {
        id: 'Common.numberFormat',
        defaultMessage: '# {number}'
    },
    noticeSendCode: {
        id: 'Common.noticeSendCode',
        defaultMessage: 'You can send the code again via <minutes></minutes> m <seconds></seconds> s'
    },
    needFillContactDetails: {
        id: 'Common.needFillContactDetails',
        defaultMessage: 'Patient contact information must be filled'
    },
    onlineRegistrationWarning: {
        id: 'Common.onlineRegistrationWarning',
        defaultMessage:
            'Attention! Before printing documents at the online registration counter, please contact your office administrator for temperature measurements. In order to avoid the spread of coronavirus infection, we do not provide services in the Invitro medical offices if your temperature is above 37.5.'
    },
    ruble: {
        id: 'Common.ruble',
        defaultMessage: 'ruble'
    },
    tenge: {
        id: 'Common.tenge',
        defaultMessage: 'tenge'
    },
    hryvnia: {
        id: 'Common.hryvnia',
        defaultMessage: 'hryvnia'
    },
    dram: {
        id: 'Common.dram',
        defaultMessage: 'dram'
    },
    som: {
        id: 'Common.som',
        defaultMessage: 'som'
    },
    notAllowedLoyalty: {
        id: 'Common.notAllowedLoyalty',
        defaultMessage:
            'You don’t have access to loyalty program at the moment. To get access, please, request confirmation in "Patients" module and activate "Allow to use loyalty program" option.'
    },
    phoneFormatError: {
        id: 'Common.phoneFormatError',
        defaultMessage: 'Enter the phone number in the format {formatPhone}'
    },
    invitro: {
        id: 'Common.invitro',
        defaultMessage: 'INVITRO'
    },
    ivitro: {
        id: 'Common.ivitro',
        defaultMessage: 'IVITRO'
    },
    feedback: {
        id: 'Common.feedback',
        defaultMessage: 'Feedback'
    },
    buttonContinue: {
        id: 'Common.buttonContinue',
        defaultMessage: 'Continue'
    },
    buttonConfirmSelection: {
        id: 'Common.buttonConfirmSelection',
        defaultMessage: 'Confirm selection'
    },
    enterEmail: {
        id: 'Common.enterEmail',
        defaultMessage: 'Enter e-mail'
    },
    requiredField: {
        id: 'Common.requiredField',
        defaultMessage: 'required field'
    },
    paymentPhoneNotice: {
        id: 'Common.paymentPhoneNotice',
        defaultMessage: 'Make sure that the phone number entered below belongs to you'
    },
    approvalData: {
        id: 'Common.approvalData',
        defaultMessage: 'Fill in the details below to increase the likelihood of installment approval:'
    },
    emailForCheck: {
        id: 'Common.emailForCheck',
        defaultMessage: 'E-mail for sending the check'
    },
    yes: {
        id: 'Common.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'Common.no',
        defaultMessage: 'No'
    },
    quantity: {
        id: 'Common.quantity',
        defaultMessage: 'pcs'
    },
    reorderNumberTitle: {
        id: 'Common.reorderNumberTitle',
        defaultMessage: 'Additional order to order # <orderNumber></orderNumber>'
    },
    couponNotApplicable: {
        id: 'Common.couponNotApplicable',
        defaultMessage: 'Coupon not applicable'
    },
    certificateNotApplicable: {
        id: 'Common.certificateNotApplicable',
        defaultMessage: 'Certificate not applicable'
    },
    contactUs: {
        id: 'Common.contactUs',
        defaultMessage: 'Contact us'
    },
    chat: {
        id: 'Common.chat',
        defaultMessage: 'Chat'
    },
    call: {
        id: 'Common.call',
        defaultMessage: 'Call'
    },
    callToCallCenter: {
        id: 'Common.callToCallCenter',
        defaultMessage: 'Call to the call center'
    },
    connection: {
        id: 'Common.connection',
        defaultMessage: 'Connection...'
    },
    clickSelectDevices: {
        id: 'Common.clickSelectDevices',
        defaultMessage: 'Click to select devices (microphone, camera, speaker)'
    },
    devices: {
        id: 'Common.devices',
        defaultMessage: 'Devices'
    },
    clickCheckConnectionQuality: {
        id: 'Common.clickCheckConnectionQuality',
        defaultMessage: 'Click to check connection quality'
    },
    testLine: {
        id: 'Common.testLine',
        defaultMessage: 'Testing the connection'
    },
    clickCallUsPhone: {
        id: 'Common.clickCallUsPhone',
        defaultMessage: 'Click to call us by phone'
    },
    video: {
        id: 'Common.video',
        defaultMessage: 'Video'
    },
    selectDevices: {
        id: 'Common.selectDevices',
        defaultMessage: 'Select devices'
    },
    microphone: {
        id: 'Common.microphone',
        defaultMessage: 'Microphone'
    },
    speaker: {
        id: 'Common.speaker',
        defaultMessage: 'Speaker'
    },
    camera: {
        id: 'Common.camera',
        defaultMessage: 'Camera'
    },
    viberHeader: {
        id: 'Common.viberHeader',
        defaultMessage: 'Open a chat or download Viber'
    },
    viberOpenChat: {
        id: 'Common.viberOpenChat',
        defaultMessage: 'Open a chat with INVITRO RU'
    },
    viberInApp: {
        id: 'Common.viberInApp',
        defaultMessage: 'in the installed application'
    },
    downloadAppViber: {
        id: 'Common.downloadAppViber',
        defaultMessage: 'Download Viber app'
    },
    unknownError: {
        id: 'Common.unknownError',
        defaultMessage: 'Please try again later. We apologize for any inconvenience caused!'
    },
    attemptsNoticeCode: {
        id: 'Common.attemptsNoticeCode',
        defaultMessage: 'Error when entering code, {attempts} attempts left.'
    },
    attemptsNoticePassword: {
        id: 'Common.attemptsNoticePassword',
        defaultMessage: 'Incorrect password, {attempts} attempts left.'
    },
    phoneRegistrationNotice: {
        id: 'Common.phoneRegistrationNotice',
        defaultMessage: 'Registration and login to the profile are only possible for subscribers from these countries.'
    },
    RussianFederation: {
        id: 'Common.RussianFederation',
        defaultMessage: 'Russian Federation'
    },
    RepublicOfArmenia: {
        id: 'Common.RepublicOfArmenia',
        defaultMessage: 'Republic of Armenia'
    },
    RepublicOfBelarus: {
        id: 'Common.RepublicOfBelarus',
        defaultMessage: 'Republic of Belarus'
    },
    RepublicOfKazakhstan: {
        id: 'Common.RepublicOfKazakhstan',
        defaultMessage: 'Republic of Kazakhstan'
    },
    KyrgyzRepublic: {
        id: 'Common.KyrgyzRepublic',
        defaultMessage: 'Kyrgyz Republic'
    },
    RUSSIA: {
        id: 'Common.RUSSIA',
        defaultMessage: 'Russia'
    },
    BELARUS: {
        id: 'Common.BELARUS',
        defaultMessage: 'Belarus'
    },
    KAZAKHSTAN: {
        id: 'Common.KAZAKHSTAN',
        defaultMessage: 'Kazakhstan'
    },
    UKRAINE: {
        id: 'Common.UKRAINE',
        defaultMessage: 'Ukraine'
    },
    ARMENIA: {
        id: 'Common.ARMENIA',
        defaultMessage: 'Armenia'
    },
    KYRGYZSTAN: {
        id: 'Common.KYRGYZSTAN',
        defaultMessage: 'Kyrgyzstan'
    },
    phoneDoesNotExist: {
        id: 'Common.phoneDoesNotExist',
        defaultMessage: 'This phone does not exist'
    },
    enterCodeFromMail: {
        id: 'Common.enterCodeFromMail',
        defaultMessage: 'Enter the code from the letter'
    },
    weSentCodePhone: {
        id: 'Common.weSentCodePhone',
        defaultMessage: 'We sent a 6-digit code to the number '
    },
    weSentCodeEmail: {
        id: 'Common.weSentCodeEmail',
        defaultMessage: 'We sent a 6-digit code by email '
    },
    requestSMSCodeAgain: {
        id: 'Common.requestSMSCodeAgain',
        defaultMessage: 'Request SMS code again'
    },
    requestCodeAgain: {
        id: 'Common.requestCodeAgain',
        defaultMessage: 'Request code again'
    },
    requestSMSCodeAgainAfterTime: {
        id: 'Common.requestSMSCodeAgainAfterTime',
        defaultMessage: 'Request SMS code again after {time}'
    },
    requestCodeAgainAfterTime: {
        id: 'Common.requestCodeAgainAfterTime',
        defaultMessage: 'Request code again after {time}'
    },
    electronicMail: {
        id: 'Common.electronicMail',
        defaultMessage: 'Electronic mail'
    },
    addLater: {
        id: 'Common.addLater',
        defaultMessage: 'Add later'
    },
    cancel: {
        id: 'Common.cancel',
        defaultMessage: 'Cancel'
    },
    INTERNAL_SERVER_ERROR: {
        id: 'Common.INTERNAL_SERVER_ERROR',
        defaultMessage: 'Something went wrong. Please try to repeat the action later. We apologize for the inconvenience!'
    },
    enterCodeSMS: {
        id: 'Common.enterCodeSMS',
        defaultMessage: 'Enter the code from SMS'
    },
    afterTime: {
        id: 'Common.afterTime',
        defaultMessage: 'after {time}'
    },
    next: {
        id: 'Common.next',
        defaultMessage: 'Continue'
    },
    register: {
        id: 'Common.register',
        defaultMessage: 'Register'
    },
    registration: {
        id: 'Common.registration',
        defaultMessage: 'Registration'
    },
    serverErrorConflictTitle: {
        id: 'Common.serverErrorConflictTitle',
        defaultMessage: 'Oops, something went wrong \uD83D\uDE1E'
    },
    serverErrorConflictDescription: {
        id: 'Common.serverErrorConflictDescription',
        defaultMessage: 'Please try again.'
    },
    buttonRepeat: {
        id: 'Common.buttonRepeat',
        defaultMessage: 'Repeat'
    }
});
