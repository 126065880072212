import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/button/Button';
import styles from './ButtonSvg.module.css';
const cx = classNames.bind(styles);

type TProps = {
    svg: string;
    left?: boolean;
    text: string;
    onClick: () => void;
    className: string;
};

const ButtonSvg: FC<TProps> = ({ text, className, left, svg, ...rest }) => {
    const positionClass = left ? styles.iconLeft : styles.iconRight;
    return (
        <Button className={cx(styles.button, positionClass, className)} {...rest}>
            <svg>
                <use href={svg} />
            </svg>
            <span>{text}</span>
        </Button>
    );
};

export default memo(ButtonSvg);
