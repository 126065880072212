import { defineMessages } from 'react-intl';

export default defineMessages({
    description: {
        id: 'AddPatientPage.description',
        defaultMessage: 'This is necessary for the full use of the functionality of the personal account.'
    },
    patientNotFound: {
        id: 'AddPatientPage.patientNotFound',
        defaultMessage: 'The patient has not been found. Please try to enter other data.'
    },
    addPatientFillEmailAndPhone: {
        id: 'AddPatientPage.addPatientFillEmailAndPhone',
        defaultMessage: "To add a patient, you must also fill in the patient's email and phone number."
    },
    successTitle: {
        id: 'AddPatientPage.successTitle',
        defaultMessage: 'A new patient has been successfully added!'
    }
});
