import React, { FC, InputHTMLAttributes, memo, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './Input.module.css';
const cx = classNames.bind(styles);

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    ref?: React.ForwardedRef<any>;
    error?: boolean | string;
    autoFocus?: boolean;
}

const Input: FC<IProps> = ({ autoFocus, error, className, disabled, style, type = 'text', ...rest }) => {
    const inputRef = useRef<any>(null);

    useEffect(() => {
        if (autoFocus) {
            inputRef?.current?.focus();
        }
    });

    return (
        <input
            ref={inputRef}
            type={type}
            disabled={disabled}
            style={style}
            className={cx(
                {
                    input: true,
                    error
                },
                className
            )}
            {...rest}
        />
    );
};

export default memo(Input);
