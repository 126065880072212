//@ts-nocheck
import Button from 'components/button/Button';
import React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styles from './AuthBlock.module.css';
import messages from 'page/orders/component/authBlock/AuthBlockMessages';

const AuthBlock = ({ vnd = false }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickAuth = (action) => {
        navigate('/login', { state: { referrer: location, action: action } });
    };

    return (
        <div>
            <div className={styles.authBlockContent}>
                <h2 className={cn({ [styles.vndTitle]: vnd })}>{intl.formatMessage(vnd ? messages.vndTitle : messages.title)}</h2>
                <Button className={styles.authBlockButton} onClick={() => handleClickAuth()}>
                    {intl.formatMessage(messages.loginButton)}
                </Button>
                <Link
                    className={styles.authBlockLink}
                    onClick={(event) => {
                        event.preventDefault();
                        handleClickAuth('registration');
                    }}
                    to={'/login'}
                >
                    {intl.formatMessage(messages.registrationButton)}
                </Link>
            </div>
        </div>
    );
};

export default AuthBlock;
