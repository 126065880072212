import { defineMessages } from 'react-intl';

export default defineMessages({
    solutionsDescription: {
        id: 'IKBCashbackPage.solutionDescription',
        defaultMessage: 'Want to get 15% cashback? Connect one of the thematic complexes that we have collected taking into account the most common needs.'
    },
    servicesDescription: {
        id: 'IKBCashbackPage.servicesDescription',
        defaultMessage:
            'For those who want to create an individual accrual program for themselves, we give the opportunity to choose up to three separate services with a 10% cashback.'
    },
    regularCardDescription: {
        id: 'IKBCashbackPage.regularCardDescription',
        defaultMessage: `We will increase cashback to {first}% for those who regularly take tests`
    },
    regularCardFooterTitle: {
        id: 'IKBCashbackPage.regularCardFooterTitle',
        defaultMessage: `Cashback is credited for all services in the orders.`
    },
    regularOrdersPerMonth: {
        id: 'IKBCashbackPage.regularOrdersPerMonth',
        defaultMessage: `{order} {order, plural, one {order} other {orders}} per month {percent}%`
    },
    notice: {
        id: 'IKBCashbackPage.notice',
        defaultMessage: 'The rest of the services will be credited with {percent}% cashback'
    },
    fetchError: {
        id: 'IKBCashbackPage.fetchError',
        defaultMessage: 'An error occurred while loading data'
    },
    back: {
        id: 'IKBCashbackPage.back',
        defaultMessage: 'Back'
    }
});
