export const cardFormConfig = {
    cvv: {
        mask: '999',
        placeholder: '000',
        type: 'password',
        autoComplete: 'cc-csc',
        name: 'cvc'
    },
    cardNumber: {
        mask: `9999 9999 9999 9999 999`,
        placeholder: '0000 0000 0000 0000',
        type: 'text',
        autoComplete: 'cc-number',
        name: 'cardnumber'
    },
    expDate: {
        mask: '99 / 99',
        placeholder: '00 / 00',
        type: 'text'
    }
};

export const INPUTS_LIST = {
    CARD_NUMBER: 'cardNumber',
    EXP_DATE: 'expDate',
    CVV: 'cvv'
};

export const INPUT_ERRORS_CODES = {
    CardNumber_Empty: 'CardNumber_Empty',
    CardNumber_Length: 'CardNumber_Length',
    CardNumber_Invalid: 'CardNumber_Invalid',
    Cvv_Empty: 'Cvv_Empty',
    Cvv_Invalid: 'Cvv_Invalid',
    ExpDateMonthYear_Empty: 'ExpDateMonthYear_Empty',
    ExpDateMonthYear_Invalid: 'ExpDateMonthYear_Invalid',
    ExpDateMonth_Empty: 'ExpDateMonth_Empty',
    ExpDateMonth_Invalid: 'ExpDateMonth_Invalid',
    ExpDateYear_Empty: 'ExpDateYear_Empty',
    ExpDateYear_Invalid: 'ExpDateYear_Invalid',
    Name_Empty: 'Name_Empty',
    Name_Invalid: 'Name_Invalid',
    Name_TooLong: 'Name_TooLong',
    Name_TooShort: 'Name_TooShort'
};

export const NUMBER = '+7 (800) 200-363-0';
