import React, { FC, useEffect, Fragment, memo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import {
    setCashbackType,
    searchCashbackServices,
    setNumberOfServices,
    clearSelectedCashbackServices,
    selectCashbackService,
    fetchCategories
} from 'redux/loyaltyIKB/actions';
import {
    maxCountSelectedServicesSelector,
    servicesSelector,
    fetchCategoriesSelector,
    categoriesSelector,
    loyaltyIKBSelector
} from 'redux/loyaltyIKB/selectors';
import Button from 'components/button/Button';
import Switch from 'components/switch/Switch';
import SearchComponent from 'page/orders/component/search/SearchComponent';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import clearSearchIcon from './assets/clearSearchIcon.svg';
import noResultsIcon from './assets/noResultsIcon.svg';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import commonMessages from 'messages/CommonMessages';
import messages from './IKBServicesPageMessages';
import styles from './IKBServicesPage.module.css';
import { IKBNumberOfServices } from 'constants/ikbNumberOfServices';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import { currentTerritorySelector } from 'redux/cities/selectors';
import Loader from 'components/loader/Loader';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { IKBService } from 'types/common';

const IKBServicesPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const { searchString, selectedCashbackServices, searchResults, numberOfServices } = useAppSelector((state) => loyaltyIKBSelector(state));
    const services = useAppSelector((state) => servicesSelector(state));
    const maxCountSelectedServices = useAppSelector((state) => maxCountSelectedServicesSelector(state));
    const categories = useAppSelector((state) => categoriesSelector(state));
    const territory = useAppSelector((state) => currentTerritorySelector(state));
    const fetchedCategories = useAppSelector((state) => fetchCategoriesSelector(state));

    useEffect(() => {
        dispatch(setCashbackType(ikbCashbackType.INDIVIDUAL_SERVICES));
        dispatch(searchCashbackServices({ services, param: '' }));
        if (categories.length === 0) {
            dispatch(
                fetchCategories({
                    patientId: searchParams.get('patientId') as string,
                    territory,
                    positionFrom: 0,
                    positionTo: 99999
                })
            );
        } else if (services && services.length <= 0) {
            navigate('/loyalty');
        }
    }, []);

    const handleShowMoreServices = () => {
        dispatch(setNumberOfServices(numberOfServices + IKBNumberOfServices));
    };

    const handleSelectCashbackService = (service: IKBService, isSelectedService: boolean) => {
        if (!isSelectedService && selectedCashbackServices.length >= maxCountSelectedServices) {
            return;
        }
        return dispatch(
            selectCashbackService({
                service: service,
                selected: isSelectedService
            })
        );
    };

    const renderContent = () => {
        const outputServices = searchString.length > 0 ? searchResults.slice(0, numberOfServices) : services.slice(0, numberOfServices);
        const patientId = searchParams.get('patientId') as string;

        if (fetchedCategories && fetchedCategories.loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        }

        return (
            <Fragment>
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <div className='navigationBarInner'>
                        <IKBCashbackTypesButtons />
                    </div>
                </IKBNavigationBar>
                <div
                    className={classNames({
                        [styles.container]: true,
                        [styles.noResultsWrap]: searchString.length > 0 && searchResults.length < 1
                    })}
                >
                    <IKBNotification />
                    <div className='navigationBarInner'>
                        <SearchComponent
                            // @ts-ignore
                            clearReset={true}
                            name='searchString'
                            className={styles.searchString}
                            value={searchString}
                            placeholder={formatMessage(messages.searchHint)}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setNumberOfServices(IKBNumberOfServices));
                                dispatch(searchCashbackServices({ services, param: event.target.value }));
                            }}
                            clearIcon={
                                <img
                                    onClick={() => {
                                        dispatch(setNumberOfServices(IKBNumberOfServices));
                                        dispatch(searchCashbackServices({ services, param: '' }));
                                    }}
                                    src={clearSearchIcon}
                                    alt=''
                                    className={styles.clearSearchIcon}
                                />
                            }
                        />
                    </div>
                    {searchString.length > 0 && searchResults.length < 1 && (
                        <div className={styles.noResults}>
                            <img alt='' src={noResultsIcon} className={styles.noResultsIcon} />
                            <h1 className={styles.noResultsTitle}>{formatMessage(messages.noResultsTitle)}</h1>
                            <p className={styles.noResultsInfo}>{formatMessage(messages.noResultsInfo)}</p>
                        </div>
                    )}
                    <div className={styles.cards}>
                        {outputServices.length > 0 &&
                            outputServices.map((service) => {
                                const isSelectedService =
                                    selectedCashbackServices.filter((selectedService) => service.productId === selectedService.productId).length > 0;

                                return (
                                    <div
                                        className={classNames({
                                            [styles.card]: true,
                                            [styles.selectedService]: isSelectedService,
                                            [styles.disabledService]: !isSelectedService && selectedCashbackServices.length >= maxCountSelectedServices
                                        })}
                                        key={service.productId}
                                    >
                                        <div className={styles.infoBlock}>
                                            <div className={styles.info}>
                                                <div className={styles.topBlock}>
                                                    <h3 className={styles.title} onClick={() => handleSelectCashbackService(service, isSelectedService)}>
                                                        {formatMessage(commonMessages.numberFormat, {
                                                            number: service.article
                                                        })}
                                                    </h3>
                                                    <div className={styles.discountSwitchBlock}>
                                                        <div className={styles.discount}>{service.charge}%</div>
                                                        <div className={styles.serviceSwitch}>
                                                            <Switch
                                                                active={isSelectedService}
                                                                onClick={() => handleSelectCashbackService(service, isSelectedService)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link className={styles.description} to={`/loyalty/ikb/cashback/services/product/${service.productId}`}>
                                                    {service.name}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    {(searchString?.length > 0 ? searchResults.length : services.length) > numberOfServices && (
                        <Button onClick={() => handleShowMoreServices()} className={styles.showMore}>
                            {formatMessage(messages.showMore)}
                        </Button>
                    )}
                </div>
                <CategoriesPanel
                    categories={selectedCashbackServices}
                    maxCountSelected={maxCountSelectedServices}
                    onClear={() => dispatch(clearSelectedCashbackServices())}
                    onCategoryClear={(service: IKBService) =>
                        dispatch(
                            selectCashbackService({
                                service: service,
                                selected: true
                            })
                        )
                    }
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/services/confirmation?patientId=${patientId}`)}
                    cashbackType={ikbCashbackType.INDIVIDUAL_SERVICES}
                />
            </Fragment>
        );
    };

    return renderContent();
};

export default memo(IKBServicesPage);
