import { createRequestTypes } from 'redux/helpers';

export const SEARCH_RESULT_UNAUTH = createRequestTypes('UNAUTH_RESULT/SEARCH');
export const SEND_EMAIL_RESULT_UNAUTH = createRequestTypes('UNAUTH_RESULT/SEND_EMAIL');
export const PARSE_KEY_UNAUTH = createRequestTypes('UNAUTH_RESULT/PARSE_KEY');

export const ADD_RESULT = createRequestTypes('RESULT/ADD');
export const REQUEST_TRANSLATION = createRequestTypes('RESULT/TRANSLATE');
export const SEND_EMAIL_RESULT = createRequestTypes('RESULT/SEND_EMAIL');

export const CLEAR_SEARCH_RESULT_UNAUTH = createRequestTypes('UNAUTH_RESULT/CLEAR_SEARCH');
export const CLEAR_SEND_EMAIL_RESULT_UNAUTH = createRequestTypes('UNAUTH_RESULT/CLEAR_SEND_EMAIL');

export const CLEAR_ADD_RESULT = createRequestTypes('RESULT/CLEAR_ADD');
export const CLEAR_REQUEST_TRANSLATION = createRequestTypes('RESULT/CLEAR_TRANSLATE');
export const CLEAR = createRequestTypes('RESULT/CLEAR');

export const SEARCH_RESULT_BY_KEY = createRequestTypes('UNAUTH_RESULT/SEARCH_RESULT_BY_KEY');

export const addResult = (payload: {
    lastName: string;
    orderNumber: string;
    birthday: string;
    firstName?: string;
    email?: string;
    phone?: string;
    cityId?: string;
}) => ADD_RESULT.base(payload);
export const clearAddResult = () => CLEAR_ADD_RESULT.base();
export const parseKey = (payload: { key: string }) => PARSE_KEY_UNAUTH.base(payload);
export const searchResultByKey = (payload: { lastName?: string | null; key: string }) => SEARCH_RESULT_BY_KEY.base(payload);
export const searchResultUnauth = (payload: { inz: string; birthDate: string | undefined; lastName: string }) => SEARCH_RESULT_UNAUTH.base(payload);
export const sendEmailResultUnauth = (payload: { key: string; lang: string; email: string; territory: string }) => SEND_EMAIL_RESULT_UNAUTH.base(payload);
export const clearSendEmail = () => CLEAR_SEND_EMAIL_RESULT_UNAUTH.base();
export const clearSearchResultUnauth = () => CLEAR_SEARCH_RESULT_UNAUTH.base();
