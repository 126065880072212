import React, { FC, Fragment, memo } from 'react';
import { useIntl } from 'react-intl';
import { age, formatDate, parseISO } from 'utils/timeUtils';
import messages from './BirthdayMessages';

type TProps = {
    birthday: Date;
    locale: string;
};

const Birthday: FC<TProps> = ({ birthday, locale }) => {
    const { formatMessage } = useIntl();

    const renderAge = (birthday: Date) => {
        return ` (${formatMessage(messages.years, { years: age(birthday) })})`;
    };

    return (
        <Fragment>
            <span>{formatDate(parseISO(birthday), locale)}</span>
            {renderAge(parseISO(birthday))}
        </Fragment>
    );
};

export default memo(Birthday);
