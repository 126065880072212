import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cashbackStatusSelector, dataLoyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import NextChangeDays from 'page/loyalty/ikb/components/NextChangeDays';
import { getDaysToChanges } from 'utils/cashbackUtils';
import { setCashbackStatus, clearCashbackStatus } from 'redux/loyaltyIKB/actions';
import { fetchLoyaltyStatus } from 'redux/loyaltyStatus/actions';
import { select as selectPatients } from 'redux/patient/actions';
import commonMessages from 'messages/CommonMessages';
import styles from './IKBSolutionDetails.module.css';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';

const IKBSolutionDetailsPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const bonusStatus = useAppSelector((state) => cashbackStatusSelector(state));
    const { loyaltyStatus, loadingLoyaltyStatus } = useAppSelector((state) => dataLoyaltyIKBSelector(state));

    useEffect(() => {
        const patientId = searchParams.get('patientId');
        if (bonusStatus && bonusStatus.category && bonusStatus.category.maxFavoriteProducts > 0) {
            navigate('/loyalty');
        } else if (isEmpty(bonusStatus) && patientId) {
            dispatch(selectPatients(patientId as string));
            dispatch(fetchLoyaltyStatus({ patientId: patientId, territory }));
        }
        return () => {
            dispatch(clearCashbackStatus());
        };
    }, []);

    useEffect(() => {
        if (isEmpty(bonusStatus) && !loadingLoyaltyStatus) {
            dispatch(setCashbackStatus(loyaltyStatus?.bonusProgramStatus?.bonusStatus));
        }
    }, [loadingLoyaltyStatus]);

    const renderContent = () => {
        if (bonusStatus) {
            const { category } = bonusStatus;

            return (
                <div>
                    <IKBNavigationBar title={formatMessage(commonMessages.readySolutions)} back={true} />
                    <div className={styles.container}>
                        {category && (
                            <div className={styles.card}>
                                <h5 className={styles.title}>{category.name}</h5>
                                {category.categoryProducts.length > 0 && (
                                    <div className={styles.products}>
                                        {category.categoryProducts.map((product: any) => (
                                            <div className={styles.productItem} key={product.productId}>
                                                <div className={styles.productTitle}>{product.shortName}</div>
                                                <div className={styles.productDiscount}>{product.charge}%</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {category.charge && (
                                    <div className={styles.noticeCashback}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: formatMessage({ id: 'Common.otherProductsNotice' })
                                            }}
                                        />
                                        <span>{category.charge}%</span>
                                    </div>
                                )}
                                {bonusStatus.newChangeDate && (
                                    <div className={styles.noticeSolution}>
                                        <NextChangeDays days={getDaysToChanges(bonusStatus.newChangeDate)} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return renderContent();
};

export default memo(IKBSolutionDetailsPage);
