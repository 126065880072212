import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'AuthBlock.title',
        defaultMessage: 'Authorization'
    },
    loginButton: {
        id: 'AuthBlock.loginButton',
        defaultMessage: 'Login'
    },
    registrationButton: {
        id: 'AuthBlock.registrationButton',
        defaultMessage: 'Registration'
    },
    vndTitle: {
        id: 'AuthBlock.vndTitle',
        defaultMessage: 'Login or register to place an order'
    }
});
