//@ts-nocheck
import React from 'react';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';

import styles from './HeaderBottomMenu.module.css';

interface IHeaderBottomMenuLinkProps {
    to: string;
    title: string;
    tooltip?: Element;
}

const HeaderBottomMenuLink = ({ to, title, tooltip }: IHeaderBottomMenuLinkProps) => {
    return (
        <li className={classNames(styles.navItem, styles.navScrollerItem)}>
            <NavLink to={to} className={({ isActive }) => (isActive ? styles.navLinkActive : styles.navLink)}>
                {title}
            </NavLink>
            {tooltip}
        </li>
    );
};
export default HeaderBottomMenuLink;
