//@ts-nocheck
import { CLEAR_ORDERS_COUNT, FETCH } from 'redux/ordersCount/actions';
import { all, put, takeEvery } from 'redux-saga/effects';

import * as orderClient from 'client/OrderClient';
import { request } from 'redux/helpers';

function* fetch(action) {
    const { patientId } = action.meta;
    yield request(FETCH, [orderClient.fetchOrdersCount, patientId], patientId);
}

function* clear() {
    yield put(CLEAR_ORDERS_COUNT.success());
}

export default function* orderStatisticSagas() {
    yield all([takeEvery(FETCH.BASE, fetch), takeEvery(CLEAR_ORDERS_COUNT.BASE, clear)]);
}
