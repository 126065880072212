import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'DeliveryBlock.title',
        defaultMessage: 'Contacts to send test results'
    },
    hint: {
        id: 'DeliveryBlock.hint',
        defaultMessage: 'Specify at least one of the contacts to get results'
    },
    codeWord: {
        id: 'DeliveryBlock.codeWord',
        defaultMessage: 'Code word'
    }
});
