import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'Payment.title',
        defaultMessage: 'Payment methods'
    },
    online: {
        id: 'Payment.online',
        defaultMessage: 'Cart on the site'
    },
    onlineDescription: {
        id: 'Payment.onlineDescription',
        defaultMessage: 'МИР, MasterCard, Visa'
    },
    office: {
        id: 'Payment.office',
        defaultMessage: 'In medical office'
    },
    officeDescription: {
        id: 'Payment.officeDescription',
        defaultMessage: 'Office {officeName}'
    },
    sberPay: {
        id: 'Payment.sberPay',
        defaultMessage: 'SberBank'
    },
    sberPayDescription: {
        id: 'Payment.sberPayDescription',
        defaultMessage: 'SberBank Application'
    },
    alfaPay: {
        id: 'Payment.alfaPay',
        defaultMessage: 'Подели'
    },
    alfaPayDescription: {
        id: 'Payment.alfaPayDescription',
        defaultMessage: 'In parts without overpayments'
    }
});
