import { defineMessages } from 'react-intl';

export default defineMessages({
    bonusHistory: {
        id: 'IKBBonusHistoryPage.bonusHistory',
        defaultMessage: 'Bonus account history'
    },
    bonusMovement: {
        id: 'IKBBonusHistoryPage.bonusMovement',
        defaultMessage: 'Accruals and withdrawals'
    },
    changesProgram: {
        id: 'IKBBonusHistoryPage.changesProgram',
        defaultMessage: 'Program changes'
    },
    periodLabel: {
        id: 'IKBBonusHistoryPage.periodLabel',
        defaultMessage: 'View by period'
    },
    eventType: {
        id: 'IKBBonusHistoryPage.eventType',
        defaultMessage: 'Event type'
    },
    date: {
        id: 'IKBBonusHistoryPage.date',
        defaultMessage: 'Date'
    },
    event: {
        id: 'IKBBonusHistoryPage.event',
        defaultMessage: 'Event'
    },
    accrued: {
        id: 'IKBBonusHistoryPage.accrued',
        defaultMessage: 'Points accrued'
    },
    writeOff: {
        id: 'IKBBonusHistoryPage.writeOff',
        defaultMessage: 'Points written off'
    },
    paid: {
        id: 'IKBBonusHistoryPage.paid',
        defaultMessage: 'Paid'
    },
    fetchError: {
        id: 'IKBBonusHistoryPage.fetchError',
        defaultMessage: 'Unable to get bonus history'
    },
    noHistory: {
        id: 'IKBBonusHistoryPage.noHistory',
        defaultMessage: 'There is no history for the selected period'
    }
});
