import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import RU from './flags/russia.svg';
import AM from './flags/armenia.svg';
import BY from './flags/belarus.svg';
import KZ from './flags/kazakhstan.svg';
import KG from './flags/kyrgyzstan.svg';
import InputMask from 'react-input-mask';
import { InputPhoneData } from 'types/common';
import commonMessages from 'messages/CommonMessages';
import CommonMessages from 'messages/CommonMessages';
import examples from 'libphonenumber-js/examples.mobile.json';
import styles from 'containers/input/phone/InputPhone.module.css';
import SelectPicker from 'components/selectpicker/SelectPicker';
import { AsYouType, CountryCode, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

type TProps = {
    name: string;
    value: any;
    onBlur?: () => void;
    onChange: (data: InputPhoneData) => void;
};

const InputPhone: FC<TProps> = ({ name, value, onChange, onBlur }) => {
    const { formatMessage } = useIntl();
    const inputRef = useRef<HTMLInputElement>();
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>('RU');

    useEffect(() => {
        if (inputRef.current?.value) {
            handleOnChange(inputRef.current?.value);
        }
    }, [selectedCountry]);

    const renderFlag = (countryCode: string) => {
        return <img src={flags[countryCode]} alt={''} className={styles.flag} />;
    };

    const renderCode = (value: string) => {
        if (value) {
            return <div className={styles.code}>{countryCodes[value]}</div>;
        }
    };

    const countries: Record<string, { label: string; value: string }> = {
        RU: { label: formatMessage(CommonMessages.RussianFederation), value: 'RU' },
        AM: { label: formatMessage(CommonMessages.RepublicOfArmenia), value: 'AM' },
        BY: { label: formatMessage(CommonMessages.RepublicOfBelarus), value: 'BY' },
        KZ: { label: formatMessage(CommonMessages.RepublicOfKazakhstan), value: 'KZ' },
        KG: { label: formatMessage(CommonMessages.KyrgyzRepublic), value: 'KG' }
    };

    const flags: Record<string, string> = {
        RU,
        AM,
        BY,
        KZ,
        KG
    };

    const masks: Record<string, string> = {
        RU: '(000) 000-00-00',
        AM: '(00) 000000',
        BY: '(00) 000 00 00',
        KZ: '(000) 000 00 00',
        KG: '(000) 000 000'
    };

    const countryCodes: Record<string, string> = {
        RU: '+7',
        AM: '+374',
        BY: '+375',
        KZ: '+7',
        KG: '+996'
    };

    const getMask = (countryCode: CountryCode) => {
        if (masks[selectedCountry]) {
            return {
                mask: masks[selectedCountry].replaceAll('0', '9'),
                placeholder: masks[selectedCountry]
            };
        } else {
            const asYouType = new AsYouType(countryCode);
            const phoneNumber = getExampleNumber(countryCode, examples);
            asYouType.input(phoneNumber?.formatInternational().replace(`+${phoneNumber.countryCallingCode}`, '').trim() || '');

            return {
                mask: asYouType.getTemplate().replaceAll('x', '9'),
                placeholder: asYouType.getTemplate().replaceAll('x', '0')
            };
        }
    };

    const handleFocus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleOnChange = (value: string) => {
        const code = countryCodes[selectedCountry];
        const phoneNumber = parsePhoneNumberFromString(value || '', selectedCountry);
        onChange({ value, code, number: phoneNumber?.format('E.164'), isValid: phoneNumber?.isValid() || false, notValid: false });
    };

    return (
        <div className={styles.container}>
            <SelectPicker
                label={formatMessage(commonMessages.phone)}
                value={{
                    value: '',
                    label: (
                        <div className={styles.option}>
                            {renderFlag(selectedCountry)}
                            {renderCode(selectedCountry)}
                        </div>
                    )
                }}
                options={[countries.RU, countries.AM, countries.BY, countries.KZ, countries.KG].map(({ label, value }: { label: string; value: string }) => ({
                    value: value,
                    label: (
                        <div className={styles.option}>
                            {renderFlag(value)}
                            {renderCode(value)}
                            <div className={styles.label}>{label}</div>
                        </div>
                    )
                }))}
                notice={commonMessages.phoneRegistrationNotice}
                name={name}
                isClearable={false}
                isSearchable={false}
                onChange={({ value }) => {
                    handleFocus();
                    setSelectedCountry(value);
                }}
            />
            <InputMask
                maskChar={''}
                value={value}
                onBlur={onBlur}
                alwaysShowMask={false}
                className={styles.input}
                mask={getMask(selectedCountry).mask}
                onChange={({ target }) => {
                    handleOnChange(target.value);
                }}
            >
                {
                    // @ts-ignore
                    (inputProps) => {
                        return <input ref={inputRef} type={'tel'} autoComplete={'tel'} placeholder={getMask(selectedCountry).placeholder} {...inputProps} />;
                    }
                }
            </InputMask>
        </div>
    );
};

export default memo(InputPhone);
