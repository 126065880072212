import React, { FC, memo } from 'react';
import { citiesSelector, currentCitySelector } from 'redux/cities/selectors';
import styles from './Cities.module.css';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';
import messages from './CitiesMessages';
import mapBulletIcon from './assets/mapBullet.svg';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { showChangeCityForm, showCityPanel } from 'redux/cities/actions';
const cx = classNames.bind(styles);

const Cities: FC = () => {
    const { formatMessage } = useIntl();
    const { isShowCityPanel } = useAppSelector((state) => citiesSelector(state));
    const activeCity = useAppSelector((state) => currentCitySelector(state));
    const dispatch = useAppDispatch();

    return (
        <div
            className={cx({
                city: 'city',
                open: isShowCityPanel
            })}
        >
            <div>
                <div className={styles.cityLabelBlock} onClick={() => dispatch(showCityPanel(!isShowCityPanel))}>
                    <span className={styles.cityLabelIcon}>
                        <img src={mapBulletIcon} alt='' />
                    </span>
                    <span className={styles.cityLabelText}>{formatMessage(messages.location)}</span>
                    <span
                        className={cx({
                            cityName: true,
                            open: isShowCityPanel
                        })}
                    >
                        {activeCity.name}
                    </span>
                    <span
                        className={cx({
                            cityLabelTextArrow: true,
                            open: isShowCityPanel
                        })}
                    />
                </div>
                {isShowCityPanel && (
                    <div className={styles.cityChange}>
                        <p>
                            <span>{formatMessage(messages.hintLocation)}</span>
                            <span className={styles.cityName}>{activeCity.name}</span>
                        </p>
                        <div>
                            <button
                                className={cx({
                                    cityConfirmBtn: true,
                                    btn: true
                                })}
                                onClick={() => dispatch(showCityPanel(false))}
                            >
                                {formatMessage(messages.confirmLocation)}
                            </button>
                            <button
                                className={cx({
                                    cityChangeBtn: true,
                                    btn: true
                                })}
                                onClick={() => dispatch(showChangeCityForm(true))}
                            >
                                {formatMessage(messages.chooseAnother)}
                            </button>
                        </div>
                        <div className={styles.citySmall}>{formatMessage(messages.notice)}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(Cities);
