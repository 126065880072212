//@ts-nocheck
import { basicReducer } from 'redux/helpers';
import { FETCH_ALL, UPDATE_QUANTITY, CLEAR } from 'redux/products/actions';

const initialState = {
    entities: {},
    fetchAll: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED:
        case FETCH_ALL.SUCCEEDED:
        case FETCH_ALL.ERRORED: {
            let entities = state.entities;
            if (action.type === FETCH_ALL.SUCCEEDED) {
                const fetchedProducts = action.payload.data;
                entities = fetchedProducts.reduce((map, product) => {
                    map[product.id] = product;
                    return map;
                }, {});
            }
            return {
                ...state,
                entities,
                fetchAll: basicReducer(state.fetchAll, action)
            };
        }

        case UPDATE_QUANTITY.BASE: {
            const { id, quantity } = action.meta;

            const entities = { ...(state.entities || {}) };
            entities[id] = { ...entities[id], quantity };

            return {
                ...state,
                entities
            };
        }

        case CLEAR.BASE: {
            return initialState;
        }
        default:
            return state;
    }
}
