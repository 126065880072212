import { defineMessages } from 'react-intl';

export default defineMessages({
    confirmSelection: {
        id: 'IKBServicesConfirmationPage.confirmSelection',
        defaultMessage: 'Confirm selection'
    },
    notice: {
        id: 'IKBServicesConfirmationPage.notice',
        defaultMessage: 'The following changes will be possible <span></span> after the date the button "Confirm selection" was pressed.'
    },
    amountDays: {
        id: 'IKBServicesConfirmationPage.amountDays',
        defaultMessage: '{amount} days'
    },
    requestError: {
        id: 'IKBServicesConfirmationPage.requestError',
        defaultMessage: 'Change favorite products error'
    }
});
