/**
 * Коды сообщений об ошибках от ПЛ
 */
export const IKBErrorCode = {
    /**
     * Контакт состоит в бонусной программе
     */
    CONTACT_HAS_BONUS_PROGRAM: 'CONTACT_HAS_BONUS_PROGRAM',

    /**
     * Контакт состоит в дисконтной программе
     */
    CONTACT_HAS_DISCOUNT_PROGRAM: 'CONTACT_HAS_DISCOUNT_PROGRAM',

    /**
     * Токен не обнаружен
     */
    UNKNOWN_TOKEN: 'UNKNOWN_TOKEN',

    /**
     * Контакт не обнаружен
     */
    UNKNOWN_CONTACT: 'UNKNOWN_CONTACT',

    /**
     * Получен пустой чек
     */
    CHECK_HAS_NO_ITEMS: 'CHECK_HAS_NO_ITEMS',

    /**
     * Ошибка при обращении в Manzana Loyalty
     */
    MANZANA_LOYALTY_ERROR: 'MANZANA_LOYALTY_ERROR',

    /**
     * Некорректно сформирован запрос
     */
    MALFORMED_REQUEST: 'MALFORMED_REQUEST',

    /**
     * Контакт не имеет активной бонусной карты
     */
    CONTACT_HAS_NO_ACTIVE_BONUS_CARD: 'CONTACT_HAS_NO_ACTIVE_BONUS_CARD',

    /**
     * Недопустимое действие с программой лояльности
     */
    LOYALTY_PROGRAM_WRONG_ACTION: 'LOYALTY_PROGRAM_WRONG_ACTION',

    /**
     * Неизвестная ошибка
     */
    UNSPECIFIED_ERROR: 'UNSPECIFIED_ERROR',

    /**
     * Неверно указан источник запроса
     */
    SOURCE_INVALID: 'SOURCE_INVALID',

    /**
     * Невыполнимый запрос на действие над программой лояльности
     */
    INCORRECT_LOYALTY_OPERATION_REQUEST: 'INCORRECT_LOYALTY_OPERATION_REQUEST',

    /**
     * Невыполнимый запрос на действие над заказом
     */
    INCORRECT_ORDER_OPERATION_REQUEST: 'INCORRECT_ORDER_OPERATION_REQUEST',

    /**
     * Нарушение внутренней логики проведения транзакций
     */
    INCORRECT_TRANSACTION_OPERATION: 'INCORRECT_TRANSACTION_OPERATION',

    /**
     * Превышено максимально доступное количество бонусов к списанию
     */
    EXCEEDED_MAXIMUM_BONUS_WRITE_OFF: 'EXCEEDED_MAXIMUM_BONUS_WRITE_OFF',

    /**
     * Бонусный счет не обнаружен
     */
    ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',

    /**
     * Бонусный счет заблокирован
     */
    ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',

    /**
     * Бонусный счет деактивирован
     */
    ACCOUNT_DEACTIVATED: 'ACCOUNT_DEACTIVATED',

    /**
     * Контакт деактивирован
     */
    CONTACT_DEACTIVATED: 'CONTACT_DEACTIVATED',

    /**
     * Соответствие не обнаружено
     */
    CONTACT_NO_MATCH_ACCOUNT: 'CONTACT_NO_MATCH_ACCOUNT',

    /**
     * Контакт обнаружен, но бонусный счет не может быть создан
     */
    CREATE_ACCOUNT_NOT_ALLOWED: 'CREATE_ACCOUNT_NOT_ALLOWED',

    /**
     * Статус бонусного счета "Активный"
     */
    ACCOUNT_STATUS_ACTIVE: 'ACCOUNT_STATUS_ACTIVE',

    /**
     * Активация была выполнена ранее
     */
    ACCOUNT_ALREADY_ACTIVATED: 'ACCOUNT_ALREADY_ACTIVATED',

    /**
     * Номер телефона не соответствует контакту
     */
    CONTACT_WRONG_PHONE: 'CONTACT_WRONG_PHONE',

    /**
     * Код подтверждения уже был выслан ранее, новая отправка кода станет доступна с "{time}"
     */
    PIN_SEND_TIMEOUT_NOT_EXPIRED: 'PIN_SEND_TIMEOUT_NOT_EXPIRED',

    /**
     * Превышено время ожидания кода подтверждения
     */
    PIN_AWAITING_TIME_EXPIRED: 'PIN_AWAITING_TIME_EXPIRED',

    /**
     * Не верный код активации
     */
    WRONG_PIN: 'WRONG_PIN',

    /**
     * Для данного счета код подтверждения не отправлялся
     */
    PIN_NOT_SENT: 'PIN_NOT_SENT',

    /**
     * Не удалось найти свойства для события
     */
    EVENT_PROPERTIES_NOT_FOUND: 'EVENT_PROPERTIES_NOT_FOUND'
};
