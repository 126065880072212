//@ts-nocheck
import { all, takeLatest, put } from 'redux-saga/effects';
import { request } from 'redux/helpers';
import * as conclusionsClient from 'client/ConclusionsClient';
import { getCart } from 'redux/cart/sagas';
import cookieService from 'services/CookieService';
import { INVITRO_CART_COOKIE_KEY } from 'redux/cart/constants';
import { ADD_PRODUCT, FETCH } from 'redux/cart/actions';

import { FETCH_CONCLUSIONS, FETCH_NEXT_CONCLUSIONS_BATCH, ADD_RECOMMENDED_PRODUCT, SEND_DOCUMENT, CANCEL_APPOINTMENT } from 'redux/conclusions/actions';

function* fetchConclusions(action) {
    yield request(FETCH_CONCLUSIONS, [conclusionsClient.fetchConclusions, action.meta], action.meta);
}

function* fetchNextConclusions(action) {
    yield request(FETCH_NEXT_CONCLUSIONS_BATCH, [conclusionsClient.fetchConclusions, action.meta], action.meta);
}

function* addRecommendedProduct(action) {
    const article = action.meta;
    const cart = yield getCart();

    if (!cart.hasOwnProperty('products')) {
        cart.products = [];
    }

    const articleInCookie = cart.products.map((el) => el.article);
    if (!articleInCookie.includes(article)) {
        cart.products.push({
            article
        });
    }

    yield cookieService.set(INVITRO_CART_COOKIE_KEY, JSON.stringify(cart));
    yield put(ADD_PRODUCT.success({}, { article: article }));
    yield put(FETCH.success({ data: { products: cart.products } }));
}

function* sendDocument(action) {
    yield request(SEND_DOCUMENT, [conclusionsClient.sendDocument, action.meta]);
}

function* cancelAppointment(action) {
    yield request(CANCEL_APPOINTMENT, [conclusionsClient.cancelAppointment, action.meta], action.meta);
}

export default function* conclusionsSagas() {
    yield all([
        takeLatest(FETCH_CONCLUSIONS.BASE, fetchConclusions),
        takeLatest(FETCH_NEXT_CONCLUSIONS_BATCH.BASE, fetchNextConclusions),
        takeLatest(ADD_RECOMMENDED_PRODUCT.BASE, addRecommendedProduct),
        takeLatest(SEND_DOCUMENT.BASE, sendDocument),
        takeLatest(CANCEL_APPOINTMENT.BASE, cancelAppointment)
    ]);
}
