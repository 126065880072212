import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'LastNameModal.title',
        defaultMessage: 'Order'
    },
    description: {
        id: 'LastNameModal.description',
        defaultMessage: 'Please, enter patient last name to view the result'
    }
});
