import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './IKBСashbackTypesButtons.module.css';
import commonMessages from 'messages/CommonMessages';
import ButtonSecondary from 'components/button/secondary/ButtonSecondary';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import { categoriesSelector, loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'app/Hooks';

type TProps = {
    className?: string;
};

const IKBCashbackTypesButtons: FC<TProps> = ({ className }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const patientId = searchParams.get('patientId');
    const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
    const categories = useAppSelector((state) => categoriesSelector(state));
    const { activeCashbackType } = useAppSelector((state) => loyaltyIKBSelector(state));
    const regularCategory = ikbLoyaltyService.getRegularCategory(categories);

    return (
        <div className={classNames(styles.cashbackTypesButtons, className)}>
            <ButtonSecondary
                variant={activeCashbackType === ikbCashbackType.READY_SOLUTIONS ? 'active' : ''}
                text={formatMessage(commonMessages.readySolutions)}
                onClick={() => navigate(`/loyalty/ikb/cashback/solutions?patientId=${patientId}`, { replace: true })}
            />
            <ButtonSecondary
                variant={activeCashbackType === ikbCashbackType.INDIVIDUAL_SERVICES ? 'active' : ''}
                text={formatMessage(commonMessages.individualServices)}
                onClick={() => navigate(`/loyalty/ikb/cashback/services?patientId=${patientId}`, { replace: true })}
            />
            {regularCategory && (
                <ButtonSecondary
                    variant={activeCashbackType === ikbCashbackType.REGULAR_CASHBACK ? 'active' : ''}
                    text={formatMessage(commonMessages.regularCashback)}
                    onClick={() => navigate(`/loyalty/ikb/cashback/regular?patientId=${patientId}`, { replace: true })}
                />
            )}
        </div>
    );
};

export default memo(IKBCashbackTypesButtons);
