//@ts-nocheck
import { FETCH_ALL, CLEAR } from 'redux/watcher/actions';

const initialState = {
    entities: {},
    loading: false,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL.STARTED:
            return {
                ...initialState,
                loading: true
            };
        case FETCH_ALL.SUCCEEDED:
            const watchers = action.payload.data;
            const entities = {};
            watchers.forEach((watcher) => (entities[watcher.id] = watcher));
            return {
                ...state,
                loading: false,
                entities
            };
        case FETCH_ALL.ERRORED: {
            return {
                ...state,
                loading: false,
                error: action.payload?.response?.data || true
            };
        }
        case CLEAR.BASE:
            return initialState;
        default:
            return state;
    }
}
