import { createSelector } from 'reselect';
import { isService } from 'utils/classifierTypeUtils';
import { FlocktoryActions } from 'constants/flocktoryActions';
import { calculateProductsCost, calculateProfilePrice, cartOrderTypeSelector, checkupsDataSelector, productsSelector } from 'redux/cart/selectors';

export const flocktoryDataSelector = createSelector(
    [cartOrderTypeSelector, productsSelector, checkupsDataSelector],
    (cartOrderType, products, checkupsData) => {
        const itemsPostCheckout = (cartOrderType.checkups ? products?.checkups.valid : products?.analyzes.valid || [])?.filter(
            (item: any) => !isService(item) && !item?.profileId
        );

        const allItemsUpdateCart = [...(products?.analyzes?.valid || []), ...(products?.checkups?.valid || [])];
        const filteredItemsUpdateCart = allItemsUpdateCart?.filter((item: any) => !isService(item) && !item?.profileId && !item?.deleted);

        return {
            [FlocktoryActions.POST_CHECKOUT]: {
                user: {
                    email: getUserEmail()
                },
                order: {
                    price: calculateProductsCost(products?.valid) || checkupsData.cost,
                    items:
                        itemsPostCheckout?.map((item: any) => {
                            const { article, shortName, price = 0 } = item?.product || item;
                            return { id: article, title: shortName, price: item.cost || price, count: item.quantity || 1 };
                        }) || []
                },
                spot: 'InvitroDesktop'
            },
            [FlocktoryActions.UPDATE_CART]: {
                cart: {
                    items: filteredItemsUpdateCart?.map((item: any) => ({
                        id: item.article,
                        price: item.price || calculateProfilePrice(item.id, allItemsUpdateCart) || '',
                        count: item.count || 1,
                        brand: 'Invitro',
                        categoryId: ''
                    }))
                }
            }
        };
    }
);

const getUserEmail = () => {
    return document.querySelector(`div[data-fl-user-email]`)?.getAttribute('data-fl-user-email');
};
