import React, { FC, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { changeCategory as change, clearChangedCategory } from 'redux/loyaltyIKB/actions';
import { cashbackStatusSelector, categoriesDataSelector, loyaltyIKBSelector, mainCategorySelector } from 'redux/loyaltyIKB/selectors';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { currentTerritorySelector } from 'redux/cities/selectors';
import Loader from 'components/loader/Loader';
import Button from 'components/button/Button';
import IKBNoticeCard from 'page/loyalty/ikb/components/cards/notice/IKBNoticeCard';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import { getLoyaltyErrorText } from 'utils/messageUtils';
import commonMessages from 'messages/CommonMessages';
import messages from './IKBServicesConfirmationMessages';
import styles from './IKBServicesConfirmation.module.css';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

const IKBServicesConfirmationPage: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { formatMessage } = useIntl();
    const categoriesData = useAppSelector((state) => categoriesDataSelector(state));
    const { selectedCashbackServices } = useAppSelector((state) => loyaltyIKBSelector(state));
    const { changeCategory } = useAppSelector((state) => loyaltyIKBSelector(state));
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const territory = useAppSelector((state) => currentTerritorySelector(state));
    const mainCategory = useAppSelector((state) => mainCategorySelector(state));
    const bonusStatus = useAppSelector((state) => cashbackStatusSelector(state));
    const { loading, error, success } = useAppSelector(() => changeCategory);

    useEffect(() => {
        if (selectedCashbackServices.length === 0) {
            history.back();
        }
        return () => {
            dispatch(clearChangedCategory());
        };
    }, []);

    useEffect(() => {
        if (success) {
            navigate('/loyalty');
        }
    }, [success]);

    const createNotice = (text: string) => {
        return <span key={text}>{text}</span>;
    };

    const modifyCategoryName = (value: string) => value?.replace(/ *\([^)]*\) */g, '')?.trim();

    const renderContent = () => {
        const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);

        if (loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        } else if (error) {
            return (
                <IKBNoticeCard
                    title={formatMessage(messages.requestError)}
                    description={getLoyaltyErrorText(intl, error.errorCode, error.message)}
                    icon={ikbLoyaltyService.getCashbackNoticeIcon()}
                />
            );
        } else if (selectedCashbackServices) {
            const activeCategory = categoriesData?.categories?.filter((category) => category?.favoriteProducts?.length > 0)[0];

            return (
                <div className={styles.card}>
                    <h5 className={styles.title}>{modifyCategoryName(activeCategory?.name)}</h5>
                    <div className={styles.products}>
                        {selectedCashbackServices.map((product) => (
                            <div className={styles.productItem} key={product.productId}>
                                <div className={styles.productTitle}>
                                    {formatMessage(commonMessages.numberFormat, {
                                        number: product.article
                                    })}{' '}
                                    — {product.shortName}
                                </div>
                                <div className={styles.productDiscount}>{product.charge}%</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.noticeCashback}>
                        <p>{formatMessage(commonMessages.otherServices)}</p>
                        <span>{ikbLoyaltyService.getBaseDiscountPercent()}%</span>
                    </div>
                    <div className={styles.noticeService}>
                        {formatMessage(messages.notice, {
                            span: () => createNotice(`${formatMessage(messages.amountDays, { amount: 30 })}`)
                        })}
                    </div>
                    <Button className={styles.confirmButton} onClick={() => handleConfirmButton()}>
                        {formatMessage(messages.confirmSelection)}
                    </Button>
                </div>
            );
        }

        return null;
    };

    const handleConfirmButton = () => {
        dispatch(
            change({
                patientId: selectedPatient.id || (searchParams.get('patientId') as string),
                territory: territory as string,
                categoryId: mainCategory?.categoryId || '',
                number: bonusStatus.number,
                favoriteProductIds: selectedCashbackServices.map((product) => product.productId)
            })
        );
    };

    return (
        <div>
            <IKBNavigationBar title={formatMessage(commonMessages.individualServices)} back={true} />
            <div className={styles.container}>{renderContent()}</div>
        </div>
    );
};

export default memo(IKBServicesConfirmationPage);
