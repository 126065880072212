import React, { FC, memo, useState, useEffect, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { setSaleAction } from 'redux/cart/actions';

import Input from 'components/input/Input';
import Button from 'components/button/Button';
import messages from './CertificatesMessages';
import commonMessages from 'messages/CommonMessages';
import Hint from 'components/hint/Hint';
import ButtonLinkSvg from 'components/button/link/svg/ButtonLinkSvg';
import closeSvg from 'page/orders/assets/componentImg/close.svg';

import { useAppDispatch, useAppSelector } from 'app/Hooks';
import {
    cartAnalyzesSelector,
    cartConfirmationErrorSelector,
    certificatesDataSelector,
    commonCalculationDataSelector,
    saleActionSelector
} from 'redux/cart/selectors';

import styles from './Certificates.module.css';
import cn from 'classnames/bind';
import AlertWarning from 'components/alert/AlertWarning';
import Cost from 'containers/const/Cost';
import { Certificate } from 'types/common';
import { clearDataState } from 'redux/orders/actions';
const classNames = cn.bind(styles);

type TProps = {
    handleCertificates: (data?: Certificate[]) => void;
    currency: string;
};

const Certificates: FC<TProps> = ({ handleCertificates, currency }) => {
    const dispatch = useAppDispatch();
    const { formatMessage } = useIntl();
    const saleAction = useAppSelector(saleActionSelector);
    const cartAnalyzes = useAppSelector(cartAnalyzesSelector);
    const cartConfirmationError = useAppSelector(cartConfirmationErrorSelector);
    const calculationData = useAppSelector(commonCalculationDataSelector);
    const certificatesData = useAppSelector(certificatesDataSelector);
    const [certificates, setCertificates] = useState<Certificate[]>([]);
    const [currentCertificate, setCurrentCertificate] = useState<string>('');
    const [notAppliedCurrentCertificate, setNotAppliedCurrentCertificate] = useState(false);

    useEffect(() => {
        setCertificates(certificatesData.filter((certificate: Certificate) => certificate.number) || []);
    }, [certificatesData]);

    useEffect(() => {
        const isAppliedCertificate = certificates?.find((el: Certificate) => el.number === currentCertificate)?.applied;
        if (isAppliedCertificate) {
            setCurrentCertificate('');
        }
    }, [certificates]);

    useEffect(() => {
        const getCurrentCertificate = certificates?.filter((el: Certificate) => el.number === currentCertificate)[0];
        if (!getCurrentCertificate?.applied && getCurrentCertificate?.number) {
            setNotAppliedCurrentCertificate(true);
        } else {
            setNotAppliedCurrentCertificate(false);
        }
        handleClearDataState();
    }, [certificates, currentCertificate]);

    const renderCertificates = () => {
        return certificates.map((certificate, index) => {
            const { number, applied, value, remainder, errorDescription } = certificate;

            return (
                number &&
                number !== currentCertificate && (
                    <div key={number} className={styles.appliedCertificates}>
                        <div className={styles.appliedCertificate}>
                            <div className={classNames({ certificate: true, inapplicable: !applied })}>
                                <Cost
                                    currency={currency}
                                    amount={value || 0}
                                    customFormatMessage={{ message: messages.certificateNumber, values: { certificate: number } }}
                                />
                            </div>
                            <ButtonLinkSvg className={styles.closeBtn} svg={`${closeSvg}#icon-close`} onClick={() => removeCertificate(index)} />
                        </div>
                        {applied && remainder && remainder > 0 ? (
                            <div className={styles.warning}>
                                <Cost
                                    currency={currency}
                                    amount={remainder || 0}
                                    customFormatMessage={{ message: messages.certificateWarning, values: { cost: remainder } }}
                                />
                            </div>
                        ) : null}
                        {!applied && (
                            <div className={styles.inapplicableHint}>
                                {formatMessage(commonMessages.certificateNotApplicable)}
                                {errorDescription && <Hint className={styles.hint}>{errorDescription}</Hint>}
                            </div>
                        )}
                    </div>
                )
            );
        });
    };

    const removeCertificate = (index: number) => {
        const data = [...certificates?.filter((_el, idx) => idx !== index)];
        handleCertificates(data);
    };

    const handleSetSaleAction = () => {
        const certificates = certificatesData.filter((certificate: Certificate) => certificate.number !== currentCertificate);
        dispatch(setSaleAction({ calculationData, data: { certificates } }));
    };

    const applyCertificate = () => {
        const data = [
            { number: currentCertificate },
            ...certificates
                ?.filter((certificate: Certificate) => certificate.number !== currentCertificate)
                .map((item: Certificate) => {
                    return {
                        number: item?.number
                    };
                })
        ];
        handleCertificates(data);
    };

    const renderInput = () => {
        const certificateIsPossible = certificatesData.filter((certificate: Certificate) => certificate.number === null)?.length > 0;

        return (
            certificateIsPossible && (
                <Input
                    type={'text'}
                    name={'certificate'}
                    value={currentCertificate}
                    placeholder={formatMessage(messages.certificatePlaceholder)}
                    className={classNames({ input: true, error: notAppliedCurrentCertificate })}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (!saleAction && event.target.value.length < 1) {
                            handleCertificates();
                        }
                        if (notAppliedCurrentCertificate) {
                            handleSetSaleAction();
                        }
                        setCertificates((prevState) => prevState?.filter((certificate: Certificate) => certificate.number !== currentCertificate));
                        setCurrentCertificate(event.target.value.replace(/\s/g, ''));
                    }}
                />
            )
        );
    };

    const renderError = (certificateNumber: string) => {
        const certificate = certificatesData.find((el: Certificate) => el.number === certificateNumber);

        if (!certificate?.applied && certificate?.errorDescription) {
            return <AlertWarning className={styles.errorText} content={certificate.errorDescription} />;
        }
    };

    const handleClearDataState = () => {
        if (cartConfirmationError) {
            dispatch(clearDataState());
        }
    };

    return (
        <Fragment>
            <div className={styles.wrapperForm}>
                {!cartAnalyzes.isPrepaid && (
                    <Fragment>
                        <div className={styles.labelWrapper}>
                            <label>{formatMessage(messages.formLabel)}</label>
                            <Hint className={styles.hint}>{formatMessage(messages.certificateHint)}</Hint>
                        </div>
                        {renderInput()}
                    </Fragment>
                )}
                {renderError(currentCertificate)}
            </div>
            {currentCertificate && <Button className={styles.applyButton} text={formatMessage(messages.applyCertificate)} onClick={applyCertificate} />}
            {certificatesData.length > 0 && <div>{renderCertificates()}</div>}
        </Fragment>
    );
};

export default memo(Certificates);
