export const OrderStatus = {
    /**
     * Предзаказ
     */
    PREORDER: 'PREORDER',

    /**
     * Новый
     */
    NEW: 'NEW',

    /**
     * Частично готов
     */
    PARTIAL: 'PARTIAL',

    /**
     * Готов
     */
    COMPLETED: 'COMPLETED',

    /**
     * Отменен
     */
    CANCELLED: 'CANCELLED',

    /**
     * Ошибка данных
     */
    ERROR_DATA: 'ERROR_DATA',

    /**
     * Не оплаченный предзаказ
     */
    NOT_PAID_PREORDER: 'NOT_PAID_PREORDER',

    /**
     * Оплаченный предзаказ
     */
    PAID_PREORDER: 'PAID_PREORDER',

    /**
     * Предзаказ, оплата которого происходит в данный момент
     */
    PAYMENT_IN_PROGRESS_PREORDER: 'PAYMENT_IN_PROGRESS_PREORDER'
};
