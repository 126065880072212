//@ts-nocheck
import React, { Component } from 'react';

import style from './InputBlock.module.css';
import cardStyle from '../CardForm.module.css';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon, Input } from 'page/cart/confirmation/cardForm/components/index';

export class InputBlock extends Component {
    static Input = Input;
    static Icon = Icon;

    constructor(props) {
        super(props);
    }

    render() {
        const { className, title, error } = this.props;

        return (
            <div className={classNames(className, { [cardStyle.error]: Boolean(error) })}>
                <h3 className={style.title}>{title}</h3>
                <div className={style.content}>{this.props.children}</div>
                {error && <span>{error}</span>}
            </div>
        );
    }
}

InputBlock.propTypes = {
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    error: PropTypes.string
};

InputBlock.Icon.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

InputBlock.Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    mask: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    input: PropTypes.shape({
        ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
        focused: PropTypes.bool,
        touched: PropTypes.bool
    }),
    onBlur: PropTypes.func,
    type: PropTypes.string
};
