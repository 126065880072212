import { defineMessages } from 'react-intl';

export default defineMessages({
    itemTitle: {
        id: 'OrderList.itemTitle',
        defaultMessage: 'Products:'
    },
    checkupTitle: {
        id: 'OrderList.checkupTitle',
        defaultMessage: 'Health programs:'
    }
});
