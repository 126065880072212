import React, { FC, memo, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import Card from 'page/auth/components/cards/Card';
import { ErrorResponse } from 'types/common';
import { useNavigate } from 'react-router-dom';
import commonMessages from 'messages/CommonMessages';

interface TProps {
    onClick?: () => void;
    statuses: number[];
    errors: ErrorResponse[];
    title?: { id: string; defaultMessage: string };
    textButton?: { id: string; defaultMessage: string };
    description?: { id: string; defaultMessage: string };
    children?: ReactElement | ReactElement[];
}

const ServerError: FC<TProps> = ({
    errors,
    statuses,
    children,
    title = commonMessages.serverErrorConflictTitle,
    description = commonMessages.serverErrorConflictDescription,
    textButton = commonMessages.buttonRepeat
}) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const isServerError = () => {
        const serverErrors: number[] = [];

        statuses.forEach((status) => {
            if (errors?.filter((error) => error?.status === status && !error?.attempts).length > 0) {
                serverErrors.push(status);
            }
        });

        return serverErrors.length > 0;
    };

    return isServerError() ? (
        <Card
            show={true}
            icon={'warning'}
            title={formatMessage(title)}
            onClick={() => navigate('/')}
            description={formatMessage(description)}
            textButton={formatMessage(textButton)}
        />
    ) : (
        children
    );
};

export default memo(ServerError);
