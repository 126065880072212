//@ts-nocheck
import * as codeClient from 'client/CodeClient';
import { all, put, takeLatest } from 'redux-saga/effects';
import { CLEAR_CONFIRMATION, CLEAR_REACTIVATION, CLEAR_RECOVERY, CLEAR_LOGIN } from 'redux/auth/actions';
import { request } from 'redux/helpers';
import { CLEAR, SEND } from 'redux/code/actions';

function* send(action) {
    yield put(CLEAR_CONFIRMATION.base());
    yield put(CLEAR_RECOVERY.base());
    yield put(CLEAR_REACTIVATION.base());
    yield put(CLEAR_LOGIN.base());
    yield request(SEND, [codeClient.send, action.meta], action.meta);
}

function* clear() {
    yield put(CLEAR.base());
}

export default function* codeSagas() {
    yield all([
        takeLatest(SEND.BASE, send),
        takeLatest(CLEAR_CONFIRMATION.BASE, clear),
        takeLatest(CLEAR_RECOVERY.BASE, clear),
        takeLatest(CLEAR_REACTIVATION.BASE, clear),
        takeLatest(CLEAR_LOGIN.BASE, clear)
    ]);
}
