import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'CardForm.title',
        defaultMessage: 'Card details'
    },
    cardNumber: {
        id: 'CardForm.cardNumber',
        defaultMessage: 'Card number'
    },
    cvv: {
        id: 'CardForm.cvv',
        defaultMessage: 'CVC/CVV'
    },
    expDate: {
        id: 'CardForm.expDate',
        defaultMessage: 'mm/year'
    },
    description: {
        id: 'CardForm.description',
        defaultMessage:
            'Payment is secure. We do not store card data; all transactions are handled by CloudPayments, a payment system certified to the international PCI DSS security standard.'
    },
    refundMessage: {
        id: 'CardForm.refundMessage',
        defaultMessage: 'To make a refund, please call {number}'
    },
    CardNumber_Empty: {
        id: 'CardForm.CardNumber_Empty',
        defaultMessage: 'Enter card number'
    },
    CardNumber_Length: {
        id: 'CardForm.CardNumber_Length',
        defaultMessage: 'Incomplete card number entered'
    },
    CardNumber_Invalid: {
        id: 'CardForm.CardNumber_Invalid',
        defaultMessage: 'Invalid card number entered'
    },
    ExpDateMonthYear_Empty: {
        id: 'CardForm.ExpDateMonthYear_Empty',
        defaultMessage: 'Enter expiration date'
    },
    ExpDateMonthYear_Invalid: {
        id: 'CardForm.ExpDateMonthYear_Invalid',
        defaultMessage: 'Enter the correct expiration date'
    },
    ExpDateMonth_Invalid: {
        id: 'CardForm.ExpDateMonth_Invalid',
        defaultMessage: 'Enter correct month in the date'
    },
    Cvv_Empty: {
        id: 'CardForm.Cvv_Empty',
        defaultMessage: 'Enter CVC/CVV code'
    },
    Cvv_Invalid: {
        id: 'CardForm.Cvv_Invalid',
        defaultMessage: 'CVC/CVV code is incomplete'
    },
    Unknown_error: {
        id: 'CardForm.Unknown_error',
        defaultMessage: 'Unknown error'
    },
    confirmButton: {
        id: 'ConfirmationBlock.confirmButton',
        defaultMessage: 'Confirm order'
    },
    payButton: {
        id: 'ConfirmationBlock.payButton',
        defaultMessage: 'Pay'
    },
    notice: {
        id: 'ConfirmationBlock.notice',
        defaultMessage: 'Authorization required'
    },
    yandexBtn: {
        id: 'ConfirmationBlock.yandexBtn',
        defaultMessage: 'Pay with'
    },
    mir: {
        id: 'ConfirmationBlock.mir',
        defaultMessage: 'MIR'
    },
    pci: {
        id: 'ConfirmationBlock.pci',
        defaultMessage: 'pci'
    },
    mastercard: {
        id: 'ConfirmationBlock.mastercard',
        defaultMessage: 'MasterCard'
    },
    visa: {
        id: 'ConfirmationBlock.visa',
        defaultMessage: 'Visa'
    }
});
