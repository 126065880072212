import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './NextChangeDaysMessages';
import styles from './NextChangeDays.module.css';

type TProps = {
    days: number;
};

const NextChangeDays: FC<TProps> = ({ days }) => {
    const { formatMessage } = useIntl();

    return (
        <span>
            {formatMessage(messages.nextChange, {
                text: <span className={styles.orangeDays}>{formatMessage(messages.nextChangeDays, { days })}</span>
            })}
        </span>
    );
};

export default memo(NextChangeDays);
