import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'CartAcceptedPage.pageTitle',
        defaultMessage: 'Thank you for the order'
    },
    documentNotice: {
        id: 'CartAcceptedPage.documentNotice',
        defaultMessage: 'To place this order, you must <span> present your passport  or a document replacing it</span>'
    },
    orderNumberLinkOffice: {
        id: 'CartAcceptedPage.orderNumberLinkOffice',
        defaultMessage: 'Your order #{number}'
    },
    orderAcceptedTitleOffice: {
        id: 'CartAcceptedPage.orderAcceptedTitleOffice',
        defaultMessage: 'Accepted'
    },
    orderAcceptedTitleHome: {
        id: 'CartAcceptedPage.orderAcceptedTitleHome',
        defaultMessage: 'for "Home visit" is accepted'
    },
    prepareLink: {
        id: 'CartAcceptedPage.prepareLink',
        defaultMessage: 'Preparation for analyzes'
    },
    printLink: {
        id: 'CartAcceptedPage.printLink',
        defaultMessage: 'Print order'
    },
    saveLink: {
        id: 'CartAcceptedPage.saveLink',
        defaultMessage: 'Save order'
    },
    prepareLinkUrl: {
        id: 'CartAcceptedPage.prepareLinkUrl',
        defaultMessage: 'https://www.invitro.ru/analizes/for-doctors/prepare/'
    },
    documentNote: {
        id: 'CartAcceptedPage.documentNote',
        defaultMessage: `* migration card, temporary registration at the place of residence, serviceman's certificate,
            certificate from the passport office in case of loss of passport, registration card from the hotel.
            The presented document must contain information about the temporary or
            permanent registration on the territory of the Russian Federation and a photograph.`
    },
    overdueNotice: {
        id: 'CartAcceptedPage.overdueNotice',
        defaultMessage:
            'Your order is available within 7 days up to and including {date}. To submit medical investigation chosen, visit a medical office by that date of the latest. Otherwise preorder will be automatically cancelled, and money will be returned.'
    },
    registrationCode: {
        id: 'CartAcceptedPage.registrationCode',
        defaultMessage: 'Registration code: {code}'
    },
    onlineRegistrationStep1: {
        id: 'CartAcceptedPage.onlineRegistrationStep1',
        defaultMessage: 'With the code received, go to the selected office'
    },
    onlineRegistrationStep2: {
        id: 'CartAcceptedPage.onlineRegistrationStep2',
        defaultMessage: 'Go to the online registration terminal at the Invitro office and enter the code'
    },
    onlineRegistrationStep3: {
        id: 'CartAcceptedPage.onlineRegistrationStep3',
        defaultMessage: 'After printing the documents, take them and go to the treatment room!'
    },
    office: {
        id: 'CartAcceptedPage.office',
        defaultMessage: 'Medical office'
    },
    phonesText: {
        id: 'CartAcceptedPage.phonesText',
        defaultMessage: 'Reference:'
    },
    phoneFree: {
        id: 'CartAcceptedPage.phoneFree',
        defaultMessage: 'Free call within Russia'
    },
    paymentError: {
        id: 'Payment.error',
        defaultMessage: 'Unaccepted'
    },
    paymentSuccess: {
        id: 'Payment.success',
        defaultMessage: 'Accepted'
    },
    paymentCompleted: {
        id: 'Payment.completed',
        defaultMessage: 'Paid'
    },
    paymentAwaiting: {
        id: 'Payment.awaiting',
        defaultMessage: 'In progress'
    },
    paymentRequestsLimit: {
        id: 'Payment.requestsLimit',
        defaultMessage: 'Number of requests exceeded'
    },
    paymentAwaitingMessage: {
        id: 'Payment.paymentAwaitingMessage',
        defaultMessage: 'We are waiting for payment confirmation from the bank. Please wait.'
    },
    paymentErrorMessage: {
        id: 'Payment.paymentErrorMessage',
        defaultMessage: 'An error occurred while making a payment.'
    }
});
