export const TerritoryType = {
    /**
     * РФ
     */
    RUSSIA: 'RUSSIA',

    /**
     * Крым
     */
    CRIMEA: 'CRIMEA',

    /**
     * РБ
     */
    BELARUS: 'BELARUS',

    /**
     * Казахстан
     */
    KAZAKHSTAN: 'KAZAKHSTAN',

    /**
     * Украина
     */
    UKRAINE: 'UKRAINE',

    /**
     * Армения
     */
    ARMENIA: 'ARMENIA',

    /**
     * Кыргызстан
     */
    KYRGYZSTAN: 'KYRGYZSTAN'
};
