/**
 * Перечисление типов операций
 */
export const IKBOperation = {
    /**
     * Отправить код подтвержения
     */
    SEND_ACTIVATION_PIN: 'SEND_ACTIVATION_PIN_REQUEST',

    /**
     * Активировать бонусный счет
     */
    BONUS_ACTIVATION: 'BONUS_ACTIVATION_REQUEST',

    /**
     * Найти доступные категории
     */
    FIND_CATEGORIES: 'FIND_CATEGORIES_REQUEST',

    /**
     * Сменить бонусную категорию
     */
    BONUS_CATEGORY_CHANGE: 'BONUS_CATEGORY_CHANGE_REQUEST'
};
