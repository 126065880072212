import React, { FC, Fragment, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Card from 'page/auth/components/cards/Card';
import Error from 'page/auth/components/error/Error';
import PageLayout from 'page/layout/PageLayout';
import styles from 'page/auth/Common.module.css';
import { resetAuthState } from 'redux/auth/actions';
import { clearAddResult } from 'redux/result/actions';
import { clearPatients } from 'redux/patient/actions';
import commonMessages from 'messages/CommonMessages';
import ServerError from 'page/auth/components/cards/ServerError';
import messages from 'page/add/AddPatientPage/AddPatientPageMessages';
import AlertWarning from 'components/alert/AlertWarning';
import { addResultSelector } from 'redux/result/selectors';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import ButtonLink from 'components/button/link/ButtonLink';
import { useLocation, useNavigate } from 'react-router-dom';
import AddResultDialog from 'page/partial/result/add/AddResultDialog';
import { INTERNAL_SERVER_ERROR, NOT_FOUND, WRONG_CREDENTIALS } from 'redux/auth/errorCodes';

const AddPatientPage: FC = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const addResultData = useAppSelector(addResultSelector);

    useEffect(() => {
        const { user } = location.state || {};
        if (!user) {
            navigate('/', { replace: true });
        }

        return () => {
            dispatch(clearPatients());
            dispatch(clearAddResult());
            dispatch(resetAuthState());
        };
    }, []);

    return (
        <PageLayout
            main={
                <div className={styles.main}>
                    <div className={styles.container}>
                        <ServerError errors={[addResultData.error]} statuses={[WRONG_CREDENTIALS]}>
                            <Fragment>
                                {!addResultData.success && (
                                    <Fragment>
                                        <AddResultDialog
                                            show={true}
                                            modal={false}
                                            title={formatMessage(commonMessages.addPatient)}
                                            description={formatMessage(messages.description)}
                                            renderError={() => {
                                                const status = addResultData?.error?.status;

                                                if (status === NOT_FOUND) {
                                                    return <AlertWarning className={styles.alertWarning} content={formatMessage(messages.patientNotFound)} />;
                                                }
                                                if (addResultData.errors?.phone || addResultData.errors?.email) {
                                                    return (
                                                        <AlertWarning
                                                            className={styles.alertWarning}
                                                            content={formatMessage(messages.addPatientFillEmailAndPhone)}
                                                        />
                                                    );
                                                }
                                                if (status === INTERNAL_SERVER_ERROR) {
                                                    return (
                                                        <AlertWarning
                                                            className={styles.alertWarning}
                                                            content={formatMessage(commonMessages.INTERNAL_SERVER_ERROR)}
                                                        />
                                                    );
                                                } else {
                                                    return <Error error={addResultData?.error} timeToResend={true} />;
                                                }
                                            }}
                                        />
                                        <ButtonLink onClick={() => navigate('/')} className={styles.buttonLink}>
                                            {formatMessage(commonMessages.addLater)}
                                        </ButtonLink>
                                    </Fragment>
                                )}
                                <Card
                                    icon={'success'}
                                    show={addResultData.success}
                                    onClick={() => navigate('/')}
                                    title={formatMessage(messages.successTitle)}
                                    textButton={formatMessage(commonMessages.buttonContinue)}
                                />
                            </Fragment>
                        </ServerError>
                    </div>
                </div>
            }
        />
    );
};

export default memo(AddPatientPage);
