//@ts-nocheck
import apiClient from 'client/ApiClient';
import { BASE_URL } from 'client/constants';
import queryString from 'query-string';

const REORDERS_URL = `${BASE_URL}/reorders`;

export const fetchAvailableReorder = (orderId, officeId, config) => {
    return apiClient.get(`${REORDERS_URL}?${queryString.stringify({ orderId, officeId })}`, config);
};

export const createReorder = (data) => {
    return apiClient.post(`${REORDERS_URL}/create`, data);
};
