import { createRequestTypes } from 'redux/helpers';

export const FETCH_CHECKUPS = createRequestTypes('CHECKUPS/FETCH_CHECKUPS');
export const ACTIVATE_CHECKUP = createRequestTypes('CHECKUPS/ACTIVATE_CHECKUP');
export const CLEAR_ACTIVATE_CHECKUP = createRequestTypes('CHECKUPS/CLEAR_ACTIVATE_CHECKUP');
export const CLEAR_ALL_CHECKUPS = createRequestTypes('CHECKUPS/CLEAR_ALL_CHECKUPS');

export const fetchCheckups = (payload?: { patientId: string; territory: string }) => FETCH_CHECKUPS.base(payload);
export const activateCheckup = (payload: {}) => ACTIVATE_CHECKUP.base(payload);
export const clearActivateCheckup = () => CLEAR_ACTIVATE_CHECKUP.base();
export const clearAllCheckup = () => CLEAR_ALL_CHECKUPS.base();
