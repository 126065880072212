import React, { FC, Fragment, memo, ReactNode, useContext, useEffect } from 'react';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import commonMessages from 'messages/CommonMessages';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import { FormattedMessage, useIntl } from 'react-intl';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import { setCashbackType, fetchCategories } from 'redux/loyaltyIKB/actions';
import styles from './style.module.css';
import cart from './assets/cart.svg';
import calendar from './assets/calendar.svg';
import infoIcon from './assets/infoIcon.svg';
import messages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
// @ts-ignore
import { MediaContext } from 'react-media-query-hoc';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import RegularPageCard from 'page/loyalty/ikb/regular/RegularPageCard/RegularPageCard';
import { categoriesSelector, fetchCategoriesSelector } from 'redux/loyaltyIKB/selectors';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import { currentTerritorySelector } from 'redux/cities/selectors';
import Loader from 'components/loader/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/Hooks';

const IKBRegularPage: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const media = useContext(MediaContext);
    const [searchParams] = useSearchParams();
    const territory = useAppSelector((state) => currentTerritorySelector(state));
    const categories = useAppSelector((state) => categoriesSelector(state));
    const fetchCategoriesData = useAppSelector((state) => fetchCategoriesSelector(state));
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(setCashbackType(ikbCashbackType.REGULAR_CASHBACK));
        if (categories.length === 0) {
            dispatch(
                fetchCategories({
                    patientId: searchParams.get('patientId'),
                    territory,
                    positionFrom: 0,
                    positionTo: 99999
                })
            );
        }
    }, []);

    const renderContent = () => {
        const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
        const regularCategory = ikbLoyaltyService.getRegularCategory(categories);

        if (fetchCategoriesData && fetchCategoriesData.loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        }

        return (
            <Fragment>
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <IKBCashbackTypesButtons />
                </IKBNavigationBar>
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <IKBNotification />
                        <section>
                            <div className={styles.detailsContainer}>
                                <div className={styles.detailsCard}>
                                    <img src={cart} alt='cart' />
                                    <p>
                                        <FormattedMessage
                                            {...messages.cardInfo}
                                            values={{
                                                b: (v) => <b>{v}</b>
                                            }}
                                        />
                                    </p>
                                </div>
                                <div className={styles.detailsCard}>
                                    <img src={calendar} alt='calendar' />
                                    <p>{formatMessage(messages.cardInfo2)}</p>
                                </div>
                            </div>
                            <div className={styles.featureContainer}>
                                {regularCategory?.percents?.map((category, i) => (
                                    <RegularPageCard key={i} index={i} {...category} />
                                ))}
                            </div>
                            <div className={styles.infoContainer}>
                                {
                                    // @ts-ignore
                                    !media.mobile && <img src={infoIcon} alt='infoIcon' />
                                }
                                <div>
                                    {formatMessage(messages.info, {
                                        p: (value: ReactNode) => <p>{value}</p>
                                    })}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <CategoriesPanel
                    categories={[{ name: formatMessage(commonMessages.regularCashback) }]}
                    maxCountSelected={1}
                    cashbackType={ikbCashbackType.REGULAR_CASHBACK}
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/regular/confirm?patientId=${searchParams.get('patientId')}`)}
                />
            </Fragment>
        );
    };

    return renderContent();
};

export default memo(IKBRegularPage);
