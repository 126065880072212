import React, { FC, memo } from 'react';

const IvitroLogo: FC = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='186.731px' height='42px' id='svg2'>
            <defs id='defs4'>
                <clipPath id='clipPath3011'>
                    <path d='m 0,121.89 282.334,0 L 282.334,0 0,0 0,121.89 z' id='path3013' />
                </clipPath>
            </defs>
            <g transform='matrix(0.720567, 0, 0, 0.720567, -157.782623, -333.779602)' id='layer1'>
                <g transform='translate(-26.428572,-39.999997)' id='layer1-0' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                    <g transform='matrix(1.25,0,0,-1.25,198.96962,608.11993)' id='g2993' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                        <g transform='translate(117.6254,83.2795)' id='g2995' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                            <path
                                d='m 0,0 -10.269,0 -9.041,-45.344 10.266,0 L 0,0 z'
                                id='path2997'
                                style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                            />
                        </g>
                        <g transform='translate(104.0214,83.2795)' id='g2999' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                            <path
                                d='M 0,0 -10.267,0 -29.393,-31.473 -35.427,0 l -10.269,0 8.692,-45.344 9.456,0 L 0,0 z'
                                id='path3001'
                                style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                            />
                        </g>
                        <g transform='translate(156.0492,83.2795)' id='g3003' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                            <path
                                d='m 0,0 -33.537,0 -1.779,-9.523 11.667,0 -7.141,-35.821 10.269,0 7.143,35.821 11.604,0 L 0,0 z'
                                id='path3005'
                                style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                            />
                        </g>
                        <g id='g3007' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                            <g clipPath='url(#clipPath3011)' id='g3009' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                                <g transform='translate(180.475,83.2795)' id='g3015' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                                    <path
                                        d='m 0,0 -18.84,0 -9.046,-45.344 10.273,0 7.144,35.821 8.88,0 c 2.055,0 3.734,-0.667 4.826,-1.794 0.962,-0.988 1.472,-2.481 1.325,-3.988 -0.435,-4.554 -4.014,-6.654 -8.672,-6.654 l -7.495,0 9.651,-23.385 10.269,0 -6.414,15.541 c 7.797,1.251 12.657,6.989 13.037,14.731 C 15.366,-6.288 8.898,0 0,0'
                                        id='path3017'
                                        style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                                    />
                                </g>
                                <g transform='translate(234.9125,60.6052)' id='g3019' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                                    <path
                                        d='M 0,0 C 0.471,7.727 -4.985,14.172 -13.229,14.172 -21.46,14.172 -27.56,7.861 -28.038,0 c -0.468,-7.727 4.983,-14.171 13.223,-14.171 8.24,0 14.336,6.315 14.815,14.171 m -37.588,0 c 0.742,12.816 11.297,23.316 24.983,23.316 C 1.078,23.316 10.301,12.946 9.55,0 8.807,-12.818 -1.747,-23.314 -15.438,-23.314 c -13.682,0 -22.9,10.367 -22.15,23.314'
                                        id='path3021'
                                        style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                                    />
                                </g>
                                <g transform='translate(54.5629,73.7917)' id='g3023' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                                    <path
                                        d='m 0,0 1.892,9.488 -10.27,0 L -10.27,0 0,0 z'
                                        id='path3025'
                                        style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                                    />
                                </g>
                                <g transform='translate(43.4784,69.7068)' id='g3027' style={{ fill: '#0097a9', fillOpacity: 1 }}>
                                    <path
                                        d='m 0,0 -6.335,-31.771 10.272,0 L 10.271,0 0,0 z'
                                        id='path3029'
                                        style={{ fill: '#0097a9', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none' }}
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default memo(IvitroLogo);
