import React, { FC, Fragment, memo, useEffect } from 'react';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import { useIntl } from 'react-intl';
import { changeCategory } from 'redux/loyaltyIKB/actions';
import styles from './style.module.css';
import Button from 'components/button/Button';
import { selectedPatientSelector } from 'redux/patient/selectors';
import messages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
import commonMessages from 'messages/CommonMessages';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import RegularPageConfirmCard from 'page/loyalty/ikb/regular/RegularPageConfirmCard/RegularPageConfirmCard';
import { useAppDispatch, useAppSelector } from 'app/Hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { cashbackStatusSelector, categoriesSelector, loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';

const IKBRegularPageConfirm: FC = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [searchParams] = useSearchParams();
    const selectedPatient = useAppSelector((state) => selectedPatientSelector(state));
    const bonusStatus = useAppSelector((state) => cashbackStatusSelector(state));
    const loyaltyIKB = useAppSelector((state) => loyaltyIKBSelector(state));
    const territory = useAppSelector((state) => currentTerritorySelector(state));
    const { loading, success } = useAppSelector(() => loyaltyIKB.changeCategory);
    const categories = useAppSelector((state) => categoriesSelector(state));

    const ikbLoyaltyService = new IKBLoyaltyService(null, intl);
    const regularCategory = ikbLoyaltyService.getRegularCategory(categories);

    useEffect(() => {
        if (!regularCategory) {
            navigate(-1);
        }
    }, []);

    useEffect(() => {
        if (success) {
            navigate('/loyalty');
        }
    }, [success]);

    const handleConfirmButton = (categoryId: string) => {
        dispatch(
            changeCategory({
                patientId: selectedPatient?.id || (searchParams.get('patientId') as string),
                territory,
                categoryId,
                number: bonusStatus.number
            })
        );
    };

    return (
        <Fragment>
            <IKBNavigationBar title={formatMessage(messages.title)} back={true} />
            <div className={styles.confirmPageWrapper}>
                <div className={styles.container}>
                    <section>
                        <div className={styles.cardContainer}>
                            <div className={styles.cardContent}>
                                {regularCategory?.percents?.map((category, i) => (
                                    <RegularPageConfirmCard key={i} message={category.message} percent={category.percent} />
                                ))}
                            </div>
                            <div className={styles.cardDivider}></div>
                            <div className={styles.cardConfirmInfo}>
                                <p>{formatMessage(messages.nextChangesAvailable, { mark: (v: any) => <mark>{v}</mark> })}</p>
                            </div>
                            <div className={styles.cardFooter}>
                                <Button disabled={loading} onClick={() => handleConfirmButton(regularCategory?.category.categoryId)}>
                                    {formatMessage(commonMessages.buttonConfirmSelection)}
                                </Button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    );
};

export default memo(IKBRegularPageConfirm);
