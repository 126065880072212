//@ts-nocheck
import { defineMessages } from 'react-intl';
import regularMessages from 'page/loyalty/ikb/regular/IKBRegularPage.messages';
import { isAfter } from 'date-fns';

import { SUPPORTED_TERRITORIES } from 'redux/cities/constants';
import cashbackIcon from 'page/loyalty/ikb/components/assets/cashbackIcon.svg';
import cashbackIconT from 'page/loyalty/ikb/components/assets/cashbackIconT.svg';
import cashbackIconBr from 'page/loyalty/ikb/components/assets/cashbackIconBr.svg';
import cashbackIconDram from 'page/loyalty/ikb/components/assets/cashbackIconDram.svg';
import cashbackIconSom from 'page/loyalty/ikb/components/assets/cashbackIconSom.svg';

import cashbackNoticeIcon from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIcon.svg';
import cashbackNoticeIconT from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconT.svg';
import cashbackNoticeIconBr from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconBr.svg';
import cashbackNoticeIconDram from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconDram.svg';
import cashbackNoticeIconSom from 'page/loyalty/ikb/solutionsConfirmation/assets/cashbackNoticeIconSom.svg';

import activatedCashbackIcon from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIcon.svg';
import activatedCashbackIconT from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconT.svg';
import activatedCashbackIconBr from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconBr.svg';
import activatedCashbackIconDram from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconDram.svg';
import activatedCashbackIconSom from 'page/loyalty/ikb/cashback/assets/activated/activatedCashbackIconSom.svg';

import cashbackOrange from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackorange.svg';
import cashbackRed from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackred.svg';
import cashbackBlue from 'page/loyalty/ikb/regular/assets/RegularPageCard/cashbackblue.svg';

const messages = defineMessages({
    description3: {
        id: 'IKBLoyaltyService.description3',
        defaultMessage: '1 bonus ruble is equal to 1 ruble'
    },
    kazakhstanDescription3: {
        id: 'IKBLoyaltyService.kazakhstanDescription3',
        defaultMessage: '1 bonus tenge is equal to 1 tenge'
    },
    kyrgyzstanDescription3: {
        id: 'IKBLoyaltyService.kyrgyzstanDescription3',
        defaultMessage: '1 bonus som equals 1 som'
    },
    armeniaDescription3: {
        id: 'IKBLoyaltyService.armeniaDescription3',
        defaultMessage: '1 bonus dram equals 1 dram'
    },
    bonusGiftInfo: {
        id: 'IKBLoyaltyService.bonusGiftInfo',
        defaultMessage: 'bonus rubles as a gift'
    },
    kazakhstanBonusGiftInfo: {
        id: 'IKBLoyaltyService.kazakhstanBonusGiftInfo',
        defaultMessage: 'bonus tenge as a gift'
    },
    kyrgyzstanBonusGiftInfo: {
        id: 'IKBLoyaltyService.kyrgyzstanBonusGiftInfo',
        defaultMessage: 'bonus soms as a gift'
    },
    armeniaBonusGiftInfo: {
        id: 'IKBLoyaltyService.armeniaBonusGiftInfo',
        defaultMessage: 'bonus drams as a gift'
    },
    description4: {
        id: 'IKBLoyaltyService.description4',
        defaultMessage: 'Write off bonuses for any procedures'
    },
    kazakhstanDescription4: {
        id: 'IKBLoyaltyService.kazakhstanDescription4',
        defaultMessage: 'Bonus tenge can be used for laboratory diagnostics services'
    },
    belarusDescription4: {
        id: 'IKBLoyaltyService.belarusDescription4',
        defaultMessage: 'Bonus roubles can be used for laboratory diagnostics services'
    },
    kyrgyzstanDescription4: {
        id: 'IKBLoyaltyService.kyrgyzstanDescription4',
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection'
    },
    armeniaDescription4: {
        id: 'IKBLoyaltyService.armeniaDescription4',
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection'
    },
    crimeaDescription4: {
        id: 'IKBLoyaltyService.crimeaDescription4',
        defaultMessage: 'Write off bonuses for laboratory diagnosis and biomaterial collection'
    },
    cardTitle: {
        id: 'IKBLoyaltyService.cardTitle',
        defaultMessage: '{balance} b. r.'
    },
    kazakhstanCardTitle: {
        id: 'IKBLoyaltyService.kazakhstanCardTitle',
        defaultMessage: '{balance} b. t.'
    },
    kyrgyzstanCardTitle: {
        id: 'IKBLoyaltyService.kyrgyzstanCardTitle',
        defaultMessage: '{balance} b.s.'
    },
    armeniaCardTitle: {
        id: 'IKBLoyaltyService.armeniaCardTitle',
        defaultMessage: '{balance} b.d.'
    },
    motivationTextTooltip: {
        id: 'IKBLoyaltyService.cashbackMotivationTextTooltip',
        defaultMessage: 'We give you {bonusValue} bonus rubles'
    },
    kazakhstanMotivationTextTooltip: {
        id: 'IKBLoyaltyService.kazakhstanMotivationTextTooltip',
        defaultMessage: 'We give you {bonusValue} bonus tenge'
    },
    kyrgyzstanMotivationTextTooltip: {
        id: 'IKBLoyaltyService.kyrgyzstanMotivationTextTooltip',
        defaultMessage: 'We give you {bonusValue} bonus soms'
    },
    armeniaMotivationTextTooltip: {
        id: 'IKBLoyaltyService.armeniaMotivationTextTooltip',
        defaultMessage: 'We give you {bonusValue} bonus drams'
    },
    motivationTextCard: {
        id: 'IKBLoyaltyService.cashbackMotivationTextCard',
        defaultMessage: 'After activating the account, you will receive {bonusValue}'
    },
    featureText: {
        id: 'IKBLoyaltyService.featureText',
        defaultMessage: 'Register for the loyalty program "Healthy Cashback" and get {bonusValue} bonus points'
    }
});

const Program = {
    RUSSIA: {
        baseDiscountPercent: 3,
        maxWriteOffPercent: 50,
        giftBonusSize: 200,
        description3: messages.description3,
        description4: messages.description4,
        bonusGiftInfo: messages.bonusGiftInfo,
        noticeLink: 'invitro.ru',
        currencySign: '₽',
        cardTitle: messages.cardTitle,
        cashbackIcon: cashbackIcon,
        cashbackNoticeIcon: cashbackNoticeIcon,
        activatedCashbackIcon: activatedCashbackIcon,
        motivationTextTooltip: messages.motivationTextTooltip,
        motivationTextCard: messages.motivationTextCard,
        featureText: messages.featureText
    },

    CRIMEA: () => {
        return {
            ...Program.RUSSIA,
            description4: messages.crimeaDescription4,
            noticeLink: 'ivitro.su'
        };
    },

    KAZAKHSTAN: {
        baseDiscountPercent: 3,
        maxWriteOffPercent: 90,
        giftBonusSize: 500,
        description3: messages.kazakhstanDescription3,
        description4: messages.kazakhstanDescription4,
        bonusGiftInfo: messages.kazakhstanBonusGiftInfo,
        noticeLink: 'invitro.kz',
        currencySign: '₸',
        cardTitle: messages.kazakhstanCardTitle,
        cashbackIcon: cashbackIconT,
        cashbackNoticeIcon: cashbackNoticeIconT,
        activatedCashbackIcon: activatedCashbackIconT,
        motivationTextTooltip: messages.kazakhstanMotivationTextTooltip,
        motivationTextCard: messages.motivationTextCard,
        featureText: messages.featureText
    },

    BELARUS: {
        baseDiscountPercent: 3,
        maxWriteOffPercent: 30,
        giftBonusSize: 5,
        description3: messages.description3,
        description4: messages.belarusDescription4,
        bonusGiftInfo: messages.bonusGiftInfo,
        noticeLink: 'invitro.by',
        currencySign: 'Br',
        cardTitle: messages.cardTitle,
        cashbackIcon: cashbackIconBr,
        cashbackNoticeIcon: cashbackNoticeIconBr,
        activatedCashbackIcon: activatedCashbackIconBr,
        motivationTextTooltip: messages.motivationTextTooltip,
        motivationTextCard: messages.motivationTextCard,
        featureText: messages.featureText
    },

    KYRGYZSTAN: {
        baseDiscountPercent: 3,
        maxWriteOffPercent: 50,
        giftBonusSize: 250,
        description3: messages.kyrgyzstanDescription3,
        description4: messages.kyrgyzstanDescription4,
        bonusGiftInfo: messages.kyrgyzstanBonusGiftInfo,
        noticeLink: 'invitro.kg',
        currencySign: 'с',
        cardTitle: messages.kyrgyzstanCardTitle,
        cashbackIcon: cashbackIconSom,
        cashbackNoticeIcon: cashbackNoticeIconSom,
        activatedCashbackIcon: activatedCashbackIconSom,
        motivationTextTooltip: messages.kyrgyzstanMotivationTextTooltip,
        motivationTextCard: messages.motivationTextCard,
        featureText: messages.featureText
    },

    ARMENIA: {
        baseDiscountPercent: 3,
        maxWriteOffPercent: 50,
        giftBonusSize: 1000,
        description3: messages.armeniaDescription3,
        description4: messages.armeniaDescription4,
        bonusGiftInfo: messages.armeniaBonusGiftInfo,
        noticeLink: 'invitromed.am',
        currencySign: '֏',
        cardTitle: messages.armeniaCardTitle,
        cashbackIcon: cashbackIconDram,
        cashbackNoticeIcon: cashbackNoticeIconDram,
        activatedCashbackIcon: activatedCashbackIconDram,
        motivationTextTooltip: messages.armeniaMotivationTextTooltip,
        motivationTextCard: messages.motivationTextCard,
        featureText: messages.featureText
    }
};

class IKBLoyaltyService {
    constructor(territory, intl) {
        this.territory = territory;
        this.intl = intl;
    }

    getBaseDiscountPercent() {
        const program = getProgram(this.territory);
        return program.baseDiscountPercent;
    }

    getMaxWriteOffPercent() {
        const program = getProgram(this.territory);
        return program.maxWriteOffPercent;
    }

    getGiftBonusSize() {
        const program = getProgram(this.territory);
        return program.giftBonusSize;
    }

    getNoticeLink() {
        const program = getProgram(this.territory);
        return program.noticeLink;
    }

    getDescription3Message() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.description3);
    }

    getDescription4Message() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.description4);
    }

    getBonusGiftInfoMessage() {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.bonusGiftInfo);
    }

    getCurrencySign() {
        const program = getProgram(this.territory);
        return program.currencySign;
    }

    getCardTitleMessage(balance) {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.cardTitle, balance);
    }

    getCashbackIcon() {
        const program = getProgram(this.territory);
        return program.cashbackIcon;
    }

    getCashbackNoticeIcon() {
        const program = getProgram(this.territory);
        return program.cashbackNoticeIcon;
    }

    getActivatedCashbackIcon() {
        const program = getProgram(this.territory);
        return program.activatedCashbackIcon;
    }

    getMotivationTextTooltip(bonusValue) {
        const program = getProgram(this.territory);
        return this.intl.formatMessage(program.motivationTextTooltip, bonusValue);
    }

    getMotivationTextCard(bonusValue) {
        const program = getProgram(this.territory);
        return `${this.intl.formatMessage(program.motivationTextCard, bonusValue)} ${this.intl.formatMessage(program.bonusGiftInfo)}`;
    }

    getFeatureText(bonusValue) {
        const program = getProgram(this.territory);
        return `${this.intl.formatMessage(program.featureText, bonusValue)}`;
    }

    getRegularCategory(categories) {
        const category = categories?.find((c) => c.multiplicator && (isAfter(new Date(c.validDate), new Date(Date.now())) || !c.validDate));

        if (!category) return null;

        return {
            category,
            percents: [
                {
                    percent: category?.thirdOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 3 }),
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 3 }),
                    icon: cashbackRed
                },
                {
                    percent: category?.secondOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 2 }),
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 2 }),
                    icon: cashbackOrange
                },
                {
                    percent: category?.firstOrderCashback,
                    title: this.intl.formatMessage(regularMessages.featuredCardTitle, { count: 1 }),
                    message: this.intl.formatMessage(regularMessages.purchasesPerMonth, { count: 1 }),
                    icon: cashbackBlue
                }
            ]
        };
    }
}

export default IKBLoyaltyService;

function getProgram(territory) {
    switch (territory) {
        case SUPPORTED_TERRITORIES.RUSSIA:
            return Program.RUSSIA;
        case SUPPORTED_TERRITORIES.CRIMEA:
            return Program.CRIMEA();
        case SUPPORTED_TERRITORIES.KAZAKHSTAN:
            return Program.KAZAKHSTAN;
        case SUPPORTED_TERRITORIES.BELARUS:
            return Program.BELARUS;
        case SUPPORTED_TERRITORIES.KYRGYZSTAN:
            return Program.KYRGYZSTAN;
        case SUPPORTED_TERRITORIES.ARMENIA:
            return Program.ARMENIA;
        default:
            return {};
    }
}
