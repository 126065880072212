import React, { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import ButtonLinkSvg from 'components/button/link/svg/ButtonLinkSvg';
import commonMessages from 'messages/CommonMessages';
import styles from 'components/view/ButtonView.module.css';
import viewSvg from 'components/view/view.svg';

type TProps = {
    text: string;
    onClick: () => void;
};

const ButtonView: FC<TProps> = ({ text, ...rest }) => {
    const { formatMessage } = useIntl();
    return <ButtonLinkSvg className={styles.button} svg={`${viewSvg}#icon-view`} text={text || formatMessage(commonMessages.view)} {...rest} />;
};

export default memo(ButtonView);
