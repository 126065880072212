import React, { Fragment, FC, useEffect, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import styles from './IKBServiceDetailsPage.module.css';
import { loyaltyIKBSelector, servicesSelector } from 'redux/loyaltyIKB/selectors';
import { searchCashbackServices } from 'redux/loyaltyIKB/actions';
import { useAppDispatch, useAppSelector } from 'app/Hooks';

const IKBServiceDetailsPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { productId } = useParams();
    const { searchString } = useAppSelector((state) => loyaltyIKBSelector(state));
    const services = useAppSelector((state) => servicesSelector(state));

    useEffect(() => {
        if (services && services.length <= 0) {
            navigate('/loyalty');
        }
    }, []);

    useEffect(() => {
        return () => {
            if (searchString.length > 0) {
                dispatch(searchCashbackServices({ services, param: searchString }));
            }
        };
    }, []);

    const renderContent = () => {
        const service = services.filter((service) => service.productId === productId)[0];

        return (
            <div>
                {service && (
                    <Fragment>
                        <IKBNavigationBar title={`№ ${service.article}`} back={true} />
                        <div className={styles.container}>
                            <div className={styles.info}>
                                <h3 className={styles.description}>{service.name}</h3>
                                <p className={styles.detailedDescription} dangerouslySetInnerHTML={{ __html: service.description }} />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    };

    return renderContent();
};

export default memo(IKBServiceDetailsPage);
