//@ts-nocheck
import { differenceInDays } from 'utils/timeUtils';
import LocalStorageService from 'services/LocalStorageService';
import IKBTooltipMenu from 'constants/ikbTooltipMenu';
import IKBTooltipConstants from 'constants/ikbTooltipMenu';

export function getDaysToChanges(newChangeDate) {
    return differenceInDays(new Date(newChangeDate), new Date()) + 1;
}

export function checkShowTooltipMenu(patientId) {
    const tooltipSelectedPatient = getTooltipSelectedPatient(patientId);
    const passedDays = differenceInDays(new Date(tooltipSelectedPatient?.showTooltip), new Date());
    return passedDays >= IKBTooltipMenu.HIDE_FOR_DAYS || tooltipSelectedPatient?.showTooltip === undefined;
}

export function checkBlockTooltipMenu(patientId) {
    const tooltipSelectedPatient = getTooltipSelectedPatient(patientId);
    return tooltipSelectedPatient?.showTooltip === false;
}

function getTooltipSelectedPatient(patientId) {
    const tooltipDataStorage = JSON.parse(LocalStorageService.getItem(IKBTooltipConstants.LOCAL_STORAGE_KEY)) || [];
    return tooltipDataStorage.filter((item) => item.patientId === patientId)[0];
}
