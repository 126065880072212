//@ts-nocheck
import React, { Component } from 'react';
import cn from 'classnames';

import styles from './CardForm.module.css';
import cvvIcon from 'components/icon/svg/cvvIcon.svg';

import { cardFormConfig, INPUT_ERRORS_CODES, INPUTS_LIST } from 'page/cart/confirmation/cardForm/cardForm.config';
import { cardValidation } from 'page/cart/confirmation/cardForm/cardForm.validations';

import { InputBlock } from 'page/cart/confirmation/cardForm/components/InputBlock';
import { PaymentSystems } from 'page/cart/confirmation/cardForm/components/PaymentSystems';
import Actions from 'page/cart/confirmation/cardForm/components/ActionsBlock';
import FormField from 'components/form/field/FormField';
import Input from 'components/input/Input';
import FormHelper from 'utils/FormHelper';
import { validateNotBlank, validateEmail } from 'utils/validators';

import { injectIntl } from 'react-intl';
import messages from 'page/cart/confirmation/cardForm/cardForm.messages';
import commonMessages from 'messages/CommonMessages';

class CardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                cvv: '',
                cardNumber: '',
                expDate: ''
            },
            inputErrors: {
                cvv: '',
                cardNumber: '',
                expDate: ''
            },
            cpErrors: {},
            email: '',
            errors: {}
        };

        this.inputs = {
            cardNumber: {
                ref: React.createRef(),
                focused: false,
                touched: false
            },
            expDate: {
                ref: React.createRef(),
                focused: false,
                touched: false
            },
            cvv: {
                ref: React.createRef(),
                focused: false,
                touched: false
            }
        };

        this.handleFormEdit = this.handleFormEdit.bind(this);
        this.onBlurValidation = this.onBlurValidation.bind(this);
        this.setState = this.setState.bind(this);

        const validators = {
            email: (email) => validateNotBlank(email) && validateEmail(email)
        };

        this.formHelper = new FormHelper(this, validators);
    }

    handleFormEdit(key, value) {
        const { inputErrors, cpErrors } = this.state;

        if (inputErrors[key] || cpErrors[key]) {
            this.setState((prevState) => ({
                ...prevState,
                inputErrors: {
                    ...prevState.inputErrors,
                    [key]: ''
                },
                cpErrors: {
                    ...prevState.cpErrors,
                    [key]: ''
                }
            }));
        }

        const valueLength = value.replaceAll(' ', '').replace('/', '').length;

        switch (key) {
            case INPUTS_LIST.CARD_NUMBER:
                if (valueLength === 16 && !this.inputs[key].touched) {
                    this.inputs.expDate.ref.current.focus();
                } else {
                    this.inputs.cardNumber.ref.current.setSelectionRange(valueLength, valueLength);
                }
                break;
            case INPUTS_LIST.EXP_DATE:
                if (!inputErrors.expDate && valueLength === 4) {
                    this.inputs.cvv.ref.current.focus();
                }
        }

        this.setState((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [key]: value
            }
        }));
    }

    onBlurValidation(key, event) {
        const valueWithoutSpace = event.target.value.replaceAll(' ', '');

        const validator = cardValidation(valueWithoutSpace);

        switch (key) {
            case INPUTS_LIST.CARD_NUMBER:
                try {
                    validator.number.empty();
                    validator.number.length();
                } catch (e) {
                    this.setState((prevState) => ({
                        ...prevState,
                        inputErrors: {
                            ...prevState.inputErrors,
                            [key]: e.message
                        }
                    }));
                }
                break;
            case INPUTS_LIST.EXP_DATE:
                try {
                    validator.date.empty();

                    const yearWithZero = validator.date.addZero();

                    if (yearWithZero) {
                        this.setState((prevState) => ({
                            ...prevState,
                            formData: {
                                ...prevState.formData,
                                [key]: yearWithZero
                            }
                        }));
                    }

                    cardValidation(yearWithZero || valueWithoutSpace).date.min();
                    cardValidation(yearWithZero || valueWithoutSpace).date.betweenMonth();
                } catch (e) {
                    this.setState((prevState) => ({
                        ...prevState,
                        inputErrors: {
                            ...prevState.inputErrors,
                            [key]: e.message
                        }
                    }));
                }
                break;
            case INPUTS_LIST.CVV:
                try {
                    validator.cvv.empty();
                    validator.cvv.min();
                } catch (e) {
                    this.setState((prevState) => ({
                        ...prevState,
                        inputErrors: {
                            ...prevState.inputErrors,
                            [key]: e.message
                        }
                    }));
                }
                break;
        }
    }

    render() {
        const { intl, cart, confirmable } = this.props;

        const getTranslatedError = (key) => {
            const { inputErrors, cpErrors } = this.state;

            if (!cpErrors[key] && !inputErrors[key]) {
                return;
            }

            if (!inputErrors[key] && !Object.keys(INPUT_ERRORS_CODES).includes(cpErrors[key])) {
                return intl.formatMessage(messages['Unknown_error']);
            }

            return intl.formatMessage(messages[cpErrors[key] || inputErrors[key]]);
        };

        return (
            <>
                {(!cart.hasAppliedCertificates || (cart.hasAppliedCertificates && !cart.isPrepaid)) && (
                    <>
                        <h2 className={styles.title}>{intl.formatMessage(messages.title)}</h2>
                        <form className={styles.form} autoComplete={'off'}>
                            {Object.values(INPUTS_LIST).map((key) => (
                                <InputBlock key={key} className={styles[key]} title={intl.formatMessage(messages[key])} error={getTranslatedError(key)}>
                                    <InputBlock.Input
                                        onChange={(e) => this.handleFormEdit(key, e.target.value)}
                                        value={this.state.formData[key]}
                                        input={this.inputs[key]}
                                        onBlur={(e) => this.onBlurValidation(key, e)}
                                        {...cardFormConfig[key]}
                                    />
                                    {key === INPUTS_LIST.CVV && <InputBlock.Icon src={cvvIcon} alt={'cvv-icon'} />}
                                </InputBlock>
                            ))}
                        </form>
                        {confirmable.hasEmail && (
                            <FormField
                                className={cn(styles.email, { [styles.error]: this.state.errors.email })}
                                label={intl.formatMessage(commonMessages.emailForCheck)}
                            >
                                <Input
                                    name='email'
                                    placeholder={intl.formatMessage(commonMessages.requiredField)}
                                    defaultValue={this.state.email}
                                    onChange={(event) => {
                                        this.formHelper.handleValueChange(event);
                                    }}
                                    error={this.state.errors.email}
                                    className={cn(styles.inputEmail, { [styles.error]: this.state.errors.email })}
                                />
                                {this.state.errors.email && <span className={styles.textError}>{intl.formatMessage(commonMessages.enterEmail)}</span>}
                            </FormField>
                        )}
                        <PaymentSystems intl={intl} />
                    </>
                )}
                <Actions
                    cartOrderType={this.props.cartOrderType}
                    email={this.state.email}
                    emailError={this.state.errors}
                    intl={intl}
                    setState={this.setState}
                    formData={this.state.formData}
                    inputErrors={this.state.inputErrors}
                    onConfirmation={this.props.onConfirmation}
                />
            </>
        );
    }
}

export default injectIntl(CardForm);
