import { defineMessages } from 'react-intl';

export default defineMessages({
    orderContentsLinkShow: {
        id: 'ProductList.orderContentsLinkShow',
        defaultMessage: 'Show order contents'
    },
    orderContentsLinkHide: {
        id: 'ProductList.orderContentsLinkHide',
        defaultMessage: 'Hide order contents'
    },
    reorderContentsLinkShow: {
        id: 'ProductList.reorderContentsLinkShow',
        defaultMessage: 'Show reorder contents'
    },
    reorderContentsLinkHide: {
        id: 'ProductList.reorderContentsLinkHide',
        defaultMessage: 'Hide reorder contents'
    }
});
