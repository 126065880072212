//@ts-nocheck
import queryString from 'query-string';
import { ADDITIONAL_PHONE, EMAIL, EMAIL_2, EMAIL_3, MOBILE_PHONE, WORK_PHONE } from 'page/patients/constants/constants';
import { age, parseISO } from 'utils/timeUtils';

export function fullName(patient) {
    return [patient.lastName, patient.firstName, patient.middleName].filter((value) => value).join(' ');
}

export function isAdult(patient) {
    return patient && patient.birthday && age(parseISO(patient.birthday)) >= 18;
}

export function getOptions(patient) {
    const options = [];

    if (!patient) {
        return options;
    }

    const { email } = patient;
    if (email) {
        options.push({ value: email, label: email, type: EMAIL });
    }
    const { email2 } = patient;
    if (email2) {
        options.push({ value: email2, label: email2, type: EMAIL_2 });
    }
    const { email3 } = patient;
    if (email3) {
        options.push({ value: email3, label: email3, type: EMAIL_3 });
    }
    const { mobilePhone } = patient;
    if (mobilePhone) {
        options.push({ value: mobilePhone, label: mobilePhone, type: MOBILE_PHONE });
    }
    const { workPhone } = patient;
    if (workPhone) {
        options.push({ value: workPhone, label: workPhone, type: WORK_PHONE });
    }
    const { additionalPhone } = patient;
    if (additionalPhone) {
        options.push({ value: additionalPhone, label: additionalPhone, type: ADDITIONAL_PHONE });
    }

    return options;
}

export function getPhones(patient, typePhone?) {
    return getOptions(patient)
        .filter((option) => {
            if (typePhone) {
                return option.type === typePhone;
            } else {
                return option.type === MOBILE_PHONE || option.type === WORK_PHONE || option.type === ADDITIONAL_PHONE;
            }
        })
        .map((option) => option.value);
}

export function updateSelected(location, selected, select, dispatch?) {
    const query = queryString.parse(location.search);
    if (query.patientId) {
        dispatch ? dispatch(select(query.patientId)) : select(query.patientId);
    } else {
        if (selected) {
            dispatch ? dispatch(select(selected.id)) : select(selected.id);
        }
    }
}
