//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import closeIcon from 'page/layout/assets/closeIcon.svg';
import tooltipIcon from 'page/layout/assets/tooltipIcon.svg';
import styles from './IKBTooltipMenu.module.css';
import LocalStorageService from 'services/LocalStorageService';
import { selectedPatientSelector } from 'redux/patient/selectors';
import { currentTerritorySelector } from 'redux/cities/selectors';
import { checkShowTooltipMenu, checkBlockTooltipMenu } from 'utils/cashbackUtils';
import IKBTooltipConstants from 'constants/ikbTooltipMenu';
import IKBLoyaltyService from 'services/IKBLoyaltyService';
import { FETCH_MOTIVATING_TOOLTIP } from 'redux/loyaltyIKB/actions';
import classNames from 'classnames/bind';
import withRouter from 'adapters/WithRouter';

const IKBTooltipMenu = ({ intl, selected, territory, showMotivatingTooltip, statusMotivatingTooltip, fetchMotivatingTooltip }) => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const iconRef = useRef(null);

    const setTooltipStorage = (patientId, param) => {
        const tooltipDataStorage = JSON.parse(LocalStorageService.getItem(IKBTooltipConstants.LOCAL_STORAGE_KEY)) || [];
        LocalStorageService.setItem(
            IKBTooltipConstants.LOCAL_STORAGE_KEY,
            JSON.stringify([
                ...tooltipDataStorage.filter((item) => item.patientId !== patientId),
                {
                    patientId,
                    showTooltip: param
                }
            ])
        );
    };

    const initTooltip = () => {
        if (checkShowTooltipMenu(selected?.id)) {
            setTimeout(() => {
                setShow(true);
                setTimeout(() => {
                    setShow(false);
                    setTooltipStorage(selected?.id, new Date());
                }, 8000);
            }, 3000);
        }
    };

    const onClose = () => {
        setShow(false);
        setTooltipStorage(selected?.id, false);
    };

    useEffect(() => {
        if (showMotivatingTooltip) {
            initTooltip();
        }
    }, [showMotivatingTooltip, selected?.id]);

    useEffect(() => {
        if (selected?.id) {
            fetchMotivatingTooltip({ patientId: selected.id, territory });
        }
    }, [selected?.id]);

    useEffect(() => {
        if (statusMotivatingTooltip.success && showMotivatingTooltip === true) {
            initTooltip();
        }
    }, [statusMotivatingTooltip, showMotivatingTooltip]);

    useEffect(() => {
        if (location.pathname === IKBTooltipConstants.PATH) {
            onClose();
        }
    }, [location]);

    const ikbLoyaltyService = new IKBLoyaltyService(territory, intl);

    if (!showMotivatingTooltip || checkBlockTooltipMenu(selected?.id)) {
        return null;
    }

    return (
        <div className={styles.container}>
            {!checkBlockTooltipMenu(selected?.id) && (
                <div className={classNames(styles.content, show ? styles.show : styles.hide)}>
                    <p>{ikbLoyaltyService.getMotivationTextTooltip({ bonusValue: ikbLoyaltyService.getGiftBonusSize() })}</p>
                    <img onClick={onClose} className={styles.closeIcon} alt='' src={closeIcon} />
                </div>
            )}
            <img ref={iconRef} alt='' className={styles.icon} src={tooltipIcon} />
        </div>
    );
};

const mapStateToPropsContent = (state) => {
    const selected = selectedPatientSelector(state);
    return {
        selected,
        territory: currentTerritorySelector(state),
        showMotivatingTooltip: state.loyaltyIKB.showMotivatingTooltip,
        statusMotivatingTooltip: state.loyaltyIKB.fetchMotivatingTooltip
    };
};

const mapDispatchToProps = {
    fetchMotivatingTooltip: FETCH_MOTIVATING_TOOLTIP.base
};

export default withRouter(injectIntl(connect(mapStateToPropsContent, mapDispatchToProps)(IKBTooltipMenu)));
