import { defineMessages } from 'react-intl';

export default defineMessages({
    accrued: {
        id: 'IKBBonusHistoryRecord.accrued',
        defaultMessage: 'Accrued:'
    },
    writeOff: {
        id: 'IKBBonusHistoryRecord.writeOff',
        defaultMessage: 'Written Off:'
    },
    paid: {
        id: 'IKBBonusHistoryRecord.paid',
        defaultMessage: 'Paid:'
    },
    order: {
        id: 'IKBBonusHistoryRecord.order',
        defaultMessage: 'Order №{number}'
    },
    orderReturn: {
        id: 'IKBBonusHistoryRecord.orderReturn',
        defaultMessage: 'Order return №{number}'
    }
});
