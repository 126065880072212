import mir from './mir.svg';
import pci from './pci.svg';
import mastercard from './mastercard.svg';
import visa from './visa.svg';

export const paymentSystemsIcons = [
    {
        id: 1,
        src: mir,
        alt: 'mir'
    },
    {
        id: 2,
        src: pci,
        alt: 'pci'
    },
    {
        id: 3,
        src: mastercard,
        alt: 'mastercard'
    },
    {
        id: 4,
        src: visa,
        alt: 'visa'
    }
];
