import React, { Fragment, FC, useEffect, memo } from 'react';
import classNames from 'classnames';
import { FormattedDate, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { currentTerritorySettingsSelector } from 'redux/startup/selectors';
import { fetchCategories, selectCashbackSolution, setCashbackType, searchCashbackServices } from 'redux/loyaltyIKB/actions';
import { solutionsSelector, fetchCategoriesSelector, categoriesSelector, loyaltyIKBSelector } from 'redux/loyaltyIKB/selectors';
import Switch from 'components/switch/Switch';
import IKBNavigationBar from 'page/loyalty/ikb/components/navigationBar/IKBNavigationBar';
import IKBCashbackTypesButtons from 'page/loyalty/ikb/components/cashbackTypesButtons/IKBСashbackTypesButtons';
import { ikbCashbackType } from 'constants/ikbCashbackType';
import commonMessages from 'messages/CommonMessages';
import messages from 'page/loyalty/ikb/solutions/IKBSolutionsPageMessages';
import styles from './IKBSolutionsPage.module.css';
import IKBNotification from 'page/loyalty/ikb/components/notification/IKBNotification';
import CategoriesPanel from 'page/loyalty/ikb/components/CategoriesPanel';
import Loader from 'components/loader/Loader';
import { IKBSolution } from 'types/common';
import { useAppDispatch, useAppSelector } from 'app/Hooks';

const IKBSolutionsPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { territory } = useAppSelector((state) => currentTerritorySettingsSelector(state));
    const { formatMessage } = useIntl();
    const solutions = useAppSelector((state) => solutionsSelector(state));
    const { selectedCashbackSolutions } = useAppSelector((state) => loyaltyIKBSelector(state));
    const categories = useAppSelector((state) => categoriesSelector(state));
    const fetchCategoriesData = useAppSelector((state) => fetchCategoriesSelector(state));

    useEffect(() => {
        dispatch(setCashbackType(ikbCashbackType.READY_SOLUTIONS));
        if (categories.length === 0) {
            dispatch(
                fetchCategories({
                    patientId: searchParams.get('patientId'),
                    territory,
                    positionFrom: 0,
                    positionTo: 99999
                })
            );
        } else if (solutions && solutions.length <= 0) {
            navigate('/loyalty');
        }
    }, [solutions]);

    useEffect(() => {
        return () => {
            dispatch(searchCashbackServices({ services: [], param: '' }));
        };
    }, []);

    const handleSelectCashbackSolution = (solution: IKBSolution, isSelectedSolution: boolean) => {
        if (isSelectedSolution && selectedCashbackSolutions.length >= 1) {
            return;
        }
        return dispatch(
            selectCashbackSolution({
                solution,
                selected: isSelectedSolution
            })
        );
    };

    const renderContentColumns = (solutions: IKBSolution[]) => {
        return solutions.map((solution) => {
            const isSelectedSolution = selectedCashbackSolutions.filter((selectedSolution) => solution.categoryId === selectedSolution.categoryId).length > 0;
            return (
                <div
                    className={classNames({
                        [styles.card]: true,
                        [styles.selectedSolution]: isSelectedSolution
                    })}
                    key={solution.categoryId}
                >
                    <div className={styles.infoBlock}>
                        <div className={styles.topBlock}>
                            <h3 className={styles.title} onClick={() => handleSelectCashbackSolution(solution, isSelectedSolution)}>
                                {solution.name}
                            </h3>
                            <div className={styles.solutionSwitch}>
                                <Switch active={isSelectedSolution} onClick={() => handleSelectCashbackSolution(solution, isSelectedSolution)} />
                            </div>
                        </div>
                        {solution.validDate && (
                            <div className={styles.solutionExpire}>
                                {formatMessage(messages.solutionExpire)}{' '}
                                <FormattedDate value={new Date(solution.validDate)} day='numeric' month='long' year='numeric' />
                            </div>
                        )}
                    </div>
                    <div className={styles.products}>
                        {solution.categoryProducts &&
                            solution.categoryProducts.map((product) => (
                                <div className={styles.productItem} key={product.productId}>
                                    <Link className={styles.productTitle} to={`/loyalty/ikb/cashback/solutions/product/${product.productId}`}>
                                        {product.shortName}
                                    </Link>
                                    <div className={styles.productDiscount}>{product.charge}%</div>
                                </div>
                            ))}
                    </div>
                    {solution.charge && (
                        <div className={styles.notice}>
                            <div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: formatMessage({ id: 'Common.otherProductsNotice' })
                                    }}
                                />{' '}
                                {solution.charge}%
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    const renderCards = () => {
        return (
            <div className={styles.cards}>
                {solutions.length > 1 && (
                    <Fragment>
                        <div className={styles.leftColumn}>{renderContentColumns(solutions.filter((item, i) => i % 2 === 0 && item))}</div>
                        <div className={styles.rightColumn}>{renderContentColumns(solutions.filter((item, i) => i % 2 > 0 && item))}</div>
                    </Fragment>
                )}
                {solutions.length === 1 && renderContentColumns(solutions)}
            </div>
        );
    };

    const renderContent = () => {
        if (fetchCategoriesData && fetchCategoriesData.loading) {
            return (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            );
        }

        return (
            <Fragment>
                <IKBNavigationBar title={formatMessage(commonMessages.increaseCashback)} back={true}>
                    <IKBCashbackTypesButtons />
                </IKBNavigationBar>
                <div className={styles.container}>
                    <IKBNotification />
                    {renderCards()}
                </div>
                <CategoriesPanel
                    categories={selectedCashbackSolutions}
                    onConfirm={() => navigate(`/loyalty/ikb/cashback/solutions/confirmation?patientId=${searchParams.get('patientId')}`)}
                    maxCountSelected={1}
                    cashbackType={ikbCashbackType.READY_SOLUTIONS}
                />
            </Fragment>
        );
    };

    return renderContent();
};

export default memo(IKBSolutionsPage);
