//@ts-nocheck
import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import FormHelper from 'utils/FormHelper';
import Input from 'components/input/Input';

import styles from './SearchComponent.module.css';
import iconSearch from 'page/orders/assets/componentImg/iconSearch.svg';

export default class SearchComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            search: props.value,
            errors: {}
        };
        this.formHelper = new FormHelper(this, {});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setState({ search: this.props.value });
        }
    }

    render() {
        return (
            <div className={classNames(styles.container, this.props.className)}>
                <Input
                    name='search'
                    className={styles.searchInput}
                    placeholder={this.props.placeholder}
                    value={this.state.search}
                    onChange={(event) => {
                        if (this.props.clearReset && event.target.value === '') {
                            this.setState({ search: '' }, () => {
                                this.props.onChange({
                                    target: {
                                        name: this.props.name,
                                        value: this.state.search
                                    }
                                });
                            });
                        } else {
                            this.formHelper.handleValueChange(event);
                        }
                    }}
                    onKeyUp={(event) => this.handleKeyUp(event)}
                />
                <button onClick={() => this.onClick()} className={styles.searchBtn}>
                    <svg className={styles.searchBtnIcon}>
                        <use xlinkHref={`${iconSearch}#icon-search`} />
                    </svg>
                </button>
                {this.props.clearIcon && this.state.search?.length > 0 && this.props.clearIcon}
            </div>
        );
    }

    handleKeyUp(event) {
        if (event.keyCode === 13) {
            this.onClick();
        }
    }

    onClick() {
        if (this.props.value !== this.state.search) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: this.state.search
                }
            });
        }
    }
}

SearchComponent.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string
};
